import {Translate_en} from "./Translate_en";

export class Translate_ru extends Translate_en{
    "Select scenes to export" = "Для экспорты выберите сцены"
    "Exporting a map window"="Экспорт окна карты"
    "ISO-XML (file for technology)"="ISO-XML (файл для техники)"
    "Fertilizer"="Удобрение"
    "Create an area"="Создать область"
    "File in .geojson format, EPSG:4326"="Файл в формате .geojson, EPSG:4326"
    "File name"="Название файла"
    "User doesn't exist" ="Пользователь не зарегистрирован"
    "User isn't valid" = "Пользователь не активирован"
    "Project doesn't exist or was deleted" = "Проект не существует или был удален"
    "You don't have access to perform this operation" = "У вас нет прав для настройки доступа"

    "Search by name or email"="Поиск по имени или Email"
    "Sum of active (daily average > 10°)"="Сумма активных (среднесуточная > 10°)"
    "Sum of active (daily average > 5°)"="Сумма активных (среднесуточная > 5°)"

    "To move the map while drawing, hold down the key"="Для передвижения карты во время рисования удерживайте клавишу"
    "To quickly open this window, click"="Чтобы быстро открыть это окно, нажмите"
    "Visualization"="Визуализация"
    "Delete object"="Удаление объекта"
    "Edit object"="Редактирование объекта"
    "Action type"="Тип действия"
    "Date / time"="Дата / время"
    "Create the request"="Создание запроса"
    "Download the report"="Скачивание запроса"
    "Changing the contours of the ACS"="Изменение контуров ОКС"
    "History of user actions"="История действий пользователя"
    "pcs"="шт."
    "Merge/Add patch"="Прирастить/Добавить \"заплатку\""
    "Split parts"="Разрезать на части"
    "Add hole"="Добавить дырку"
    "Delete part"="Удалить часть"
    "Delete all"="Удалить все"

    "Map window export" = "Экспорт окна карты"
    "Search"="Поиск"
    "Fatal error"="Ошибка"
    "Canceled"="Отменён"
    "Deleted"="Удалён"
    "Group order"="Порядок группировки"
    "Categories"="Категории"
    "Save for current session only"="Сохранить только для текущей сессии"
    "Groups"="Группы"
    "lat, lon:"="ш. д.:"
    "(geojson, kml, shp in zip)" = "(geojson, kml, shp в архиве )"
    "To download Sentinel-1 images go to the {0} tab in the sidebar"="Чтобы скачать снимки Sentinel-1, откройте вкладку {0} в сайдбаре"
    "For export, set the active area" = "Для экспорта задайте активную область"
    more = "больше"
    less = "меньше"
    or = "или"
    "Not field!"="Не поле!"
    "Change name"="Изменить название"
    "Project settings"="Настройки проекта"
    "Order type"="Тип заказа"
    "Merge by dates"="Объединить по датам"
    "Merge scenes into single dates within current map extent"="Объединить сцены по датам в пределах окна карты"
    "cells"="ячейки"
    "double click"="двойной клик"
    "pictures"="снимков"
    "charts"="графиков"
    "fields"="полей"
    "photos"="снимков"
    "Remaining for the period:"="Осталось за период:"
    "Period start date:"="Дата начала периода:"
    "Period end date:"="Дата окончания периода:"
    "Turn on Sentinel-2 scene"="Включите снимок Sentinel-2"
    "Merge scenes from single path"="Склеивать сцены из одного пролёта"
    "Manager"="Менеджер"
    "Аdding a comment"="Добавление комментария"
    "Editing a comment"="Редактирование комментария"
    "Unlimit"="Безлимит"
    "Limit"="Лимит"
    "Subscription"="Подписка"
    "Comment"="Комментарий"
    "Search by name, email, comments"="Поиск по имени, Email, комментариям"
    "Search by title or owner"="Поиск по названию или владельцу"
    "Admin Panel"="Панель администратора"
    "Project owner"="Владелец проекта"
    "Confirmation does not match password"="Подтверждение не совпадает с паролем"
    "Password has been changed"="Пароль был изменён"
    "Change password"="Изменить пароль"
    "Current password"="Текущий пароль"
    "New password again"="Новый пароль еще раз"
    "Change personal data"="Изменить персональные данные"
    "Firstname"="Имя"
    "Surname"="Фамиля"
    "Current period"="Текущий период"
    "Personal data"="Персональные данные"
    "General information"="Общая информация"
    "Personal Are"="Личный кабинет"
    "Average rate"="Средняя норма"
    "Total application volume"="Общий объем внесения"
    "Total area"="Общая площадь"
    "Pixel Size (m)"="Размер пиксела (м)"
    "Exporting a map by spectral indices"="Экспорт карты по спектральным индексам"
    "Geometry type"="Тип геометрии"
    "Zones"="Зоны"
    "Grid"="Грид"
    "File format"="Формат файла"
    "Export only visible classes"="Экспортировать только видимые классы"
    "Cut visible classes from active area"="Вырезать видимые классы из активной области"
    "Split by objects"="Разбить по полям"
    "Attention! The selected objects will be permanently removed from the project with all information attached"="Внимание! Выбранные объекты будут безвозвратно удалены из проекта со всей прикрепленной информацией"
    "Nesting"="Вложенность"
    Format="Формат"
    object_create='Создать объект'
    object_creation='Создание объекта'
    object_editing='Редактирование объекта'
    objects_editing='Редактирование объектов'
    object_border='Контур объекта'

    "Are you sure you want to delete the user?"="Вы уверены, что хотите удалить пользователя?"
    "Project type"="Тип проекта"
    "Change permissions"="Изменить права доступа"
    "Are you sure you want to delete the project and all attached data?"="Вы уверены, что хотите удалить проект и все вложенные данные?"
    "Project {0} has been deleted"="Проект {0} был удалён"
    "sortByName"="По названию"
    "sortByDate"="По дате"
    "User Email"="Email пользователя"
    "Adding a new user"="Добавление нового пользователя"
    "Access rights"="Права доступа"
    "User access privileges"="Права доступа пользователей"
    "Add user"="Добавить пользователя"
    "View"="Просмотр"
    "Edit"="Редактирование"
    "EditTo"="Редактировать"
    "Agro"="Агро"
    "Agricultural insurance"="Агрострахование"
    "Parcel assess"="Залоги"
    "pnt_yld_assess"=""
    "Show only current objects on map"="Оставить на карте только текущие объекты"
    "Show all objects on map"="Показывать на карте все объекты"
    "Revert to default"="Вернуть по умолчанию"
    "Daily precipitation"="Суточные осадки"
    "Accumulated precipitation"="Накопленные осадки"
    "Daily radiation"="Суточная радиация"
    "Accumulated radiation"="Накопленная радиация"

    "Accumulated" = "Накопленные"
    "Daily min, max" = "Суточная мин.-макс."
    "Daily minimum" = "Суточная минимальная"
    "Daily maximum" = "Суточная максимальная"
    "Help"="Справка"
    "NOAA (since 2015)"="NOAA (с 2015 г.)"
    "ERA5 (since 2010)"="ERA5 (с 2010 г.)"
    "Sentinel-1 IW"="Радиолокационная съемка IW"
    "Sentinel-1 EW"="Радиолокационная съемка EW"
    "Maximum number of points: {0}"="Максимальное количество точек: {0}"
    "From 1 to 175 (comma separated)"="От 1 до 175 (разделенных запятой)"
    "Relative orbit"="Относительная орбита"
    "To combine scenes from the same date, create a hotspot at the bottom of the Image search options panel {0}"="Чтобы объединить сцены за одну дату, создайте активную область в нижней части панели параметров поиска снимков {0}"
    "Charts"="Графики"
    "Points"="Точки"
    "Satellites"="Спутники"
    "Sources"="Источники"
    "Compare sources"="Сравнить источники"
    "Select source"="Выбор источника"
    "Spectral indices"="Спектральные индексы"
    "Solar radiation"="Солнечная радиация"
    "Relative Humidity"="Относительная влажность"
    "Space photography"="Космическая съемка"
    "Weather data"="Метео данные"
    "Category"="Категория"

    "Maximum number of objects in active area: {0}"= "Маскимальное число объектов в активной области: {0}"
    "Show vertices"="Показать вершины"
    "Selected"="Выделено"
    "Convert spectral bands to albedo (0-1)"="Привести спектральные каналы к альбедо (0-1)"
    "Combine products into single TIFF"="Записать продукты в один TIFF"
    "Cancel active area"="Отменить активную область"
    "Downloading" = "Загрузка";
    "Export" = "Экспорт";
    "ExportTo" = "Экспортировать";
    "Select file format to export"="Выберите формат файла для экспорта";
    "Select the attributes of your table from the uploaded file that you want to import and match them to the characteristics of your objects"="Выберите атрибуты вашей таблицы из загружаемого файла, которые вы хотите импортировать, и соотнесите их с характеристиками ваших объектов";
    "Objects not selected"="Объекты не выбраны"
    "Import from file"="Импортировать из файла";
    "Project structure"="Структура проекта";
    "is not a number"="не является числом";
    "Not set"="Не задано";
    "Removed successfully"="Успешно удалено";
    "Different values"= "Несколько значений";
    "Not valid value" = "Неправильное значение";
    "Field {0} must have only one of the existing values." = "Поле {0} должно иметь только одно из существующих значений.";
    "Required field not filled: {0}"="Не заполнено обязательное поле: {0}";
    "Total"="Всего";
    "Season search"="Сезонный поиск";
    "Specialty"="Особенность";
    "Don't import"="Не импортировать"
    "Area (ha)"="Площадь (га)"
    "Saved successfully"="Успешно сохранено";
    "Remove all"="Удалить все"
    "Pixel value"="Значение в пикселе"
    "Permalink"="Постоянная ссылка"
    "Layers"="Слои"
    "Measure"="Измерить"
    "Soil grid"="Карты почв"
    "Order"="Заказать"
    "Orders"="Заказы"
    "Selected images"="Выбранные снимки"
    "Item has been removed"="Элемент был удалён"
    "Are you sure you want to delete this field(s)?"="Вы действительно хотите удалить это поле?"
    "Equal area"="Равные площади"
    "Min-Max"="Мин - Макс"
    "Calc. by active area"="Рассчитать по области"
    "Gradient" = "Градиент"
    "Discrete"="Разбить на классы"
    "Active area is defined"="Есть активная область"
    "Active area created" = "Создана активная область"
    "Active zone created" = "Создана активная зона"
    "Actions with selected objects" = "Действия с выделенными объектами"
    "Selected objects to active area" = "Выделенные объекты в активную область"
    "Transform to active area" = "Перевести в активную область"
    "Too large area for preview"="Слишком большой контур для превью"
    "Mosaic"="Мозаика"
    "The password must contain at least 8 characters, consist of letters of the Latin alphabet (A-z), Arabic numbers (0-9) and special characters."="Пароль должен содержать как минимум 8 символов, состоять из букв латинского алфавита (A-z), арабских цифр (0-9) и специальных символов."
    "Password changed successfully"="Пароль успешно изменен"
    "New password"="Новый пароль"
    "A link to reset your password has been sent to your email."="Ссылка для восстановления пароля отправлена вам на Email"
    "Interval (days)"="Интервал (дней)"
    "Opacity"="Непрозрачность"
    "Min. quality"="Мин. качество"
    "< 100m"="< 100м"
    "The newest"="Самые новые"
    "Takes the newest among valid pixels"="Использование самых свежих валидных пикселей"
    "The oldest"="Самые старые"
    "Takes the oldest among valid pixels"="Использование самых старых валидных пикселей"
    "Maximum"="Максимум"
    "Calculates maximum among valid pixels"="Использование максимального значения среди валидных пикселей"
    "Minimum"="Минимум"
    "Calculates minimum among valid pixels"="Использование минимального значения среди валидных пикселей"
    "Mean"="Среднее"
    "Calculates mean among valid pixels"="Использование среднеарифметического значения среди валидных пикселей"
    "Median"="Медиана"
    "Calculates median among valid pixels"="Использование медианного значения среди валидных пикселей"
    "Root mean square"="Корень среднеквадратичного"
    "Calculates root mean square among valid pixels"="Использование корня среднеквадратичного значения среди валидных пикселей"

    "Analysis objects" = "Объекты анализа"
    Method="Метод"
    December="Декабрь"
    November="Ноябрь"
    October="Октябрь"
    September="Сентябрь"
    August="Август"
    July="Июль"
    June="Июнь"
    May="Май"
    April="Апрель"
    March="Март"
    February="Февраль"
    January="Январь"
    "Month" = "Месяц"
    "Period" = "Период"
    "RSHB" = "РСХБ"
    "Create the first request" = "Создайте первый запрос"
//    "Sentinel-5" = ""
    ObjectsFields = "Объекты"
    Found = "Найдено"
    Meteo = "Метео"
    "Search options" = "Параметры поиска"
    "ha" = "га"
    "Sign in via"="Войти через"
    "Already have an account?"="Уже есть аккаунт?"
    "Privacy Policy"="Политика конфиденциальности"
    "Terms of Use"="Условия эксплуатации"
    "By clicking on the \"Sign up\" button, I accept the {0} and {1} and consent to the processing of personal data, as well as to receive advertising and other types of letters associated with my account" = "Нажимая кнопку \"Зарегистрироваться\", я принимаю {0} и {1} и даю согласие на обработку персональных данных, а также на получение рекламных и других типов писем, связанных с моей учетной записью."
    "Confirm password"="Ещё раз пароль"
    "Email is also your login"="Email также является вашим логином"
    "Second name"="Фамилия"
    "First name"="Имя"
    "Password mismatch"="Несоответствие пароля"
    "Please specify password"="Укажите пароль"
    "Please specify valid email"="Укажите действующий адрес электронной почты"
    "Please specify second name"="Укажите второе имя"
    "Please specify first name"="Пожалуйста, укажите имя"
    "Sign up"="Регистрация"
    "Not yet with us?"="Еще не с нами?"
    "Log in"="Войти"
    Password="Пароль"
    "Login or Email"="Логин или Email"
    "Sign in"="Войти"
    "About CLASS"="О CLASS"
    Welcome="Добро пожаловать"
    "Send"="Выслать"
    "Forgot password"="Восстановление пароля"
    "Enter the email address to which we will send you an email with a link to create a new password"="Введите адрес электронной почты, на который мы отправим вам письмо со ссылкой для создания нового пароля"
    "Security code is incorrect or has expired"="Код безопасности неправильный или истек срок его действия"
    "Your mail has been verified, you can now log in"="Ваша почта подтверждена, теперь вы можете войти в систему"
    "Mail confirmed"="Почта подтверждена"
    "Sending confirmation..."="Отправка подтверждения..."
    "Registration was successful, an email was sent to your mail with a confirmation link"="Регистрация прошла успешно, на вашу почту было отправлено письмо со ссылкой на подтверждение"
    "Congratulations!"="Поздравляем!"
    Status="Статус"
    Role="Роль"
    "Last enter"="Последнее обращение"
    "User name"="Имя пользователя"
    Users="Пользователи"
    "Active"="Активен"
    "Blocked"="Заблокирован"
    "Guest"="Гость"
    "User"="Пользователь"
    "Administrator"="Администратор"
    "Copied!"="Скопировано!"
    Legend="Легенда"
    "Select year"="Выберите год"
    "Climate"="Климат"
    Description="Описание"
    "Are you sure to delete all points?"="Вы уверены, что удалите все точки?"
    Index="Индекс"
    "Radius (m)"="Радиус (м)"
    "Use the cursor {0} to place a point on the map where you would like to calculate the data type"="С помощью курсора {0} поставьте точку на карте, в том месте, в котором вы бы хотели произвести расчет типа данных"
    "Polygon is not included in the spatial filter of images search"="Полигон не попадает в пространственный фильтр поиска снимков"
    "The point is not included in the spatial filter of image search" = "Точка не попадает в пространственный фильтр поиска снимков"
    Mode="Режим"
    "Search: coordinates, OSM objects"="Поиск по координатам и OSM"
    "Get object profile"="Получит профиль объекта"
    "Get point profile"="Получить профиль в точке"
    "Copy coordinates"="Копировать координаты"
    "Get geometries"="Получить геометрии в точке"
    // "© Copyright"=""
    "File loaded. {0} objects have been created."="Файл загружен. Создано {0} объектов."
    "Specify mapping from columns of the input file into the properties of your fields"="Выберите атрибуты вашей таблицы из загружаемого файла, которые вы хотите импортировать, и соотнесите их с характеристиками ваших полей"
    "Loading objects from a file"="Загрузка объектов из файла"
    "CLASS system"="Система CLASS"
    "File"="Файл"
    "Project name"="Название"
    "Name of the company"="Название компании"
    "Create project"="Создание проекта"
    "Edit project"="Редактирование проекта"
    "To edit the\ncrop rotation, you must\nselect the fields in the left\npanel"="Для редактирования\n севооборота необходимо\n выбрать поля в левой\n панели"
    "Delete for season(s)"="Удалить за сезон(ы)"
    "For removing\ncrop rotation is necessary\nselect fields in the left\npanels"="Для удаления\n севооборота необходимо\n выбрать поля в левой\n панели"
    "Attention!"="Внимание!"
    "Delete crop rotation"="Удаление севооборота"
    "Harvest date"="Дата уборки"
    "Sowing date"="Дата сева"
    "Variety/species"="Сорт/Гибрид"
    "Crop"="Культура"
    "Draw"="Нарисовать"
    "Object border"="Контур объекта"
    "Field name"="Название поля"
    "Change field"="Изменение поля"
    Save="Сохранить"
    "Year"="Год"
    "Required data"="Необходимо заполнить"
    "Farm name"="Название хозяйства"
    "Create farm"="Создание хозяйства"
    "Delete selection"="Удалить выделенное"
    "Edit crop rotation"="Редактировать севооборот"
    "Load fields"="Загрузить поля"
    "Create object"="Создать поле"
    "Edit farm"="Редактировать хозяйство"
    "Selected fields"="Полей выделено"
    "Fields total"="Полей всего"
    "Project"="Проект"
    "Projects"="Проекты"
    "Farms"="Хозяйства"
    "Are you sure you want to delete this farm?"="Вы уверены, что хотите удалить данное хозяйство?"
    "Crop rotation"="Севооборот"

    "There is no georeference"="Отсутствует геопривязка"
    "Upload"="Загрузить"
    "Don't download possible duplicates"="Не загружать возможные дубликаты"
    "Loading stopped"="Загрузка остановлена"
    "Loading is complete"="Загрузка завершена"
    Loading="Загрузка"
    "Upload photos"="Загрузка фотографий"
    "Not found"="Не найдено"
    "Add"="Добавить"
    "Selected photos"="Выбрано фото"
    "Removing tags"="Удаление тегов"
    "Adding tags"="Добавление тегов"
    "Create group"="Создать группу"
    "Create tag group"="Создать группу тегов"
    "Tags reference"="Справочник тегов"
    "Changing the tag name"="Изменение названия тега"
    "Name"="Название"
    Change="Изменить"
    "Are you sure you want to remove the tag?"="Вы уверены, что хотите удалить тег?"
    Photos="Фотографии"
    "Filters"="Фильтры"
    "Gallery"="Галерея"
    "All photos uploaded"="Все фотографии загружены"
    "Loading error"="Ошибка загрузки"
    "You_have_no_uploaded_files_you_can_upload_them_with_buttons"="У вас нет загруженных\nфотографий, вы можете\nзагрузить их с помощью\nкнопки"
    "Select files"="Выбрать файлы"
    "Uploading photos"="Загрузка фотографий"
    "Show all"="Показать все"
    "Minimize"="Свернуть"
    "Search by tags"="Поиск по тегам"
    "Tags"="Теги"
    "Use map extent"="Использовать экстент карты"
    "Delete photos ({0})?"="Удалить фотографии: {0} шт ?"
    "Delete photo"="Удалить фото"
    "Delete tags"="Удалить теги"
    Photo = "Фото"
    "List" = "Список"
    Farm = "Хозяйство"
    "Region" = "Регион"
    "Season"="Сезон"
    "Change farm"="Изменение хозяйства"
    "m" = "м"
    "km" = "км"
    "m²" = "м²"
    "km²" = "км²"
    "meters" = "метры"
    "degrees" = "градусы"
    "Slopes" = "Уклоны"
    "Heights" = "Высоты"
    Area = "Площадь"
    Palettes="Палитры"
    Palette="Палитра"
    Min="Мин"
    Max="Макс"
    Classes="Классы"
    Interpolation="Интерполяция"
    "Pixels transparent <min"="Прозрачность < мин"
    "Pixels transparent >max"="Прозрачность > макс"
    Default="По умолчанию"
    Apply="Применить"
    Remember="Запомнить"
    "AI digitization"="Оцифровка AI"
    "Polygon simplification, m"="Упрощение полигона, м"
    "Mean +/- STD x"="Среднее +/- STD x"
    Mask="Маска"
    //LUT=""
    "Loading..."="Загрузка..."
    "Order history"="История заказов"
    "Order ID"="ID заказа"
    "Start / Finish"="Старт / финиш"
    Action="Действие"
    Error="Ошибка"
    Ready="Готово"
    Wait="Ожидание"
    "Polarization type"="Поляризация"
    "Multitime filtering" = "Мультивременная фильтрация";
    "Comma separated relative orbit numbers" = "Список орбит, разделенных запятыми"
    "Specify search area to activate Run button"="Укажите контур поиска, чтобы активировать кнопку «Выполнить»."
    "Active area"="Активная область"
    "{0} scenes" = "{0} сцен"
    "Quicklook is not found" = "Quicklook не найден"
    "Export options" = "Настройки экспорта"
    "Crop to active area" = "Обрезать по активной области"
    "Products" = "Продукты"
    "Delete all elements from favorites?" = "Удалить все элементы из избранного?"
    "Changing the name of a tag group" = "Изменение названия группы тегов"
    "Create tag" = "Создание тега"
    "Delete" = "Удалить"
    "Tag" = "Тег"
    "Spectral bands" = "Спектрал. каналы"
    "Spectral indexes" = "Спектрал. индексы"
//    "Email" = "Электронная почта"
    "Copied" = "Скопировано"
    "Are you sure you want to delete the group?" = "Вы уверены, что хотите удалить группу?"
    "Personal Area" = "Личный кабинет"
    "Log out" = "Выйти"
    "Admin panel" = "Панель администратора"
    Create = "Создать"
    Update = "Изменить"
    Title = "Название"
    All = "Все"
    "Temperature °C" = "Температура °C"
    "Precipitation mm" = "Осадки мм"
    "Close" = "Закрыть"
    "Cancel upload" = "Отменить загрузку"
    "NDVI (250 m)" = "NDVI (250м)"
    "Day" = "День"
    "Daily" = "Суточные"
    "Daily mean" = "Среднесуточная"
    "Temperature" = "Температура"
    "Precipitation, NOAA" = "Осадки"
    "recipitation, NOAA, mm" = "Осадки, мм"
    "Value type"="Тип данных"
    "Chart type" = "Категория"
    "Accumulated daily mean" = "Накопленная среднесуточная"
    NDVI = "Вегетационный индекс"
    //Static = ""
    //Adaptive = ""
    "Adjust pixel values" = "Выравнивание"
    "Run" = "Выполнить"
    "Cancel" = "Отменить"
    "Only visible" = "Только видимые"
    "Task name" = "Название"
    "Orbit number filter" = "Номера орбит"
    "Properties" = "Свойства"
    "All visible" = "Включить все"
    "No images" = "Нет снимков"
    "Back" = "Назад"
    "Listed" = "Показано"
    "Total found" = "Найдено"
    "Nothing found, change search parameters" = "Нет снимков, измените критерии поиска"
    "Zoom in to search images" = "Приблизьте карту, чтобы увидеть снимки"
    "Off nadir angle" = "Угол съемки"
    "Metadata" = "Метаданные"
    "Images" = "Снимки"
    "Sensors" = "Тип спутника"
    "Sensor" = "Спутник"
    "Select all" = "Выбрать все"
    hello = "привет"
    Scenes = "Сцены"
    Dates = "Даты"
    Date = "Дата"
    "Image search" = "Поиск снимков"
    "Max. cloud coverage" = "Облачность"
    "Data type" = "Тип данных"
    "Compare mode" = "Режим сравнения"
    "Baselayers" = "Подложки"
    "Overlays" = "Слои"
    "Multi temporal overlays"="Мультивременные оверлеи"
    "Reset bearing to north" = "Восстановить направление на север"

    "Click to draw a point" = "Кликните, чтобы нарисовать точку"
    "Click to draw vetrices and double click to finish" = "Клик - добавить вершину, двойной клик - завершить"
    "Click to draw a vertex, drag and click to finish" = "Кликните, чтобы добавить вершину, протяните и кликните"

    "Download" = "Скачать"
    "Remove" = "Удалить"
    "Set active area" = "Установить активную область"

    "Cartogram parameters" = "Параметры картограммы"
    "Parameter" = "Параметр"
    "Aggregation for the period" = "Агрегация за период"
    "The period length is no more than 366 days" = "Длина периода не более 366 дней"
    "Indicator value" = "Значение показателя"
    "Operator" = "Операция"
    "Threshold value" = "Пороговое значение"
    "Color settings" = "Настройки отображения"
    "Quantiles, min-max" = "Мин-макс по квантилям"
    "Scale" = "Шкала"
    "Precipitation" = "Осадки"
    "Active temperature, °C" = "Активные температуры, °C"
    "Atmospheric/soil drought" = "Засуха атмосферная/почвенная"
    "Precipitation, mm" = "Осадки, мм"
    "Selyaninov hydrothermal coefficient" = "Гидротермический коэффициент Селянинова"
    "Sum" = "Сумма"
    "Multiyear mean of sum" = "Климатическая норма суммы"
    "Difference with multiyear mean" = "Разность с климатической нормой"
    "Relation to the climatic norm for the period" = "Отношение к климатической норме за период"
    "Ratio to multiyear mean (in percentages)" = "Отношение к климатической норме (в процентах)"
    "No filter" = "Без фильтра"
    ">" = ">"
    "≥" = "≥"
    "=" = "="
    "≤" = "≤"
    "<" = "<"
    "≠" = "≠"
    "Incorrect dates" = "Неправильные даты"
    "Period must be no more than 366 days" = "Период должен быть не более 366 дней"
    "Data available since 2010-01-01" = "Данные доступны с 2010-01-01"
    "Cadastral number" = "Кадастровый номер"
    "Creating a request" = "Создание запроса"
    "Create a request" = "Создать запрос"
    "Borrower" = "Заемщик"
    "Pledger" = "Залогодатель"
    "Specialist" = "Специалист"
    "Regions" = "Регионы"
    "Requests" = "Запросы"
    "Entered information" = "Введенная информация"
    "Information from EGRN" = "Информация из ЕГРН"
    "Cadastral boundary" = "Кадастровая граница"
    "Address" = "Адрес"
    "Request with cadastral number {0} has already been created" = "Заявка с кадастровым номером {0} уже создана"
    "Permitted use" = "Разрешенное использование"
    "Cadastral value" = "Кадастровая стоимость"
    "Price change date" = "Дата изменения стоимости"
    "Take to work" = "Взять в работу"
    "EGRN" = "ЕГРН"
    "Waiting for processing" = "Ожидание обработки"
    "Creating report" = "Создание отчета"
    "Report ready" = "Отчет готов"
    "Report is editing" = "Отчет редактируется"
    "Request deleted" = "Запрос удален"
    "EGRN error" = "Ошибка ЕГРН"
    "Encumbrances" = "Обременения"
    "No encumbrances" = "Отсутствуют"
    "Encumbrances found" = "Присутствуют"
    "Request {0} not found" = "Запрос {0} не найден"
    "Registered rights / encumbrances" = "Зарегистрированные права / обременения"
    "Owner" = "Собственник"
    "Right" = "Право"
    "Encumbrance" = "Обременение"
    "Number" = "Номер"
    "Recipient" = "Получатель"
    "Document" = "Документ"
    "Wrong format" = "Неверный формат"
    "open on PKK" = "открыть на ПКК"
    "Report not created" = "Отчет не создан"
    "New request" = "Новый запрос"
    "Agri. Soil Analysis Data" = "Анализ почвы"
    "Editing a agricultural soil analysis map (ASA Map)" = "Редактирование карты отбора проб (КОП)"
    "Create a agricultural soil analysis map (ASA Map)"="Создать карту отбора проб"
    "Merge Selected"="Объеденить выбранное"
    "Delete selected"="Удалить выбранное"
    "Agricultural soil analysis maps (ASA Maps)" = "Карты отбора проб (КОП)"
    "Trial evaluation of the work of cartograms" = "Пробная оценка работы картограмм"
    "Cells" = "Ячейки"
    "Cells:" = "Ячеек:"
    "Field outlines"="Контуры полей"
    "Tracks" = "Треки"
    "Download indicators" = "Загрузить показатели"
    "Loading indicators" = "Загрузка показателей"
    "Visualization settings" = "Настройки визуализации"
    "Cell labels" = "Подписи ячеек"
    "Meaning of indicators" = "Значение показателей"
    "Cell numbers" = "Номера ячеек"
    "Scale and palette type" = "Тип шкалы и палитры"
    "Cancellation" = "Отмена"
    "Cartograms" = "Картограммы"
    "ASA Maps" = "КОПов"
    "Attention! The selected objects will be permanently deleted with all information attached" = "Внимание! Выбранные объекты будут безвозвратно удалены со всей прикрепленной информацией"
    "To get started, download data from the laboratory in Excel format" = "Для начала работы загрузите данные из лаборатории в формате Excel"
    "Agrochemical data can be downloaded in OpenDocument (.ods) format (supported by MS Excel, Google Sheets, OpenOffice, etc.). The column names must match the" = "Данные агрохимических показателей можно загрузить в формате OpenDocument (.ods) (поддерживается в MS Excel, Google Sheets, OpenOffice и др). Названия колонок должны соответствовать"
    "pattern" = "образцу"
    ". How to correct the original spreadsheet file from the lab is described in" = ". Как скорректировать исходный табличный файл из лаборатории, описано в"
    "instructions" = "инструкции"
    "The file has been successfully processed and matches the sample" = "Файл успешно обработан и соответствует образцу"
    "The file was processed successfully." = "Файл успешно обработан."
    "Some column names do not match the template" = "Названия некоторых колонок не соответствует образцу"
    "The file has been processed with errors. Fix the following bugs" = "Файл обработан с ошибками. Исправьте следующие ошибки"
    "Loaded indicators" = "Загруженные индикаторы"
    "Number of samples" = "Кол-во проб"
    "Columns" = "Колонки"
    "Indicators" = "Показатели"
    "Indicators:" = "Показателей:"
    "Probes" = "Проб"
    "Sample" = "Образец"
    "Instruction" = "Инструкция"
    "Delete blue contour" = "Удалить синий контур"
    "Delete contour" = "Удалить контур"
    "Link to cells (permalink)" = "Ссылка на ячейки (пермалинк)"
    "Upload file" = "Загрузить файл"
    "Editing AXO metadata" = "Редактирование метаданных АХО"
    "Are you sure you want to delete AXO data with name" = "Вы уверены, что хотите удалить данные АХО с названием"
    "Fields" = "Поля"
    "Units" = "Единицы измерения"
    "Standard" = "Стандарт"
    "Filtration" = "Фильтрация"
    "Cancel all" = "Отменить все"
    "Introduction date" = "Дата введения"
    "Reason for introduction" = "Причина введения"
    "Code" = "Код"
    "Administrative unit" = "Единица АТД"
    "Values filter" = "Фильтрация значений"
    "Reference" = "Ссылка"
    "YYYY-MM-DD" = "ГГГГ-ММ-ДД"
}
