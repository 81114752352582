import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoSelectColumnPanelComp.scss'
import {action, observable} from "mobx";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {DropDownComp, IDropDownItem} from "../../../../app/components/Common/DropDownComp";
import {AhoCellsValidateStatus, ICellsUploadColumn} from "../../agroAhoUploadCellsStore";
import classNames from 'classnames';


@observer
export class AgroAhoSelectColumnPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }



    onSelectChange(item: IDropDownItem){
        let ustore = this.props.store.agroAhoStore.uploadCellsStore;
        ustore.ahoValidateStatus = AhoCellsValidateStatus.Ready;
        ustore.column = item.data;
    }

    onClickSave(){
        let ustore = this.props.store.agroAhoStore.uploadCellsStore;
        ustore.saveClick();
    }

    @action
    onClickSelect(){
        let ustore = this.props.store.agroAhoStore.uploadCellsStore;
        // if (ustore.ahoValidateStatus == AhoCellsValidateStatus.Error) return;
        let vres = ustore.cellValidateResult?.errors;
        let cnts = this.parseErrors(vres);
        let downloadDisable = (ustore.ahoValidateStatus == AhoCellsValidateStatus.NoValid && cnts.err) ||
            ustore.ahoValidateStatus == AhoCellsValidateStatus.ServerError;
        if (downloadDisable) return;
        ustore.onClickSelectColumn(ustore.column.name);
        // store_.toggleModal(false);
    }

    @action
    onClickCancel(){
        let store_ = this.props.store.agroAhoStore;
        store_.toggleModal(false);
    }

    parseErrors(vres: any){
        if (!vres) return {err: 0, warn: 0};
        let errCnt = 0;
        let warnCnt = 0;
        if (vres.common) vres.common.forEach((it: any)=>{
            if (it.fatal == 0) {++warnCnt} else {++errCnt}
        });
        if (vres.row) vres.row.forEach((it: any)=>{
            if (it.fatal == 0) {++warnCnt} else {++errCnt}
        });
        return {err: errCnt, warn: warnCnt};
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let ustore = store_.uploadCellsStore;
        // let stage = ustore.
        let res = ustore.cellsLoadedResult;
        if (!res) return <div> </div>;
        // {"cnt": int, "columns": [{"name": "col_name", "values": ["v1", "v2", "v3", "v4", "v5"]}], "file_id": "identificator"
        let items: IDropDownItem[] = res.columns.map(a=>{
            let t: IDropDownItem = {key: a.name, data: a, value: a.name};
            return t;
        });
        let vres = ustore.cellValidateResult?.errors;
        let cnts = this.parseErrors(vres);
        let errCnt = cnts.err;
        let warnCnt = cnts.warn;
        // if (ustore.ahoValidateStatus == AhoCellsValidateStatus.NoValid) {
        //     let cnts = this.parseErrors(vres);
        //     errCnt = cnts.err;
        //     warnCnt = cnts.warn;
        // }
        let isWhite = (!errCnt && warnCnt) || (errCnt && !warnCnt);
        let downloadDisable = (ustore.ahoValidateStatus == AhoCellsValidateStatus.NoValid && errCnt) ||
            ustore.ahoValidateStatus == AhoCellsValidateStatus.ServerError;



        return <div className="AgroAhoSelectColumnPanelComp-main">
            <div className="AgroAhoSelectColumnPanelComp-title">{store_.trans["Selecting a column with cell numbers"]}</div>
            <div className="AgroAhoSelectColumnPanelComp-scroll style-4">

                <div className="AgroAhoSelectColumnPanelComp-msg">{store_.trans["Below you need to select the column from your table that matches the cell numbers."]}</div>
                <div className="AgroAhoSelectColumnPanelComp-line"> </div>
                <div className="AgroAhoSelectColumnPanelComp-columnTitle">{store_.trans["Column"]}</div>
                <div className="AgroAhoSelectColumnPanelComp-selFileCover">
                    <div className="AgroAhoSelectColumnPanelComp-selInput">
                        <DropDownComp items={items} currentKey={ustore.column.name} onChange={this.onSelectChange} />
                        {/*{ustore.ahoValidateStatus == AhoCellsValidateStatus.Error &&*/}
                        {/*<div className="AgroAhoSelectColumnPanelComp-error">{store_.trans["Loading error"]}</div>}*/}
                    </div>
                    <div className="AgroAhoSelectColumnPanelComp-arrowRightCover">
                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.4375 0.6875L17.4375 8L8.4375 15.3125V10.8125H3.9375V5.1875H8.4375V0.6875Z" stroke="#6D7785" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M0.5625 5.1875V10.8125" stroke="#6D7785" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    <div className="AgroAhoSelectColumnPanelComp-example">
                        <div className="AgroAhoSelectColumnPanelComp-exampleTitle">{store_.trans["Example of values in the selected column:"]}</div>
                        {ustore.column.values.slice(0,5).map((item, index)=>
                            <div className="AgroAhoSelectColumnPanelComp-exampleItem" key={index}>{item}</div>)}
                    </div>
                </div>
                <div className="AgroAhoSelectColumnPanelComp-line"> </div>
                <div className="AgroAhoSelectColumnPanelComp-msgCover">
                    {ustore.ahoValidateStatus == AhoCellsValidateStatus.NoValid && <React.Fragment>
                        {!errCnt && !!warnCnt && <div className="AgroAhoSelectColumnPanelComp-msgWarning">
                            {/*<span>{store_.trans["Cells processed successfully"]}</span>*/}
                            {/*<span>{store_.trans["There are remarks"]}:&nbsp;{warnCnt}</span>*/}
                            <span>{store_.trans["There are remarks"]}!</span>
                            <span className="AgroAhoSelectColumnPanelComp-splitter"> </span>
                            <span>{store_.trans["Remarks:"]}&nbsp;{warnCnt}</span>
                        </div>}
                        {!!errCnt && !!warnCnt && <div className="AgroAhoSelectColumnPanelComp-msgErr">
                            <span>{store_.trans["There are comments and errors"]}!</span>
                            <span className="AgroAhoSelectColumnPanelComp-splitter"> </span>
                            <span className="AgroAhoSelectColumnPanelComp-msgRem">{store_.trans["Remarks:"]}&nbsp;{warnCnt}</span>
                            <span>{store_.trans["Errors:"]}&nbsp;{errCnt}</span>
                        </div>}
                        {!!errCnt && !warnCnt && <div className="AgroAhoSelectColumnPanelComp-msgErr">
                            <span>{store_.trans["The file has errors"]}!</span>
                            <span className="AgroAhoSelectColumnPanelComp-splitter"> </span>
                            <span>{store_.trans["Errors:"]}&nbsp;{errCnt}</span>
                        </div>}

                        <div className="AgroAhoSelectColumnPanelComp-tableHeader">
                            <span>{store_.trans["Cell / field number"]}</span>
                            <span>{store_.trans["Remark / error"]}</span>
                        </div>
                        <div className="AgroAhoSelectColumnPanelComp-tableCover">
                            <table>
                                <tbody>
                                    {vres.common.map((item:any) =>
                                        <tr className={classNames("AgroAhoSelectColumnPanelComp-tr",
                                            {"AgroAhoSelectColumnPanelComp-trWarn": !isWhite && !item.fatal},
                                            {"AgroAhoSelectColumnPanelComp-trErr": !isWhite && item.fatal})} key={item.field_name}>
                                            <td>{item.field_name}</td>
                                            <td>{item.msg}</td>
                                        </tr>
                                    )}
                                    {vres.row.map((item:any) =>
                                        <tr className={classNames("AgroAhoSelectColumnPanelComp-tr",
                                            {"AgroAhoSelectColumnPanelComp-trWarn": !isWhite && !item.fatal},
                                            {"AgroAhoSelectColumnPanelComp-trErr": !isWhite && item.fatal})} key={item.cell_id}>
                                            <td>{item.cell_id}</td>
                                            <td>{item.msg}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>}
                    {ustore.ahoValidateStatus == AhoCellsValidateStatus.ServerError && <React.Fragment>
                        <div className="AgroAhoSelectColumnPanelComp-errTitle">
                            <span>{store_.trans["File loaded with errors. Fix the following errors"]}:</span>
                        </div>
                        <div className="AgroAhoSelectColumnPanelComp-errMsg style-4">{ustore.validateErrMsg}</div>
                    </React.Fragment>}
                </div>
            </div>

            <div className="AgroAhoSelectColumnPanelComp-buttons">
                <button className="large-button left-sidebar-cancel button-jump AgroAhoSelectColumnPanelComp-cancelButton"
                    onClick={this.onClickCancel}>{store_.trans.Cancel}</button>
                {(ustore.ahoValidateStatus !== AhoCellsValidateStatus.NoValid ||
                    (ustore.ahoValidateStatus == AhoCellsValidateStatus.NoValid && !!errCnt)) &&
                <button className={classNames("large-button left-sidebar-active AgroAhoSelectColumnPanelComp-selectButton",
                    // {"button-jump": ustore.ahoValidateStatus !== AhoCellsValidateStatus.NoValid},
                    // {"AgroAhoSelectColumnPanelComp-disabled": ustore.ahoValidateStatus == AhoCellsValidateStatus.NoValid})}
                    {"button-jump": !downloadDisable},
                    {"AgroAhoSelectColumnPanelComp-disabled": downloadDisable})}
                    onClick={this.onClickSelect}>{store.trans["Upload"]}</button>}
                {ustore.ahoValidateStatus == AhoCellsValidateStatus.NoValid && !errCnt &&
                <button className="large-button left-sidebar-active button-jump AgroAhoSelectColumnPanelComp-selectButton"
                        onClick={this.onClickSave}>{store_.trans["Save"]}</button>}
            </div>
        </div>
    }
}
