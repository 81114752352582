import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoEditorEditPanelComp.scss'
import classNames from "classnames";
import {observable} from "mobx";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AhoIndicatorFormat} from "../../agroAhoCardStore";
import {AgroAhoZoomSvg} from "../icons/AgroAhoZoomSvg";

@observer
export class AgroAhoEditorEditPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    onCancelClick(e: any){
        this.props.store.agroAhoStore.editorStore.doSelectModeClick();
    }

    onSaveClick(e: any){
        this.props.store.agroAhoStore.editorStore.doSaveClick();
    }

    onZoomClick(){
        this.props.store.agroAhoStore.editorStore.doZoomClick();
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let field_name = store_.editorStore.fieldInfo?.[0]?.field_name;

        return <div className="AgroAhoEditorEditPanelComp-main">
            <div className="AgroAhoEditorEditPanelComp-window">
                <div className="AgroAhoEditorEditPanelComp-len">
                    <span>{store_.trans['Field']}:&nbsp;</span>
                    <span>{field_name}</span>
                </div>
                <div className="AgroAhoEditorEditPanelComp-cell">
                    <span>{store_.trans["Cell"]}:&nbsp;</span>
                    <span>{store_.editorStore.cell_id}</span>
                </div>
                <div className="AgroAhoEditorEditPanelComp-zoomCover" onClick={this.onZoomClick}>
                    <AgroAhoZoomSvg/>
                </div>
                <div className="AgroAhoEditorEditPanelComp-splitter"> </div>


                <div className="AgroAhoEditorEditPanelComp-cancelButton button-jump" onClick={this.onCancelClick}>
                    {store_.trans.Cancel}</div>
                <div className="AgroAhoEditorEditPanelComp-saveButton button-jump" onClick={this.onSaveClick}>
                    {store_.trans.Save}</div>
            </div>
        </div>
    }
}
