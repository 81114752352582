import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoEditorWaitPanelComp.scss'
import classNames from "classnames";
import {observable} from "mobx";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AhoIndicatorFormat} from "../../agroAhoCardStore";

@observer
export class AgroAhoEditorWaitPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    onGoOutClick(e: any){
        this.props.store.agroAhoStore.editorStore.doDisableModeClick();
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;

        return <div className="AgroAhoEditorWaitPanelComp-main">
            <div className="AgroAhoEditorWaitPanelComp-window">
                <div>{store_.trans["To change a track, double click on the cell."]}</div>
                <div className="AgroAhoEditorWaitPanelComp-splitter"> </div>
                <div className="AgroAhoEditorWaitPanelComp-exitButton button-jump" onClick={this.onGoOutClick}>
                    {store_.trans["Go out"]}</div>
            </div>
        </div>
    }
}
