import {autorun, IReactionDisposer} from "mobx";
import {CustomStoreTool} from "../general/CustomStoreTool";
import {Utils} from "../../../helper/utils/Utils";

export class GibsOverlayTool extends CustomStoreTool {
    static LAYER_ID = "class_gibsLayer";
    static SOURCE_ID = "class_gibsSource";

    onInstall() {
    }

    onUninstall() {
    }

    onSubscription(): IReactionDisposer[] {
        return [
            autorun(() => {
                this.setupLayers();
            }), ...super.onSubscription()
        ];
    }

    setupLayers() {
        this.unloadOverlay();
        this.loadOverlay();
    }


    unloadOverlay() {
        let map = this.store.map.mapbox;
        if (map.getLayer(GibsOverlayTool.LAYER_ID))
            this.removeLayer(GibsOverlayTool.LAYER_ID);
        if (map.getSource(GibsOverlayTool.SOURCE_ID))
            map.removeSource(GibsOverlayTool.SOURCE_ID);
    }

    loadOverlay(){
        let map = this.store.map.mapbox;
        let gibs = this.store.map.gibs;
        if (! gibs.active || !gibs.gibsLayerId) return;
        let template: string = null;
        gibs.params.layers.forEach(lr => {
            if (lr.id == gibs.gibsLayerId) template = lr.tilesTemplate;
        });
        let tiles = template.replace("{date}", Utils.formatDate(gibs.date));

        if (map.getSource(GibsOverlayTool.SOURCE_ID) == null) {
            map.addSource(GibsOverlayTool.SOURCE_ID, {
                type: "raster",
                tiles: [tiles],
                tileSize: 256,
                scheme: "xyz"
            });
        }
        this.addLayer({
            id: GibsOverlayTool.LAYER_ID,
            source: GibsOverlayTool.SOURCE_ID,
            type: 'raster'
        });
    }
}