import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoDelRNItemPanelComp.scss'
import {action} from "mobx";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {TranslateUtils} from "../../../../app/helper/lang/TranslateUtils";

@observer
export class AgroAhoDelRNItemPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickDelete(){
        let store_ = this.props.store.agroAhoStore;
        let cstore = store_.cardStore;
        let tstore = store_.tourStore;
        let mstore = store_.mapStore;
        let rn = mstore.mapRNStore.rnForRemoval;
        let tour_ids = tstore.doGetRNFertTours(rn.fert_id);
        cstore.deleteRecNorm(cstore.card.map_id, rn.rn_id, tour_ids);
        store_.toggleModal(false);
    }

    onClickCancel(){
        let store = this.props.store.agroAhoStore;
        store.toggleModal(false);
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let tstore = store_.tourStore;
        let ustore = store_.uploadTableStore;
        let rn = store_.mapStore.mapRNStore.rnForRemoval;
        let tour_ids = tstore.doGetRNFertTours(rn.fert_id);
        let tours = tstore.torusToDisplay.filter(t=>tour_ids.indexOf(t.tour_id)>=0);
        let tour_names = tours.map(t=>t.tour_name);
        // console.log('tour_ids:', tour_ids, store_.cardStore.torusToDisplay.map(t=>t.tour_id), tours.map(t=>t.tour_id), tour_names);

        return <div className="AgroAhoDelRNItemPanelComp-main">
            <div className="AgroAhoDelRNItemPanelComp-caption">{store.trans.Delete}</div>
            <div className="AgroAhoDelRNItemPanelComp-msg">{TranslateUtils.createSpansWithReplaceMarkers(
                store_.trans['Attention! All data fertilizer will be removed from the tours: {0}'],
                tour_names.join(', '))}</div>
            <div className="AgroAhoDelRNItemPanelComp-stat">
                <span>{ustore.getCropName(rn.crop_id)}&nbsp;-&nbsp;{ustore.getFertName(rn.fert_id)}</span>
            </div>
            <div className="AgroAhoDelRNItemPanelComp-line"> </div>
            <div className="AgroAhoDelRNItemPanelComp-buttonCover">
                <button className="large-button left-sidebar-cancel button-jump AgroAhoDelRNItemPanelComp-cancel"
                        onClick={this.onClickCancel}>{store.trans.Cancel}</button>
                <button className="large-button left-sidebar-red button-jump"
                        onClick={this.onClickDelete}>{store.trans.Delete}</button>
            </div>
        </div>;
    }
}
