import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {action, observable} from "mobx";
import classNames from "classnames";
import {ContextMenuCommonComp} from "../../../../Common/ContextMenuCommonComp";
import {FilterProjectsType} from "../../../../../store/agro/AgroStore";
import {OrderByType} from "../../../../../const/OrderByType";

@observer
export class AgroProjectListFiltersComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }
    @action
    onChangeFilter(e: any){
        let txt = e.currentTarget.value;
        this.props.store.agro2.projectList.filter_projects = txt;
    }
    @action
    onClickClear(){
        this.props.store.agro2.projectList.filter_projects = "";
    }
    @observable
    down: boolean = false;

    @action
    onClosePopup(){
        this.down = false;
    }
    @action
    onClickSort(){
        this.down = true;
    }
    @action
    onClickSortByName(){
        if (this.props.store.agro2.projectList.filter_projects_type == FilterProjectsType.byName){
            this.reversDirection();
        }else {
            this.props.store.agro2.projectList.filter_projects_type = FilterProjectsType.byName;
            this.props.store.agro2.projectList.filter_projects_direction = OrderByType.ASC;
        }
    }
    @action
    onClickSortByDate(){
        if (this.props.store.agro2.projectList.filter_projects_type == FilterProjectsType.byDate){
            this.reversDirection();
        }else {
            this.props.store.agro2.projectList.filter_projects_type = FilterProjectsType.byDate;
            this.props.store.agro2.projectList.filter_projects_direction = OrderByType.ASC;
        }
    }
    reversDirection(){
        if (this.props.store.agro2.projectList.filter_projects_direction == OrderByType.ASC)
            this.props.store.agro2.projectList.filter_projects_direction = OrderByType.DESC;else
            this.props.store.agro2.projectList.filter_projects_direction = OrderByType.ASC;
    }

    getIconSortMenu(type: FilterProjectsType){
        if (this.props.store.agro2.projectList.filter_projects_type != type){
            return <div className="A2NavigatorComp-popup-props-line-noIcon"></div>
        }
        if (this.props.store.agro2.projectList.filter_projects_direction == OrderByType.ASC){
            return <svg width="12" height="7" viewBox="0 0 12 7"  xmlns="http://www.w3.org/2000/svg">
                <rect width="12" height="1" rx="0.5" stroke="none"/>
                <rect y="3" width="12" height="1" rx="0.5" stroke="none"/>
                <rect y="6" width="7" height="1" rx="0.5" stroke="none"/>
            </svg>;
        }else{
            return <svg width="12" height="7" viewBox="0 0 12 7" xmlns="http://www.w3.org/2000/svg">
                <rect width="12" height="1" rx="0.5" transform="matrix(-1 0 0 1 12 0)" stroke="none"/>
                <rect width="12" height="1" rx="0.5" transform="matrix(-1 0 0 1 12 3)" stroke="none"/>
                <rect width="7" height="1" rx="0.5" transform="matrix(-1 0 0 1 12 6)" stroke="none"/>
            </svg>;
        }
    }

    render() {
        let store = this.props.store;
        let projListStore = store.agro2.projectList;

        return <div className="flex-columns-centerByVertical" style={{marginBottom: "14px"}}>
            <div className="AgroFarmList-filterInputBox">
                <input type="text" style={{margin: "", height: "30px"}}
                       className={classNames("text-box-editor AgroFarmList-filterInput")}
                       value={projListStore.filter_projects}
                       onChange={this.onChangeFilter}
                       placeholder={store.trans["Search by title or owner"]} />
                {projListStore.filter_projects != "" && <div className="AgroFarmList-filterInputBoxClear" onClick={this.onClickClear}>
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.1875 0.8125C8.9375 0.5625 8.5625 0.5625 8.3125 0.8125L5 4.125L1.6875 0.8125C1.4375 0.5625 1.0625 0.5625 0.8125 0.8125C0.5625 1.0625 0.5625 1.4375 0.8125 1.6875L4.125 5L0.8125 8.3125C0.5625 8.5625 0.5625 8.9375 0.8125 9.1875C0.9375 9.3125 1.0625 9.375 1.25 9.375C1.4375 9.375 1.5625 9.3125 1.6875 9.1875L5 5.875L8.3125 9.1875C8.4375 9.3125 8.625 9.375 8.75 9.375C8.875 9.375 9.0625 9.3125 9.1875 9.1875C9.4375 8.9375 9.4375 8.5625 9.1875 8.3125L5.875 5L9.1875 1.6875C9.4375 1.4375 9.4375 1.0625 9.1875 0.8125Z" fill="#C5C5C5"/>
                    </svg>
                </div>}
            </div>

            <div className={classNames("flex-center-content white_svg blue_svg_onhover pointer",
                {
                    "blue-svg-important": this.down
                })} style={{margin:"0 16px"}} onClick={this.onClickSort}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.5 6.5L2.75 0.5H3.25L5.5 6.5" fill="none" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M1.25 4.5H4.75" fill="none" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M0.5 9.5H5.5L0.5 15.5H5.5" fill="none" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M11.5 0.5V15.5" fill="none" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M7.5 11.5L11.5 15.5L15.5 11.5" fill="none" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                {this.down && <ContextMenuCommonComp onClose={this.onClosePopup}
                                                     indentVertical={5} className="A2NavigatorComp-menuPopup ">
                    <div className={"flex-columns flex-align-items-center A2NavigatorComp-popup-props-line white_svg"}
                         onClick={this.onClickSortByName}>
                        {this.getIconSortMenu(FilterProjectsType.byName)}
                        <div className="flex-stretch-item text-next">
                            {store.trans["sortByName"]}
                        </div>
                    </div>
                    <div className="flex-columns flex-align-items-center A2NavigatorComp-popup-props-line white_svg"
                         onClick={this.onClickSortByDate}>
                        {this.getIconSortMenu(FilterProjectsType.byDate)}
                        <div className="flex-stretch-item text-next">
                            {store.trans["sortByDate"]}
                        </div>
                    </div>
                </ContextMenuCommonComp>}

            </div>
        </div>;
    }
}
