import * as React from 'react';
import { Visibility } from 'maplibre-gl';
import { useState } from 'react';
import { LegendLinearComp } from '../LegendLinearComp';
import { ISuperStore } from '../../../../../pluginApi/store/SuperStore';
import ClassLegendComp from '../../../../../plugins/meteo/Meteo/ClassLegendComp';
import { IDeviation, MeteoStore } from '../../../../../plugins/meteo/MeteoStore';
import { MeteoTool } from '../../../../../plugins/meteo/MeteoTool';
import { IMeteoSuperStore, LeftPanelModeMeteo } from '../../../../../plugins/meteo/meteoPlugin';
import { ColorHelper, IRGBA } from '../../../../helper/utils/ColorHelper';
import { IDiscreteInterval, IndexBandProductStore, Interpolation } from '../../../../store/productSetting/IndexBandProductStore';
import Legend from '../../../Common/Legend/Legend';
import { LegendDiscreteComp } from '../LegendDiscreteComp';
import autoBindReact from 'auto-bind/react';
import { action } from 'mobx';
import { observer } from 'mobx-react';

import { IStoreProps } from '../../../../helper/structs/IStoreProps';
import { MinSvg } from '../../../icons/Legend/MinSvg';
import { LegendCommonComp } from '../LegendCommonComp';
import { LegendFixIndexComp } from '../LegendFixIndexComp';
import { LegendMinComp } from '../LegendLayer/LegendMinComp';
import { Utils } from '../../../../helper/utils/Utils';
import { OverlayTool } from '../../../../store/tools/class/OverlayTool';
import { MapComp } from '../../Map/MapComp';

@observer
export class LegendHeightComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        if (store.map.currentBaselayerKey !== 'heights') return null;
        
       
        const minHeight = MapComp.getMinHeight() || 0;
        const maxHeight = MapComp.getMaxHeight() || 0;
        let colors: IRGBA[] = [
            { r: 48, g: 18, b: 59, a: 1 },
            { r: 70, g: 130, b: 248, a: 1 },
            { r: 25, g: 226, b: 187, a: 1 },
            { r: 159, g: 253, b: 63, a: 1 },
            { r: 250, g: 186, b: 57, a: 1 },
            { r: 232, g: 75, b: 12, a: 1 },
            { r: 122, g: 4, b: 3, a: 1 }
        ];


        return <LegendCommonComp store={store} className="legend-panel qtransit" style={{top:'5px', bottom:"auto" }}>
            {<React.Fragment>   
                        <LegendLinearComp store={store} max={maxHeight} min={minHeight} visibleCheckbox={false}
                         hideMoreLess={true}
                                          description={""} values={null} 
                                          top_name={store.trans["Heights"]}
                                          colors={colors.map(a => a)}   />
            </React.Fragment>}
            <React.Fragment>
            <div className="separator_line"/>
            <div className={"legend-panel_linearTitle"}>{store.trans["meters"]}</div>
        </React.Fragment>
        </LegendCommonComp>;
    }
}


