import {action, autorun, computed, observable} from "mobx";
import {CustomStore, ObservableCustomStore} from "../CustomStore";
import {LeftPanelMode} from "../SearchStore";
import {ProductSettingStore} from "../productSetting/ProductSettingStore";
import {save} from "../PermalinkDecor";
import {IReactionDisposer} from "mobx/lib/internal";

export class LegendStore extends ObservableCustomStore{
    @save @observable legendMinimize: boolean = false;

    @observable
    private _invisibleCategories: Set<any> = new Set<any>();

    @action
    clearVisibleCategories(){
        this._invisibleCategories.clear();
    }

    isVisibleCategories(categoryValue: any): boolean{
        return !this._invisibleCategories.has(categoryValue);
    }
    @action
    setVisibleCategories(categoryValue: any, visible: boolean){
        if (visible) this._invisibleCategories.delete(categoryValue)
        else this._invisibleCategories.add(categoryValue);
        this.root.agro2.fieldToolController.fieldLayerTool.reInstallLayers();
    }
    @computed
    get legendNdviShow(): boolean{
        let store = this.root;
        let lpm = store.searchPanel.leftPanelMode;
        if (!(
            lpm == LeftPanelMode.agro ||
            lpm == LeftPanelMode.photo ||
            lpm == LeftPanelMode.search ||
            lpm == LeftPanelMode.favorite ||
            lpm == LeftPanelMode.createProject ||
            lpm == LeftPanelMode.editProject ||
            lpm == LeftPanelMode.uploadFields)) return false;
        if (this.getProdInfo() == null) return false;
        return true;
    }
    get legendLayerShow(): boolean{
        return !this.legendNdviShow && this.root.agro2.agroFieldVisible;
    }



    getProdInfo(): {prod: ProductSettingStore}{
        let store = this.root;
        let prodSet = store.map.productInfo.activeProductsSet;
        let prod: ProductSettingStore = null;
        if (prodSet != null) {
            if (store.map.mapScenesStore.favoriteScenesVisible &&
                store.searchPanel.favoriteList.getAllSceneItems().length > 0) {

                let newProd = store.map.productInfo.activeProductsSet.activeCodeFavorite.currentProductStore;
                if (this.isVisibleProd(newProd)) prod = newProd;
            }
            if (store.searchPanel.currentSceneVisible &&
                store.searchPanel.currentSceneid != null) {
                let newProd = store.map.productInfo.activeProductsSet.activeCodeCurrent.currentProductStore;
                if (this.isVisibleProd(newProd)) prod = newProd;
            }
            if (prod == null) return null;
        }
        if (!this.isVisibleProd(prod)) return null;
        return {prod: prod};
    }
    isVisibleProd(prod: ProductSettingStore): boolean{
        return !(prod == null || prod.bandType == "RGB");
    }

}