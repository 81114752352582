import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoTableUpload3Comp.scss'
import {action, observable} from "mobx";
import classNames from "classnames";
import {AgroAhoProgressComp} from "./../AgroAhoProgressComp";
import {AgroAhoDownloadFileSvg} from "./../AgroAhoDownloadSvg";
import {Utils} from "../../../../app/helper/utils/Utils";
import {AhoFileLoadStage, AhoLoadStatus} from "../../agroAhoUploadIndcStore";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AgroAhoStepsComp} from "./AgroAhoStepsComp";
import {DropDownComp, IDropDownItem} from "../../../../app/components/Common/DropDownComp";
import {AgroAhoCardTourItemComp} from "../card/AgroAhoCardTourItemComp";
import {AhoUploadTablePath, UploadTableColumn, UploadTableData} from "../../agroAhoUploadTableStore";
import {AgroAhoBackSvg} from "../icons/AgroAhoBackSvg";
import {AgroAhoArrowRightSvg} from "../icons/AgroAhoArrowRightSvg";
import {AhoUtils} from "../../agroAhoUtils";
import {AgroAhoHelpFileSvg} from "../icons/AgroAhoHelpSvg";

@observer
export class AgroAhoTableUpload3Comp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickNext(){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        console.log('click id', ustore.idColValuesError);
        if (ustore.idColValuesError) return;
        ustore.step3NextClick().catch(()=>{});
    }

    @action
    onClickCancel(){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        ustore.uploadCancelClick();
    }

    @action
    onClickBack(){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        ustore.uploadBackClick();
    }

    @action
    onDownloadSampleClick(){
        let url = `образец_данные_АХО.ods`;
        Utils.downloadFile(url);
    }

    @action
    onDownloadInstructionsClick(){
        let url = `Инструкция.pdf`;
        Utils.downloadFile(url);
    }

    @action
    onColumnChange(col: any){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        if (ustore.curColumn.num === col.data.num) return;
        ustore.idColValuesError = false;
        ustore.idColErrorList = [];
        ustore.curColumn = col.data;
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let ustore = store_.uploadTableStore;
        let tstore = store_.tourStore;
        let hasError = ustore.idColValuesError && ustore.idColErrorList.length;

        let items: IDropDownItem[] = [/*{key: 0, data: {tour_id: 0}, value: store_.trans['Create a new tour2']}*/];
        ustore.loadedFileData.columns.forEach(c=>{
            items.push({key: c.num, data: c, value: c.title});
        });
        let list: any[];
        if (hasError) {
            list = ustore.idColErrorList.map((v, i)=>{
                let type = '';
                if (v.type == 'duplicate') type = store_.trans.duplicate;
                if (v.type == 'fk') type = store_.trans['incorrect cell number'];
                return <div key={i}>
                    <span>{v.cell_id}&nbsp;(</span>
                    <span className="AgroAhoTableUpload3Comp-idError">{type}</span>
                    {v.type == 'duplicate' && <span>,&nbsp;{store_.trans.row}&nbsp;{v.row}</span>}
                    <span>)</span>
                </div>;
            });
        } else {
            let row_num = 0;
            list = ustore.curColumn.sample.map((v, i)=>{
                row_num = i;
                return <div key={i} className={classNames(
                    {"AgroAhoTableUpload3Comp-idError": !ustore.checkTableId(v.value)})}>{v.value}</div>;
            });
            list.push(<div key={row_num + 1}>...</div>);
        }

        return <div className={classNames("AgroAhoTableUpload3Comp-main",{
            "AgroAhoTableUpload3Comp-hidden": !store_.ahoDialogShow})}>
            <div className="AgroAhoTableUpload3Comp-cover">
                <div className="AgroAhoTableUpload3Comp-topPanel">
                    <div className="AgroAhoTableUpload3Comp-titleCover">
                        <div className="AgroAhoTableUpload3Comp-title">{store_.trans['Step 3. Selecting a column with sample numbers']}</div>
                        <AgroAhoStepsComp step={ustore.ahoUploadTableStage}/>
                    </div>
                    <div className="AgroAhoTableUpload3Comp-subTitleCover">
                        {/*<div className="AgroAhoTableUpload3Comp-titleStatus">{store_.trans['The file was successfully processed and matches the sample']}</div>*/}
                        <div className="AgroAhoTableUpload3Comp-titleFileName">{store_.trans.File}: {tstore.curTour?.tour_file_name}</div>
                        <div className="AgroAhoTableUpload3Comp-titleTourName">{store_.trans.Tour}: {tstore.curTour?.tour_name}</div>
                    </div>
                    <div className="AgroAhoTableUpload3Comp-line"/>
                    {/*<div className="AgroAhoTableUpload3Comp-msg">{store_.trans['Below you need to select a column from the loaded table that corresponds to the cell numbers']}</div>*/}
                    <div className="AgroAhoTableUpload3Comp-msg">{store_.trans['Below you need to select a column from the loaded table that corresponds to the sample numbers (=cell numbers in the ASA Map). The values in this column can only be non-negative integers. Using these values, a comparison is made between the loaded data from the table and the cells (=sections) into which the field is divided.']}</div>

                    <div className={classNames("AgroAhoTableUpload3Comp-table", {
                        "AgroAhoTableUpload3Comp-idValuesError": ustore.idColValuesError
                    })}>
                        <div className="AgroAhoTableUpload3Comp-leftCol">
                            <div className="AgroAhoTableUpload3Comp-selTourCaption">{store_.trans['Column title']}</div>
                            <div className="AgroAhoTableUpload3Comp-colCover">
                                <DropDownComp items={items} onChange={this.onColumnChange} currentKey={ustore.curColumn.num}/>
                            </div>
                            <div className="AgroAhoTableUpload3Comp-errMsg">{store_.trans['The selected column contains incorrect values!']}</div>
                        </div>
                        <div className="AgroAhoTableUpload3Comp-centerCol">
                            <AgroAhoArrowRightSvg/>
                        </div>
                        <div className="AgroAhoTableUpload3Comp-rightCol">
                            <div className="AgroAhoTableUpload3Comp-rightColTitle">
                                {!hasError && store_.trans['Examples of values in the selected column']}
                                {hasError &&store_.trans['Incorrect values']}:
                            </div>
                            <div className="AgroAhoTableUpload3Comp-rightColList style-4">{list}</div>
                        </div>
                    </div>
                </div>
                <div className={classNames("AgroAhoTableUpload3Comp-bottomPanel", {
                    "AgroAhoTableUpload3Comp-idValuesError": ustore.idColValuesError
                })}>
                    {/*<div className="AgroAhoTableUpload3Comp-linkSample" onClick={this.onDownloadSampleClick}>*/}
                    {/*    <AgroAhoDownloadFileSvg />*/}
                    {/*    <span>{store.trans.Sample}</span>*/}
                    {/*</div>*/}
                    {/*<div className="AgroAhoTableUpload3Comp-linkInstructions" onClick={this.onDownloadInstructionsClick}>*/}
                    {/*    <AgroAhoDownloadFileSvg />*/}
                    {/*    <span>{store.trans.Instruction}</span>*/}
                    {/*</div>*/}
                    <div className="AgroAhoTableUpload3Comp-linkInstructions" onClick={this.onDownloadInstructionsClick}>
                        <AgroAhoHelpFileSvg/>
                        <span>{store.trans.Help}</span>
                    </div>
                    <div className="AgroAhoTableUpload3Comp-splitter"> </div>
                    <button className="large-button left-sidebar-cancel AgroAhoTableUpload3Comp-cancelButton button-jump"
                        onClick={this.onClickCancel}>{store.trans.Cancel}</button>
                    <div className="AgroAhoTableUpload3Comp-backButton" onClick={this.onClickBack}>
                        <AgroAhoBackSvg/>
                        <span>{store.trans.Back}</span>
                    </div>
                    <div className="AgroAhoTableUpload3Comp-nextButton" onClick={this.onClickNext}>
                        <span>{store_.trans.Next}</span>
                        <AgroAhoBackSvg isNext={true}/>
                    </div>
                </div>
            </div>
        </div>
    }
}
