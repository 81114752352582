import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoDelIndicatorPanelComp.scss'
import {action} from "mobx";
import {IAgroAhoStoreProps} from "../agroAhoPlugin";
import {TranslateUtils} from "../../../app/helper/lang/TranslateUtils";

@observer
export class AgroAhoDelIndicatorPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickDelete(){
        let store_ = this.props.store.agroAhoStore;
        let cstore = store_.cardStore;
        let tstore = store_.tourStore;

        let ind = cstore.removableIndicator;
        let indc_tours = tstore.doGetIndicatorTours(ind.indc_code);
        let tour_ids = tstore.torusToDisplay.filter(t=>indc_tours.indexOf(t.tour_id)>=0).map(t=>t.tour_id);
        // store.deleteCards();
        cstore.deleteIndicator(cstore.card.map_id, ind, tour_ids);
        store_.toggleModal(false);
    }

    onClickCancel(){
        let store = this.props.store.agroAhoStore;
        store.toggleModal(false);
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let tstore = store_.tourStore;
        let selected = store_.cardsStore.getSelectedCards().length;
        let ind = store_.cardStore.removableIndicator;
        let tour_ids = tstore.doGetIndicatorTours(ind.indc_code);
        let tours = tstore.torusToDisplay.filter(t=>tour_ids.indexOf(t.tour_id)>=0);
        let tour_names = tours.map(t=>t.tour_name);
        console.log('tour_ids:', tour_ids, tstore.torusToDisplay.map(t=>t.tour_id), tours.map(t=>t.tour_id), tour_names);

        return <div className="AgroAhoDelIndicatorPanelComp-main">
            <div className="AgroAhoDelIndicatorPanelComp-caption">{store.trans.Delete}</div>
            <div className="AgroAhoDelIndicatorPanelComp-msg">{TranslateUtils.createSpansWithReplaceMarkers(
                store_.trans['Attention! All indicator values will be removed from the tours: {0}'],
                    tour_names.join(', '))}</div>
            <div className="AgroAhoDelIndicatorPanelComp-stat">
                <span>{ind.indc_name}</span>
                {ind.standard && <span>&nbsp;({ind.standard})</span>}
            </div>
            <div className="AgroAhoDelIndicatorPanelComp-line"> </div>
            <div className="AgroAhoDelIndicatorPanelComp-buttonCover">
                <button className="large-button left-sidebar-cancel button-jump AgroAhoDelIndicatorPanelComp-cancel"
                    onClick={this.onClickCancel}>{store.trans.Cancel}</button>
                <button className="large-button left-sidebar-red button-jump"
                    onClick={this.onClickDelete}>{store.trans.Delete}</button>
            </div>
        </div>;
    }
}
