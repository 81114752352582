import {autorun, IReactionDisposer} from "mobx";
import {CustomStoreTool} from "../general/CustomStoreTool";

export class DapFieldsMaskOverlayTool extends CustomStoreTool {
    static LAYER_ID = "class_dapFieldMaskLayer";
    static SOURCE_ID = "class_dapFieldMaskSource";

    onInstall() {
    }

    onUninstall() {
    }

    onSubscription(): IReactionDisposer[] {
        return [
            autorun(() => {
                this.setupLayers();
            }), ...super.onSubscription()
        ];
    }

    setupLayers() {
        this.unloadOverlay();
        this.loadOverlay();
    }


    unloadOverlay() {
        let map = this.store.map.mapbox;
        if (map.getLayer(DapFieldsMaskOverlayTool.LAYER_ID))
            this.removeLayer(DapFieldsMaskOverlayTool.LAYER_ID);
        if (map.getSource(DapFieldsMaskOverlayTool.SOURCE_ID))
            map.removeSource(DapFieldsMaskOverlayTool.SOURCE_ID);
    }

    loadOverlay(){
        let map = this.store.map.mapbox;
        let dfm = this.store.map.dfm;
        if (! dfm.active || !dfm.currentModel?.uuid) return;
        let tiles = dfm.params.tilesTemplate.replace("{uuid}", dfm.currentModel.uuid);

        if (map.getSource(DapFieldsMaskOverlayTool.SOURCE_ID) == null) {
            map.addSource(DapFieldsMaskOverlayTool.SOURCE_ID, {
                type: "raster",
                tiles: [tiles],
                scheme: "xyz"
            });
        }
        this.addLayer({
            id: DapFieldsMaskOverlayTool.LAYER_ID,
            source: DapFieldsMaskOverlayTool.SOURCE_ID,
            type: 'raster'
        });
    }
}