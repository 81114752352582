import {CustomStore} from "../../CustomStore";

export class MapRootStore extends CustomStore{
    rootFolder: MapFolderItemStore = new MapFolderItemStore(this);
    static prefix: string = "class_map_";

    @observable
    sources: MapSourceStore[] = [];

    loadFromJson(json: IConfigMap){
        if (json.sources) json.sources.forEach(s => {
            let t: MapSourceStore = new MapSourceStore(this);
            t.sourceId = MapRootStore.prefix+ s.sourceId;
            t.sourceNativeJson = s.nativeSource;
            this.sources.push(t);
        });
        if (json.rootFolder){
            loadFolder(json.rootFolder, this.rootFolder);
        }

        function loadFolder(json: IConfigMapFolderItem, folder: MapFolderItemStore){
            folder.items = [];
            if (json.itemId != null) folder.itemId = json.itemId; else folder.itemId = Utils.randomStr();
            if (json.title != null) folder.title = json.title;
            if (json.visible != null) folder.visible = json.visible;
            if (json.items != null){
                json.items.forEach(j2 => {
                    let t: MapItemType = MapItemType.layer;
                    if (j2.itemType == MapItemType.folder) t = MapItemType.folder;
                    if (t == MapItemType.folder){
                        let f2 = new MapFolderItemStore(folder);
                        loadFolder(j2 as IConfigMapFolderItem, f2);
                        folder.items.push(f2);
                    }
                    if (t == MapItemType.layer){
                        loadLayer(j2 as IConfigMapLayerItem, folder);
                    }
                });
            }
        }
        function loadLayer(json: IConfigMapLayerItem, folder: MapFolderItemStore){
            let q: MapLayerItemStore = new MapLayerItemStore(folder);
            if (json.itemId != null) q.itemId = json.itemId; else q.itemId = Utils.randomStr();
            if (json.title != null) q.title = json.title;
            if (json.visible != null) q.visible = json.visible;
            q.sourceId = MapRootStore.prefix+ json.sourceId;
            q.sourceLayer = json.sourceLayer;
            folder.items.push(q);
            json.sublayers.forEach(sl =>{
                let z: MapLayerSubLayerStore = new MapLayerSubLayerStore(q);
                //let layerId =
                if (sl.layerId != null) z.layerId = MapRootStore.prefix+ sl.layerId; else z.layerId = MapRootStore.prefix+ Utils.randomStr();
                z.layerJson = sl.nativeLayer;
                q.subLayers.push(z);
            });
        }
    }
}
import {MapFolderItemStore} from "./MapFolderItemStore";
import {MapSourceStore} from "./MapSourceStore";
import {observable} from "mobx";
import {IConfigMap, IConfigMapFolderItem, IConfigMapLayerItem, MapItemType} from "../../config/MapConfigInterfaces";
import {MapLayerItemStore} from "./MapLayerItemStore";
import {MapLayerSubLayerStore} from "./MapLayerSubLayerStore";
import {Utils} from "../../../helper/utils/Utils";
