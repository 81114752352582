import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoExportPanelComp.scss'
import {action, observable} from "mobx";
import classNames from "classnames";
import {AgroAhoRectCheckboxSvg} from "./AgroAhoRectCheckboxSvg";
import {IAgroAhoStoreProps} from "../agroAhoPlugin";
import {AgroAhoRoundCheckboxSvg} from "./AgroAhoRoundCheckboxSvg";
import {AhoCardShowType, AhoIndicatorFormat} from "../agroAhoCardStore";
import {AgroAhoSwitcherSvg} from "./icons/AgroAhoSwitcherSvg";



@observer
export class AgroAhoExportPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    // @observable
    // cartogramChecked: boolean = true;
    // @observable
    // indicatorsChecked: boolean = false;
    // @observable
    // cellsChecked: boolean = false;
    // @observable
    // visFieldsChecked: boolean = false;
    // @observable
    // ahoCardShowType: AhoCardShowType = AhoCardShowType.Cells;
    // @observable
    // indcFormat: AhoIndicatorFormat = AhoIndicatorFormat.AgroSoft;

    @observable
    spinnerShow: boolean = false;

    // @action
    // onSettingsCellClick(){
    //     if (!this.cartogramChecked) return;
    //     this.ahoCardShowType = AhoCardShowType.Cells;
    // }
    //
    // @action
    // onSettingsIndcClick(){
    //     if (!this.cartogramChecked) return;
    //     this.ahoCardShowType = AhoCardShowType.Indicators;
    // }
    //
    // @action
    // onSettingsAgroSoftClick(){
    //     if (!this.cartogramChecked) return;
    //     this.indcFormat = AhoIndicatorFormat.AgroSoft;
    // }
    //
    // @action
    // onSettingsAgroplemClick(){
    //     if (!this.cartogramChecked) return;
    //     this.indcFormat = AhoIndicatorFormat.Agroplem;
    // }

    @action
    onCartogramClick(){
        let cs = this.props.store.agroAhoStore.cardStore;
        cs.cartogramChecked = !cs.cartogramChecked;
    }

    @action
    onCellsClick(){
        let cs = this.props.store.agroAhoStore.cardStore;
        cs.cellsChecked = !cs.cellsChecked;
    }

    @action
    onIndicatorsClick(){
        let cs = this.props.store.agroAhoStore.cardStore;
        cs.indicatorsChecked = !cs.indicatorsChecked;
    }

    @action
    onClickExport(){
        let store_ = this.props.store.agroAhoStore;
        let cs = store_.cardStore;
        if ((!cs.cartogramChecked && !cs.indicatorsChecked && !cs.cellsChecked) || this.spinnerShow) return;
        this.spinnerShow = true;
        store_.fileStore.doExport(this.exportCallback);
        // store_.fileStore.doExport(store_.cardStore.card.map_id, cs.cartogramChecked, this.indicatorsChecked,
        //     store_.cardStore.ahoCardShowType, store_.cardStore.indcFormat == AhoIndicatorFormat.AgroSoft,
        //     store_.cardStore.exportVisFields);
        // store_.toggleModal(false);
    }

    exportCallback(res: string){
        this.spinnerShow = false;
    }

    @action
    onClickCancel(){
        let store_ = this.props.store.agroAhoStore;
        store_.fileStore.cancelExport = true;
        store_.toggleModal(false);
    }

    @action
    onClickVisFields(){
        let cstore = this.props.store.agroAhoStore.cardStore;
        if (!cstore.getFieldSelected()?.length) return;
        cstore.exportVisFields = !cstore.exportVisFields;
        // this.visFieldsChecked = !this.visFieldsChecked;
    }

    onExpFileNameChange(e: any){
        let cstore = this.props.store.agroAhoStore.cardStore;
        cstore.exportFileName = e?.currentTarget?.value;
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let cstore = store_.cardStore;
        let tstore = store_.tourStore;
        let disabled = (!cstore.cartogramChecked && !cstore.indicatorsChecked && !cstore.cellsChecked) ||
            this.spinnerShow;
        let type = cstore.ahoCardShowType;
        let labels = store_.trans["Without labels"];
        if (type == AhoCardShowType.Cells) labels = store_.trans["Cell numbers"];
        if (type == AhoCardShowType.Indicators) labels = store_.trans["Meaning of indicators"];
        let scale = cstore.indcFormat == AhoIndicatorFormat.AgroSoft ? store_.trans["Agro-Soft"] :
            store_.trans["AGROPLEM"];
        let tours = tstore.selTours.map(t=>t.tour_name).join(',');

        return <div className="AgroAhoExportPanelComp-main">
            <div className="AgroAhoExportPanelComp-title">{store_.trans['Export of AXO indicators']}</div>

            <div className={classNames("AgroAhoExportPanelComp-spinner", {
                "AgroAhoExportPanelComp-hidden": !this.spinnerShow})} >
                <div>
                    <div className="AgroAhoExportPanelComp-ls spinner"/>
                    <div className="AgroAhoExportPanelComp-spinnerMsg">
                        {store_.trans['Preparations are underway for export']}...</div>
                </div>
            </div>
            <div className={classNames("AgroAhoExportPanelComp-content", {
                "AgroAhoExportPanelComp-hidden": this.spinnerShow})} >

                <div className="AgroAhoExportPanelComp-msg">
                    <span>{store.trans["Indicators:"]}&nbsp;</span>
                    <span>{cstore.cardIndicators.length}</span>
                </div>
                <div className="AgroAhoExportPanelComp-msg">
                    <span>{store_.trans["Cell labels"]}&nbsp;(pdf):&nbsp;</span>
                    <span>{labels.toLowerCase()}</span>
                </div>
                <div className="AgroAhoExportPanelComp-msg">
                    <span>{store_.trans["Scale and palette type"]}&nbsp;(pdf):&nbsp;</span>
                    <span>{scale}</span>
                </div>
                {!!tours && <div className="AgroAhoExportPanelComp-msg">
                    <span>{store_.trans.Tours}:&nbsp;</span>
                    <span>{tours}</span>
                </div>}
    
                <div className="AgroAhoExportPanelComp-fileNameTitle">
                    <div>{store_.trans['File name']}</div>
                    <div className="AgroAhoExportPanelComp-zip">ZIP</div>
                </div>
                <input type="text" className="AgroAhoExportPanelComp-fileName text-box-editor"
                       value={cstore.exportFileName} onChange={this.onExpFileNameChange}/>
    
                <div className="AgroAhoExportPanelComp-formatTitleCover">
                    <div className="AgroAhoExportPanelComp-sectTitle">{store.trans.Format}</div>
                    {/*<div className="AgroAhoExportPanelComp-sectLine"> </div>*/}
                </div>
    
                <div className="AgroAhoExportPanelComp-checkboxCover" onClick={this.onIndicatorsClick}>
                    <AgroAhoRectCheckboxSvg className="AgroAhoExportPanelComp-formatCheckbox"
                                                   checked={cstore.indicatorsChecked}/>
                    <span className="AgroAhoExportPanelComp-formatCaption">
                        {store_.trans['Tabular file, all indicators (.ods)']}</span>
                </div>
                <div className="AgroAhoExportPanelComp-checkboxCover" onClick={this.onCartogramClick}>
                    <AgroAhoRectCheckboxSvg className="AgroAhoExportPanelComp-formatCheckbox"
                                                   checked={cstore.cartogramChecked}/>
                    <span className="AgroAhoExportPanelComp-formatCaption">
                        {store_.trans['Cartograms, all indicators (.pdf)']}</span>
                </div>
                <div className="AgroAhoExportPanelComp-checkboxCover" onClick={this.onCellsClick}>
                    <AgroAhoRectCheckboxSvg className="AgroAhoExportPanelComp-formatCheckbox"
                                            checked={cstore.cellsChecked}/>
                    <span className="AgroAhoExportPanelComp-formatCaption">
                        {store_.trans['Cells, all indicators (.shp)']}</span>
                </div>
                <div className={classNames("AgroAhoExportPanelComp-checkboxCover AgroAhoExportPanelComp-switcherCover",
                    {"AgroAhoExportPanelComp-switcherDisabled": !cstore.getFieldSelected()?.length})} onClick={this.onClickVisFields}>
                    <AgroAhoSwitcherSvg className="AgroAhoExportPanelComp-switcher" checked={cstore.exportVisFields}/>
                    <span className="AgroAhoExportPanelComp-formatCaption">{store_.trans["Export only selected fields"]}</span>
                </div>
            </div>

            {/*<div className="AgroAhoExportPanelComp-exportTitleCover">*/}
            {/*    <div className="AgroAhoExportPanelComp-sectTitle">{store_.trans["Map export settings"]}</div>*/}
            {/*    <div className="AgroAhoExportPanelComp-sectLine"> </div>*/}
            {/*</div>*/}

            {/*<div className={classNames("AgroAhoExportPanelComp-settingsCover",*/}
            {/*    {"AgroAhoExportPanelComp-disabled": !this.cartogramChecked})}>*/}
            {/*    <div className="AgroAhoExportPanelComp-settingsCells">*/}
            {/*        <div className="AgroAhoExportPanelComp-settingsTitle">{store.trans["Cell labels"]}</div>*/}
            {/*        <div className="AgroAhoExportPanelComp-checkboxCover" onClick={this.onSettingsCellClick}>*/}
            {/*            <AgroAhoRoundCheckboxSvg checked={this.ahoCardShowType == AhoCardShowType.Cells}/>*/}
            {/*            <span className="AgroAhoExportPanelComp-formatCaption">{store_.trans["Cell numbers"]}</span>*/}
            {/*        </div>*/}
            {/*        <div className="AgroAhoExportPanelComp-checkboxCover" onClick={this.onSettingsIndcClick}>*/}
            {/*            <AgroAhoRoundCheckboxSvg checked={this.ahoCardShowType == AhoCardShowType.Indicators}/>*/}
            {/*            <span className="AgroAhoExportPanelComp-formatCaption">{store.trans["Meaning of indicators"]}</span>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="AgroAhoExportPanelComp-settingsScale">*/}
            {/*        <div className="AgroAhoExportPanelComp-settingsTitle">{store.trans["Scale and palette type"]}</div>*/}
            {/*        <div className="AgroAhoExportPanelComp-checkboxCover" onClick={this.onSettingsAgroSoftClick}>*/}
            {/*            <AgroAhoRoundCheckboxSvg checked={this.indcFormat == AhoIndicatorFormat.AgroSoft}/>*/}
            {/*            <span className="AgroAhoExportPanelComp-formatCaption">Агро-Софт</span>*/}
            {/*        </div>*/}
            {/*        <div className="AgroAhoExportPanelComp-checkboxCover" onClick={this.onSettingsAgroplemClick}>*/}
            {/*            <AgroAhoRoundCheckboxSvg checked={this.indcFormat == AhoIndicatorFormat.Agroplem}/>*/}
            {/*            <span className="AgroAhoExportPanelComp-formatCaption">АГРОПЛЕМ</span>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}


            <div className="AgroAhoExportPanelComp-line"> </div>

            <div className="AgroAhoExportPanelComp-buttons">
                <button className="large-button left-sidebar-cancel button-jump AgroAhoExportPanelComp-cancelButton"
                    onClick={this.onClickCancel}>{store_.trans.Cancel}</button>
                <button className={classNames("large-button left-sidebar-active AgroAhoExportPanelComp-exportButton", {
                    "button-jump": !disabled}, {"AgroAhoExportPanelComp-disabled": disabled})}
                    onClick={this.onClickExport}>{store_.trans.Export}</button>
            </div>
        </div>
    }
}
