import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";
import {manyCheck} from "../../helper/utils/Utils";

@observer
export class LogoSvgComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    render() {
        let store = this.props.store;
        if (manyCheck(()=>store.config.theme, ()=>store.config.theme.logo)){
            return <img src={store.config.theme.logo}/>;
        }

        return <svg xmlns="http://www.w3.org/2000/svg" width="100" height="46" viewBox="0 0 100 46" fill="none">
        <g clipPath="url(#clip0_202_602)">
        <path d="M28.0867 36.9601C27.22 36.9601 26.4145 36.8234 25.6702 36.5498C24.936 36.2762 24.2988 35.8912 23.7584 35.3947C23.218 34.8881 22.7948 34.2954 22.4889 33.6166C22.1932 32.9377 22.0454 32.1981 22.0454 31.3977C22.0454 30.5871 22.1932 29.8475 22.4889 29.1788C22.7948 28.4999 23.218 27.9123 23.7584 27.4158C24.2988 26.9092 24.936 26.5191 25.6702 26.2456C26.4145 25.972 27.22 25.8352 28.0867 25.8352C28.9635 25.8352 29.769 25.972 30.5032 26.2456C31.2373 26.5191 31.8746 26.9092 32.415 27.4158C32.9554 27.9123 33.3734 28.4999 33.6691 29.1788C33.975 29.8475 34.1279 30.5871 34.1279 31.3977C34.1279 32.1981 33.975 32.9377 33.6691 33.6166C33.3734 34.2954 32.9554 34.8881 32.415 35.3947C31.8746 35.8912 31.2373 36.2762 30.5032 36.5498C29.769 36.8234 28.9635 36.9601 28.0867 36.9601ZM28.0867 34.0573C28.4231 34.0573 28.7341 33.9965 29.0196 33.8749C29.3153 33.7534 29.5702 33.5811 29.7843 33.3582C30.0087 33.1252 30.182 32.8465 30.3043 32.5223C30.4267 32.188 30.4879 31.8131 30.4879 31.3977C30.4879 30.9823 30.4267 30.6124 30.3043 30.2882C30.182 29.9539 30.0087 29.6752 29.7843 29.4523C29.5702 29.2193 29.3153 29.042 29.0196 28.9204C28.7341 28.7988 28.4231 28.738 28.0867 28.738C27.7502 28.738 27.4341 28.7988 27.1384 28.9204C26.8529 29.042 26.598 29.2193 26.3737 29.4523C26.1596 29.6752 25.9913 29.9539 25.869 30.2882C25.7466 30.6124 25.6854 30.9823 25.6854 31.3977C25.6854 31.8131 25.7466 32.188 25.869 32.5223C25.9913 32.8465 26.1596 33.1252 26.3737 33.3582C26.598 33.5811 26.8529 33.7534 27.1384 33.8749C27.4341 33.9965 27.7502 34.0573 28.0867 34.0573Z" fill="#C5C5C5"/>
        <path d="M15.4864 30.0298H20.2582V32.6135H15.4864V30.0298ZM15.7311 34.0117H21.0841V36.7169H12.1828V26.0784H20.8853V28.7836H15.7311V34.0117Z" fill="#C5C5C5"/>
        <path d="M5.98009 36.9601C5.11341 36.9601 4.313 36.8284 3.57888 36.565C2.85494 36.2914 2.22278 35.9064 1.68238 35.4099C1.15217 34.9135 0.739227 34.3258 0.443536 33.647C0.147845 32.9681 0 32.2184 0 31.3977C0 30.577 0.147845 29.8272 0.443536 29.1484C0.739227 28.4695 1.15727 27.8819 1.69767 27.3854C2.23807 26.8889 2.87534 26.509 3.60947 26.2456C4.35379 25.972 5.16949 25.8352 6.05656 25.8352C7.11697 25.8352 8.06012 26.0125 8.88601 26.3671C9.7221 26.7218 10.4103 27.2284 10.9507 27.8869L8.6413 29.9083C8.30483 29.5233 7.93776 29.2345 7.54011 29.042C7.15265 28.8393 6.71931 28.738 6.24009 28.738C5.84244 28.738 5.48047 28.7988 5.15419 28.9204C4.83811 29.042 4.56791 29.2193 4.34359 29.4523C4.11928 29.6854 3.94594 29.964 3.82359 30.2882C3.70123 30.6124 3.64005 30.9823 3.64005 31.3977C3.64005 31.7928 3.70123 32.1576 3.82359 32.4919C3.94594 32.8162 4.11928 33.0948 4.34359 33.3278C4.56791 33.5609 4.83301 33.7432 5.1389 33.8749C5.45498 33.9965 5.80675 34.0573 6.19421 34.0573C6.60206 34.0573 6.99971 33.9915 7.38717 33.8597C7.77462 33.7179 8.18757 33.4798 8.62601 33.1454L10.6449 35.6075C9.9923 36.0432 9.24798 36.3775 8.41189 36.6106C7.5758 36.8436 6.7652 36.9601 5.98009 36.9601ZM7.50952 35.1516V31.1089H10.6449V35.6075L7.50952 35.1516Z" fill="#C5C5C5"/>
        <path d="M83.4508 36.9601C82.5535 36.9601 81.6868 36.8588 80.8507 36.6562C80.0147 36.4535 79.3264 36.1901 78.786 35.8659L79.9484 33.2518C80.4582 33.5457 81.0241 33.7838 81.6461 33.9661C82.2782 34.1384 82.89 34.2245 83.4814 34.2245C83.828 34.2245 84.0982 34.2042 84.292 34.1637C84.4959 34.113 84.6437 34.0472 84.7355 33.9661C84.8273 33.8749 84.8732 33.7686 84.8732 33.647C84.8732 33.4545 84.7661 33.3025 84.552 33.191C84.3379 33.0796 84.0524 32.9884 83.6955 32.9175C83.3488 32.8364 82.9665 32.7554 82.5484 32.6743C82.1304 32.5831 81.7072 32.4666 81.279 32.3248C80.8609 32.1829 80.4735 31.9955 80.1166 31.7624C79.7699 31.5294 79.4896 31.2254 79.2754 30.8505C79.0613 30.4655 78.9542 29.9893 78.9542 29.4219C78.9542 28.7634 79.1378 28.1656 79.5048 27.6286C79.8821 27.0814 80.4378 26.6458 81.1719 26.3215C81.9163 25.9973 82.839 25.8352 83.9402 25.8352C84.6641 25.8352 85.3779 25.9112 86.0814 26.0632C86.785 26.2152 87.4171 26.4482 87.9779 26.7623L86.892 29.3611C86.3618 29.0977 85.8469 28.9001 85.3473 28.7684C84.8579 28.6367 84.3786 28.5708 83.9096 28.5708C83.5629 28.5708 83.2876 28.6012 83.0837 28.662C82.8798 28.7228 82.732 28.8039 82.6402 28.9052C82.5586 29.0065 82.5178 29.118 82.5178 29.2396C82.5178 29.4219 82.6249 29.5688 82.839 29.6803C83.0531 29.7816 83.3335 29.8677 83.6802 29.9387C84.0371 30.0096 84.4245 30.0856 84.8426 30.1666C85.2708 30.2477 85.694 30.3591 86.112 30.501C86.53 30.6428 86.9124 30.8303 87.2591 31.0633C87.6159 31.2964 87.9014 31.6003 88.1156 31.9752C88.3297 32.3501 88.4367 32.8162 88.4367 33.3734C88.4367 34.0219 88.2481 34.6196 87.8709 35.1668C87.5038 35.7038 86.9532 36.1394 86.2191 36.4738C85.4849 36.798 84.5622 36.9601 83.4508 36.9601Z" fill="#41ACB2"/>
        <path d="M73.399 36.9601C72.5017 36.9601 71.635 36.8588 70.7989 36.6562C69.9628 36.4535 69.2746 36.1901 68.7342 35.8659L69.8966 33.2518C70.4064 33.5457 70.9723 33.7838 71.5942 33.9661C72.2264 34.1384 72.8382 34.2245 73.4296 34.2245C73.7762 34.2245 74.0464 34.2042 74.2402 34.1637C74.4441 34.113 74.5919 34.0472 74.6837 33.9661C74.7755 33.8749 74.8213 33.7686 74.8213 33.647C74.8213 33.4545 74.7143 33.3025 74.5002 33.191C74.286 33.0796 74.0005 32.9884 73.6437 32.9175C73.297 32.8364 72.9146 32.7554 72.4966 32.6743C72.0786 32.5831 71.6554 32.4666 71.2272 32.3248C70.8091 32.1829 70.4217 31.9955 70.0648 31.7624C69.7181 31.5294 69.4377 31.2254 69.2236 30.8505C69.0095 30.4655 68.9024 29.9893 68.9024 29.4219C68.9024 28.7634 69.086 28.1656 69.453 27.6286C69.8303 27.0814 70.386 26.6458 71.1201 26.3215C71.8644 25.9973 72.7872 25.8352 73.8884 25.8352C74.6123 25.8352 75.3261 25.9112 76.0296 26.0632C76.7331 26.2152 77.3653 26.4482 77.9261 26.7623L76.8402 29.3611C76.31 29.0977 75.7951 28.9001 75.2955 28.7684C74.806 28.6367 74.3268 28.5708 73.8578 28.5708C73.5111 28.5708 73.2358 28.6012 73.0319 28.662C72.828 28.7228 72.6801 28.8039 72.5884 28.9052C72.5068 29.0065 72.466 29.118 72.466 29.2396C72.466 29.4219 72.5731 29.5688 72.7872 29.6803C73.0013 29.7816 73.2817 29.8677 73.6284 29.9387C73.9852 30.0096 74.3727 30.0856 74.7907 30.1666C75.219 30.2477 75.6421 30.3591 76.0602 30.501C76.4782 30.6428 76.8606 30.8303 77.2073 31.0633C77.5641 31.2964 77.8496 31.6003 78.0637 31.9752C78.2779 32.3501 78.3849 32.8162 78.3849 33.3734C78.3849 34.0219 78.1963 34.6196 77.819 35.1668C77.452 35.7038 76.9014 36.1394 76.1672 36.4738C75.4331 36.798 74.5104 36.9601 73.399 36.9601Z" fill="#41ACB2"/>
        <path d="M55.9226 36.7169L60.6027 26.0784H64.151L68.831 36.7169H65.0992L61.6427 27.7805H63.0498L59.5933 36.7169H55.9226Z" fill="#41ACB2"/>
        <path d="M47.4962 36.7169V26.0784H51.1056V33.9357H55.9387V36.7169H47.4962Z" fill="#41ACB2"/>
        <path d="M41.6515 36.9601C40.795 36.9601 39.9997 36.8284 39.2656 36.565C38.5417 36.2914 37.9095 35.9064 37.3691 35.4099C36.8389 34.9135 36.426 34.3258 36.1303 33.647C35.8346 32.9681 35.6867 32.2184 35.6867 31.3977C35.6867 30.577 35.8346 29.8272 36.1303 29.1484C36.426 28.4695 36.8389 27.8819 37.3691 27.3854C37.9095 26.8889 38.5417 26.509 39.2656 26.2456C39.9997 25.972 40.795 25.8352 41.6515 25.8352C42.7017 25.8352 43.6296 26.0176 44.4351 26.3823C45.2508 26.7471 45.9237 27.2739 46.454 27.9629L44.1751 29.9843C43.859 29.5891 43.5072 29.2852 43.1198 29.0724C42.7425 28.8495 42.3143 28.738 41.8351 28.738C41.4578 28.738 41.1162 28.7988 40.8103 28.9204C40.5045 29.042 40.2394 29.2193 40.015 29.4523C39.8009 29.6854 39.6327 29.9691 39.5103 30.3034C39.388 30.6276 39.3268 30.9924 39.3268 31.3977C39.3268 31.803 39.388 32.1728 39.5103 32.5071C39.6327 32.8313 39.8009 33.11 40.015 33.343C40.2394 33.5761 40.5045 33.7534 40.8103 33.8749C41.1162 33.9965 41.4578 34.0573 41.8351 34.0573C42.3143 34.0573 42.7425 33.9509 43.1198 33.7382C43.5072 33.5153 43.859 33.2062 44.1751 32.8111L46.454 34.8324C45.9237 35.5113 45.2508 36.0381 44.4351 36.413C43.6296 36.7778 42.7017 36.9601 41.6515 36.9601Z" fill="#41ACB2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M87.0935 44.9393C91.8722 41.4891 95.3301 36.2314 96.3387 29.9778C98.3608 17.4402 89.7718 5.64754 77.1547 3.63821C64.5376 1.62888 52.6702 10.1638 50.6481 22.7014C50.5976 23.0148 50.5537 23.3277 50.5163 23.6401C52.9501 11.6228 64.1152 3.50194 75.8977 5.37836C87.9891 7.30396 96.1519 19.0287 94.1298 31.5664C93.2683 36.9084 90.6967 41.536 87.0935 44.9393Z" fill="url(#paint0_linear_202_602)"/>
        </g>
        <defs>
        <linearGradient id="paint0_linear_202_602" x1="48.009" y1="23" x2="90.635" y2="46.2255" gradientUnits="userSpaceOnUse">
        <stop stopColor="#41ACB2" stopOpacity="0"/>
        <stop offset="0.490625" stopColor="#41ACB2"/>
        <stop offset="1" stopColor="#41ACB2" stopOpacity="0"/>
        </linearGradient>
        <clipPath id="clip0_202_602">
        <rect width="100" height="46" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        
        /*
        return <svg width="100" height="21" viewBox="0 0 100 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M37.0602 17.2791C35.7619 17.2791 34.5849 17.0024 33.5293 16.4489C32.4857 15.8835 31.6606 15.1074 31.0539 14.1209C30.4594 13.1223 30.1621 11.9974 30.1621 10.7461C30.1621 9.49486 30.4594 8.37595 31.0539 7.38939C31.6606 6.39079 32.4857 5.61478 33.5293 5.06134C34.5849 4.49587 35.768 4.21313 37.0784 4.21313C38.1826 4.21313 39.1776 4.40563 40.0633 4.79064C40.9612 5.17564 41.7135 5.72907 42.3202 6.45095L40.4274 8.18345C39.5659 7.19689 38.4981 6.70361 37.224 6.70361C36.4353 6.70361 35.7316 6.87806 35.1127 7.22697C34.4939 7.56384 34.0085 8.03907 33.6567 8.65267C33.3169 9.26626 33.147 9.96408 33.147 10.7461C33.147 11.5281 33.3169 12.226 33.6567 12.8395C34.0085 13.4531 34.4939 13.9344 35.1127 14.2833C35.7316 14.6202 36.4353 14.7886 37.224 14.7886C38.4981 14.7886 39.5659 14.2893 40.4274 13.2907L42.3202 15.0232C41.7135 15.7571 40.9612 16.3166 40.0633 16.7016C39.1654 17.0866 38.1644 17.2791 37.0602 17.2791Z" fill="#4DB6BC"/>
            <path d="M46.6775 4.4297H49.626V14.6803H56.0145V17.0625H46.6775V4.4297Z" fill="#4DB6BC"/>
            <path d="M68.5252 14.3555H62.61L61.4815 17.0625H58.4602L64.1389 4.4297H67.051L72.7478 17.0625H69.6537L68.5252 14.3555ZM67.597 12.1357L65.5767 7.29915L63.5564 12.1357H67.597Z" fill="#4DB6BC"/>
            <path d="M80.6219 17.2791C79.6148 17.2791 78.6381 17.1467 77.6916 16.8821C76.7573 16.6053 76.005 16.2504 75.4347 15.8173L76.4358 13.6156C76.9818 14.0126 77.6309 14.3314 78.3832 14.572C79.1355 14.8127 79.8878 14.933 80.6401 14.933C81.4774 14.933 82.0962 14.8127 82.4966 14.572C82.897 14.3194 83.0973 13.9885 83.0973 13.5795C83.0973 13.2787 82.9759 13.032 82.7332 12.8395C82.5027 12.635 82.1993 12.4726 81.8232 12.3523C81.4592 12.232 80.9617 12.0996 80.3307 11.9552C79.36 11.7267 78.5653 11.4981 77.9464 11.2695C77.3276 11.0409 76.7937 10.6739 76.3448 10.1686C75.9079 9.66329 75.6895 8.98954 75.6895 8.14736C75.6895 7.41345 75.8897 6.75173 76.2902 6.1622C76.6906 5.56064 77.2912 5.0854 78.092 4.73649C78.905 4.38759 79.8939 4.21313 81.0588 4.21313C81.8717 4.21313 82.6665 4.30938 83.4431 4.50188C84.2196 4.69438 84.8991 4.9711 85.4816 5.33204L84.5715 7.55181C83.3945 6.89009 82.2175 6.55923 81.0406 6.55923C80.2155 6.55923 79.6027 6.69157 79.2023 6.95626C78.814 7.22095 78.6199 7.56986 78.6199 8.00298C78.6199 8.43611 78.8443 8.76095 79.2933 8.97751C79.7544 9.18204 80.4521 9.38658 81.3864 9.59111C82.3571 9.8197 83.1519 10.0483 83.7707 10.2769C84.3895 10.5055 84.9173 10.8664 85.3542 11.3597C85.8031 11.853 86.0276 12.5207 86.0276 13.3629C86.0276 14.0848 85.8213 14.7465 85.4088 15.3481C85.0083 15.9376 84.4016 16.4068 83.5887 16.7557C82.7757 17.1046 81.7868 17.2791 80.6219 17.2791Z" fill="#4DB6BC"/>
            <path d="M94.5946 17.2791C93.5875 17.2791 92.6107 17.1467 91.6643 16.8821C90.73 16.6053 89.9777 16.2504 89.4074 15.8173L90.4084 13.6156C90.9545 14.0126 91.6036 14.3314 92.3559 14.572C93.1082 14.8127 93.8605 14.933 94.6128 14.933C95.4501 14.933 96.0689 14.8127 96.4693 14.572C96.8697 14.3194 97.0699 13.9885 97.0699 13.5795C97.0699 13.2787 96.9486 13.032 96.7059 12.8395C96.4754 12.635 96.172 12.4726 95.7959 12.3523C95.4318 12.232 94.9344 12.0996 94.3034 11.9552C93.3327 11.7267 92.5379 11.4981 91.9191 11.2695C91.3003 11.0409 90.7664 10.6739 90.3174 10.1686C89.8806 9.66329 89.6622 8.98954 89.6622 8.14736C89.6622 7.41345 89.8624 6.75173 90.2628 6.1622C90.6632 5.56064 91.2639 5.0854 92.0647 4.73649C92.8777 4.38759 93.8666 4.21313 95.0314 4.21313C95.8444 4.21313 96.6392 4.30938 97.4157 4.50188C98.1923 4.69438 98.8718 4.9711 99.4542 5.33204L98.5442 7.55181C97.3672 6.89009 96.1902 6.55923 95.0132 6.55923C94.1881 6.55923 93.5754 6.69157 93.1749 6.95626C92.7867 7.22095 92.5925 7.56986 92.5925 8.00298C92.5925 8.43611 92.817 8.76095 93.2659 8.97751C93.727 9.18204 94.4247 9.38658 95.359 9.59111C96.3298 9.8197 97.1245 10.0483 97.7433 10.2769C98.3622 10.5055 98.89 10.8664 99.3268 11.3597C99.7758 11.853 100 12.5207 100 13.3629C100 14.0848 99.794 14.7465 99.3814 15.3481C98.981 15.9376 98.3743 16.4068 97.5613 16.7557C96.7484 17.1046 95.7595 17.2791 94.5946 17.2791Z" fill="#4DB6BC"/>
            <rect width="9.35822" height="1.66047" fill="#4DB6BC"/>
            <rect y="2.53952" width="9.35822" height="1.66047" fill="#4DB6BC"/>
            <rect y="5.07904" width="9.35822" height="1.66047" fill="#4DB6BC"/>
            <rect y="7.61859" width="9.35822" height="1.66047" fill="#4DB6BC"/>
            <rect x="21.2285" y="21" width="9.40747" height="1.66047" transform="rotate(-180 21.2285 21)" fill="#4DB6BC"/>
            <rect x="21.2285" y="18.4605" width="9.40747" height="1.66047" transform="rotate(-180 21.2285 18.4605)" fill="#4DB6BC"/>
            <rect x="21.2285" y="15.921" width="9.40747" height="1.66047" transform="rotate(-180 21.2285 15.921)" fill="#4DB6BC"/>
            <rect x="21.2285" y="13.3814" width="9.40747" height="1.66047" transform="rotate(-180 21.2285 13.3814)" fill="#4DB6BC"/>
            <rect x="21.2773" y="0.000213623" width="9.27909" height="1.67463" transform="rotate(90 21.2773 0.000213623)" fill="white"/>
            <rect x="18.7168" width="9.27909" height="1.67463" transform="rotate(90 18.7168 0)" fill="white"/>
            <rect x="16.1553" width="9.27909" height="1.67463" transform="rotate(90 16.1553 0)" fill="white"/>
            <rect x="13.5947" width="9.27909" height="1.67463" transform="rotate(90 13.5947 0)" fill="white"/>
            <rect y="21" width="9.27909" height="1.67463" transform="rotate(-90 0 21)" fill="white"/>
            <rect x="2.56055" y="21" width="9.27909" height="1.67463" transform="rotate(-90 2.56055 21)" fill="white"/>
            <rect x="5.12207" y="21" width="9.27909" height="1.67463" transform="rotate(-90 5.12207 21)" fill="white"/>
            <rect x="7.68457" y="21" width="9.27909" height="1.67463" transform="rotate(-90 7.68457 21)" fill="white"/>
        </svg>;*/
        
    }
}
