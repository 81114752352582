import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoCardCellItemComp.scss'
import {action} from "mobx";
import {IAgroAhoSuperStore} from "../../agroAhoPlugin";
import {CellItem} from "../../agroAhoCardStore";
import {AgroAhoRectCheckboxSvg} from "../AgroAhoRectCheckboxSvg";
import {AgroAhoZoomSvg} from "../icons/AgroAhoZoomSvg";
import classNames from "classnames";


export interface IAgroAhoCardCellItemCompProps {
    store: IAgroAhoSuperStore;
    cell: CellItem;
}

@observer
export class AgroAhoCardCellItemComp extends React.PureComponent<IAgroAhoCardCellItemCompProps, undefined> {
    constructor(props: IAgroAhoCardCellItemCompProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onZoomClick(e: any){
        this.props.store.agroAhoStore.cardStore.doCellZoomClick(this.props.cell);
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    render() {

        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let cell = this.props.cell;
        let area: any = cell.area_ha && parseFloat(cell.area_ha.toFixed(1));
        area = store_.numWithSpaces(area);

        return <div className={classNames("AgroAhoCardCellItemComp-main", {
            "AgroAhoCardCellItemComp-hidden": cell.filtered})}>
            <span className="AgroAhoCardCellItemComp-name">{cell.cell_id}</span>
            <div className="AgroAhoCardCellItemComp-rightCover">
                {area && <div className="AgroAhoCardCellItemComp-ha">
                    <span>{area}</span>
                    <span>&nbsp;{store.trans.ha}</span>
                </div>}
                <div className="AgroAhoCardCellItemComp-zoomCover" onClick={this.onZoomClick}>
                    <AgroAhoZoomSvg/>
                </div>
            </div>
        </div>
    }
}
