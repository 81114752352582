import {CustomStore, ObservableCustomStore} from "./CustomStore";
import {action, observable} from "mobx";
import {IOverlayConfig, IOverlayGroup, ITemporalGroupParams, TemporalTimeStyle} from "./config/ConfigStore";
import {save} from "./PermalinkDecor";
import {Utils} from "../helper/utils/Utils";
import {OverlayTool} from "./tools/class/OverlayTool";
import { Dictionary } from "lodash";
import { RasterSource, VectorSource } from "maplibre-gl";
import { isThrowStatement } from "typescript";

export class TemporalGroupStore extends ObservableCustomStore {
    constructor (parent: CustomStore, group: IOverlayGroup) {
        super(parent);        
        let tp: ITemporalGroupParams = group.temporalParams;
        this.group = group;
        this.defaultTemporalColumn = eval(tp.defaultTemporalColumn);
        this._start = eval(tp.start);
        this._end = eval(tp.end);
    }

    class(): string {return "TemporalGroupStore";}

    group: IOverlayGroup;
   
    @save @observable
    private _start? : any;
    public get start() : any {
        return this._start;
    }
    public set start(v : any) {
        this._start = v;
        this.group.overlays.forEach(ov => {
            this.root.map.overlays.prepareTemporalSource(ov);
        });
    }
    
    @save @observable
    private _end? : any;
    public get end() : any {
        return this._end;
    }
    public set end(v : any) {
        this._end = v;
        this.group.overlays.forEach(ov => {
            this.root.map.overlays.prepareTemporalSource(ov);
        });
    }

    public setPeriod(v0: any, v1: any) {
        this._start = v0;
        this._end = v1;
        this.group.overlays.forEach(ov => {
            this.root.map.overlays.prepareTemporalSource(ov);
        });
    }
    
    defaultTemporalColumn?: string;
}

export class OverlayObjectStore extends ObservableCustomStore {
    init() {
        let arr: string[] = [];
        this.root.config.map_layers.overlays.forEach((item) => {            
            if (Object.keys(item).indexOf('overlays') >= 0) { //is group
                let g = item as IOverlayGroup;
//                g.overlays.forEach((lr) => lr.group = g);                
                g.overlays.forEach((lr) => this._overlaysGroups.set(lr.id, g));
                if (g.isExpanded) //is expanded
                    arr.push(g.id);
                if (g.temporalParams)
                    this._temporalGroups.set(g.id, new TemporalGroupStore(this, g))
            }                
        });
        this._expandedGroups = arr;
    }

    class(): string {return "OverlayObjectStore";}

    private _overlaysGroups : Map<string, IOverlayGroup> = new Map();

    private _temporalGroups : Map<string, TemporalGroupStore> = new Map();
    temporalGroup(groupId: string) {
        return this._temporalGroups.get(groupId);
    }

    @save @observable
    private _activeOverlays : string[] = [];

    @save @observable
    private _expandedGroups : string[];

    @action
    setVisible(overlayId: string, value: boolean){
        if (value){
            if (this._activeOverlays.find(a => a == overlayId) == null){
                this._activeOverlays.push(overlayId);
            }
        }else {
            Utils.arrayRemoveByValue(this._activeOverlays, overlayId);
        }
    }

    getVisible(overlayId: string): boolean {
        return (this._activeOverlays.find(a => a == overlayId) != null);
    }

    public static getOverlayLayerName(sourceName: string, layerNum: number) {
        return OverlayTool.OVERLAY_LAYER_PREFIX + sourceName + '_' + layerNum.toString();
    }

    get visibleOverlays() : IOverlayConfig[] {
        let ovs: IOverlayConfig[] = [];
        this.overlayesConfig.forEach(ov => {
            if (this._activeOverlays.indexOf(ov.id) >= 0)
                ovs.push(ov);
        });
        return ovs;
    }

    get overlayesConfig(): IOverlayConfig[]{
        if (!this.root.config.map_layers) return [];
        if (!this.root.config.map_layers.overlays) return [];
        let arr: IOverlayConfig[] = [];
        this.root.config.map_layers.overlays.forEach((item) => {            
            if (Object.keys(item).indexOf('overlays') < 0) //is overlay
                arr.push(item as IOverlayConfig);
            else {
                let g = item as IOverlayGroup;
                g.overlays.forEach((lr) => arr.push(lr));
            }
        });
        return arr;
    }

    @action
    toggleGroup(groupId: string, value: boolean=null) {
        let items = this.root.config.map_layers.overlays;
        for (let i = 0; i < items.length; i++) {
            if (items[i].id != groupId || Object.keys(items[i]).indexOf('overlays') < 0) continue;
            let gr = items[i] as IOverlayGroup;
            if (value == null)
                value = !gr.isExpanded;
            gr.isExpanded = value;
            return;
        }
    }

    getGroup(groupId: string) : IOverlayGroup {
        let items = this.root.config.map_layers.overlays;
        for (let i = 0; i < items.length; i++) {
            if (items[i].id != groupId || Object.keys(items[i]).indexOf('overlays') < 0) continue;
            return items[i] as IOverlayGroup;
        }
    }

    isExpanded(groupId : string) {
        return this._expandedGroups.indexOf(groupId) >= 0;
    }
    
    @action
    setExpanded(groupId : string, value: boolean) {
        let idx = this._expandedGroups.indexOf(groupId);
        if (value && idx < 0)
            this._expandedGroups.push(groupId);
        else if (!value && idx >= 0)
            Utils.arrayRemoveByIndex(this._expandedGroups, idx);
    }

    prepareTemporalSource(ov: IOverlayConfig) {
        let group = this._overlaysGroups.get(ov.id);
        if (! group?.temporalParams || ["raster", "vector"].indexOf(ov.source.type) == -1) return;
        let src: RasterSource | VectorSource = ov.source as RasterSource | VectorSource;
        let dateLimit: any = {}; 
        let tg = this.temporalGroup(group.id);
        if (tg.defaultTemporalColumn && (tg.start || tg.end)) {
            if (tg.start)
                dateLimit['$>='] = tg.start;
            if (tg.end)
                dateLimit['$<='] = tg.end;
            let fObj: any = {};
            fObj[tg.defaultTemporalColumn] = dateLimit;
            for (let i = 0; i < src.tiles.length; i++) {
                let url = new URL(src.tiles[i]);
                let curFilter: any = null;
                if (url.searchParams.has("filter")) {
                    const entries = Array.from(url.searchParams.entries());
                    url.search = "";
                    entries.forEach(item => { //without urlencode! z={z}
                        if (item[0] != "filter")
                            url.search += (url.search != ''? '&' : '?') + `${item[0]}=${item[1]}`;
                        else
                            curFilter = JSON.parse(item[1]);
                    });
                }
                if (curFilter) { //есть текущий фильтр
                    let andOperation : any = curFilter["$and"];
                    //если уже есть временнОе условие само либо в and - меняем
                    if (curFilter.hasOwnProperty(tg.defaultTemporalColumn)) {
                        curFilter[tg.defaultTemporalColumn] = dateLimit;
                    }
                    else if (andOperation) {
                        let exists : boolean = false;
                        for (let i = andOperation.length - 1; i >= 0; i--) {
                            if (andOperation[i].hasOwnProperty(tg.defaultTemporalColumn)) {
                                andOperation[i][tg.defaultTemporalColumn] = dateLimit;
                                exists = true;
                                break;
                            }
                        }
                        if (! exists)
                            andOperation.push(fObj);
                    }
                    else
                        curFilter = {"$and": [curFilter, fObj]}
                }
                else
                    curFilter = fObj;
                url.search += (url.search != ''? '&' : '?') + `filter=${JSON.stringify(curFilter)}`;
                src.tiles[i] = url.toString();
            }
        }
    }

}

