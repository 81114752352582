import {AgroAhoTranslate_en} from "./AgroAhoTranslate_en";
import * as React from "react";

export class AgroAhoTranslate_ru extends AgroAhoTranslate_en{
    "Upload cells"="Загрузить ячейки"
    "Uploading cells"="Загрузка ячеек"
    "You can upload cells in GeoJSON, KML, or SHP format in a zip archive."="Можно загружать ячейки в формате GeoJSON, KML, либо SHP в zip-архиве."
    "Attention"="Внимание"
    "Loading new cells will delete existing cells, tracks, and metrics (if any)."="В процессе загрузки новых ячеек будут удалены уже существующие ячейки, треки и показатели (если они имеются)."
    "Select a file"="Выбрать файл"
    "Selecting a column with cell numbers"="Выбор колонки с номерами ячеек"
    "Below you need to select the column from your table that matches the cell numbers."="Ниже вам нужно выбрать колонку из вашей таблицы, которая соответствует номерам ячеек."
    "Column"="Колонка"
    "Cancel"="Отменить"
    "Download"="Загрузить"
    "Loading error"="Ошибка загрузки"
    "Save"="Сохранить"
    "Generate"="Сгенерировать"
    "Example of values in the selected column:"="Пример значений в выбранной колонке:"
    "Cells processed successfully"="Ячейки успешно обработаны"
    "There are remarks"="Есть замечания"
    "File loaded with errors. Fix the following errors"="Файл загружен с ошибками. Исправьте следующие ошибки"
    "Cell number"="Номер ячейки"
    "Remarks"="Замечания"
    "Save error"="Ошибка сохранения"
    "Cell outlines loaded successfully"="Контура ячеек успешно загружены"
    "There are comments and errors"="Есть замечания и ошибки"
    "The file has errors"="В файле есть ошибки"
    "Remarks:"="Замечаний:"
    "Errors:"="Ошибок:"
    "Cell / field number"="Номер ячейки/поля"
    "Remark / error"="Замечание/ошибка"
    "Map export settings"="Настройки экпорта картограмм"
    "Cell numbers"="Номера ячеек"
    "Cell labels" = "Подписи ячеек"
    "Meaning of indicators" = "Значение показателей"
    "Meaning of indicators/rec. norm" = "Значения показателей/рек. норм"
    "Scale and palette type" = "Тип шкалы и палитры"
    "Agro-Soft" = "Агро-Софт"
    "AGROPLEM" = "АГРОПЛЕМ"
    "Visualization setup"="Настройка визуализации"
    "Default scale and palette"="Шкала и палитра по умолчанию"
    "Upload indicators" = "Загрузить показатели"
    "Agri. soil analysis maps (ASA maps)" = "Карты отбора проб (КОП)"
    "Attention! The selected indicator will be permanently deleted with all attached information" = "Внимание! Выбранный показатель будет безвозвратно удален со всей прикрепленной информацией"
    "sortByName"="По названию"
    "sortByDate"="По дате"
    "ASAs"="КОПов"
    "Without labels"="Без подписей"
    "To change a track, double click on the cell."="Чтобы изменить трек, сделайте двойной клик по ячейке."
    "To change the detour order, double-click on the field"="Чтобы изменить порядок объезда, сделайте двойной клик по полю"
    "Go out"="Выйти"
    "Cell"="Ячейка"
    "Field"="Поле"
    "Selected"="Выделено"
    "Clear"="Очистить"
    "Export only visible fields"="Экспортировать только видимые поля"
    "Export only selected fields"="Экспортировать только выделенные поля"
    "There are no norms set for the selected fields"="Для выделенных полей не заданы нормы"
    "Total fields"="Всего полей"
    "Search"="Поиск"
    "Select all"="Выделить все"
    "Total cells"="Всего ячеек"
    "Search by cell number"="Поиск по номеру ячейки"
    "Tours"="Туры"
    "Selection points"="Точки отбора"
    "Survey data is loaded by field, field survey data is organized into rounds. There can be several rounds in the system; for each cell, the data can be located in different rounds. For each tour, you must indicate the name and date of field work."="Данные обследований загружаются по полям, данные полевых обследований организованы в туры. В системе может быть несколько туров, для каждой ячейки данные могут находится в разных турах. Для каждого тура необходимо указать название и дату полевых работ."
    "On to a new tour"="В новый тур"
    "To an existing tour"="В существующий тур"
    "Where do you want to upload the indicators"="Куда вы хотите загрузить показатели"
    "Load cells from kml/geojson/shp to zip"="Загрузить ячейки из km/geojson/shp в zip"
    "Selected fields"="Выделено полей"
    "AXO data"="Данные АХО"
    "Introduction cards"="Карты внесения"
    "Indicators"="Индикаторы"
    "Recommended norms"="Рекомендуемые нормы"
    "Download table"="Загрузить таблицу"
    "Next"="Далее"
    "Step 1. Select a tour"="Шаг 1. Выбор тура"
    "Choose from existing tours or create a new one"="Выберите из существующих туров или создайте новый"
    "Create a new tour"="Создать новый тур"
    "Tour selection"="Выбор тура"
    "Loading into an existing tour"="Загрузка в существующий тур"
    "Tour name"="Название тура"
    "Tour date"="Дата проведения тура"
    "Step 2. Loading a file with agrochemical indicators"="Шаг 2. Загрузка файла с агрохимическими показателями"
    "Step 3. Selecting a column with sample numbers"="Шаг 3. Выбор колонки с номерами проб"
    "The file was successfully processed and matches the sample"="Файл успешно обработан и соответствует образцу"
    "Tour"="Тур"
    "Below you need to select a column from the loaded table that corresponds to the cell numbers"="Ниже вам нужно выбрать колонку из загруженной таблицы, которая соответствует номерам ячеек"
    "Examples of values in the selected column"="Примеры значений в выбранной колонке"
    "Column title"="Название колонки"
    "Step 4: Configure Compliance"="Шаг 4. Настройка соответствия"
    "The selected column contains incorrect values!"="Выбранная колонка содержит неправильные значения!"
    "Match the cells in your table to what constitutes their essence. If necessary, you can choose which cells to load and which not"="Сопоставьте ячейки в вашей таблице с тем, что составляет их сущность. При необходимости вы можете выбрать, какие ячейки загружать, а какие нет"
    "loading?"="Загружаем?"
    "Values"="Значений"
    "Data type"="Тип данных"
    "Matching"="Соответствие"
    "Indicator"="Показатель"
    "Recom. norm"="Реком. норма"
    "kg/ha"="кг/га"
    "Not set"="Не задан"
    "Empty field"="Пустое поле"
    "yields"="урожайность"
    "c/ha"="ц/га"
    "for"="для"
    "Step 5: View value intersections and errors"="Шаг 5. Просмотр пересечений и ошибок обработки значений"
    "The tour already contains the following data, which is also contained in the downloadable table:"="В туре уже имеются следующие данные, которые содержатся и в загружаемой таблице:"
    "Cell values from intersecting data:"="Значения в ячейках по пересекающимся данным:"
    "Overwrite"="Перезаписать"
    "Do not overwrite"="Не перезаписывать"
    "The file was processed with errors. Correct the following errors in the Excel file:"="Файл обработан с ошибками. Исправьте следующие ошибки в Excel-файле:"
    "Data must be uploaded in OpenDocument (.ods) format. This format is supported in MS Excel, Google Sheets, OpenOffice and other applications. The table must have a column with sample numbers, and the sample numbers must correspond to the numbers of elementary sections/cells in the ASA Map."="Данные должны быть загружены в формате OpenDocument (.ods). Этот формат поддерживается в MS Excel, Google Sheets, OpenOffice и в др. приложениях. В таблице обязательно должна быть колонка с номерами проб, при этом номера проб должны соответствовать номерам элементарных участков/ячеек в КОПе."
    "Below you need to select a column from the loaded table that corresponds to the sample numbers (=cell numbers in the ASA Map). The values in this column can only be non-negative integers. Using these values, a comparison is made between the loaded data from the table and the cells (=sections) into which the field is divided."="Ниже вам нужно выбрать колонку из загруженной таблицы, которая соответствует номерам проб (=номерам ячеек в КОПе). Значения в этой колонке могут быть только целые неотрицательные числа. По этим значениям выполняется сопоставление между загружаемыми данными из таблицы и ячейками (=участками), на которые разбито поле."
    "File"="Файл"
    "Match the columns in the table with the data types and required parameters. You can choose which columns to load and which not."="Сопоставьте колонки в таблице с типами данных и необходимыми параметрами. Вы можете выбрать, какие колонки загружать, а какие нет."
    "Column with sample numbers"="Колонка с номерами проб"
    "There are non-numeric values"="Есть нечисловые значения"
    "Error creating tour"="Ошибка создания тура"
    "Attention! All indicator values will be removed from the tours: {0}"="Внимание! Все значения показателя будут удалены из туров: {0}"
    "Permalink loading error"="Ошибка загрузки пермалинка"
    "Attention! All tour {0} data will be deleted"="Внимание! Все данные тура {0} будут удалены"
    "Successfully deleted"="Успешно удалены"
    "duplicate"="дубликат"
    "repeat key"="повтор ключа"
    "row"="строка"
    "incorrect cell number"="некорректный номер ячейки"
    "Error deleting tour"="Ошибка удаления тура"
    "The file has been processed successfully!"="Файл успешно обработан!"
    "The data downloaded from the table file (AHO indicators, recommended application rates) must be assigned to a particular Tour. Grouping data by Tour allows, for example, to save a history of values for one cell over different seasons."="Загружаемые из табличного файла данные (показтели АХО, рекомендуемые нормы внесения) должны быть отнесены к какому-либо Туру. Группировка данных по Турам позволяет, например, сохранять историю значений для одной ячейки за различные сезоны."
    "Attention! All data for culture {0} will be deleted"="Внимание! Все данные для культуры {0} будут удалены"
    "Active substance (as)"="Действующее вещ.-во (дв)"
    "Fertilizers in phys. wt"="Удобрения в физ. весе"
    "Attention! All data fertilizer will be removed from the tours: {0}"="Внимание! Все данные об удобрениях будут удалены из туров: {0}"
    "Fields"="Полей"
    "Export of AXO indicators" = "Экспорт показателей АХО"
    "File name" = "Название файла"
    "Tabular file, all indicators (.ods)" = "Табличный файл, все показатели (.ods)"
    "Cartograms, all indicators (.pdf)" = "Картограммы, все показатели (.pdf)"
    "Cells, all indicators (.shp)" = "Ячейки, все показатели (.shp)"
    "Export of recommended norms" = "Экспорт рекомендуемых норм"
    "Tabular file, all norms (.ods)" = "Табличный файл, все нормы (.ods)"
    "grid file for technology (ISO-XML)" = "грид-файл для техники (ISO-XML)"
    "grid file (.shp)" = "грид-файл (.shp)"
    "Cell borders (.shp)" = "Границы ячеек (.shp)"
    "Grid grain size (m)" = "Размер зерна грида (м)"
    "Show field names" = "Показать названия полей"
    "Preparations are underway for export" = "Идет подготовка к экспорту"
    "Incorrect values" = "Неправильные значения"
    "Merge ISO-XML files" = "Объединить файлы ISO-XML"
    "Merge multiple ISO-XML" = "Объединение нескольких ISO-XML"
    "Merge" = "Объединить"
    "Merged file name" = "Название объединенного файла"
    "Merge error" = "Ошибка объединения"
    "Farm not specified"="Хозяйство не задано"
    "Error, more than 2 files selected"="Ошибка, выбрано больше 2 файлов"
    "Copy selected fields"="Копировать выделенные поля"
    "Remove selected fields from ASA map"="Удалить выделенные поля из КОПа"
    "Attention! For selected fields in the ASA map, all related data will be deleted: cells, indicators, recommended norms!"="Внимание! Для выделенных полей в КОПе будут удалены все связанные данные: ячейки, показатели, рекомендованные нормы!"
    "Copy to new ASA map"="Скопировать в новую КОП"
    "Copy to existing ASA map"="Скопировать в существующую КОП"
    "Copying selected fields"="Копирование выделенных полей"
    "cells"="ячейки"
    "New ASA map"="Новая КОП"
    "Search by ASA map"="Поиск по КОПам"
    "Copy"="Скопировать"
    "Add value to cell numbers"="Добавить к номерам ячеек значение"
    "Numbers of copied cells"="Номера копируемых ячеек"
    "The value specified in the input field above will be added to the cell numbers of all copied fields."="К номерам ячеек всех копируемых полей будет добавлено значение, указанное в поле ввода выше."
    "Some numbers coincide with those already available in"="Некоторые номера совпадают с уже имеющимися в"
    "Cell range"="Диапозон ячеек"
    "Successfully copied"="Успешно скопированны"
    "Error creating a new ASA map"="Ошибка создания нового КОПа"
    "The ASA map {0} already has the following fields:"="В КОП {0} уже есть следующие поля:"
    "The ASA map {0} already has the following cell numbers:"="В КОП {0} уже есть следующие номера ячеек:"
    "Deletion error"="Ошибка удаления"
    "Copy selected tour"="Копировать выделенный тур"
    "Merge selected tours"="Объеденить выделенные туры"
    "Delete selected tours from ASA map"="Удалить выделенные туры из КОПа"
    "Attention! All data from tours {0} will be deleted"="Внимание! Все данные туров {0} будут удалены"
    "Copying a selected tour"="Копирование выделенного тура"
    "Where to copy"="Куда копируем"
    "Merging selected tours"="Объединение выделенных туров"
    "Copy error"="Ошибка копирования"
    "The ASA map {0} does not have the following fields:"="В КОП {0} нет следующих полей:"
    "The ASA map {0} does not have the following cell numbers:"="В КОП {0} нет следующих номеров ячеек:"
    "A new tour will be created from the union of these tours:"="Будет создан новый тур из объединения данных туров:"
    "Tour priority (when overlapping values)"="Тур приоритет (при наложении значений)"
    "Routes"="Маршруты"
    "Total volume of fertilizers"="Общий объем удобрений"
    "Min. norm"="Мин. норма"
    "Avg. norm"="Средн. норма"
    "Max. norm"="Макс. норма"
    "kg"="кг"
    "Return to recommended value"="Вернуться к рекомендованному значению"
    "pcs"="шт"
    "Sort by"="Сортировать по"
    "Search by field name"="Поиск по названию поля"
    "Search by field name and crop"="Поиск по названию поля и культуре"
    "Off"="Выкл"
    "Crops per season"="Культуры за сезон"
    "Examples of meanings"="Примеры значений"
    "Edit selection route"="Редактировать маршрут отбора"
    "Set the detour order"="Задать порядок объезда"
    "Export"="Экспортировать"
}

export class AgroAhoTranslate_ru2 extends AgroAhoTranslate_en{
    "Cancel"="Отмена"
    "Fields"="Поля"
    "Successfully copied"="Успешно скопированно"
    "Title"="Названию"
    "Crop"="Культуре"
    "Set the detour order"="Задайте порядок объезда"
}