import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {action, observable} from "mobx";
import {ra} from "../../../../../helper/utils/mobxUtils";
import union from "@turf/union";
import {ContextMenuCommonComp, PopupCorner, PopupDirection} from "../../../../Common/ContextMenuCommonComp";
import classNames from "classnames";
import './A2FieldSelectedButton.scss';
import {NavigatorItemStoreType} from "../../../../../store/agro/A2NavigatorItemStore";
import {A2ExportFormat} from "../../../../../store/agro/A2AgroStore";
import {A2ConfirmExportMode} from "../../../../../store/agro/Confirms/A2ConfirmExportStore";
import {TranslateUtils} from "../../../../../helper/lang/TranslateUtils";
import {IEventAgroEditField} from "../../../../../../pluginApi/PluginsEvents";

@observer
export class A2FieldSelectedButton extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClick(){
        let agro = this.props.store.agro2;
        if (agro.projectStruct.fieldsPage.selectedFields.length == 0) return;
        agro.agroButtonPopupVisible = !agro.agroButtonPopupVisible;
    }

    @action
    onClose(){
        this.props.store.agro2.agroButtonPopupVisible = false;
    }

    @action
    async cancelActiveArea(){
        let store = this.props.store;
        if (store.map.searchObject.isEmpty) return;
        this.onClose();
        try {
            await store.map.searchObject.clearAndSetDefault();
        }catch (e) {
            this.props.store.addError(e);
        }
    }
    @action
    async selectionToActiveArea(){
        let agro = this.props.store.agro2;
        let store = this.props.store;
            if (agro.projectStruct.fieldsPage.selectedFields.length == 0) return;
            let field_ids = agro.projectStruct.fieldsPage.selectedFields.map(a => a.id);
            try {
                if (field_ids.length > store.constants.MAX_ACTIVE_FIEDLS){
                    throw TranslateUtils.format(store.trans["Maximum number of objects in active area: {0}"], store.constants.MAX_ACTIVE_FIEDLS);
                }
                let ff = await agro.getFields(field_ids);
                ra(()=>{
                    if (ff.length == 0) {
                        this.onClose();
                        return;
                    }
                    let superF: any = ff[0];
                    for(let i = 1; i < ff.length; i++){
                        superF = union(superF, ff[i] as any);
                    }
                    store.map.searchObject.content =  {'type': 'FeatureCollection', 'features': [superF]};
                    store.map.searchObject.fieldDataset = agro.projectInfo.projectInfoJson.type_info.type_schema.obj.view.dataset.ds_code;
                    store.map.searchObject.fieldIds = field_ids;
                    this.props.store.addInfo(store.trans["Active area created"]);
                    this.onClose();
                });
            }catch (e){
                this.props.store.addError(e);
                this.onClose();
            }
    }

    @action
    async clickDeleteSelectedFields()
    {
        let agro = this.props.store.agro2;
        if (!agro.permission.canDeleteObject()) return;
        agro.agroButtonPopupVisible = false;
        try {
            await this.getCountSelectionInfo();
            agro.confirmDelete.area = this.area_sum;
            agro.confirmDelete.fields = this.count_obj;
            agro.confirmDelete.filterSelected = this.getSelectionFilter();
            agro.confirmDelete.mode_export = A2ConfirmExportMode.selectedFields;
            agro.confirmDelete.doShow();
        }catch (err) {
            this.props.store.addError(err);
        }
    }
    @action
    onClickEdit(){
        if (!this.props.store.agro2.permission.canEditObject()) return;
        if (this.props.store.agro2.projectStruct.lastNavigatorItem != null
            && this.props.store.agro2.projectStruct.lastNavigatorItem.type == NavigatorItemStoreType.fields){
            let ids = this.props.store.agro2.projectStruct.lastNavigatorItem.selectedFields.map(a => a.id);
            if (ids.length > 0){
                this.onClose();
                let flds = this.props.store.agro2.projectStruct.lastNavigatorItem.selectedFields;
                this.props.store.agro2.fieldEditorForm.initMultiEditField(flds);
            }
        }
    }


    @action
    async onClickExport(){
        let store = this.props.store;

        store.agro2.agroButtonPopupVisible = false;
        try {
            await this.getCountSelectionInfo();
            store.agro2.confirmExport.area = this.area_sum;
            store.agro2.confirmExport.fields = this.count_obj;
            store.agro2.confirmExport.filterSelected = this.getSelectionFilter();
            store.agro2.confirmExport.mode_export = A2ConfirmExportMode.selectedFields;
            store.agro2.confirmExport.doShow();
        }catch (err) {
            this.props.store.addError(err);
        }
    }

    async getCountSelectionInfo(){
        let filter = this.getSelectionFilter();
        let r = await this.props.store.agro2.groupFields(filter);
        this.count_obj = r.obj_cnt;
        this.area_sum = r.area_sys;
    }
    @observable
    count_obj: number;
    @observable
    area_sum: number;


    getSelectionFilter():any{
        let agro = this.props.store.agro2;
        let store = this.props.store;
        if (agro.projectStruct.fieldsPage.selectedFields.length == 0) return;
        let field_ids = agro.projectStruct.fieldsPage.selectedFields.map(a => a.id);
        let filter: any = {};
        filter[store.agro2.projectInfo.fieldName_id] = {"$in":field_ids};
        return filter;
    }



    render() {
        let store = this.props.store;
        let agro = this.props.store.agro2;
        if (agro.projectStruct.fieldsPage == null) return null;
        //if (agro.projectStruct.fieldsPage.selectedFields.length == 0) return null;

        let showDeleteFiledStr: {ok: boolean} = {ok: true};
        store.events.onAgroCanDeleteField.call(showDeleteFiledStr);
        let showDeleteFiled = showDeleteFiledStr.ok;

        let ev = {
            ok: true
        };
        store.events.onAgroCanEditGroupField.call(ev);
        let canMultiEdit = ev.ok;
        let child: any[] = [];
        child.push(<div className='A2FieldSelectedButton-popup-header'>{store.trans["Actions with selected objects"]}</div>);
        if (canMultiEdit) child.push(<div key="objects_editing" className={classNames("flex-columns flex-align-items-center A2FieldSelectedButton-popup-props-line",{"disabled": !store.agro2.permission.canEditObject()})}
                              onClick={this.onClickEdit}>
            <div className="flex-stretch-item text-next">
                {store.trans.EditTo}
            </div>
        </div>);
        if (canMultiEdit) child.push(<div key="objects_editing_div" className="A2FieldSelectedButton-popup-props-line-div" />);
        child.push(<div key="Selected objects to active area" className={classNames("flex-columns flex-align-items-center A2FieldSelectedButton-popup-props-line")}
             onClick={this.selectionToActiveArea}>
            <div className="flex-stretch-item text-next">
                {store.trans['Move to active area']}
            </div>
        </div>);
        child.push(<div key="cancel active" className={classNames("flex-columns flex-align-items-center A2FieldSelectedButton-popup-props-line",
            {"disabled": store.map.searchObject.isEmpty})}
             onClick={this.cancelActiveArea}>
            <div className="flex-stretch-item text-next">
                {store.trans["Cancel active area"]}
            </div>
        </div>);
        child.push(<div key="Selected objects to active area_div" className="A2FieldSelectedButton-popup-props-line-div" />);
        child.push(<div key="export" className={classNames("flex-columns flex-align-items-center A2FieldSelectedButton-popup-props-line")}
             onClick={this.onClickExport}>
            <div className="flex-stretch-item text-next">
                {store.trans.ExportTo}
            </div>
        </div>);
        if (showDeleteFiled) child.push(<div key="export_div" className="A2FieldSelectedButton-popup-props-line-div" />);
        if (showDeleteFiled) child.push(<div key="delete_selection" className={classNames("flex-columns flex-align-items-center A2FieldSelectedButton-popup-props-line",
            {"disabled": !agro.permission.canDeleteObject()})}
                                 onClick={this.clickDeleteSelectedFields}>
            <div className="flex-stretch-item text-next red">
                {store.trans.Delete}
            </div>
        </div>);
        child = store.events.onAgroFieldSelectedDropDownChildren.call(child);


        return <div className={classNames("A2FieldSelectedButton-button", {"noselection": agro.projectStruct.fieldsPage.selectedFields.length == 0})} onClick={this.onClick}>
            <svg width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.25 7C0.25 7.9665 1.0335 8.75 2 8.75C2.9665 8.75 3.75 7.9665 3.75 7C3.75 6.0335 2.9665 5.25 2 5.25C1.0335 5.25 0.25 6.0335 0.25 7Z" fill="#2B3138"/>
                <path d="M0.25 1.75C0.25 2.7165 1.0335 3.5 2 3.5C2.9665 3.5 3.75 2.7165 3.75 1.75C3.75 0.783502 2.9665 -3.42479e-08 2 -7.64949e-08C1.0335 -1.18742e-07 0.25 0.783502 0.25 1.75Z" fill="#2B3138"/>
                <path d="M0.25 12.25C0.25 13.2165 1.0335 14 2 14C2.9665 14 3.75 13.2165 3.75 12.25C3.75 11.2835 2.9665 10.5 2 10.5C1.0335 10.5 0.25 11.2835 0.25 12.25Z" fill="#2B3138"/>
            </svg>
            {agro.agroButtonPopupVisible &&
            <ContextMenuCommonComp onClose={this.onClose}
                                   autoCornerHeight={100} autoCornerWidth={100}
                                   direction={PopupDirection.vertical}
                                   popupCorner={PopupCorner.rightTop}
            >
                <div className="A2FieldSelectedButton-popup-props">
                    {child}
                </div>
            </ContextMenuCommonComp>
            }
        </div>;

    }
}