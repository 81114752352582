import {CustomStore} from "../CustomStore";
import {v4} from "uuid";
import {save} from "../PermalinkDecor";
import {computed, observable} from "mobx";
import {PointStore} from "../MapStore";
import {Geometry} from "geojson";
import {IndexByPointYearStore} from "./IndexByPointYearStore";
import {IndexByPointGraphStore} from "./IndexByPointGraphStore";
import {IndexByPointerStore} from "./IndexByPointerStore";

export enum IndexAreaType{ point, field}

export class IndexByPointGraphPointStore extends CustomStore<IndexByPointerStore>{
    constructor(parent: IndexByPointerStore) {
        super(parent);
        this.id = v4();
    }
    class(): string {return "IndexByPointGraphPointStore";}
    @save @observable
    id: string;//не меняется
    @save @observable
    point: PointStore = new PointStore(this);
    //кэш геометрии поля
    fieldGeometry: Geometry = null;
    @save @observable
    num: number;
    @save @observable
    description: string = "";

    @save @observable
    areaType: IndexAreaType = IndexAreaType.point;
    @save @observable
    field_id: number = null;
    @save @observable
    filed_name: string = "";

    @computed
    get graphs(): IndexByPointGraphStore[]{
        return this.root.indexByPointer.graphs.filter(p => this.id == p.idPoint);
    }
    get yearPoints(): IndexByPointYearStore[]{
        return this.root.indexByPointer.yearPoints.filter(a => a.idPoint == this.id);
    }

}
