import {AnySourceData, CirclePaint, FillPaint, Layer, LinePaint, Style, SymbolLayout} from "maplibre-gl";
import {CustomStore} from "../CustomStore";
import {Interpolation} from "../productSetting/IndexBandProductStore";
import {IConfigMap} from "./MapConfigInterfaces";
import {IProjectTableLang, ProjType} from "../user/UserStore";
import {IndexDataGroup, IndexDataGroups, IndexDataType} from "../indeByPoint/IndexByPointerStore";
import {Satellite} from "../../helper/utils/satellliteDic";
import {IDictonaryType} from "../../helper/utils/Utils";
import {Lang} from "../../../pluginApi/store/Lang";

export enum ProductStyleType{
    static= 'static',
    LUT = 'LUT',
    adaptive = 'adaptive',
    index = 'index'
}

export type ParameterizedOverlayType = 'gibs_modis' | 'dap_fields_mask' | 'gfs_wind';

interface ITheme{
    logo: string;
    "logo-left": string;
    "logo-left_relativeTop": string;
    welcome_text: string;
    help_link: string;
    agro_button_icon: string;
    agro_button_icon_mini: string;
}
export type IProductType = "RGB" | "index" | "fixedIndex";

export interface IProductConfig {
    full_name: string;
    abbreviation: string;
    icon: string;
    type: IProductType;
    formulae:string;
    description:string;
    tile_prod_name:string;//code
    unitName: string;
    unitDescription: string;
    bands: string[];
    styles: (IProductStyleStaticConfig | IProductStyleLUTConfig | IProductStyleAdaptiveConfig | IProductStyleIndexConfig)[];
    def_style: ProductStyleType;
    fixedIndexColors: {color: string, title: IProjectTableLang}[];
}

export interface IProductStyleStaticConfig{
    type: ProductStyleType.static;
    bands: IBandProductStyleStaticConfig[];
}
export interface IBandProductStyleStaticConfig{
    min: number;
    max: number;
    band: string;
}
export interface IProductStyleLUTConfig{
    type: ProductStyleType.LUT;
}
export interface IProductStyleIndexConfig{
    type: ProductStyleType.index;
    min: number;
    max: number;
    def_min: number;
    def_max: number
    def_color_preset: string;
    def_classes: number;
    def_interpolation: Interpolation;
}
export interface IProductStyleAdaptiveConfig{
    type: ProductStyleType.adaptive;
    std_m: number;
    masks: IProductStyleAdaptiveMaskConfig[];
}
export interface IProductStyleAdaptiveMaskConfig {
    mask: string;
    default: boolean;
}
export interface IBandStore{
    name: string;
    resolution: string;
}
export interface IOrderedProducts{
    bands: IBandStore[];
    indexes: string[];
}
export interface IMapboxImage{
    url: string,
    resourceName: string,
}
export interface IOverlayConfig {
    id: string;
    title: string;
    description: string;
    type: string;
    source: AnySourceData;
    layers: Layer[];
    images: IMapboxImage[];
}
export type TemporalTimeStyle = "date" | "dateWithTimeInterval" | "dates" | "datesWithTimeInterval";
export interface ITemporalGroupParams {
    style: TemporalTimeStyle;
    defaultTemporalColumn?: string;
    start?: string;
    end?: string;
}
export interface IOverlayGroup {
    id: string;
    title: string;
    isExpanded: boolean;
    temporalParams?: ITemporalGroupParams;
    overlays: IOverlayConfig[];
}
export interface IRasterStyle{
    style_type: string;
    interpolation: string;
    unit_name: string;
    values?: {color: string, value: number}[]
}
export interface IBaseLayer {
    key: string;
    title: string;
    icon: string;
    description: string;
    mapbox_style?: string | Style;
    tiles?: string;
    glyphs?: string;
    sprite?: string;
    raster_style?: IRasterStyle;
}
export interface Ipreset_color_value{
    color: string;
}
export interface Ipreset_colors{
    name: string;
    id: string;
    values: Ipreset_color_value[]
}
export interface ISentinelAnaliz{
    title: string;
    id: string;
    icon: string;
}
export interface IConfigMapLayers {
    overlays: (IOverlayConfig | IOverlayGroup)[],
    baselayers: IBaseLayer[],
    defaultGlyphs?: string,
    defaultSprite?: string,
    map: IConfigMap
}

export interface ISoilGridsSourceLayer{
    tiles: string;
    title: string;
}
export interface ISoilGridsSourceClass{
    title: string;
    color: string;
}
export interface ISoilGridsSource{
    sources_title: string;
    units_legend: string;
    pallete: string[];
    short_name: string;
    minValue: number;
    maxValue: number;
    layers: ISoilGridsSourceLayer[];
    classes: ISoilGridsSourceClass[];
}
export interface ISoilGridsGroup{
    titleGroup: string;
    sources: ISoilGridsSource[];
}
export interface ISoilGrids{
    groups: ISoilGridsGroup[];
    baseUrl: string;
}

export interface IPoint{
    lng: number;
    lat: number;
}

export interface IStartPos{
    zoom: number;
    center: IPoint
}

export interface IConfigIndexByPoint{
    defaultIndexType?:{[key in IndexDataGroup]: IndexDataType};
    indexDataGroups: IndexDataGroups;
}

export interface IGibsModisOverlayLayer {
    id: string;
    title: string;
    tilesTemplate: string;
}

export interface IGibsModisOverlayParams {
    layers: IGibsModisOverlayLayer[];
}

export interface IDapFieldsMaskOverlayParams {
    tilesTemplate: string;
    metadataUrl: string;
}

export interface IWindOverlayParams {
    dataTemplateUrl: string;
}

export interface IParameterizedOverlay{
    id: ParameterizedOverlayType;
    title: string;
    params: IGibsModisOverlayParams | IDapFieldsMaskOverlayParams
      | IWindOverlayParams; // | other (or any?)
}

export interface IConfigStore{
    projectTypeFilters?: ProjType[];
    tile_domains?: string[];
    prodstat_domains?: string[],
    map_layers: IConfigMapLayers;
    productsS5: IProductConfig[];
    productsS1_IW: IProductConfig[];
    productsS1_EW: IProductConfig[];
    products: IProductConfig[];
    preset_colors: Ipreset_colors[],
    soilGrids?: ISoilGrids;
    ordered_products?: {[key in Satellite]: IOrderedProducts};//IDictonaryType<IOrderedProducts>;
    theme?: ITheme;
    //indexes: string[];
    accessToken: string;
    agroShow?: boolean;
    autoPolygon?: boolean;
    sampleSelection?:boolean;//dafult: false
    photoShow?: boolean;
    meteoShow?: boolean;
    emergencyShow?: boolean;
    rshbOrdersShow?: boolean;
    agroAhoShow?: boolean;
    indiaRepsShow?: boolean;
    rshbShow?: boolean;
    testShow?: boolean;
    startPosition?: IStartPos;
    s1_order_active: boolean;
    s5_order_active?: boolean;
    showRoom2_active?: boolean;
    pixelValue?: boolean;
    searchImage?: boolean;
    default_left_panel?: string;
    download_index_band_discrete?: boolean;
    indexByPoint?: IConfigIndexByPoint;
    lang?: Lang[];
    translate?: any;
    new_project_types?: ProjType[];

    default_language?: Lang;
    parameterizedOverlays?: IParameterizedOverlay [];
}

export class ConfigUtil extends CustomStore{
    class(): string {return "ConfigUtil";}
    getProductFullByCode(productCode: string): IProductConfig{
        let p = this.root.config.products.filter(a => a.tile_prod_name == productCode);
        if (p.length == 0) return null;
        return p[0];
    }

}