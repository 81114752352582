import {ObservableCustomStore} from "../../app/store/CustomStore";
import {observable} from "mobx";
import {fetchJsonGet} from "../../app/helper/utils/FetchUtils";
import {AgroAhoStore, AhoModalType, AhoStage} from "./agroAhoStore";
import {makeLogger} from "ts-loader/dist/logger";
import {AhoIndicatorFormat, Card, CopyType} from "./agroAhoCardStore";
import {TextUtils} from "../../app/helper/utils/TextUtils";
import {AhoUtils} from "./agroAhoUtils";
import {AgroAhoProjStore} from "./agroAhoProjStore";
import {AgroAhoUploadIsoXmlStore} from "./agroAhoUploadIsoXmlStore";
import {ra} from "../../app/helper/utils/mobxUtils";
import {RecNormColumns} from "./card/agroAhoCardRN";


// export interface TourItem {
//     map_id: number;
//     tour_id: number;
//     tour_date: string;
//     tour_name: string;
//     obs_info?: any;
//     cells?: any;
//     checked?: boolean;
//     file_name?: string;
// }

interface TourCnt {
    cnt: number
}
interface DictTourColumns {
    [indc_code: string]: TourCnt; // содержит индикатор с именем cell_id
}
export interface TourColumns {
    columns: DictTourColumns;
}

interface DictTourValues {
    [code: string]: number; // содержит индикатор с именем cell_id
}

export interface TourItem2 {
    ins_tm?: string;
    map_id: number;
    observ?: TourColumns;
    observ_vals?: DictTourValues[];
    rec_norm?: RecNormColumns;
    rec_norm_vals?: DictTourValues[];
    tour_date?: string;
    tour_id: number;
    tour_name: string;
    upd_tm?: string;
    checked?: boolean;
    tour_file_name?: string;
}

export class AgroAhoTourStore extends ObservableCustomStore {
    constructor(parent: AgroAhoStore) {
        super(parent);
        this.parentStore = parent;
    }

    parentStore: AgroAhoStore;
    @observable
    toursItems: TourItem2[] = [];
    @observable
    toursShow: boolean = false;
    @observable
    selectTourAll: boolean = false;
    @observable
    sortToursDesc: boolean = false;
    @observable
    curTour: TourItem2 = null;
    newTour: TourItem2 = null;
    @observable
    newTourName: string = '';
    @observable
    newTourDate: Date = null;
    tourForDel: TourItem2
    @observable
    copyTourMapId: number = null;
    @observable
    copyTourNewName: string = '';
    @observable
    mergeTourMapName: string = '';
    @observable
    notHaveFields: string = '';
    @observable
    notHaveCells: string = '';
    @observable
    mergeTourDate: Date = null;
    @observable
    curMergeTour: TourItem2 = null;

    isNewTour(){
        return !!this.curTour && this.curTour?.tour_id === 0;
    }

    doTourClick(tour: TourItem2){
        tour.checked = !tour.checked;
        this.updSelectTourAll();
    }

    doSortToursClick(){
        this.sortToursDesc = !this.sortToursDesc;
        this.sortTours(this.sortToursDesc);
    }

    onTourCopyClick(){
        let ps = this.parentStore;
        let cs_s = ps.cardsStore;
        let cs = ps.cardStore;
        let cards_ = cs_s.cards.filter(c => !c.filtered && c.map_id != cs.card.map_id);
        this.copyTourMapId = cards_[0].map_id;
        this.copyTourNewName = this.selTours[0].tour_name;
        this.resetNoHaveErr();
        ps.toggleModal(true, AhoModalType.CopyTour);
    }

    resetNoHaveErr(){
        this.notHaveCells = '';
        this.notHaveFields = '';
    }

    async doTourCopyClick(){
        let cstore = this.parentStore.cardStore;
        let proj_name = this.parentStore.projStore.projName;
        let map_id = cstore.card.map_id;
        let tour_id = this.selTours[0].tour_id;
        let isErr = false;
        this.parentStore.mapStore.resetCellCache();
        let p = {params: {dst_map: {map_id: this.copyTourMapId}, tour: {tour_name: this.copyTourNewName}}}
        let url = `/api/projects/${proj_name}/asa2/map/${map_id}/tour/${tour_id}/copy`;
        await fetchJsonGet(url, p).then(async r=>{
            if (r?.status == 'error') {
                if (r?.errors?.no_fields && r?.errors?.no_fields.length) {
                    this.notHaveFields = r?.errors?.no_fields?.map((f: any)=>f.field_id).join(', ');
                }
                if (r?.errors?.no_cells && r?.errors?.no_cells.length) {
                    this.notHaveCells = r?.errors?.no_cells?.map((c: any)=>c.cell_id).join(', ');
                }
                if (this.notHaveFields || this.notHaveCells) isErr = true;
            }
            if (r?.status == 'ok') this.root.addInfo(this.parentStore.trans2['Successfully copied']);
        }).catch(err=>{
            console.log('tour copy error', err);
            this.root.addError(this.parentStore.trans['Copy error']);
        });
        if (!isErr) this.parentStore.toggleModal(false);
    }

    onTourMergeClick(){
        this.mergeTourMapName = this.selTours[0].tour_name;
        this.curMergeTour = this.selTours[0];
        let date: Date = null;
        let date0: Date = new Date(this.selTours[0].tour_date);
        let date0_: number = date0?.getTime();
        if (this.selTours.every(t=>(new Date(t.tour_date))?.getTime() == date0_)) date = date0;
        this.mergeTourDate = date;
        this.parentStore.toggleModal(true, AhoModalType.MergeTours);
    }

    doTourMergeClick(){
        console.log(`Merge name "${this.mergeTourMapName}" tours "${this.selTours.map(t=>t.tour_name).join(',')}"` +
            ` date "${this.parentStore.format_date2(this.mergeTourDate)}" priority "${this.curMergeTour?.tour_name}"`);

        // let cstore = this.parentStore.cardStore;
        // let proj_name = this.parentStore.projStore.projName;
        // let map_id = cstore.card.map_id;
        // if (!this.selTours.length) return;
        // let tours = this.selTours.map(t=>t.tour_id);
        // let url = `/api/projects/${proj_name}/asa2/map/${map_id}/tour/delete?tour_ids=${tours.join(',')}`;
        // await fetchJsonGet(url).then(async res=>{
        //     this.toursItems = this.toursItems.filter(t=>tours.indexOf(t.tour_id) < 0);
        //     await this.parentStore.cardStore.updIndicators();
        //     await AhoUtils.delay(300);
        //     if (!cstore.cardIndicators.length) this.toursShow = false;
        //     if (res?.result == 'ok') this.root.addInfo(this.parentStore.trans['Successfully deleted']);
        //
        // }).catch(err=>{
        //     console.log('tours delete error', err);
        //     this.root.addError(this.parentStore.trans['Error deleting tour']);
        // });
    }

    doGetIndicatorTours(indc_code: string){
        let res: number[] = [];
        let cstore = this.parentStore.cardStore;
        let url = `/api/projects/${this.parentStore.projStore.projName}/asa2/map/${cstore.card.map_id}/tour/select`;
        let tours_list: TourItem2[] = this.parentStore.getJsonFromCache(url);
        tours_list.forEach(t=>{
            if (t?.observ?.columns && Object.keys(t.observ.columns).indexOf(indc_code.toLowerCase()) >= 0) {
                res.push(t.tour_id);
            }
        });
        return res;
    }

    async createNewTour(){
        let err_msg = this.parentStore.trans['Error creating tour'];
        if (!this.newTourName || !this.newTourDate) {
            this.root.addError(err_msg);
            throw new Error(err_msg);
        }
        console.log('new tour', this.newTourName, this.newTourDate);
        let proj_name = this.parentStore.projStore.projName;
        let map_id = this.parentStore.cardStore.card.map_id;
        let url = `/api/projects/${proj_name}/asa2/map/${map_id}/tour/create`;
        let date = this.parentStore.format_date3(this.newTourDate);
        let param = `?tour={"tour_name":"${this.newTourName}","tour_date":"${date}"}`;
        // tour={"tour_name": str, "tour_date": "YYYY-MM-DD"}
        let tour = await fetchJsonGet(url + param);
        if (!tour || !tour?.tour_id) {
            console.log(err_msg, tour);
            this.root.addError(err_msg);
            throw new Error(err_msg);
        } else {
            console.log('create result:', tour);
            this.curTour = tour;
        }
    }

    sortTours(desc: boolean){
        if (desc) {
            this.toursItems = this.toursItems.slice().sort((a,b)=> TextUtils.stringSortCompare(b.tour_name, a.tour_name));
        } else {
            this.toursItems = this.toursItems.slice().sort((a,b)=> TextUtils.stringSortCompare(a.tour_name, b.tour_name));
        }
    }


    async loadsTours(){
        let cstore = this.parentStore.cardStore;
        // console.log('before laod tours');
        // https://dev.agrometrika.ru/api/projects/proj276/asa2/map/2/tour/select
        let url = `/api/projects/${this.parentStore.projStore.projName}/asa2/map/${cstore.card.map_id}/tour/select`;
        let tours_list = await this.parentStore.cachedFetchJsonGet(url, true).catch(()=>{});
        // console.log('tours_list', tours_list);
        if (tours_list) {
            tours_list = tours_list.slice().sort((a: TourItem2, b: TourItem2)=> TextUtils.stringSortCompare(b.tour_date, a.tour_date));
            let tour_cell_inds_promises:any[] = [];
            let tour_cell_rec_norm_vals_promises:any[] = [];
            tours_list.forEach((tour_: TourItem2)=>{
                // https://dev.agrometrika.ru/api/projects/proj276/asa2/map/2/tour/1/observs/select
                let url_ = `/api/projects/${this.parentStore.projStore.projName}/asa2/map/${cstore.card.map_id
                }/tour/${tour_.tour_id}`;
                let url__ = `${url_}/observ/select`;
                tour_cell_inds_promises.push(
                    this.parentStore.cachedFetchJsonGet(url__, true).catch(()=>{}));
                url__ = `${url_}/rec_norm/select`;
                tour_cell_rec_norm_vals_promises.push(
                    this.parentStore.cachedFetchJsonGet(url__, true).catch(()=>{}));
            })
            let tour_cell_inds = await Promise.all(tour_cell_inds_promises);
            let tour_cell_rec_norm_vals = await Promise.all(tour_cell_rec_norm_vals_promises);
            tours_list.forEach((tour_: TourItem2, idx: number)=>{
                tour_.observ_vals = tour_cell_inds[idx];
                tour_.rec_norm_vals = tour_cell_rec_norm_vals[idx];
                // tour_.checked = true;
            });
            // console.log('load tours:', tours_list);
            this.toursItems = tours_list;
            // this.sortTours(this.sortToursDesc);
            this.updSelectTourAll();
        }
    }
    updSelectTourAll(){
        let cstore = this.parentStore.cardStore;
        this.selectTourAll = this.toursItems.every(f=>!!f.checked);
        // console.log('this.selectTourAll', this.selectTourAll, JSON.parse(JSON.stringify(this.toursItems)));
        cstore.doUpdSelIndicator();
    }
    doSelectTourAllClick(){
        // this.selectTourAll = !this.selectTourAll;
        this.setSelectTourAll(!this.selectTourAll);
    }
    setSelectTourAll(value: boolean){
        ra(()=>{
            // this.toursItems.filter(f=>!f.filtered).forEach(f=>f.checked=value);
            this.toursItems.forEach(f=>f.checked=value);
            this.updSelectTourAll();
        });
    }
    setSelectTours(tours: number[]){
        if (!tours || !Array.isArray(tours)) return;
        ra(()=>{
            // this.toursItems.filter(f=>!f.filtered).forEach(f=>f.checked=value);
            this.toursItems.forEach(f=>{
                f.checked = tours.indexOf(f.tour_id) >= 0;
            });
            this.updSelectTourAll();
        });
    }
    get isNoSelectedTours(){
        return this.toursItems.every(f=>!f.checked);
    }
    get torusToDisplay(){
        if (!this.parentStore.mapStore.isNewProj()) return [];
        if (this.isNoSelectedTours) return this.toursItems;
        return this.selTours;
    }
    get selTours(){
        let sel: TourItem2[] = [];
        if (this.toursItems) {
            if (this.isNoSelectedTours) sel = this.toursItems
            else sel = this.toursItems.filter((f: TourItem2)=>f.checked);
        }
        return sel;
    }

    doGetRNFertTours(fert_id: number){
        let res: any = {};
        this.selTours.forEach(t=>{
            let cols = t?.rec_norm?.columns;
            if (cols) {
                Object.keys(cols).forEach(rn_key=>{
                    if (cols[rn_key]?.fert_id == fert_id) res[t.tour_id] = 1;
                });
            }
        });
        return Object.keys(res).map(i=>+i);
    }
    doGetSelTourIndicatorCodes(){
        let cstore = this.parentStore.cardStore;
        let tour_ids = this.torusToDisplay.map(t=>t.tour_id);
        let url = `/api/projects/${this.parentStore.projStore.projName}/asa2/map/${cstore.card.map_id}/tour/select`;
        let tours_list: TourItem2[] = this.parentStore.getJsonFromCache(url);
        let res: string[] = [];
        // console.log('ids:', tour_ids, tours_list);
        if (tours_list) {
            tours_list.forEach(t=>{
                if (tour_ids.indexOf(t.tour_id)>=0){
                    if (t.observ.columns) {
                        Object.keys(t.observ.columns).forEach(key=>{
                            if (key !== 'cell_id')
                                res.push(key);
                        });
                    }
                }
            });
        }
        // console.log('res:', res);
        return res;
    }

    async doTmpLoadIndcsFromTours(card_id?: number){
        let cs_store = this.parentStore.cardsStore;
        let cards = cs_store.cards;
        if (card_id) cards = cs_store.cards.filter(c=>c.map_id == card_id)
        // console.log('cards:', AhoUtils.cp(cards));
        for (let i=0; i<cards.length; i++) {
            let c = cards[i];
            // c.cell_info.indicators = {};
            let url = `/api/projects/${this.parentStore.projStore.projName}/asa2/map/${c.map_id}/tour/select`;
            let tours_list:TourItem2[] = await this.parentStore.cachedFetchJsonGet(url, true).catch(()=>{});
            if (!AhoUtils.check(tours_list, 'ITour[]', false)) return Promise.reject(new Error('doLoadCards: Wrong tour format'));
            // console.log('tl', AhoUtils.cp(tours_list));
            if (card_id) delete c.cell_info.indicators;
            // console.log('tours_list', tours_list);
            tours_list.forEach(t=>{
                // if (t.observ.indicators) {
                //     if (!c.cell_info.indicators) c.cell_info.indicators = {};
                //     // console.log('len', Object.keys(t.observ.indicators).length);
                //     Object.keys(t.observ.indicators).forEach(key=>{
                //         c.cell_info.indicators[key] = {probe_cnt: t.observ.indicators[key].obs_cnt};
                //     });
                // }
                if (t.observ.columns) {
                    if (!c.cell_info.indicators) c.cell_info.indicators = {};
                    // console.log('len', Object.keys(t.observ.indicators).length);
                    Object.keys(t.observ.columns).forEach(key=>{
                        if (key !== 'cell_id')
                            c.cell_info.indicators[key] = {probe_cnt: t.observ.columns[key].cnt};
                    });
                }
            });
            if (card_id) this.parentStore.cardStore.card = c;
            // console.log('c.cell_info.indicators2', AhoUtils.cp(c.cell_info?.indicators));
        }

    }

    async onDelTourClick(tour: TourItem2){
        this.tourForDel = tour;
        this.parentStore.toggleModal(true, AhoModalType.DeleteTour);
    }
    async delTour(tour: TourItem2, isNewTour?: boolean){
        let cstore = this.parentStore.cardStore;
        let proj_name = this.parentStore.projStore.projName;
        let map_id = cstore.card.map_id;
        let url = `/api/projects/${proj_name}/asa2/map/${map_id}/tour/delete?tour_id=${tour.tour_id}`;
        await fetchJsonGet(url).then(async res=>{
            if (!isNewTour){
                this.toursItems = this.toursItems.filter(t=>t.tour_id != tour.tour_id);
                await this.parentStore.cardStore.updIndicators();
                // console.log('DEL indcs', AhoUtils.cp(cstore.cardIndicators));
                await AhoUtils.delay(300);
                if (!cstore.cardIndicators.length) this.toursShow = false;
                if (res?.result == 'ok') this.root.addInfo(this.parentStore.trans['Successfully deleted']);
                // console.log('DEL indcs2', AhoUtils.cp(cstore.cardIndicators));
            }
        }).catch(err=>{
            console.log('tour delete error', err);
            if (!isNewTour) this.root.addError(this.parentStore.trans['Error deleting tour']);
        });
    }

    async delTours(){
        let cstore = this.parentStore.cardStore;
        let proj_name = this.parentStore.projStore.projName;
        let map_id = cstore.card.map_id;
        if (!this.selTours.length) return;
        let tours = this.selTours.map(t=>t.tour_id);
        let url = `/api/projects/${proj_name}/asa2/map/${map_id}/tour/delete?tour_ids=${tours.join(',')}`;
        await fetchJsonGet(url).then(async res=>{
            this.toursItems = this.toursItems.filter(t=>tours.indexOf(t.tour_id) < 0);
            await this.parentStore.cardStore.updIndicators();
            await AhoUtils.delay(300);
            if (!cstore.cardIndicators.length) this.toursShow = false;
            if (res?.result == 'ok') this.root.addInfo(this.parentStore.trans['Successfully deleted']);

        }).catch(err=>{
            console.log('tours delete error', err);
            this.root.addError(this.parentStore.trans['Error deleting tour']);
        });
    }


}