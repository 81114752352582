import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoCardTourItemComp.scss'
import {action} from "mobx";
import {IAgroAhoSuperStore} from "../../agroAhoPlugin";
import {AgroAhoRectCheckboxSvg} from "../AgroAhoRectCheckboxSvg";
import {AgroAhoZoomSvg} from "../icons/AgroAhoZoomSvg";
import classNames from "classnames";
import {AgroAhoEditSvg} from "../icons/AgroAhoEditSvg";
import {AgroAhoDelSvg} from "../icons/AgroAhoDelSvg";
import {AgroAhoDel2Svg} from "../icons/AgroAhoDel2Svg";
import {TourItem2} from "../../agroAhoTourStore";


export interface IAgroAhoCardTourItemCompProps {
    store: IAgroAhoSuperStore;
    tour: TourItem2;
}

@observer
export class AgroAhoCardTourItemComp extends React.PureComponent<IAgroAhoCardTourItemCompProps, undefined> {
    constructor(props: IAgroAhoCardTourItemCompProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClick(){
        this.props.store.agroAhoStore.tourStore.doTourClick(this.props.tour);
    }

    onEdit(e: any){
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    onDel(e: any){
        this.props.store.agroAhoStore.tourStore.onDelTourClick(this.props.tour);
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    render() {

        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let tour = this.props.tour;

        return <div className="AgroAhoCardTourItemComp-main" onClick={this.onClick}>
            <AgroAhoRectCheckboxSvg className="AgroAhoCardTourItemComp-checkbox" checked={tour.checked}/>
            <span className="AgroAhoCardTourItemComp-name">{tour.tour_name}</span>
            <div className="AgroAhoCardTourItemComp-rightCover">
                <span className="AgroAhoCardTourItemComp-date">{store_.format_date(tour.tour_date)}</span>
                <div className="AgroAhoCardTourItemComp-edit" onClick={this.onEdit}>
                    <AgroAhoEditSvg/>
                </div>
                <div className="AgroAhoCardTourItemComp-del" onClick={this.onDel}>
                    <AgroAhoDelSvg/>
                </div>
            </div>
        </div>
    }
}
