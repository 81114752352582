import {save} from "../../PermalinkDecor";
import {action, autorun, observable, toJS} from "mobx";
import {fetchJsonPost} from "../../../helper/utils/FetchUtils";
import {ra} from "../../../helper/utils/mobxUtils";
import {Feature, Geometry, Position} from "geojson";
import {LoadStatus} from "../../../helper/structs/LoadStatus";
import {cloneDeep, isEqual} from "lodash-es";
import {IReactionDisposer} from "mobx/lib/internal";
import {LeftPanelMode} from "../../SearchStore";
import {Utils} from "../../../helper/utils/Utils";
import {A2FieldStore} from "../A2FieldStore";
import {IResponseFieldProperties} from "../AgroStore";
import {A2SubModeLeftPanel} from "../A2AgroStore";
import {MeasuringStatus} from "../../../helper/structs/MeasuringStatus";
import {A2FieldFormCustomStore, FieldEditModeType} from "./A2FieldFormCustomStore";
import {A2NavigatorItemStore} from "../A2NavigatorItemStore";

export enum ButtonGeometryCreateType{
    polygon='polygon',
    line='line',
    point='point',
    auto='auto'
}

export class A2FieldFormStore extends A2FieldFormCustomStore{
    @save @observable
    fieldId: number;
    @save @observable
    multi_fieldId: number[];
    multiGroup_filters: any[];
    @observable
    multiGroup_countFields: number;
    @observable
    multiGroup_areaFields: number;
    multiGroup_activeNavigatorItem: A2NavigatorItemStore = null;

    get name(): string{ return this.properties[this.root.agro2.projectInfo.fieldName_columnName]; }
    set name(value: string){ this.properties[this.root.agro2.projectInfo.fieldName_columnName] = value; }

    @observable
    geometryValid: boolean = true;
    @observable
    multi_features_properties: IResponseFieldProperties[];

    @observable
    createGeometryButtonDown: boolean = false;    

    @observable
    status: LoadStatus = null;
    @observable
    _editMode: FieldEditModeType = null;
    get editMode(): FieldEditModeType{
        return this._editMode;
    }
    set editMode(value: FieldEditModeType){
        this._editMode = value;
    }
    @observable
    showFieldMultiEditPanel: boolean = false;
    @observable
    submitClicked: boolean = false;

    subscription(): IReactionDisposer[] {
        return [
            autorun(()=>{
                if (this.editMode == FieldEditModeType.insert || this.editMode == FieldEditModeType.edit){
                    ra(()=>{
                        //this.root.map.defaultMeasuringStatus = MeasuringStatus.agroEdit;
                    });
                }else{
                    ra(()=>{
                        this.root.map.defaultMeasuringStatus = MeasuringStatus.None;
                        let ms = this.root.map.measuringStatus;
                        if (ms == MeasuringStatus.agroCreatePolygonHole ||
                            //ms == MeasuringStatus.agroEdit ||
                            ms == MeasuringStatus.agroDeleteContour ||
                            ms == MeasuringStatus.agroCreatePoint ||
                            ms == MeasuringStatus.agroCreatePolygon ||
                            ms == MeasuringStatus.agroCreateLine) {
                            this.root.map.resetMeasuringStatus();
                        }
                    });
                }
            }),
            autorun(()=>{
                if ((
                    (this.root.searchPanel.leftPanelMode == LeftPanelMode.agro &&
                            this.root.agro2.subModeLeftPanel == A2SubModeLeftPanel.editOrCreateField) ||
                        this.root.agro2.fieldEditorForm.showFieldMultiEditPanel)
                    && this.submitClicked
                    && this.editMode == FieldEditModeType.multiEdit){
                        this.checkFieldsValid();
                }
            }),
            autorun(()=>{
                if (this.root.agro2.subModeLeftPanel == A2SubModeLeftPanel.tree &&
                    (this.editMode == FieldEditModeType.insert || this.editMode == FieldEditModeType.edit)){
                    ra(()=>{
                        this.root.agro2.fieldToolController.clearGeometry();
                        this.editMode = null;
                    });
                }
            }),
            autorun(()=>{
                if (this.editMode == FieldEditModeType.groupEdit && this.showFieldMultiEditPanel){
                    let store = this.root;
                    let navItem = store.agro2.projectStruct.activeNavigatorPage;
                    if (this.multiGroup_activeNavigatorItem != navItem){
                        ra(()=>{
                            store.agro2.fieldEditorForm.showFieldMultiEditPanel = false;
                            this.editMode = null;
                        });
                    }
                }
            })
        ];
    }

    setGeoemtry(g: Geometry){
        this.root.agro2.editManager.setGeometry(g);
    }
    getGeometry(): Geometry{
        return this.root.agro2.editManager.getGeometry();
    }
    //восстанавливает значение свойства для множественного редактирования
    resetInitialValueProperties(columnName: string){
        if (!this.hasDiffValue.has(columnName)){
            this.properties[columnName] = this.multi_features_properties[0][columnName]
        }else{
            this.properties[columnName] = null;
        }
    }

    public clear(){
        super.clear();
        this.geometryValid = true;
        this.fieldId = null;
        this.submitClicked = false;
    }
    @action
    initNewItem(){
        this.clear();
        this.status = LoadStatus.ready;
        this.editMode = FieldEditModeType.insert;
        this.root.map.resetMeasuringStatus();//this.root.map.setMeasuringStatus(MeasuringStatus.agroEdit);

        this.root.agro2.hover_field_id = null;
        this.root.agro2.fieldToolController.editable_properties = null;
        this.root.agro2.fieldToolController.editable_field_id = null;
        this.root.map.superTools.fieldLayersTool.setFilters();
        this.root.agro2.editManager.updateGeometry();

        //this.drawMode = A2FieldFormDrawMode.empty;
    }

    fillDefaultColumns(){
        let page = this.root.agro2.projectStruct.activeNavigatorPage;
        if (page == null) return;
        page = page.parentItem;
        if (page == null) return;
        let paths = page.getNaviPath();
        paths.forEach((a, index) => {
            if (a.columnInfo != null && a.column_value != null){
                 
                this.properties[a.columnInfo.column_name] = a.column_value;
                this.changedColumns.add(a.columnInfo.column_name);
            }
        });
    }

    @action
    async initGroupEditField(itemNav: A2NavigatorItemStore, filter: any[]){
        this.clear();
        this.editMode = FieldEditModeType.groupEdit;
        this.multiGroup_filters = filter;
        this.multiGroup_activeNavigatorItem = itemNav;
        this.status = LoadStatus.ready;
        this.referencePropertiesText = {};
        this.setGeoemtry(null);
        //this.drawMode = A2FieldFormDrawMode.empty;
        this.showFieldMultiEditPanel = true;
        this.multi_features_properties = [];
        this.multi_fieldId = [];
        this.hasDiffValue.clear();
        this.properties = {};
        this.multiGroup_areaFields = 0;
        this.multiGroup_countFields = 0;

        let path = itemNav.getNaviPath();
        let start = 0;
        for(let i = start; i < path.length; i++){
            let p = path[i];
            this.properties[p.columnInfo.column_name] = p.column_value;
        }



        let res = await this.root.agro2.groupFields(filter);
        ra(()=>{
            this.multiGroup_areaFields = res.area_sys;
            this.multiGroup_countFields = res.obj_cnt;
        });
    }

    @action
    initMultiEditField(fields: A2FieldStore[]){
        this.clear();
        this.editMode = FieldEditModeType.multiEdit;
        this.status = LoadStatus.ready;
        this.referencePropertiesText = {};
        this.setGeoemtry(null);


        //this.drawMode = A2FieldFormDrawMode.empty;
        this.showFieldMultiEditPanel = true;
        this.updateMultiEdit(fields);
    }

    @action
    updateMultiEdit(fields: A2FieldStore[]){
        this.editMode = FieldEditModeType.multiEdit;
        this.status = LoadStatus.ready;
        //this.drawMode = A2FieldFormDrawMode.empty;
        this.setGeoemtry(null);

        this.multi_features_properties = fields.map(a => a.properties);
        this.multi_fieldId = fields.map(a => a.id);
        this.hasDiffValue.clear();
        this.properties = {};
        if (fields.length > 0){
            this.properties = toJS(fields[0].properties);
            fields.forEach((f, index) =>{
                if (index == 0) return;
                for (let propertiesKey in f.properties) {
                    if (f.properties[propertiesKey] != this.properties[propertiesKey]) {
                        this.properties[propertiesKey] = null;
                        this.hasDiffValue.add(propertiesKey);
                    }
                }
            });
        }
    }

    @action
    initEditField(fieldId: number){
        this.clear();
        this.status = LoadStatus.loading;
        this.editMode = FieldEditModeType.edit;

        this.root.agro2.getField(fieldId).then(f => {
            ra(()=>{
                this.status = LoadStatus.ready;
                this.fieldId = fieldId;
                this.properties = f.properties;
                this.referencePropertiesText = {};

                this.setGeoemtry(f.geometry);

                this.root.agro2.hover_field_id = null;
                this.root.agro2.fieldToolController.editable_properties = cloneDeep(this.properties);
                this.root.agro2.fieldToolController.editable_field_id = this.fieldId;
                this.root.map.resetMeasuringStatus();//this.root.map.setMeasuringStatus(MeasuringStatus.agroEdit);
                this.root.map.superTools.fieldLayersTool.setFilters();
                this.root.agro2.editManager.updateGeometry();


                //if(this.geometry == null) this.drawMode = A2FieldFormDrawMode.empty; else this.drawMode = A2FieldFormDrawMode.edit;
            });
        }).catch(err =>{ra(()=>{
            this.root.addError(err);
        })})
    }

    private getGeometryIsValid(): boolean{
        let geometryValid = true;
        if (this.editMode == FieldEditModeType.multiEdit) return true;
        let g = this.getGeometry();

        function validPolygonCoord(a: Position[][]){
            if (a.length == 0) geometryValid = false;
            a.forEach(b => {
                if (b.length < 4) geometryValid = false;
            });
        }

        if (g != null && (g.type == "MultiPolygon")){
            if (g.coordinates.length == 0) geometryValid = false;
            else{
                g.coordinates.forEach(a => {
                    validPolygonCoord(a);
                });
            }
        }else
        if (g != null && (g.type == "Polygon")){
            validPolygonCoord(g.coordinates);
        }else if (g != null && (g.type == "MultiPoint" || g.type == "Point" || g.type == "LineString" || g.type == "MultiLineString")){
            geometryValid = true;
        }else geometryValid = false;
        return geometryValid;
    }


    checkFieldsValid(): boolean{
        let validGeom = this.getGeometryIsValid();
        let newObj = this.getNotValidColumns(this.editMode != FieldEditModeType.multiEdit);

        ra(()=>{
            let old = toJS(this.notValidProperties);
            if (this.geometryValid != validGeom) {
                this.geometryValid = validGeom;
            }
            if (!isEqual(old, newObj)) this.notValidProperties = newObj;
        });

        //isEqual()
        return Utils.allKeys(newObj).length == 0 && validGeom;
    }

    async multiGroupSave(): Promise<void>{
        let url = `/api/projects/${this.root.agro.projectName}/object/modify`;
        if (this.changedColumns.size == 0) return ;
        let props: any = this.getPropertiesForSaving();

        await fetchJsonPost(url,{features:[{"properties": props, "filter": this.multiGroup_filters}]}).then(json => {
            ra(()=>{
                this.root.addInfo(this.root.trans["Saved successfully"]);
            });
        });
    }

    async multiEditSave(): Promise<void>{
        let url = `/api/projects/${this.root.agro.projectName}/object/modify`;
        if (this.changedColumns.size == 0) return ;
        let farr: Feature[] = [];
        this.multi_fieldId.forEach(id =>{
            let props: any = this.getPropertiesForSaving();
            props[this.root.agro2.projectInfo.fieldName_id] = id;
            farr.push(<any>{"type": "Feature","properties": props});
        });

        await fetchJsonPost(url,{features:farr}).then(json => {
            ra(()=>{
                this.root.addInfo(this.root.trans["Saved successfully"]);
            });
        });
    }
    async editSave(): Promise<void>{
        let url = `/api/projects/${this.root.agro.projectName}/object/modify`;
        let props: any = this.getPropertiesForSaving();
        props[this.root.agro2.projectInfo.fieldName_id] = this.fieldId;

        await fetchJsonPost(url,{features:[{"type": "Feature","properties": props,
                "geometry": toJS(this.getGeometry())}]}).then(json => {
            ra(()=>{
                this.root.addInfo(this.root.trans["Saved successfully"]);
            });
        });
    }

    async addSave():Promise<void>{
        let url = `/api/projects/${this.root.agro.projectName}/object/add`;
        let params = {features:[{"type": "Feature","properties": this.getPropertiesForSaving(),
                "geometry": toJS(this.getGeometry())}]};
        await fetchJsonPost(url,params).then(json => {
            ra(()=>{
                this.root.addInfo(this.root.trans["Saved successfully"]);
            });
        });
    }

    /*
    setDrawMode(){
        if (!this.root.map.mapReady) return;
        if (this.drawMode == A2FieldFormDrawMode.empty) {
            ra(()=>{
                this.geometry = null;
                this.root.agro2.editManager.setGeometry(null);
                this.root.agro2.hover_field_id = null;
                this.root.agro2.fieldToolController.stopDrawPolygon();
            });
        }
        if (this.drawMode == A2FieldFormDrawMode.create) {
            ra(()=> {
                this.root.agro2.hover_field_id = null;
                this.root.agro2.fieldToolController.startDrawPolygon();
            });
        }
        if (this.drawMode == A2FieldFormDrawMode.edit) {
            ra(()=> {
                this.root.agro2.hover_field_id = null;
                this.root.agro2.editManager.setGeometry(cloneDeep(this.geometry));
                this.root.agro2.fieldToolController.editable_properties = cloneDeep(this.properties);
                this.root.agro2.fieldToolController.editable_field_id = this.fieldId;
                this.root.agro2.fieldToolController.stopDrawPolygon();
                this.root.agro2.fieldToolController.toEditPolygon();
            });
        }
    }*/



}