import {ObservableCustomStore} from "../../app/store/CustomStore";
import {AgroAhoStore, AhoLocalTs, AhoStage, IAhoUrlCode, IAhoUrlType} from "./agroAhoStore";
import {LeftPanelModeAgroAho} from "./agroAhoPlugin";
import {AhoCardShowType, AhoIndicatorFormat, SoilAnalysisType} from "./agroAhoCardStore";
import {ra} from "../../app/helper/utils/mobxUtils";
import {AhoUtils} from "./agroAhoUtils";


interface Layers {
    tracks: boolean;
    cells: boolean;
    fields: boolean;
    points: boolean;
}
interface Indicators {
    indc_id: number;
    ahoCardShowType: AhoCardShowType;
    indcFormat: AhoIndicatorFormat;
    // defIndcFormat: AhoIndicatorFormat;
}
export interface AhoPermalink { // plink version 1
    projName: string;
    map_id: number;
    layers: Layers;
    indcs: Indicators;
    rn_id: number,
    soilAnalysisType: SoilAnalysisType;
    cardHidden: boolean;
    cardsHidden: boolean;
    ahoStage: AhoStage;
    selectedFields?: number[];
    selectedTours?: number[];
    agroFieldVisible: boolean;
    hideIndc?: boolean;
    ts: string;
    version: number;
}

export class AgroAhoUrlStore extends ObservableCustomStore {
    constructor(parent: AgroAhoStore) {
        super(parent);
        this.parentStore = parent;
    }

    parentStore: AgroAhoStore;

    isAhoTabSelected(){
        return this.root.searchPanel.leftPanelMode == LeftPanelModeAgroAho && this.parentStore.isActive;
    }


    // @save @observable
    // urlAhoCode: string = null;
    // urlPermalinkCode: string = null;


    // async setUrlCode(urlCode: string){
    //     await this.applyUrlCode(urlCode);
    //     this.parentStore.ahoStage = AhoStage.Card;
    //     this.root.searchPanel.switchPanel(LeftPanelModeAgroAho);
    // }

    clearPermalink(){
        console.log('clear plink');
    }

    updPermalink(){
        let ps = this.parentStore;
        let ms = ps.mapStore;
        let cs = ps.cardStore;
        let ts = ps.tourStore;
        // if (!cs.card && !cs.isCardsVisible()) return;
        let st = ts.selTours ? ts.selTours.map(t=>t.tour_id) : [];
        ps.ahoPermalink = {
            projName: ps.projStore.projName,
            map_id: cs.card?.map_id,
            layers: ms.layersShow ? {
                    tracks: ms.layerTracksShow,
                    cells: ms.layerCellsShow,
                    fields: ms.layerFieldOutlinesShow,
                    points: ms.layerPointsShow
                } : null,
            indcs: cs.soilAnalysisShow && cs.soilAnalysisType == SoilAnalysisType.Indicators ? {
                    indc_id: cs.selectedIndicator?.indc_id,
                    ahoCardShowType: cs.ahoCardShowType,
                    indcFormat: cs.indcFormat,
                    // defIndcFormat: cs.defIndcFormat
                } : null,
            rn_id: ms.mapRNStore.curRecNormItem?.rn_id,
            soilAnalysisType: cs.soilAnalysisType,
            cardHidden: !cs.isCardVisible(),
            cardsHidden: !cs.isCardsVisible(),
            ahoStage: ps.ahoStage,
            agroFieldVisible: ps.root.agro2.agroFieldVisible,
            selectedTours: st.length ? st : null,
            hideIndc: cs.hideIndc,
            ts: ps.ahoPermalink?.ts || AhoLocalTs,
            version: 1
        };
        let fields = cs.getFieldSelected();
        // console.log('link fields', fields);
        if (fields) ps.ahoPermalink.selectedFields = fields;
        // console.log('upd plink', ps.ahoPermalink);
    }

    async applyPermalink(){
        // console.log('apply permalink');
        let ps = this.parentStore;
        let cs = ps.cardStore;
        let ts = ps.tourStore;
        let ms = ps.mapStore;
        let pjs = ps.projStore;
        let pl = ps.ahoPermalink;
        if (!pl) return;

        this.parentStore.editorStore.resetFieldEditor();
        let card = cs.getCard(pl.map_id);
        if (!card) return;
        cs.card = card;
        pjs.projName = pl.projName;
        if (this.root.agro.projectName == null)
            await this.root.agro2.projectList.projectInfoRequest(pjs.projName);
        ra(async ()=>{
            cs.ignoreChangeConfig = true;
            cs.lastCardName = cs.card.map_name;
            cs.cardIndicators = await cs.getIndicators();
            await ts.loadsTours();
            let fitems: any[] = await ms.getFieldsInfo(cs.card.field_info.ds_code);
            if (pl.selectedFields) fitems.forEach(f=>f.checked = pl.selectedFields.includes(f.field_id));
            cs.fieldsItems = fitems;
            cs.sortFields(false);
            cs.cellsItems = await ms.getFieldsInfo(cs.card.cell_info.ds_code);
            // console.log('plink updCellsInfo', AhoUtils.cp(cs.cellsItems));
            if (pl.layers) {
                cs.soilAnalysisShow = false;
                ms.layersShow = true;
                ms.layerTracksShow = pl.layers.tracks;
                ms.layerCellsShow = pl.layers.cells;
                ms.layerPointsShow = pl.layers.points;
                ms.layerFieldOutlinesShow = pl.layers.fields;
                if (cs.cardIndicators && cs.cardIndicators.length)
                    cs.setIndicator(cs.cardIndicators[0]);
            } else if (cs.cardIndicators?.length || cs.cardRNStore.hasRecNorm()) {
                if (pl.indcs) {
                    if (pl.selectedTours && Array.isArray(pl.selectedTours)) ts.setSelectTours(pl.selectedTours);
                    let indc_ = cs.cardIndicators.filter(item=>item.indc_id === pl.indcs.indc_id);
                    cs.setIndicator(indc_.length ? indc_[0] : cs.cardIndicators[0]);
                    cs.ahoCardShowType = pl.indcs.ahoCardShowType;
                    cs.indcFormat = pl.indcs.indcFormat;
                }
                // console.log('rn:', pl.rn_id, cs.curRecNormItem, cs.getRNItem(pl.rn_id));
                // if (pl.rn_id) cs.curRecNormItem = cs.getRNItem(pl.rn_id);
                // console.log('rn2:', cs.curRecNormItem);
                if (pl.soilAnalysisType == SoilAnalysisType.RecNorms) await cs.cardRNStore.setRecNormTab(pl.rn_id) //cs.updRecNorm();
                    else cs.soilAnalysisType = SoilAnalysisType.Indicators;
                // if (pl.soilAnalysisType == SoilAnalysisType.Spinner) cs.soilAnalysisType = SoilAnalysisType.Indicators
                //     else cs.soilAnalysisType = pl.soilAnalysisType;
            }

            if (pl.agroFieldVisible !== undefined) {
                this.parentStore.root.agro2.agroFieldVisible = pl.agroFieldVisible;
            }
            cs.hideIndc = pl.hideIndc;
            cs.ignoreChangeConfig = false;
            ms.doChangeConfig();
            setTimeout(cs.doUpdFieldsOpacity, 1000);
        });
    }

    updUrlCode(isClear?: boolean){
        if (isClear) this.clearPermalink()
        else this.updPermalink();
    }

    // updUrlCode_(isClear?: boolean){
    //     // <projName>_<map_id>[_L_[c][t][f]] || _I_[indc_id]_[c]]
    //     if (isClear){
    //         // console.log('clear urlAhoCode');
    //         this.urlAhoCode = null;
    //         return;
    //     }
    //     let ms = this.parentStore.mapStore;
    //     let cs = this.parentStore.cardStore;
    //     if (!cs.card) return;
    //     let code = [`${this.parentStore.projStore.projName}_`];
    //     if (ms.layersShow) {
    //         code.push(`${cs.card.map_id}_L_`);
    //         if (ms.layerTracksShow) code.push('t');
    //         if (ms.layerCellsShow) code.push('c');
    //         if (ms.layerFieldOutlinesShow) code.push('f');
    //     } else if (cs.indicatorsShow) {
    //         code.push(`${cs.card.map_id}_I_`);
    //         let indc = cs.selectedIndicator?.indc_id;
    //         if (indc !== undefined) code.push(`${indc}`);
    //         if (cs.ahoCardShowType == AhoCardShowType.Cells) code.push('_c');
    //     } else {
    //         code.push(`${cs.card.map_id}`);
    //     }
    //     let code_ = code.join('');
    //     if (this.urlAhoCode !== code_) {
    //         this.urlAhoCode = code_;
    //     }
    // }

    parseUrlCode(urlCode_?: string){
        // <projName>_<map_id>[_L_[c][t][f]] || _I_[indc_id]]
        let urlCode = urlCode_; // || this.urlAhoCode || this.urlPermalinkCode;
        if (!urlCode) return;
        // let code = this.urlAhoCode.split('_');
        let code = urlCode.split('_');
        let res:IAhoUrlCode = {projName: code.shift(), map_id: +code.shift()};
        if (code.length) {
            res.type = code.shift() as IAhoUrlType;
            let data = code.shift();
            if (data) {
                if (res.type == IAhoUrlType.Layres) {
                    res.tracks = data.indexOf('t') >= 0;
                    res.cells = data.indexOf('c') >= 0;
                    res.field = data.indexOf('f') >= 0;
                } else {
                    res.indc_id = +data;
                }
                let label = code.shift();
                res.labelCell = label === 'c';
            }
        }
        return res;
    }


    async applyUrlCode(){
        return await this.applyPermalink();
    }

    async applyUrlCode_(urlCode: string){
        // <projName>_<map_id>[_L_[c][t][f]] || _I_[indc_id]_[c]]
        // if (!this.urlAhoCode) return;
        // console.log('apply 1', urlCode);
        if (!urlCode) return;
        // console.log('apply 2');
        let cs = this.parentStore.cardStore;
        let ms = this.parentStore.mapStore;
        let ps = this.parentStore.projStore;
        let code = this.parseUrlCode(urlCode);
        let card = cs.getCard(code.map_id);
        // console.log('card parsed', cs.card);
        if (!card) return;
        cs.card = card;
        // console.log('apply 3', code);
        ps.projName = code.projName;
        // console.log('apply 4');
        if (this.root.agro.projectName == null)
            await this.root.agro2.projectList.projectInfoRequest(ps.projName);
        ra(async ()=>{
            cs.lastCardName = cs.card.map_name;
            cs.cardIndicators = await cs.getIndicators();
            if (code.type == IAhoUrlType.Layres) {
                cs.soilAnalysisShow = false;
                ms.layersShow = true;
                ms.layerTracksShow = !!code.tracks;
                ms.layerCellsShow = !!code.cells;
                ms.layerFieldOutlinesShow = !!code.field;
                // cs.selectedIndicator = cs.cardIndicators[0];
                cs.setIndicator(cs.cardIndicators[0]);
            } else if (cs.cardIndicators.length) {
                let indc_ = cs.cardIndicators.filter(item=>item.indc_id === code.indc_id);
                // cs.selectedIndicator = indc_.length ? indc_[0] : cs.cardIndicators[0];
                cs.setIndicator(indc_.length ? indc_[0] : cs.cardIndicators[0]);
                if (code.labelCell) cs.ahoCardShowType = AhoCardShowType.Cells;
            }
        });
        return 'ok';
    }


}