import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoCopyTourComp.scss';
import {action, observable} from 'mobx';
import {DropDownComp, IDropDownItem} from "../../../../app/components/Common/DropDownComp";
import classNames from "classnames";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {CopyType} from "../../agroAhoCardStore";
import {TranslateUtils} from "../../../../app/helper/lang/TranslateUtils";
import {AhoUtils} from "../../agroAhoUtils";

@observer
export class AgroAhoCopyTourComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    onClickCancel(){
        this.props.store.agroAhoStore.toggleModal(false);
    }

    onClickOk(){
        if (!this.canSave()) return;
        let store_ = this.props.store.agroAhoStore;
        store_.tourStore.doTourCopyClick();
    }

    canSave(){
        let tstore = this.props.store.agroAhoStore.tourStore;
        return !!tstore.copyTourNewName && !tstore.notHaveFields && !tstore.notHaveCells;
    }

    onChangeNewName(e: any){
        let tstore = this.props.store.agroAhoStore.tourStore;
        tstore.copyTourNewName = e.currentTarget.value;
    }

    onChangeMap(item: IDropDownItem){
        let tstore = this.props.store.agroAhoStore.tourStore;
        tstore.copyTourMapId = item.key as number;
        tstore.resetNoHaveErr();
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let cstore = store_.cardStore;
        let cs_store = store_.cardsStore;
        let tstore = store_.tourStore;

        let cards_ = cs_store.cards.filter(c => !c.filtered && c.map_id != cstore.card.map_id);
        let cards:IDropDownItem[] = cards_.map(c=>{return {key: c.map_id, value: c.map_name}});
        let tourName = tstore.selTours?.[0]?.tour_name || '';
        let dstCard = cards.filter(c=>c.key == tstore.copyTourMapId);
        let dstMapName = dstCard?.length ? dstCard[0].value : '';


        return <div className="AgroAhoCopyTourComp-main">
            <div className="AgroAhoCopyTourComp-title">{store_.trans['Copying a selected tour']}</div>
            <div className="AgroAhoCopyTourComp-statistic">
                {tourName}
            </div>
            <div className="AgroAhoCopyTourComp-line" />
            <div className="AgroAhoCopyTourComp-actionCover">
                <div className="AgroAhoCopyTourComp-name">
                    {store_.trans['Where to copy']}<span className="blue-text text-next-half">*</span>
                </div>
                <div className="AgroAhoCopyTourComp-input">
                    <DropDownComp items={cards} currentKey={tstore.copyTourMapId} onChange={this.onChangeMap}
                        className="AgroAhoCopyTourComp-mapInput" />
                </div>
            </div>
            <div className="AgroAhoCopyTourComp-newNameCover">
                <div className="AgroAhoCopyTourComp-name">
                    {store.trans.Name}<span className="blue-text text-next-half">*</span>
                </div>
                <div className="AgroAhoCopyTourComp-newName">
                    <input type="text" className="text-box-editor width100" onChange={this.onChangeNewName}
                           value={tstore.copyTourNewName} />
                </div>
            </div>

            <div className={classNames("AgroAhoCopyTourComp-notHaveCover", {
                "AgroAhoCopyTourComp-hidden": !tstore.notHaveFields && !tstore.notHaveCells})}>
                <div className="AgroAhoCopyTourComp-notHaveTitle">
                    {tstore.notHaveFields ?
                        TranslateUtils.format(store_.trans['The ASA map {0} does not have the following fields:'], dstMapName) :
                        TranslateUtils.format(store_.trans['The ASA map {0} does not have the following cell numbers:'], dstMapName)}
                </div>
                <div className="AgroAhoCopyTourComp-notHaveList">{tstore.notHaveFields ? tstore.notHaveFields
                    : tstore.notHaveCells}</div>
            </div>


            <div className="AgroAhoCopyTourComp-line" />
            <div className="AgroAhoCopyTourComp-bottom">
                <button className="left-sidebar-cancel large-button button-jump AgroAhoCopyTourComp-button text-next-2"
                    onClick={this.onClickCancel}>
                    {store.trans.Cancel}
                </button>
                <button className={classNames(
                    "left-sidebar-active large-button AgroAhoCopyTourComp-button text-next-2", {
                        "button-jump": this.canSave(),
                        "AgroAhoCopyTourComp-disabled": !this.canSave()
                    })} onClick={this.onClickOk} >
                    {store_.trans.Copy}
                </button>
            </div>

        </div>;
    }
}
