import {Utils} from "../../../helper/utils/Utils";
import {CheckStates} from "../../../helper/structs/CheckStates";
import {LeftPanelMode, SceneUiType, SearchStore} from "../../SearchStore";
import {SearchItemStore} from "../../SearchItemStore";
import {AnySourceData} from "maplibre-gl";
import {MapScenesStore} from "../../MapScenesStore";
import {ProductsStore} from "../../productSetting/ProductsStore";
import {IReactionDisposer, autorun, computed} from "mobx";
import {CustomTool} from "../general/ContainerTools";
import {ConstantsStore} from "../../config/ConstantsStore";

interface IMapSceneLayerOrder{
    sceneItem: SearchItemStore;
    uiType: SceneUiType;
}
interface IMapSceneLayerLink{
    sceneId: string;
    layerName: string;
    sourceName: string;
    uiType: SceneUiType;
    uniqHash: string;
    sourceJson: AnySourceData;
}

export class SceneFavoritesTool extends CustomTool{
    mapScenesStore: MapScenesStore;
    productInfo: ProductsStore;
    searchPanel: SearchStore;

    static readonly SCENE_LAYER_PREFIX : string = ConstantsStore.COMMON_PREFIX+'scene_lr_';// используется как префикс для сцен
    static readonly SCENE_SOURCE_PREFIX : string = ConstantsStore.COMMON_PREFIX+'scene_src_';


    onSubscription(): IReactionDisposer[] {
        return [autorun(() => {
            this.syncFavorites();
        })];
    }

    private prevSceneLinks: IMapSceneLayerLink[] = [];

    getAllScenes(): IMapSceneLayerLink[]{
        return [...this.prevSceneLinks];
    }
    getOpaciyValue(sceneId: string): number{
        if (SearchStore.is_S5_SeceneId(sceneId))
            return this.productInfo.opacity/100;
        else return 1;
    }

    @computed
    get currentScenes() : string[] {
        return this.prevSceneLinks.map(s => s.sceneId);
    }

    syncFavorites(){

        let mapbox = this.map;
        if (mapbox == null) return;

        let needScenes: IMapSceneLayerOrder[] = this.getFutureLayers();
        let needLinks = this.getLinks(needScenes);
        //синхронизация нового списка и старого
        let prevSceneLinks = [...this.prevSceneLinks];
        let ths = this;
        let prevLayerId: string = null;
        needLinks.reverse().forEach(nl =>{
            let oldIndex = prevSceneLinks.findIndex(a => a.uniqHash == nl.uniqHash);
            if (oldIndex >= 0) {
                let old = prevSceneLinks[oldIndex];
                this.moveLayer(old.layerName, prevLayerId);
                let opacity = this.getOpaciyValue(nl.sceneId);
                mapbox.setPaintProperty(old.layerName, "raster-opacity", opacity);
                Utils.arrayRemoveByIndex(prevSceneLinks, oldIndex);
                prevLayerId = old.layerName;
            }else{
                let oldIndex2 = prevSceneLinks.findIndex(a => a.sourceName == nl.sourceName);
                if (oldIndex2 >= 0) {//заменяем
                    let old2 = prevSceneLinks[oldIndex2];
                    Utils.arrayRemoveByIndex(prevSceneLinks, oldIndex2);
                    ths.removeLayer(old2.layerName);
                    mapbox.removeSource(old2.sourceName);
                }

                mapbox.addSource(nl.sourceName, nl.sourceJson);
                ths.insertLayer({
                    id: nl.layerName, type: 'raster',
                    source: nl.sourceName,
                    paint: {'raster-opacity': this.getOpaciyValue(nl.sceneId)},
                    layout: {"visibility": "visible"}
                }, prevLayerId);
                prevLayerId = nl.layerName;
            }
        });

        //удаляем не используемые
        prevSceneLinks.forEach(l =>{
            //if (mapbox.getLayer(l.layerName) != null) {
            ths.removeLayer(l.layerName);
            //}
            //if (mapbox.getSource(l.sourceName) != null) {
            mapbox.removeSource(l.sourceName);
            //}
        });
        this.prevSceneLinks = needLinks;
        this.mapScenesStore.root.searchPanel.detectTopScene();
    }


    private getLinks(scenes: IMapSceneLayerOrder[]): IMapSceneLayerLink[]{
        let res: IMapSceneLayerLink[] = [];
        res = scenes.map(a =>{

            let jsonSource: any;
            if (a.sceneItem.feature.properties.tiles_exists) {
                jsonSource = this.mapScenesStore.getTilesSource(a.sceneItem.feature.properties, a.uiType);
            }
            else
                jsonSource = this.mapScenesStore.getQuicklookSourceJson(a.sceneItem.feature.properties);
            //jsonSource = this.root.map.getTilesSource(a.sceneItem.feature.properties, a.uiType);
            let l: IMapSceneLayerLink = {
                sourceName: SceneFavoritesTool.SCENE_SOURCE_PREFIX+a.sceneItem.sceneId()+"-"+a.uiType,
                layerName: SceneFavoritesTool.SCENE_LAYER_PREFIX+a.sceneItem.sceneId()+"-"+a.uiType,
                uiType: a.uiType,
                sourceJson: jsonSource,
                sceneId: a.sceneItem.sceneId(),
                uniqHash: JSON.stringify(jsonSource)+"-"+a.uiType
            };
            return l;
        });
        return res;
    }

    private getFutureLayers(): IMapSceneLayerOrder[]{

        let items: IMapSceneLayerOrder[] = [];

        this.searchPanel.favoriteList.groups
            .filter(a => a.getSelected() != CheckStates.unchecked && this.mapScenesStore.favoriteScenesVisible)
            .forEach(b => b.items.forEach(c => items.push({sceneItem: c, uiType: SceneUiType.favorite})));
        items = items.reverse();

        if (this.searchPanel.currentSceneVisible) {
            this.searchPanel.searchResult.groups.filter(a => a.isCurrent()).forEach(b => {
                b.items.forEach(c => {
                    items.push({sceneItem: c, uiType: SceneUiType.current});
                })
            });
        }
        return items;
    }

}