import * as React from 'react';
import {ReactNode, ReactNodeArray} from 'react';
import {isString} from "lodash-es";
import {ProductStyleType} from "../../store/config/ConfigStore";
import {SuperStore} from "../../store/SuperStore";
import {SearchParamsS5CompositeMethod} from "../../store/SearchParamsS5Store";
import {
    IndexDataGroup,
    PrecipitationCategory,
    SolarRadCategory,
    TemperatureCategory
} from "../../store/indeByPoint/IndexByPointerStore";
import {IProjectTableLang, ProjType} from "../../store/user/UserStore";
import {A2ExportFormat} from "../../store/agro/A2AgroStore";
import {ProjectRole} from "../../store/agro/A2PermissionStore";
import {Lang} from "../../../pluginApi/store/Lang";

export class TranslateUtils  {
    static getUserFullName(surname: string, given_name: string): string{
        return surname +" "+ given_name;
    }
    static getProjectRoleTitle(projRole: ProjectRole, store: SuperStore): string{
        switch (projRole){
            case ProjectRole.admin:
                return store.trans.Administrator;
            case ProjectRole.view:
                return store.trans.View;
            case ProjectRole.edit:
                return store.trans.Edit;
            default:
                throw "Unkonw Project Role";
        }
    }
    static getProjectTypeTitle(projType: ProjType, store: SuperStore): string{
        switch (projType){
            case ProjType.agro_base: return store.trans.Agro;
            case ProjType.agro_region: return "Agro region";
            case ProjType.agro_insur: return  store.trans["Agricultural insurance"];
            case ProjType.parcel_assess: return  store.trans["Parcel assess"];
            case ProjType.pnt_yld_assess: return store.trans["pnt_yld_assess"];
            default: return projType;
        }
    }
    static getTemperatureCategoryTitle(cat: TemperatureCategory, store: SuperStore): string{
        switch (cat){
            case TemperatureCategory.averageDaily: return  store.trans["Daily mean"];
            case TemperatureCategory.accumulated0: return  store.trans["Accumulated daily mean"]+" > 0°";
            case TemperatureCategory.accumulated5: return  store.trans["Sum of active (daily average > 5°)"];
            case TemperatureCategory.accumulated10: return  store.trans["Sum of active (daily average > 10°)"];
            case TemperatureCategory.averageMinMaxDaily: return  store.trans["Daily min, max"];
            case TemperatureCategory.maxDaily: return  store.trans["Daily maximum"];
            case TemperatureCategory.minDaily: return  store.trans["Daily minimum"];
            default: return "";
        }
    }
    static getPrecipitationCategoryTitle(cat: PrecipitationCategory, store: SuperStore): string{
        switch (cat){
            case PrecipitationCategory.daily: return  store.trans["Daily precipitation"];
            case PrecipitationCategory.accumulated: return  store.trans["Accumulated precipitation"];
            default: return "";
        }
    }
    static getSolarRadCategoryTitle(cat: SolarRadCategory, store: SuperStore): string{
        switch (cat){
            case SolarRadCategory.daily: return  store.trans["Daily radiation"];
            case SolarRadCategory.accumulated: return  store.trans["Accumulated radiation"];
            default: return "";
        }
    }

    static format(str: string, arg0: any, arg1: any = null, arg2: any = null): string{
        return str.replace("{0}", arg0).replace("{1}", arg1).replace("{2}", arg2);
    }

    static getTranslateFromDic(dic: IProjectTableLang, lang: Lang): string{
        if (dic[lang] != null) return dic[lang];
        return dic[Lang.en];
    }
    static getGeoFormatTitle(format: A2ExportFormat): string{
        switch (format) {
            case A2ExportFormat.geojson:
                return "GeoJSON";
            case A2ExportFormat.csv:
                return "CSV";
            case A2ExportFormat.shp:
                return "ESRI Shapefile";
            case A2ExportFormat.kml:
                return "KML";
        }
    }
    static getCompositeMethodShortName(compositeMethod: SearchParamsS5CompositeMethod, store: SuperStore): string{
        switch (compositeMethod) {
            case SearchParamsS5CompositeMethod.newest: return store.trans["The newest"];
            case SearchParamsS5CompositeMethod.oldest: return store.trans["The oldest"];
            case SearchParamsS5CompositeMethod.max: return store.trans["Maximum"];
            case SearchParamsS5CompositeMethod.min: return store.trans.Minimum;
            case SearchParamsS5CompositeMethod.mean: return store.trans.Mean;
            case SearchParamsS5CompositeMethod.median: return store.trans.Median;
            case SearchParamsS5CompositeMethod.rms: return store.trans["Root mean square"];
            default: return "";
        }
    }
    static getTitleIndexDataGroup(group: IndexDataGroup, store: SuperStore): string{
        switch (group) {
            case IndexDataGroup.modis: return store.trans["MODIS"];
            case IndexDataGroup.spectral: return store.trans["Spectral indices"];
            case IndexDataGroup.radar_iw: return store.trans["Sentinel-1 IW"];
            case IndexDataGroup.radar_ew: return store.trans["Sentinel-1 EW"];
            case IndexDataGroup.temperature: return store.trans.Temperature;
            case IndexDataGroup.precipitation: return store.trans.Precipitation;
            case IndexDataGroup.solarRadiation: return store.trans["Solar radiation"];
            case IndexDataGroup.relativeHumidity: return store.trans["Relative Humidity"];
            default: return "";
        }
    }
    static getCompositeMethodLongName(compositeMethod: SearchParamsS5CompositeMethod, store: SuperStore): string{
        switch (compositeMethod) {
            case SearchParamsS5CompositeMethod.newest: return store.trans["Takes the newest among valid pixels"];
            case SearchParamsS5CompositeMethod.oldest: return store.trans["Takes the oldest among valid pixels"];
            case SearchParamsS5CompositeMethod.max: return store.trans["Calculates maximum among valid pixels"];
            case SearchParamsS5CompositeMethod.min: return store.trans["Calculates minimum among valid pixels"];
            case SearchParamsS5CompositeMethod.mean: return store.trans["Calculates mean among valid pixels"];
            case SearchParamsS5CompositeMethod.median: return store.trans["Calculates median among valid pixels"];
            case SearchParamsS5CompositeMethod.rms: return store.trans["Calculates root mean square among valid pixels"];
            default: return "";
        }
    }
    static getNameOfProductStyleType(type: ProductStyleType, store: SuperStore): string{
        if (type == ProductStyleType.LUT) return store.trans.LUT;
        if (type == ProductStyleType.static) return store.trans.Static;
        if (type == ProductStyleType.adaptive) return store.trans.Adaptive;
        if (type == ProductStyleType.index) return store.trans.Index;
        return "unknow";
    }
    static createSpansWithReplaceMarkers(str: string, arg0: ReactNode, arg1: ReactNode= null, arg2: ReactNode= null, arg3: ReactNode= null): ReactNode{
        let r: ReactNodeArray = [str];
        if (arg0 != null) r = TranslateUtils.replaceArray(r,"{0}", arg0);
        if (arg1 != null) r = TranslateUtils.replaceArray(r,"{1}", arg1);
        if (arg2 != null) r = TranslateUtils.replaceArray(r,"{2}", arg2);
        if (arg3 != null) r = TranslateUtils.replaceArray(r,"{3}", arg3);
        return r;
    }
    private static replaceArray(r: ReactNodeArray, marker: string, arg: ReactNode): ReactNodeArray{
        let r2: ReactNodeArray = [];
        r.forEach(s => {
            if (isString(s)){
                let rr = TranslateUtils.replaceMarker(s, marker, arg);
                r2 = r2.concat(rr);
            }else
                r2.push(s);
        });
        return r2;
    }
    private static replaceMarker(str: string, marker: string, arg: ReactNode): ReactNodeArray{
        let arr = str.split(marker);
        let r: ReactNodeArray = [];
        arr.forEach((s, i)=>{
            r.push(<span key={i}>{s}</span>);
            if (i != arr.length-1){
                r.push(<React.Fragment key={i+"_frag"}>{arg}</React.Fragment>);
            }
        });
        return r;
    }
}
