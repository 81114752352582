import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import "./ProjectSettings.scss";
import {action} from "mobx";
import classNames from "classnames";
import {ProjectSettingPage} from "../../../../store/agro/ProjectSettingStore";
import {A2ProjectStructForm} from "../../Left/Agro2/A2ProjectStruct/A2ProjectStructForm";
import {AgroProjectSecurityComp} from "../../Left/Agro2/ProjectEditor/AgroProjectSecurityComp";
import {ProjSettingPageGeneral} from "./ProjSettingPageGeneral";
import {ProjectRole} from "../../../../store/agro/A2PermissionStore";
import {ProjSettingPageStyle} from "./ProjSettingPageStyle";

@observer
export class ProjSetting extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClose(){
        this.props.store.agro2.projectSettings.closeWindow();
    }
    @action onClickPage1(){
        this.props.store.agro2.projectSettings.page = ProjectSettingPage.general;
    }
    @action onClickPage2(){
        this.props.store.agro2.projectSettings.page = ProjectSettingPage.access;
    }
    @action onClickPage3(){
        this.props.store.agro2.projectSettings.page = ProjectSettingPage.struct;
    }
    @action onClickPage4(){
        this.props.store.agro2.projectSettings.page = ProjectSettingPage.styles;
    }

    isDisabledGeneral(): boolean{
        return (this.props.store.agro2.permission.role != ProjectRole.admin);
    }
    isDisabledRights(): boolean{
        return (this.props.store.agro2.permission.role != ProjectRole.admin);
    }

    render() {
        let store = this.props.store;
        let projStore = store.agro2.projectSettings;
        let page = projStore.page;
        if (page == ProjectSettingPage.general && this.isDisabledGeneral()) page = ProjectSettingPage.struct;
        if (page == ProjectSettingPage.access && this.isDisabledRights()) page = ProjectSettingPage.struct;

        return <div className={classNames("modal-back", {"display-none": store.agro2.projectSettings.hiddenProjectSettings})}>
            <div className="modal-full modal-full-frame ProjectSettings-window">
                <div className="ProjectSettings-body">
                    <div className="ProjectSettings-left">
                        <div className="ProjectSettings-leftTitle">
                            {store.trans["Project settings"]}
                        </div>
                        <div className="ProjectSettings-leftSubTitle">
                            {store.agro.projectTitle}
                        </div>

                        <div className="ProjectSettings-leftList">
                            <div onClick={this.onClickPage1}
                                className={classNames("ProjectSettings-leftItem",{"active": projStore.page == ProjectSettingPage.general, "opacity30": this.isDisabledGeneral()})}>
                                {store.trans["General information"]}
                            </div>
                            <div onClick={this.onClickPage2}
                                className={classNames("ProjectSettings-leftItem",{"active": projStore.page == ProjectSettingPage.access, "opacity30": this.isDisabledRights()})}>
                                {store.trans["Access rights"]}
                            </div>
                            <div onClick={this.onClickPage3}
                                 className={classNames("ProjectSettings-leftItem",{"active": projStore.page == ProjectSettingPage.struct})}>
                                {store.trans["Project structure"]}
                            </div>
                            <div onClick={this.onClickPage4}
                                 className={classNames("ProjectSettings-leftItem",{"active": projStore.page == ProjectSettingPage.styles})}>
                                {store.trans["Visualization"]}
                            </div>
                        </div>
                    </div>
                    <div className="ProjectSettings-right">
                        <div className="ProjectSettings-close white_svg blue_svg_onhover pointer" onClick={this.onClose}>
                            <svg width="14" height="14" viewBox="0 0 14 14"  xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.7 0.3C13.3 -0.1 12.7 -0.1 12.3 0.3L7 5.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L7 8.4L12.3 13.7C12.5 13.9 12.8 14 13 14C13.2 14 13.5 13.9 13.7 13.7C14.1 13.3 14.1 12.7 13.7 12.3L8.4 7L13.7 1.7C14.1 1.3 14.1 0.7 13.7 0.3Z" stroke="none"/>
                            </svg>
                        </div>
                        {page == ProjectSettingPage.struct &&
                            <A2ProjectStructForm store={store} />
                        }
                        {page == ProjectSettingPage.access &&
                            <AgroProjectSecurityComp store={store} isForm={false} showTitle={false} bodyClassName="ProjectSettings-accessBody" />
                        }
                        {page == ProjectSettingPage.general &&
                            <ProjSettingPageGeneral store={store} />
                        }
                        {page == ProjectSettingPage.styles &&
                            <ProjSettingPageStyle store={store} />
                        }
                        {/*<div className="ProjectSettings-rightColumns">
                            <div className="ProjectSettings-rightCol">
                                <div className="ProjectSettings-rightColTitle">
                                    {store.trans["Personal data"]}
                                </div>
                                <div className="ProjectSettings-rightColBody style-4 overflowAutoY">
                                </div>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </div>
        </div>;
    }
}
