import {CustomStoreTool} from "../../app/store/tools/general/CustomStoreTool";
import {IReactionDisposer} from "mobx/lib/internal";
import {autorun} from "mobx";
import {LeftPanelMode} from "../../app/store/SearchStore";
import {Utils} from "../../app/helper/utils/Utils";
import {fetchJsonGet} from "../../app/helper/utils/FetchUtils";
import {MeteoStore, ViewMode} from "./MeteoStore";
import {IMeteoSuperStore, LeftPanelModeMeteo} from "./meteoPlugin";
import { ImageSource, RasterSource } from "maplibre-gl";
import tinycolor from "tinycolor2";

export class MeteoTool extends CustomStoreTool {

    static METEO_GRID_LAYER = "class_Meteo_Grid_layer";
    static METEO_GRID_SOURCE = "class_Meteo_Grid_src";

    onInstall() {
        super.onInstall();
    }

    onUninstall() {
        super.onUninstall();
    }

    onSubscription(): IReactionDisposer[] {
        return [
            autorun(() => {
                this.setupLayers();
            })
        ];
    }

    get meteoStore(): MeteoStore{
        return (this.store as any as IMeteoSuperStore).meteoStore;
    }

    setupLayers() {
        let meteo = this.meteoStore;
        if (! meteo.active) return;
        if (this.store.searchPanel.leftPanelMode != LeftPanelModeMeteo) {
            this.removeLayers();
            if (meteo.deviation)
                meteo.deviation.autoChange = false;
        }
        else {
            if (meteo.isTempOrPrecip() || meteo.isGtk()) {
                if (meteo.somethingChanged || ! meteo.currentParams || meteo.deviation?.autoChange) //nothing to show
                    return;
                let p = meteo.getTempPrecipParams();
                meteo.currentParams = {...meteo.currentParams, style: p.style, qMin: p.qMin, qMax: p.qMax};
            }
            else if (meteo.isDrought() && meteo.droughtDate) {
                let p = meteo.getDroughtParams();
                if (p.date != meteo.currentParams?.date)
                    this.removeLayers();
                meteo.currentParams = p;
            }
            //Обновить параметры, которые влияют на раскраску без Apply
            if (meteo.viewMode == ViewMode.District) {
                meteo.currentParams.bbox = "19,41,180,82";
                this.getDistrictStats(meteo.currentParams);
            }
            else if (meteo.viewMode == ViewMode.Grid) {
                if (meteo.currentParams)
                    meteo.currentParams.bbox = "-180,-85,180,85";
                this.updateParamsGrid(meteo.currentParams);
            }
        }
    }

    getDistrictStats(params : any) {
        let statsUrl = '/meteo/api/values?min_max=1';
        statsUrl += `&year=${params.year}&from_date=${params.startDate}&to_date=${params.endDate}`
            + `&type=${params.dataType}&value=${params.valueType}&function=${params.valFunction}`
            + `&filter=${params.filterFunc}&filter_val=${params.filterValue}&bbox=${params.bbox}`;
        fetchJsonGet(statsUrl)
            .then(stats => this.appendDistrictsLayer(stats, params))
            .catch((err) => {
                this.store.addError(Utils.getErrorString(err));
            });
    }

    getGrid(params : any) {
        let statsUrl = '/meteo/api/grid?min_max=1';
        let style = JSON.stringify(params.style);
        statsUrl += `&year=${params.year}&from_date=${params.startDate}&to_date=${params.endDate}`
            + `&type=${params.dataType}&value=${params.valueType}&function=${params.valFunction}`
            + `&filter=${params.filterFunc}&filter_val=${params.filterValue}&bbox=${params.bbox}&pal=${params.palette}`
            + `&q_min=${params.qMin}&q_max=${params.qMax}&style=${encodeURIComponent(style)}`;
        if (params.vMax != null)
            statsUrl += `&v_max=${params.vMax}`;
        if (params.vMin != null)
            statsUrl += `&v_min=${params.vMin}`;
        fetchJsonGet(statsUrl)
            .then(stats => this.appendGridLayer(stats, params))
            .catch((err) => {
                this.store.addError(Utils.getErrorString(err));
            });
    }

    updateParamsGrid(params : any) {
        let meteo = this.meteoStore;
        if (meteo.gridSource && meteo.onlineChange) { //update via frontend
            meteo.onlineChange = false;
            return this.updateMeteoParamImage(null, params);
        }

        let dataUrl = "";
        let updateImage: Function = null;
        if (meteo.isTempOrPrecip()) {
            dataUrl = '/api/meteo/bingrid?' +
                `&from_date=${params.startDate}&to_date=${params.endDate}` +
                `&type=${params.dataType}&value=${params.valueType}&function=${params.valFunction}` +
                `&filter=${params.filterFunc}&filter_val=${params.filterValue}&bbox=${params.bbox}`;
            updateImage = this.updateMeteoParamImage;
        }
        else if (meteo.isGtk()) {
            let product = ({"value": "fact", "norm": "climate", "div": "ratio"} as any) [params.valFunction];
            let date1 = params.startDate;
            let date2 = params.endDate;
            if (params.valFunction == "norm") {
                date1 = date1.substr(5);
                date2 = date2.substr(5);
            }
            dataUrl = "/api/meteo/drought/map_image?index=gtk" +
                `&product=${product}&date1=${date1}&date2=${date2}`;
            updateImage = this.updateMeteoParamImage;
        }
        else if (meteo.isDrought()) {
            if (! params?.date) return;
            dataUrl = `/api/meteo/drought/image?product=gtk&date=${params.date}`;
            updateImage = this.updateDraughtImage;
        }
        fetch(dataUrl)
            .then(r => {if (r.ok) return r.arrayBuffer()})
            .then(data => updateImage(data, params))
            .catch((err) => {
                this.store.addError(Utils.getErrorString(err));
            });
    }

    getUnit(id: string) {
        switch (id) {
            case 'celsius': return ' °C';
            case 'percent': return ' %';
            case 'mm': return ' mm';
            default: return ' ';
        }
    }

    getUnitName(param: string, func: string) {
        if (['div', 'minus_div'].indexOf(func) >= 0) return 'percent';
        if (param == 'temp') return 'celsius';
        if (param == 'prec') return 'mm';
        return null;
    }

    appendDistrictsLayer(stats : any, params: any) {
        let map = this.store.map.mapbox;
        let meteo = this.meteoStore;
        meteo.stats = {
            min: stats['min'],
            max: stats['max'],
            unit: this.getUnit(stats['unit'])
        }
        if (! meteo.deviation)
            meteo.deviation = { min: stats['min'], max: stats['max'], ranges: [], autoChange: true};
        let layerUrl = `https://dev-class.ctrl2go.com/meteo/api/map?`;
        layerUrl += `year=${params.year}&from_date=${params.startDate}&to_date=${params.endDate}`
            + `&type=${params.dataType}&value=${params.valueType}&function=${params.valFunction}`
            + `&from_val=${stats['min']}&to_val=${stats['max']}`
            + `&filter=${params.filterFunc}&filter_val=${params.filterValue}`
            + '&bbox={bbox-epsg-3857}&width=256&height=256';
        if (!map.getSource(MeteoTool.METEO_GRID_SOURCE)) {
            map.addSource(MeteoTool.METEO_GRID_SOURCE,{
                type: 'raster',
                tiles: [ layerUrl ],
                tileSize: 256
            });
        }
        if (!map.getLayer(MeteoTool.METEO_GRID_LAYER)){
            this.addLayer({
                id: MeteoTool.METEO_GRID_LAYER,
                source: MeteoTool.METEO_GRID_SOURCE,
                type: 'raster',
            });
        }
    }

    appendGridLayer(stats : any, params: any) {
        let map = this.store.map.mapbox;
        let meteo = this.meteoStore;
        let colors = meteo.colors;
        let percentMode = params.valFunction == 'div';
        let overmax = stats['min'] > MeteoStore.DIV_PERCENTS[MeteoStore.DIV_PERCENTS.length - 1];
        let minVal = percentMode && !overmax ? Math.max(stats['min'], MeteoStore.DIV_PERCENTS[0]): stats['min'];
        let maxVal = percentMode && !overmax ? Math.min(stats['max'], MeteoStore.DIV_PERCENTS[MeteoStore.DIV_PERCENTS.length - 1]): stats['max'];
        meteo.stats = {
            min: stats['min'],
            max: stats['max'],
            unit: this.getUnit(stats['unit'])        
        }
        if (! meteo.deviation) {
            let vals: number[] = [];
            for (let i = 0; meteo.stats && i < colors.length - 1; i++) {
                //vals.push(stats['min'] + (stats['max'] - stats['min']) * (index + 1) / colors.length);
                let val = percentMode? (overmax? minVal : MeteoStore.DIV_PERCENTS[i + 1]):
                    (stats['stats'][(i + 0.5) * 100 / colors.length] + stats['stats'][(i + 1.5) * 100 / colors.length]) / 2;
                vals.push(Math.min(Math.max(val, minVal), maxVal));
            }
            meteo.deviation = { min: minVal, max: maxVal, ranges: vals, autoChange: true};
        }
        else {
            let vals: number[] = [];
            meteo.deviation.ranges.forEach(v => {
                vals.push(Math.min(Math.max(v, stats['min']), stats['max']));
            });
            meteo.deviation = { min: minVal, max: maxVal, ranges: vals, autoChange: true};
        }
        let img = new Image();
        img.src = "data:image/png;base64," + stats['image'];
        img.onload = (e) => {
            if (!map.getSource(MeteoTool.METEO_GRID_SOURCE)) {
                map.addSource(MeteoTool.METEO_GRID_SOURCE,{
                    type: 'image',
                    url: "data:image/png;base64," + stats['image'],
                    coordinates: [
                        [-180, 85],
                        [180, 85],
                        [180, -85],
                        [-180, -85]
                    ]
                });
            }
            if (!map.getLayer(MeteoTool.METEO_GRID_LAYER)){
                this.addLayer({
                    id: MeteoTool.METEO_GRID_LAYER,
                    source: MeteoTool.METEO_GRID_SOURCE,
                    type: 'raster',
                });
            }
        }
    }

    static getK(x: number, x0: number, x1: number) {
        return (x - x0) / (x1 - x0)
    }

    static interpolate(k: number, fromRgb: number[], toRgb: number[]) {
        let rgb: number[] = [];
        fromRgb.forEach((e, i) => {rgb.push(Math.round((1 - k) * e + k * toRgb[i]))} );
        return rgb;
    }

    drawDraught() {
        return function(val: number) {
            if (val <= 0) return [0xFF, 0, 0];
            if (val >= 5) return [0x3a, 0x4c, 0x40];
            if (val < 0.4)
                return MeteoTool.interpolate(MeteoTool.getK(val, 0, 0.4), [0xFF, 0, 0], [0xFF, 0xFF, 0]);
            if (val < 0.6)
                return MeteoTool.interpolate(MeteoTool.getK(val, 0.4, 0.6), [0xFF, 0xFF, 0], [0xc0, 0xdf, 0xd3]);
            return MeteoTool.interpolate(MeteoTool.getK(val, 0.6, 5), [0xc0, 0xdf, 0xd3], [0x3a, 0x4c, 0x40]);
        }
    }

    drawParam(colors: string[]) {
        let meteo = this.meteoStore;
        let min = meteo.deviation.min;
        let max = meteo.deviation.max;
        let ranges = meteo.deviation.ranges;
        let rangeMiddles = [meteo.deviation.min];
        for (let i = 0; i < ranges.length - 1; i++)
            rangeMiddles.push((ranges[i] + ranges[i + 1]) / 2);
        rangeMiddles.push(max);
        let rangeCount = rangeMiddles.length;
        let minColor = Utils.convertToRGB(colors[0]);
        let maxColor = Utils.convertToRGB(colors[rangeCount - 1]);
        let rangeColors = colors.map((c) => Utils.convertToRGB(c));
        
        let divFunction = function(n: number) {
            if (n > ranges[ranges.length - 1]) return maxColor;
            for (let i = 0; i < ranges.length; i++) {
                if (n <= ranges[i]) return rangeColors[i];
            }
            return [0, 0, 0]; // ERROR
        }

        let linearInterpFunction = function(n: number) {
            if (n <= min) return minColor;
            if (n >= max) return maxColor;
            for (let i = 0; i < rangeCount - 1; i++) {
                if (n < rangeMiddles[i + 1]) 
                    return MeteoTool.interpolate(MeteoTool.getK(n, rangeMiddles[i], rangeMiddles[i + 1]), rangeColors[i], rangeColors[i + 1]);
            }
            return [0, 0, 0]; // ERROR
        }

        let gtkFunction = function(n: number) {
            for (let i = 0; i < MeteoStore.HUMIDIFICATION_ZONES.length; i++) {
                let hz = MeteoStore.HUMIDIFICATION_ZONES[i];
                if (n > hz.min || Number.isNaN(hz.min)) return Utils.convertToRGB(hz.color);
            }
            return [0, 0, 0]; // ERROR
        }

        if (meteo.currentFunction.code == "div")
            return divFunction;
        else if (meteo.currentMeteoParam.code == "gtk" && meteo.currentFunction.code != "div")
            return gtkFunction;
        else
            return linearInterpFunction;
    }

    bytesToPng(byteToRgb : Function) {
        let src = this.meteoStore.gridSource;
        // Create canvas
        let canvas = document.createElement('canvas');
        let context = canvas.getContext('2d');
        let d = context.createImageData(src.width, src.height);
        canvas.height = src.height;
        canvas.width = src.width;

        let len = src.data.length;
        for(var i = 0; i < len; i++) {
            d.data[i*4 + 3] = isNaN(src.data[i])? 0 : 255;
            if (! isNaN(src.data[i]))
                [d.data[i*4], d.data[i*4 + 1], d.data[i*4 + 2]] = byteToRgb(src.data[i]);
        }

        // put data to context at (0, 0)
        context.putImageData(d, 0, 0);
        return canvas.toDataURL('image/png');
    }

    updateData(data: any) {
        if (! data) return;
        let shape = new Int16Array(data.slice(0, 4));
        let ar = new Float32Array(data, 4);
        this.meteoStore.gridSource = {
            data: ar,
            width: shape[1],
            height: shape[0],
            sortedData: ar.filter((n) => !isNaN(n)).sort()
        }
    }

    updateRaster(bytesToRgb: Function) {
        let map = this.store.map.mapbox;
        let img = new Image();
        let imgUri = this.bytesToPng(bytesToRgb);
        img.src = imgUri;
        img.onload = (e) => {
            if (!map.getSource(MeteoTool.METEO_GRID_SOURCE)) {
                map.addSource(MeteoTool.METEO_GRID_SOURCE,{
                    type: 'image',
                    url: imgUri,
                    coordinates: [
                        [-180, 85.0511288],
                        [180, 85.0511288],
                        [180, -85.0511288],
                        [-180, -85.0511288]
                    ]
                });
            }
            else
                (map.getSource(MeteoTool.METEO_GRID_SOURCE) as ImageSource).updateImage({'url': imgUri});
            if (!map.getLayer(MeteoTool.METEO_GRID_LAYER)){
                this.addLayer({
                    id: MeteoTool.METEO_GRID_LAYER,
                    source: MeteoTool.METEO_GRID_SOURCE,
                    type: 'raster',
                });
            }
        }
    }

    updateDraughtImage(data : any, params: any) {
        this.updateData(data);
        this.updateRaster(this.drawDraught());
    }

    updateMeteoParamImage(data : any, params: any) {
        let meteo = this.meteoStore;
        this.updateData(data);
        let percentMode = params.valFunction == 'div';
        let arMin = percentMode? 0 : params.qMin / 100;
        let arMax = percentMode? 1 : params.qMax / 100;
        let dataStats : any = {
            'min': Math.round(Utils.quantile(meteo.gridSource.sortedData, arMin, {sorted: true}) * 100) / 100,
            'max': Math.round(Utils.quantile(meteo.gridSource.sortedData, arMax, {sorted: true}) * 100) / 100,
            'stats': {
                0: Utils.quantile(meteo.gridSource.sortedData, 0, {sorted: true}),
                10: Utils.quantile(meteo.gridSource.sortedData, 0.1, {sorted: true}),
                30: Utils.quantile(meteo.gridSource.sortedData, 0.3, {sorted: true}),
                50: Utils.quantile(meteo.gridSource.sortedData, 0.5, {sorted: true}),
                70: Utils.quantile(meteo.gridSource.sortedData, 0.7, {sorted: true}),
                90: Utils.quantile(meteo.gridSource.sortedData, 0.9, {sorted: true}),
                100: Utils.quantile(meteo.gridSource.sortedData, 1, {sorted: true}),
            },
            'unit': this.getUnitName(params.dataType, params.valFunction)
        };
        let colors = meteo.colors;
        let overmax = dataStats['min'] > MeteoStore.DIV_PERCENTS[MeteoStore.DIV_PERCENTS.length - 1];
        let minVal = percentMode && !overmax ? Math.max(dataStats['min'], MeteoStore.DIV_PERCENTS[0]): dataStats['min'];
        let maxVal = percentMode && !overmax ? Math.min(dataStats['max'], MeteoStore.DIV_PERCENTS[MeteoStore.DIV_PERCENTS.length - 1]): dataStats['max'];
        meteo.stats = {
            min: dataStats['min'],
            max: dataStats['max'],
            unit: this.getUnit(dataStats['unit'])        
        }
        if (! meteo.deviation) {
            let vals: number[] = [];
            for (let i = 0; meteo.stats && i < colors.length - 1; i++) {
                let val = percentMode? (overmax? minVal : MeteoStore.DIV_PERCENTS[i + 1]):
                    (dataStats['stats'][(i + 0.5) * 100 / colors.length] + dataStats['stats'][(i + 1.5) * 100 / colors.length]) / 2;
                vals.push(Math.min(Math.max(val, minVal), maxVal));
            }
            meteo.deviation = { min: minVal, max: maxVal, ranges: vals, autoChange: true};
        }
        else {
            let vals: number[] = [];
            meteo.deviation.ranges.forEach(v => {
                vals.push(Math.min(Math.max(v, dataStats['min']), dataStats['max']));
            });
            meteo.deviation = { min: minVal, max: maxVal, ranges: vals, autoChange: true};
        }
        this.updateRaster(this.drawParam(colors));
    }

    removeLayers(){
        let map = this.store.map.mapbox;
        if (map.getLayer(MeteoTool.METEO_GRID_LAYER)){
            this.removeLayer(MeteoTool.METEO_GRID_LAYER);
        }
        if (map.getSource(MeteoTool.METEO_GRID_SOURCE)){
            map.removeSource(MeteoTool.METEO_GRID_SOURCE);
        }
    }

    repaintLayer() {
        //https://dev-class.ctrl2go.com/meteo/api/values?bbox=36.67192214568885,49.91571866057487,64.1157698019392,60.26695608380794&year=2021&from_date=2021-06-01&to_date=2021-06-30&type=temp&value=mean&function=minus&from_val=0&to_val=100&filter=ge&filter_val=0
        //type=temp&value=mean&function=minus&from_val=0&to_val=100&filter=ge&filter_val=0
        let map = this.store.map.mapbox;
        let meteo = this.meteoStore;
        if (!meteo.currentMeteoParam || !meteo.currentFunction || !meteo.dateInterval.isValid()
            ) return;
        let year = 2021;//getSelectValue('Year');
        let startDate = Utils.getDateStr(meteo.dateInterval.begin);//document.getElementById('StartDate').value;
        let endDate = Utils.getDateStr(meteo.dateInterval.end);//document.getElementById('EndDate').value;
        let dataType = meteo.currentMeteoParam.code;//getSelectValue('DataType');
        let valueType = 'mean';//getSelectValue('ValueType');
        let valFunction = meteo.currentFunction.code;//getSelectValue('Function');
        let fromValue = -60;//parseFloat(document.getElementById('FromValue').value);
        let toValue = 60;//parseFloat(document.getElementById('ToValue').value);
        let filterFunc = meteo.filterChecked? meteo.currentFilter.code: 'none';//getSelectValue('Filter');
        let filterValue = meteo.currentFilterValue;//parseFloat(document.getElementById('FilterValue').value);
        let b = map.getBounds();
        let bbox = b.getWest() + ',' + b.getSouth() + ',' + b.getEast() + ',' + b.getNorth();
        fetch(`/meteo/api/values?bbox=${bbox}`
            + `&year=${year}&from_date=${startDate}&to_date=${endDate}`
            + `&type=${dataType}&value=${valueType}&function=${valFunction}`
            + `&from_val=${fromValue}&to_val=${toValue}`
            + `&filter=${filterFunc}&filter_val=${filterValue}`)
            .then(res => res.json())
            .then(s => {
                let cf = this.colorFunction(fromValue, toValue);
                //console.log(s);
                let tempData : any = {};
                Object.keys(s).forEach(e => {
                    let rgb = cf(s[e]);
                    let color = '#';
                    rgb.forEach((e) => {color += ('0' + e.toString(16)).slice(-2)});
                    tempData[e] = color; //'#' + ('0' + rgb[0].toString(16)).slice(-2) + ('0' + rgb[1].toString(16)).slice(-2) + ('0' + rgb[2].toString(16)).slice(-2);
                });
                //console.log(tempData);
                map.setPaintProperty(MeteoTool.METEO_GRID_LAYER, 'fill-color', ['to-color', ['get', ['to-string', ['get', 'district_id']], ['literal', tempData]]]);
            })
            .catch(error => console.log(error));
    }

    colorFunction(fromVal: number, toVal: number) {
        let diff = toVal - fromVal;

        function getK(x: number, x0: number, x1: number) {
            return (x - x0) / (x1 - x0)
        }

        function interpolate(k: number, fromRgb: number[], toRgb: number[]) {
            let rgb: number[] = [];
            fromRgb.forEach((e, i) => {rgb.push(Math.round((1 - k) * e + k * toRgb[i]))} );
            return rgb;
        }

        function func(val: number) {
            if (val == null)
                return [0, 0, 0];
            let k = getK(val, fromVal, toVal);
            if (k <= 0)
                return [0x7f, 0x00, 0x00];
            if (0 < k && k <= 0.125)
                return interpolate(getK(val, fromVal, fromVal + 0.125*diff),
                    [0x7f, 0x00, 0x00], [0xff, 0x00, 0x00]);
            if (0.125 < k && k <= 0.375)
                return interpolate(getK(val, fromVal + 0.125*diff, fromVal + 0.375*diff),
                    [0xff, 0x00, 0x00], [0x00, 0xff, 0xff]);
            if (0.375 < k && k <= 0.625)
                return interpolate(getK(val, fromVal + 0.375*diff, fromVal + 0.625*diff),
                    [0x00, 0xff, 0xff], [0xff, 0xff, 0x00]);
            if (0.625 < k && k <= 0.875)
                return interpolate(getK(val, fromVal + 0.625*diff, fromVal + 0.875*diff),
                    [0x00, 0xff, 0xff], [0x00, 0x00, 0xff]);
            if (0.875 < k && k <= 1)
                return interpolate(getK(val, fromVal + 0.875*diff, toVal),
                    [0x00, 0x00, 0xff], [0x00, 0x00, 0x7f]);
            if (k > 1)
                return [0x00, 0x00, 0x7f];
        }
        return func;
    }
}