import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './A2NavigatorComp.scss';
import {A2NavigatorItemComp} from "./A2NavigatorItemComp";
import {A2NavigatorItemStore, NavigatorItemStoreType} from "../../../../../store/agro/A2NavigatorItemStore";
import {action, observable} from "mobx";
import {A2NavigatorSection, A2SubModeLeftPanel} from "../../../../../store/agro/A2AgroStore";
import {A2NavigatorSummaryComp} from "./A2NavigatorSummaryComp";
import {ContextMenuCommonComp, PopupCorner, PopupDirection} from "../../../../Common/ContextMenuCommonComp";
import classNames from "classnames";
import {IAgroPanelComp} from "../ProjectEditor/AgroPanelComp";
import {A2ConfirmExportMode} from "../../../../../store/agro/Confirms/A2ConfirmExportStore";
import { ProjType} from "../../../../../store/user/UserStore";
import {A2NavigatorItemListComp} from "./A2NavigatorItemListComp";

@observer
export class A2NavigatorComp extends React.Component<IAgroPanelComp, undefined> {
    constructor(props: IAgroPanelComp) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClick_home(){
        let store = this.props.store;
        //store.agro2.projectStruct.allNavigatorItems.forEach(a => a.activePage = false);
        store.agro2.navigatorSection = A2NavigatorSection.projects;
    }
    @action
    onClick_back(){
        let store = this.props.store;
        if (store.agro2.projectStruct.activeNavigatorPage == null) return;
        store.agro2.navigatorSection = A2NavigatorSection.folder;
    }
    is_buttonUpDisabled(): boolean{
        return (this.props.store.agro2.projectStruct.activeNavigatorPage.parentItem == null);
    }
    @action
    onClick_up(){
        if (this.is_buttonUpDisabled()) return;
        let store = this.props.store;
        if (store.agro2.projectStruct.activeNavigatorPage.parentItem == null){
            store.agro2.navigatorSection = A2NavigatorSection.projects;
        }
        store.agro2.projectStruct.to_up_folder();
    }

    @observable
    popupShow: boolean = false;

    @action
    onClosePopup(){
        this.popupShow = false;
    }
    @action
    onPopupClick(){
        this.popupShow = !this.popupShow;
    }

    @action
    onClickCreateProject(){
        this.props.store.agro2.projectEditor.toCreateProject();
    }

    @action
    clickCreateField(){
        if (!this.props.store.agro2.permission.canCreateObject()) return;
        this.props.store.agro2.openCreateFieldPanel();
        this.popupShow = false;
    }

    @action
    onClickProjectSettings(){
        let p = this.props.store.agro2.projectList.projects.find(a => a.proj_name == this.props.store.agro.projectName);
        if (p == null) {
            this.props.store.addError("Can not find project name. Send to administrator this message.");
            return;
        }
        this.props.store.agro2.projectEditor.setEditProject(p);
        this.props.store.agro2.projectSettings.openWindow();
        this.popupShow = false;
    }
    @action
    onClickLogView(){
        this.popupShow = false;
        this.props.store.user.logView.openLogView();
    }

    @action
    onClickUploadFields(){
        if (!this.props.store.agro2.permission.canCreateObject()) return;
        if (this.props.uploadInput.element != null) {
            this.props.uploadInput.element.click();
            this.popupShow = false;
        }
    }
    @observable
    count_obj: number;
    @observable
    area_sum: number;

    @action
    async onClickExport(){
        this.popupShow = false;
        let store = this.props.store;
        let navItem = store.agro2.projectStruct.activeNavigatorPage;
        if (navItem == null) return;
        let t = navItem.getSummary();
        this.props.store.agro2.confirmExport.fields = t.fields;
        this.props.store.agro2.confirmExport.area = t.area;
        this.props.store.agro2.confirmExport.activeNavigatorItem = navItem;
        let paths = navItem.getNaviPath();
        let pathText = store.agro.projectTitle;
        for(let i = paths.length - 1; i >= 1 ; i--){
            pathText += " > ";
            pathText += paths[i].column_stringValue;
        }
        this.props.store.agro2.confirmExport.mode_export = A2ConfirmExportMode.folder;
        this.props.store.agro2.confirmExport.path = pathText;
        this.props.store.agro2.confirmExport.doShow();
    }

    @action
    onClickDelete(){
        if (!this.props.store.agro2.permission.canDeleteObject()) return;
        this.popupShow = false;
        let store = this.props.store;
        let navItem = store.agro2.projectStruct.activeNavigatorPage;
        if (navItem == null) return;
        let t = navItem.getSummary();
        this.props.store.agro2.confirmDelete.fields = t.fields;
        this.props.store.agro2.confirmDelete.area = t.area;
        this.props.store.agro2.confirmDelete.activeNavigatorItem = navItem;
        this.props.store.agro2.confirmDelete.filterSelected = navItem.getAllFilters({notFirst: true});

        let paths = navItem.getNaviPath();
        let pathText = store.agro.projectTitle;
        for(let i = paths.length - 1; i >= 1 ; i--){
            pathText += " > ";
            pathText += paths[i].column_value;
        }
        this.props.store.agro2.confirmDelete.mode_export = A2ConfirmExportMode.folder;
        this.props.store.agro2.confirmDelete.path = pathText;
        this.props.store.agro2.confirmDelete.doShow();
    }


    render() {
        let store = this.props.store;


        let isProjectList = store.agro2.navigatorSection == A2NavigatorSection.projects;// store.agro2.projectStruct.allNavigatorItems.find(a => a.activePage) == null;
        let wasInProject = store.agro2.projectStruct.allNavigatorItems.find(a => a.activePage) != null;

        let showCreateFiledStr: {ok: boolean} = {ok: true};
        store.events.onAgroCanCreateField.call(showCreateFiledStr);
        let showCreateFiled = showCreateFiledStr.ok;
        let showDeleteFiledStr: {ok: boolean} = {ok: true};
        store.events.onAgroCanDeleteField.call(showDeleteFiledStr);
        let showDeleteFiled = showDeleteFiledStr.ok;

        let items: any[] = [];
        if (this.popupShow) {
            if (showCreateFiled) items.push(<div
                className={classNames("flex-columns flex-align-items-center A2NavigatorComp-popup-props-line", {"disabled": !this.props.store.agro2.permission.canCreateObject()})}
                onClick={this.clickCreateField} key="object_create">
                <div className="flex-stretch-item text-next">
                    {store.trans.object_create}
                </div>
            </div>);
            items.push(<div
                className={classNames("flex-columns flex-align-items-center A2NavigatorComp-popup-props-line", {"disabled": !this.props.store.agro2.permission.canCreateObject()})}
                onClick={this.onClickUploadFields} key="Import from file">
                <div className="flex-stretch-item text-next">
                    {store.trans["Import from file"]} <span
                    className="Agro-popup-props-description">{store.trans["(geojson, kml, shp in zip)"]}</span>
                </div>
            </div>);
            items.push(<div className="A2NavigatorComp-popup-props-line-div" key={"Export_div"}/>);
            items.push(<div className="flex-columns flex-align-items-center A2NavigatorComp-popup-props-line"
                            onClick={this.onClickExport}
                            key="Export">
                <div className="flex-stretch-item text-next">
                    {store.trans.Export}
                </div>
            </div>);
            if (showDeleteFiled) {
                items.push(<div className="A2NavigatorComp-popup-props-line-div" key="Delete_div"/>);
                items.push(<div
                    className={classNames("flex-columns flex-align-items-center A2NavigatorComp-popup-props-line", {"disabled": !this.props.store.agro2.permission.canDeleteObject()})}
                    onClick={this.onClickDelete} key="Delete">
                    <div className="flex-stretch-item text-next">
                        {store.trans.Delete}
                    </div>
                </div>);
            }
            items.push(<div className="A2NavigatorComp-popup-props-line-div" key={"Project settings_div"}/>);
            items.push(<div className="flex-columns flex-align-items-center A2NavigatorComp-popup-props-line"
                            onClick={this.onClickProjectSettings} key={"Project settings"}>
                <div className="flex-stretch-item text-next">
                    {store.trans["Project settings"]}
                </div>
            </div>);
            items.push(<div className="flex-columns flex-align-items-center A2NavigatorComp-popup-props-line"
                            onClick={this.onClickLogView} key={"History of user actions"}>
                <div className="flex-stretch-item text-next">
                    {store.trans["History of user actions"]}
                </div>
            </div>);
            items = store.events.onNavigatorPopup.call(items);
        }

        return <div>
            <div className="A2NavigatorComp-main">
                <div className="A2NavigatorComp-homeDiv">
                    {/*(!wasInProject || !isProjectList) &&*/
                        <div className={classNames("A2NavigatorComp-itemHome",{"disabled": isProjectList})} onClick={this.onClick_home}>

                        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <g id="home-3 1" clipPath="url(#clip0_5349_440)">
                                <g id="Group">
                                    <path id="Vector"
                                          d="M14 9C13.447 9 13 9.448 13 10V14H9V11.5C9 11.224 8.776 11 8.5 11H7.5C7.224 11 7 11.224 7 11.5V14H3V10C3 9.448 2.553 9 2 9C1.447 9 1 9.448 1 10V14C1 15.103 1.897 16 3 16H13C14.103 16 15 15.103 15 14V10C15 9.448 14.553 9 14 9Z"
                                          />
                                    <path id="Vector_2"
                                          d="M8.64995 0.241238C8.27695 -0.0797617 7.72195 -0.0797617 7.34895 0.241238L3.99895 3.11224V2.00024C3.99895 1.44824 3.55195 1.00024 2.99895 1.00024C2.44595 1.00024 1.99895 1.44824 1.99895 2.00024V4.82624L0.349951 6.24124C-0.070049 6.60024 -0.119049 7.23224 0.241951 7.65124C0.600951 8.07024 1.23295 8.11924 1.65095 7.75924L7.99995 2.31724L14.35 7.75924C14.538 7.92124 14.77 8.00024 15 8.00024C15.281 8.00024 15.562 7.88124 15.759 7.65124C16.119 7.23224 16.071 6.60024 15.651 6.24124L8.64995 0.241238Z"
                                          />
                                    <path id="Vector_3"
                                          d="M8.5 7H7.5C7.22386 7 7 7.22386 7 7.5V8.5C7 8.77614 7.22386 9 7.5 9H8.5C8.77614 9 9 8.77614 9 8.5V7.5C9 7.22386 8.77614 7 8.5 7Z"
                                          />
                                </g>
                            </g>
                            <defs>
                                <clipPath id="clip0_5349_440">
                                    <rect width="16" height="16" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>}
                    {(!isProjectList) &&  /*вверх*/
                        <div className={classNames("A2NavigatorComp-itemHome", {"disabled": this.is_buttonUpDisabled()})}
                             onClick={this.onClick_up}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16"
                                 >
                                <path
                                    d="M11.707 5.29279L6.70704 0.292786C6.51951 0.105315 6.26521 0 6.00004 0C5.73488 0 5.48057 0.105315 5.29304 0.292786L0.293041 5.29279C0.110883 5.48139 0.0100885 5.73399 0.0123669 5.99619C0.0146453 6.25838 0.119814 6.5092 0.305222 6.6946C0.490631 6.88001 0.741443 6.98518 1.00364 6.98746C1.26584 6.98974 1.51844 6.88894 1.70704 6.70679L5.00004 3.41379L5.00004 14.9998C5.00004 15.265 5.1054 15.5194 5.29293 15.7069C5.48047 15.8944 5.73482 15.9998 6.00004 15.9998C6.26526 15.9998 6.51961 15.8944 6.70715 15.7069C6.89468 15.5194 7.00004 15.265 7.00004 14.9998L7.00004 3.41379L10.293 6.70679C10.4816 6.88894 10.7342 6.98974 10.9964 6.98746C11.2586 6.98518 11.5095 6.88001 11.6949 6.6946C11.8803 6.5092 11.9854 6.25838 11.9877 5.99619C11.99 5.73399 11.8892 5.48139 11.707 5.29279Z"
                                    />
                            </svg>
                        </div>
                    }
                    {isProjectList &&
                        <div
                            className={classNames("A2NavigatorComp-itemHome", {"disabled": (!(wasInProject))})}
                            onClick={this.onClick_back}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12">
                                <path
                                    d="M5.29303 0.293081L0.29303 5.29308C0.105559 5.48061 0.000243867 5.73492 0.000243879 6.00008C0.00024389 6.26525 0.105559 6.51955 0.29303 6.70708L5.29303 11.7071C5.48163 11.8892 5.73423 11.99 5.99643 11.9878C6.25863 11.9855 6.50944 11.8803 6.69485 11.6949C6.88026 11.5095 6.98543 11.2587 6.9877 10.9965C6.98998 10.7343 6.88919 10.4817 6.70703 10.2931L3.41403 7.00008L15 7.00008C15.2652 7.00008 15.5196 6.89472 15.7071 6.70719C15.8947 6.51965 16 6.2653 16 6.00008C16 5.73486 15.8947 5.48051 15.7071 5.29297C15.5196 5.10544 15.2652 5.00008 15 5.00008L3.41403 5.00008L6.70703 1.70708C6.88919 1.51848 6.98998 1.26588 6.9877 1.00368C6.98543 0.741483 6.88026 0.490671 6.69485 0.305262C6.50944 0.119854 6.25863 0.0146854 5.99643 0.012407C5.73423 0.0101287 5.48163 0.110923 5.29303 0.293081Z"
                                />
                            </svg>
                        </div>}
                </div>
                <A2NavigatorItemListComp store={store}/>
                {isProjectList && store.user.can_create_project &&
                    <div className="A2NavigatorComp-menu white_svg blue_svg_onhover"
                         onClick={this.onClickCreateProject}>
                        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6 14H2V2H12V5.5L14 7V1C14 0.734784 13.8946 0.48043 13.7071 0.292893C13.5196 0.105357 13.2652 0 13 0L1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1L0 15C0 15.2652 0.105357 15.5196 0.292893 15.7071C0.48043 15.8946 0.734784 16 1 16H6V14Z"
                                stroke="none"/>
                            <path d="M10 4H4V5H10V4Z" stroke="none"/>
                            <path d="M10 7H4V8H10V7Z" stroke="none"/>
                            <path d="M7 10H4V11H7V10Z" stroke="none"/>
                            <rect x="11" y="8" width="2" height="8" stroke="none" />
                        <rect x="8" y="13" width="2" height="8" transform="rotate(-90 8 13)" stroke="none"/>
                    </svg>
                </div>}
                {!isProjectList && <div className="A2NavigatorComp-menu white_svg blue_svg_onhover" onClick={this.onPopupClick}>
                    <svg width="16" height="12" viewBox="0 0 16 12"
                         className={classNames("",{"blue-fill-important": this.popupShow})}
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 12H15C15.552 12 16 11.552 16 11C16 10.448 15.552 10 15 10H8C7.448 10 7 10.448 7 11C7 11.552 7.448 12 8 12Z" stroke="none" />
                        <path d="M1 2H15C15.552 2 16 1.552 16 1C16 0.448 15.552 0 15 0H1C0.448 0 0 0.448 0 1C0 1.552 0.448 2 1 2Z" stroke="none" />
                        <path d="M1 7H15C15.552 7 16 6.552 16 6C16 5.448 15.552 5 15 5H1C0.448 5 0 5.448 0 6C0 6.552 0.448 7 1 7Z"  stroke="none" />
                    </svg>
                    {this.popupShow && <ContextMenuCommonComp onClose={this.onClosePopup}
                                           className="A2NavigatorComp-menuPopup"
                                           autoCornerHeight={100} autoCornerWidth={100}
                                           direction={PopupDirection.vertical}
                                           popupCorner={PopupCorner.rightTop}
                    >
                        {items}
                    </ContextMenuCommonComp>}
                </div>}

            </div>
            <A2NavigatorSummaryComp store={store}/>
            {/*store.agro2.projectStruct.exportNavigatorConfirm.show &&
            <A2ConfirmExportFormComp confirmStore={store.agro2.projectStruct.exportSelectedFieldsConfirm}

                                     store={store} />*/}

        </div>;
    }
}
