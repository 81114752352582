import {ObservableCustomStore} from "../../../app/store/CustomStore";
import {AgroAhoCardStore, SoilAnalysisType} from "../agroAhoCardStore";
import {AhoUtils} from "../agroAhoUtils";
import {AhoModalType, AhoStage} from "../agroAhoStore";
import {LeftPanelModeAgroAho} from "../agroAhoPlugin";
import {ra} from "../../../app/helper/utils/mobxUtils";


export interface RecNormItem {
    cnt: number;
    rn_id: number;
    fert_id: number;
    crop_id: number;
    centner_ha: number;
    checked?: boolean;
}
export interface DictRecNormColumns {
    [rec_norm_code: string]: RecNormItem; // содержит индикатор с именем cell_id
}
export interface RNGroup {
    group_id: string;
    items: RecNormItem[];
    expand?: boolean;
}
export interface RNInfo {
    max?: number;
    min?: number;
    name: string;
    color?: string;
}
export interface DictRecNormColumnsGroup {
    [group_id: string]: RecNormItem[];
}
export interface RecNormColumns {
    columns: DictRecNormColumns;
}


export class AgroAhoCardRNStore extends ObservableCustomStore {
    constructor(parent: AgroAhoCardStore) {
        super(parent);
        this.cardStore = parent;
    }

    cardStore: AgroAhoCardStore;

    getRNGroupKey(rn: RecNormItem){
        return `${rn.crop_id}_${rn.centner_ha}`
    }

    getRecNorms(){
        let rec_norms: RecNormItem[] = [];
        let tstore = this.cardStore.parentStore.tourStore;
        tstore.selTours.forEach(t=>{
            let cols = t.rec_norm.columns;
            Object.keys(cols).forEach(rn_key=>{
                let rn = cols[rn_key];
                let isDouble = rec_norms.some(r=>r.rn_id == rn.rn_id);
                if (rn_key !== 'cell_id' && !isDouble) {
                    rec_norms.push(rn);
                }
            })
        });
        return rec_norms;
    }

    hasRecNorm(){
        return this.getRecNorms().length > 0;
    }

    updRNGroups(){
        let mapRNStore = this.cardStore.parentStore.mapStore.mapRNStore;
        let rec_norms = this.getRecNorms();
        // console.log('rec_norms', AhoUtils.cp(rec_norms));
        let cur_rn_id = mapRNStore.curRecNormItem?.rn_id;
        let cur_rn_group: string = null;
        let dict_rn_group: DictRecNormColumnsGroup = {};
        rec_norms.forEach(rn=>{
            let group_id = this.getRNGroupKey(rn);
            if (!dict_rn_group[group_id]) dict_rn_group[group_id] = [];
            if (rn.rn_id == cur_rn_id) cur_rn_group = group_id;
            dict_rn_group[group_id].push(rn);
        });
        let rn_groups: RNGroup[] = [];
        Object.keys(dict_rn_group).forEach(key=>{
            let rns = dict_rn_group[key];
            let rn_group: RNGroup = {group_id: key, items: rns, expand: true};
            // if (cur_rn_group == rn_group.group_id) rn_group.expand = true;
            rn_groups.push(rn_group);
        });
        if (rec_norms.length && !cur_rn_group) {
            // rn_groups[0].expand = true;
            mapRNStore.curRecNormItem = rn_groups[0].items[0];
        }

        // console.log('AhoUtils.cp(rn_groups)', AhoUtils.cp(rn_groups));

        if (AhoUtils.cp(mapRNStore.rn_groups) !== AhoUtils.cp(rn_groups)) {
            mapRNStore.rn_groups = rn_groups;
        }
    }

    onDeleteRecNorm(){
        this.cardStore.parentStore.toggleModal(true, AhoModalType.DeleteRecNorm);
    }

    onDeleteRecNormItem(){
        this.cardStore.parentStore.toggleModal(true, AhoModalType.DeleteRecNormItem);
    }

    getRNItem(rn_id: number){
        let mapRNStore = this.cardStore.parentStore.mapStore.mapRNStore;
        let res: RecNormItem = null;
        if (!mapRNStore.rn_groups) return;
        mapRNStore.rn_groups.forEach(gr=>{
            gr.items.forEach(i=>{
                if (i.rn_id == rn_id) res = i;
            });
        });
        return res;
    }

    getRNGroup(rn_id: number): RNGroup {
        let mapRNStore = this.cardStore.parentStore.mapStore.mapRNStore;
        if (!mapRNStore.rn_groups) return;
        let res: RNGroup = null;
        mapRNStore.rn_groups.forEach(gr=>{
            gr.items.forEach(i=>{
                // console.log('rn_id:', gr.group_id, i.rn_id, rn_id, i.rn_id == rn_id);
                if (i.rn_id == rn_id) res = gr;
            });
        });
        return res;
    }

    // async updRecNorm(){
    //     let ustore = this.parentStore.uploadTableStore;
    //     await ustore.loadDicts();
    //     this.updRNGroups();
    // }

    // getRNInfoName(min: number, max: number, vals: number[]){
    //     if (min == max) {
    //         let arr = vals.filter(v=>v == min);
    //         if (arr.length) return arr[0]+''
    //         else return;
    //     }
    //     let arr = vals.filter(v=>v > min && v <= max);
    //     let len = arr.length;
    //     if (!len) return;
    //     if (len === 1) return arr[0]+'';
    //     return `${arr[0]} - ${arr[len-1]}`;
    // }

    getRNInfoName(min: number, max: number){
        if (min === max) return min+'';
        return `${min} - ${max}`;
    }

    getRNInfo(){
        let pstore = this.cardStore.parentStore;
        let mapRNStore = pstore.mapStore.mapRNStore;
        let tstore = pstore.tourStore;
        let colors: any = {
            // 5: ['#ff0000', '#ff9900', '#FFFF00', '#91D050', '#00B050'],
            // 4: ['#ff0000', '#FFFF00', '#91D050', '#00B050'],
            // 3: ['#ff0000', '#FFFF00', '#00B050'],
            // 2: ['#ff0000', '#00B050'],
            5: ['#00B050', '#91D050', '#FFFF00', '#FF9900', '#FF0000'],
            4: ['#00B050', '#91D050', '#FFFF00', '#FF0000'],
            3: ['#00B050', '#FFFF00', '#FF0000'],
            2: ['#00B050', '#FF0000'],
            1: '#00B050',
            0: '#C0C0C0'
        };
        let vals: number[] = [];
        tstore.torusToDisplay.forEach(t=>{
            t.rec_norm_vals.forEach(r=>{
                Object.keys(r).forEach(key=>{
                    if (key == 'rn_' + mapRNStore.curRecNormItem.rn_id
                        && r[key] !== null && r[key] >= 0) vals.push(r[key]);
                });
            })
        });
        vals = vals.sort((a,b)=> a - b); //.reverse();
        // console.log('vals:', vals, AhoUtils.cp(mapRNStore.curRecNormItem));
        let res: RNInfo[] = [];
        // console.log('tours:', AhoUtils.cp(this.torusToDisplay));
        let len = vals.length;
        if (len > 0 && len <= 5) {
            for (let i=0;i<len;i++)
                if (vals[i] === 0)
                    res.push({min: 0, max: 0, name: '0', color: colors[0]})
                else
                    res.push({min: vals[i], max: vals[i], name: vals[i]+'',
                        color: len === 1 ? colors[1] : colors[len][i]});
            // console.log('len 1-5', JSON.stringify(res, null, 2));
        }
        if (len > 5) {
            let group_size = Math.ceil(len / 5);
            // console.log('gs', group_size);
            let step = 0;
            while (vals.length>0) {
                // console.log('before splice', vals, group_size);
                if (vals.length + step <= 5) group_size = 1;
                let group_els = vals.splice(0, group_size);
                // console.log('after spice', group_els, vals);
                let min = group_els[0];
                let max = group_els[group_els.length-1];
                let name = this.getRNInfoName(min, max);

                // console.log('iter', min, max, name);
                res.push({ min: min, max: max, name: name});
                step += 1;
            }

            // let max_val = vals[len-1];
            // let min_val = vals[0] - 0.01;
            // let step = +((max_val - min_val) / 5).toFixed(2);
            // // console.log('max_val', min_val, max_val, step);
            // let name = '';
            // for (let i=0;i<5;i++) {
            //     let min = +(min_val + (step * i)).toFixed(2);
            //     let max = +(min_val + (step * (i + 1))).toFixed(2);
            //     if (i == 4) max = max_val;
            //     name = this.getRNInfoName(min, max, vals);
            //     // console.log('info', i, min, max, name);
            //     if (name) res.push({ min: min, max: max, name: name});
            // }
            len = res.length;
            for (let i=0;i<len;i++)
                res[i].color = len === 1 ? colors[1] : colors[len][i];
            // console.log('len>5', JSON.stringify(res, null, 2));
        }
        res = res.reverse();
        // console.log('rn_info', JSON.stringify(res, null, 2));
        return res;
    }

    changeConfig(){
        let pstore = this.cardStore.parentStore;
        let cstore = pstore.cardStore;
        if (cstore.root.searchPanel.leftPanelMode == LeftPanelModeAgroAho && pstore.ahoStage == AhoStage.Card
            && cstore.soilAnalysisType == SoilAnalysisType.RecNorms && cstore.soilAnalysisShow)
            this.setRecNormTab();
    }

    async setRecNormTab(rn_id?: number){
        let pstore = this.cardStore.parentStore;
        let mapRNStore = pstore.mapStore.mapRNStore;
        let ustore = pstore.uploadTableStore;
        if (!this.hasRecNorm()) return;
        // await this.updRecNorm();
        await ustore.loadDicts();
        this.updRNGroups();
        // let gr = this.getRNGroup(rn_id);
        // console.log('gr:', AhoUtils.cp(gr), rn_id);
        // console.log('set rn tab');
        // if (gr) gr.expand = true
        //     else mapRNStore.rn_groups[0].expand = true;
        mapRNStore.curRecNormItem = rn_id ? this.getRNItem(rn_id) : mapRNStore.rn_groups[0].items[0];
        this.cardStore.soilAnalysisType = SoilAnalysisType.RecNorms;
        let el = document.querySelector('.AgroAhoRecNormItemComp-checked');
        if (el) el.scrollIntoView({ block: "center"});
    }

    async doTabRecNormClick(){
        // await this.updRecNorm();
        await this.setRecNormTab();
    }

    doRNGroupExpandClick(rng: RNGroup){
        rng.expand = !rng.expand;
    }

    doRNFertClick(rn: RecNormItem){
        let mapRNStore = this.cardStore.parentStore.mapStore.mapRNStore;
        mapRNStore.curRecNormItem = rn;
    }

}