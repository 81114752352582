import {ObservableCustomStore} from "../../../../app/store/CustomStore";
import {AgroAhoMapStore, IAhoLayers} from "../agroAhoMapStore";
import {IndicatorKey} from "../../agroAhoCardStore";
import {CirclePaint} from "maplibre-gl";
import {Feature, FeatureCollection} from "geojson";
import {AhoEditorMode} from "../../agroAhoEditorStore";


export class AgroAhoTrackFieldPointLayer extends ObservableCustomStore {
    constructor(parent: AgroAhoMapStore) {
        super(parent);
        this.mapStore = parent;
    }

    mapStore: AgroAhoMapStore;

    getTFPSourceId(ds: string): string{
        return  ds + '_tfp_src_aho';
    }
    // getTFPLayerId(ds: string): string{
    getTFPLayerId(): string{
        return `class_agroAho_${IAhoLayers.TrackFieldPoint}_aho`;
        // return  ds + '_tfp_lyr_aho';
    }

    async getTrackFieldPointData(ds: string, upd?: boolean){
        let data: any = await this.mapStore.getCellCardGeoJSONs({ds: ds, track_type: 'field',
            type: IAhoLayers.TrackFieldPoint, upd: !!upd});
        // let feats_: FeatureCollection = {'type': 'FeatureCollection', 'features': [
        //         {"type": "Feature", "properties": {}, "geometry": {"type": "MultiPoint", "coordinates": data_}}]};
        let feats: Feature[] = [];
        if (data?.features) {
            data.features.forEach((f: any, idx: number)=>{
                let fld_id = f.properties.field_id;
                let coords = f.geometry.coordinates;
                feats.push({"type": "Feature", id: idx, "properties": {id: idx, field_id: fld_id}, "geometry": {"type": "MultiPoint",
                    "coordinates": [coords[0], coords[coords.length - 1] ]}});

                // let coords = f?.geometry?.coordinates;
                // if (coords) {
                //     points.push(coords[0]);
                //     points.push(coords[coords.length - 1]);
                // }
            });
        } else return;
        return {'type': 'FeatureCollection', 'features': feats} as FeatureCollection;
    }

    async addTFPLayer(ds: string){
        if (!ds) return;
        let data: FeatureCollection = await this.getTrackFieldPointData(ds, true);
        if (!data) return;

        let src_id = this.getTFPSourceId(ds);

        let map = this.root.map.mapbox;
        this.delTFPLayer();

        if (!map.getSource(src_id)){
            map.addSource(src_id,{
                type: 'geojson',
                data: data
            });
        }

        let lyr_id = this.getTFPLayerId();
        if (!map.getLayer(lyr_id)){
            map.addLayer({
                id: lyr_id,
                type: 'circle',
                source: src_id,
                minzoom: 12,
                layout: {},
                paint: <CirclePaint>{
                    'circle-color': '#FFF',
                    'circle-radius': ['interpolate', ['linear'],
                        ['zoom'],
                        12, 3,
                        20, 7
                    ],
                    'circle-opacity': [
                        'case',
                        ['==',['feature-state', 'opac'], 1],
                        0.2, 1
                    ],
                    'circle-stroke-width': 3,
                    'circle-stroke-color': '#15db64',
                    'circle-stroke-opacity': [
                        'case',
                        ['==',['feature-state', 'opac'], 1],
                        0.2, 1
                    ],
                }
            }, await this.mapStore.getPrevLayer(IAhoLayers.TrackFieldPoint));
        }
    }

    async setTFPHidden(hidden: boolean) {
        // let cstore = this.mapStore.parentStore.cardStore;
        let map = this.root.map.mapbox;
        // let ds = cstore.card.track_info.ds_code;
        let lyr_id = this.getTFPLayerId();
        if (!map.getLayer(lyr_id)) return;
        let vis = hidden ? 'none' : 'visible';
        map.setLayoutProperty(lyr_id, 'visibility', vis);
        // console.log('set tfp vis', lyr_id, vis);
        // map.setPaintProperty(lyr_id, 'circle-opacity', opacity ? 0.2 : 1);
        // map.setPaintProperty(lyr_id, 'circle-stroke-opacity', opacity ? 0.2 : 1);
    }
    async setTFPOpacity(opacity: boolean) {
        let map = this.root.map.mapbox;
        let lyr_id = this.getTFPLayerId();
        if (!map.getLayer(lyr_id)) return;
        map.setPaintProperty(lyr_id, 'circle-opacity', opacity ? 0.2 : 1);
        map.setPaintProperty(lyr_id, 'circle-stroke-opacity', opacity ? 0.2 : 1);
    }

    delTFPLayer(){
        let map = this.root.map.mapbox;
        let layer_id = this.getTFPLayerId();
        if (map.getLayer(layer_id)){
            map.removeLayer(layer_id);
        }
    }

    // f.field_id, checked && !f.checked, checked && f.checked
    updOpacity(field_id: number, checked: boolean, selected: boolean){
        let cstore = this.mapStore.parentStore.cardStore;
        let map = this.root.map.mapbox;
        let ds = cstore.card.track_info.ds_code;
        let src_id = this.getTFPSourceId(ds);
        let data = map.querySourceFeatures(src_id);
        // console.log('data:', data);
        data.forEach((ft: any)=>{
            if (ft.properties.field_id == field_id) {
                // console.log('setFOp',src_id, ft, ft.id, checked, selected);
                this.mapStore.setFeatureIndcOpacity(src_id, ft.id, checked, selected);
            }
        });
    }

    async updTFPLayers(){
        let cstore = this.mapStore.parentStore.cardStore;
        let map = this.root.map.mapbox;
        let ds = cstore.card.track_info.ds_code;
        let src_id = this.getTFPSourceId(ds);
        let source: any = map.getSource(src_id);
        let data: FeatureCollection = await this.getTrackFieldPointData(ds, true);
        // console.log('src', data);
        source.setData(data);
        // source.setTiles(source.tiles);
    }


    async updOpacity_(data: FeatureCollection, ds: string){
        let cstore = this.mapStore.parentStore.cardStore;
        if (!cstore.fieldsItems) return;
        let checked = !cstore.fieldsItems.every(i=>!i.checked);
        for (let i=0; i<cstore.fieldsItems.length; i++){
            let fld = cstore.fieldsItems[i];
            data.features.forEach((ft: any)=>{
                if (ft.properties.field_id == fld.field_id) {
                    console.log('setFOp',ds, ft, ft.properties.id, checked && !fld.checked,
                        checked && fld.checked);
                    this.mapStore.setFeatureIndcOpacity(ds, ft.properties.id, checked && !fld.checked,
                        checked && fld.checked);
                }
            });
        }
    }
}