import {CustomStoreTool} from "../../../../app/store/tools/general/CustomStoreTool";
import {CirclePaint, SymbolLayout, SymbolPaint} from "maplibre-gl";
import {IReactionDisposer} from "mobx/lib/internal";
import {autorun} from "mobx";
import {IAgroExportSuperStore} from "../../agroExportPlugin";


export class SampleSelectionTool extends CustomStoreTool{
    static readonly LINES_NAME = "class_SampleSelection_LINES";
    static readonly CELLS_NAME = "class_SampleSelection_CELLS";
    static readonly CELLSTracks_NAME = "class_SampleSelection_CELLSTracks";
    static readonly CELLS_POINT_NAME = "class_SampleSelection_CELLS_POINT";
    static readonly CELLS_LABEL_NAME = "class_SampleSelection_CELLS_LABEL";

    get agroStore(): IAgroExportSuperStore{
        return this.store.getRootAs<IAgroExportSuperStore>();
    }
    onSubscription(): IReactionDisposer[] {
        return [
            autorun(() => {
                if (this.agroStore.agroExport.voronovExportStore.showReadyLayers()) {
                    this.showLayers();
                } else {
                    this.hideLayers();
                }
            })
        ];
    }

    get geoJsonLines(): GeoJSON.FeatureCollection<GeoJSON.Geometry>{
        return this.agroStore.agroExport.voronovExportStore.geoJsonLines.data;
    }
    get geoJsonCells(): GeoJSON.FeatureCollection<GeoJSON.Geometry>{
        return this.agroStore.agroExport.voronovExportStore.geoJsonCells.data;
    }
    get geoJsonCellsTracks(): GeoJSON.FeatureCollection<GeoJSON.Geometry>{
        return this.agroStore.agroExport.voronovExportStore.geoJsonCellTracks.data;
    }
    get geoJsonCellsPoints(): GeoJSON.FeatureCollection<GeoJSON.Geometry>{
        return this.agroStore.agroExport.voronovExportStore.geoJsonCellPoints.data;
    }

    showLayers(){
        this.hideLayers();
        if (this.map.getSource(SampleSelectionTool.CELLS_NAME) == null) {
            this.map.addSource(SampleSelectionTool.CELLS_NAME, {
                'type': 'geojson',
                'data': this.geoJsonCells
            });
        }
        if (this.map.getSource(SampleSelectionTool.LINES_NAME) == null) {
            this.map.addSource(SampleSelectionTool.LINES_NAME, {
                'type': 'geojson',
                'data': this.geoJsonLines
            });
        }
        if (this.map.getSource(SampleSelectionTool.CELLSTracks_NAME) == null) {
            this.map.addSource(SampleSelectionTool.CELLSTracks_NAME, {
                'type': 'geojson',
                'data': this.geoJsonCellsTracks
            });
        }
        if (this.map.getSource(SampleSelectionTool.CELLS_POINT_NAME) == null) {
            this.map.addSource(SampleSelectionTool.CELLS_POINT_NAME, {
                'type': 'geojson',
                'data': this.geoJsonCellsPoints
            });
        }

        if (this.map.getLayer(SampleSelectionTool.CELLS_NAME) == null) {
            this.addLayer({
                'id': SampleSelectionTool.CELLS_NAME,
                'type': 'line',
                'source': SampleSelectionTool.CELLS_NAME,
                'paint': {
                    'line-color': '#000000',
                    'line-opacity': 1.0,
                    'line-width': 2
                },
            });
        }
        if (this.map.getLayer(SampleSelectionTool.LINES_NAME) == null) {
            this.addLayer({
                'id': SampleSelectionTool.LINES_NAME,
                'type': 'line',
                'source': SampleSelectionTool.LINES_NAME,
                'paint': {
                    'line-color': '#be5c00',
                    'line-opacity': 1.0,
                    'line-width': 2,
                    'line-dasharray': [3, 1],
                },
            });
        }
        if (this.map.getLayer(SampleSelectionTool.CELLSTracks_NAME) == null) {
            this.addLayer({
                'id': SampleSelectionTool.CELLSTracks_NAME,
                'type': 'line',
                'source': SampleSelectionTool.CELLSTracks_NAME,
                'paint': {
                    'line-color': '#15db64',
                    'line-opacity': 1.0,
                    'line-width': 4
                },
            });
        }
        if (this.map.getLayer(SampleSelectionTool.CELLS_LABEL_NAME) == null) {
            this.addLayer({
                id: SampleSelectionTool.CELLS_LABEL_NAME,
                source: SampleSelectionTool.CELLS_NAME,
                type: 'symbol',
                minzoom: 14,
                layout: <SymbolLayout>{
                    'text-field': ['get', 'cell_id'],
                    'text-variable-anchor': ['center'],
                    'text-size': 14,
                    //'text-radial-offset': 0.5,
                    //'text-font': ["Open Sans Regular", "Arial Unicode MS Regular"],
                    'text-font': [
                        'Open Sans Semibold'
                    ],
                    visibility: "visible",
                },
                paint: <SymbolPaint>{
                    'text-halo-color': '#C5C5C5',
                    'text-color': "#050607",
                    'text-halo-width': 1,
                    //'text-halo-blur':2,
                }
            });
        }
        if (this.map.getLayer(SampleSelectionTool.CELLS_POINT_NAME) == null) {
            this.addLayer({
                'id': SampleSelectionTool.CELLS_POINT_NAME,
                'type': 'circle',
                'source': SampleSelectionTool.CELLS_POINT_NAME,
                'minzoom': 12,
                'paint':  <CirclePaint>{
                    'circle-color': '#FFF',
                    'circle-radius': [
                        'interpolate', ['linear'], ['zoom'],
                        12, 3,
                        20, 7
                    ],
                    'circle-stroke-width': 3,
                    'circle-stroke-color': '#15db64'
                },
            });
        }


    }
    hideLayers(){
        if (!this.map) return;
        if (this.map.getLayer(SampleSelectionTool.CELLS_LABEL_NAME) != null)
            this.removeLayer(SampleSelectionTool.CELLS_LABEL_NAME);
        if (this.map.getLayer(SampleSelectionTool.LINES_NAME) != null)
            this.removeLayer(SampleSelectionTool.LINES_NAME);
        if (this.map.getLayer(SampleSelectionTool.CELLS_NAME) != null)
            this.removeLayer(SampleSelectionTool.CELLS_NAME);
        if (this.map.getLayer(SampleSelectionTool.CELLSTracks_NAME) != null)
            this.removeLayer(SampleSelectionTool.CELLSTracks_NAME);
        if (this.map.getLayer(SampleSelectionTool.CELLS_POINT_NAME) != null)
            this.removeLayer(SampleSelectionTool.CELLS_POINT_NAME);

        if (this.map.getSource(SampleSelectionTool.CELLSTracks_NAME) != null)
            this.map.removeSource(SampleSelectionTool.CELLSTracks_NAME);
        if (this.map.getSource(SampleSelectionTool.LINES_NAME) != null)
            this.map.removeSource(SampleSelectionTool.LINES_NAME);
        if (this.map.getSource(SampleSelectionTool.CELLS_NAME) != null)
            this.map.removeSource(SampleSelectionTool.CELLS_NAME);
        if (this.map.getSource(SampleSelectionTool.CELLS_POINT_NAME) != null)
            this.map.removeSource(SampleSelectionTool.CELLS_POINT_NAME);
    }
}