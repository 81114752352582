import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoVisPanelComp.scss'
import {action} from "mobx";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AgroAhoRoundCheckboxSvg} from "../AgroAhoRoundCheckboxSvg";
import {AhoIndicatorFormat} from "../../agroAhoCardStore";

@observer
export class AgroAhoVisPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onAgroSoftClick(){
        let ps = this.props.store.agroAhoStore.projStore;
        ps.visFormat = AhoIndicatorFormat.AgroSoft;
    }

    @action
    onAgroplemClick(){
        let ps = this.props.store.agroAhoStore.projStore;
        ps.visFormat = AhoIndicatorFormat.Agroplem;
    }

    @action
    onClickSave(){
        let store = this.props.store.agroAhoStore;
        store.projStore.setVisFormat();
        store.toggleModal(false);
    }

    onClickCancel(){
        let store = this.props.store.agroAhoStore;
        store.toggleModal(false);
    }


    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let ps = store_.projStore;

        return <div className="AgroAhoVisPanelComp-main">
            <div className="AgroAhoVisPanelComp-caption">{store_.trans['Visualization setup']}</div>
            <div className="AgroAhoVisPanelComp-msg">{store_.trans['Default scale and palette']}</div>

            <div className="AgroAhoVisPanelComp-checkboxCover" onClick={this.onAgroSoftClick}>
                <AgroAhoRoundCheckboxSvg checked={ps.visFormat === AhoIndicatorFormat.AgroSoft}/>
                <span className="AgroAhoVisPanelComp-formatCaption">{store_.trans["Agro-Soft"]}</span>
            </div>
            <div className="AgroAhoVisPanelComp-checkboxCover" onClick={this.onAgroplemClick}>
                <AgroAhoRoundCheckboxSvg checked={ps.visFormat === AhoIndicatorFormat.Agroplem}/>
                <span className="AgroAhoVisPanelComp-formatCaption">{store_.trans["AGROPLEM"]}</span>
            </div>

            <div className="AgroAhoVisPanelComp-line"> </div>
            <div className="AgroAhoVisPanelComp-buttonCover">
                <button className="large-button left-sidebar-cancel button-jump AgroAhoVisPanelComp-cancel"
                    onClick={this.onClickCancel}>{store_.trans2.Cancel}</button>
                <button className="large-button button-jump left-sidebar-active"
                    onClick={this.onClickSave}>{store.trans.Save}</button>
            </div>
        </div>;
    }
}
