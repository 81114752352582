// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgroAhoUploadPanelComp-main {
  background: #2B3138;
  border: 1px solid #3E4751;
  border-radius: 10px;
  width: 385px;
  display: flex;
  flex-direction: column;
}

.AgroAhoUploadPanelComp-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #C5C5C5;
  padding: 16px 16px 23px 16px;
}

.AgroAhoUploadPanelComp-msg {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #6D7785;
  padding: 0 16px 14px 16px;
}

.AgroAhoUploadPanelComp-red {
  color: #E94F4F;
}

.AgroAhoUploadPanelComp-buttons {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #22282F;
  margin-top: 20px;
}

.AgroAhoUploadPanelComp-main .AgroAhoUploadPanelComp-cancelButton {
  background: none;
  border: 1px solid #3E4751;
  margin-right: 10px;
  opacity: 0.8;
}

.AgroAhoUploadPanelComp-main .AgroAhoUploadPanelComp-cancelButton:hover {
  background: #383F48;
  opacity: 1;
}

.AgroAhoUploadPanelComp-cancelButton {
  width: 107px;
}

.AgroAhoUploadPanelComp-disabled {
  opacity: 0.3;
  cursor: default;
}`, "",{"version":3,"sources":["webpack://./plugins/agroAho/AgroAhoPanel/card/AgroAhoUploadPanelComp.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,yBAAA;EAEA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;AAAF;;AAGA;EACE,wBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,4BAAA;AAAF;;AAEA;EACE,wBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;EACA,yBAAA;AACF;;AACA;EACE,cAAA;AAEF;;AAAA;EACE,aAAA;EACA,aAAA;EACA,yBAAA;EACA,6BAAA;EACA,gBAAA;AAGF;;AADA;EACE,gBAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;AAIF;;AAFA;EACE,mBAAA;EACA,UAAA;AAKF;;AAHA;EACE,YAAA;AAMF;;AAJA;EACE,YAAA;EACA,eAAA;AAOF","sourcesContent":[".AgroAhoUploadPanelComp-main {\r\n  background: #2B3138;\r\n  border: 1px solid #3E4751;\r\n  //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\r\n  border-radius: 10px;\r\n  width: 385px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  //margin: 8% auto 0 auto;\r\n}\r\n.AgroAhoUploadPanelComp-title {\r\n  font-family: 'Open Sans';\r\n  font-style: normal;\r\n  font-weight: 600;\r\n  font-size: 14px;\r\n  line-height: 19px;\r\n  color: #C5C5C5;\r\n  padding: 16px 16px 23px 16px;\r\n}\r\n.AgroAhoUploadPanelComp-msg {\r\n  font-family: 'Open Sans';\r\n  font-style: normal;\r\n  font-weight: 400;\r\n  font-size: 12px;\r\n  color: #6D7785;\r\n  padding: 0 16px 14px 16px;\r\n}\r\n.AgroAhoUploadPanelComp-red {\r\n  color: #E94F4F;\r\n}\r\n.AgroAhoUploadPanelComp-buttons {\r\n  padding: 16px;\r\n  display: flex;\r\n  justify-content: flex-end;\r\n  border-top: 1px solid #22282F;\r\n  margin-top: 20px;\r\n}\r\n.AgroAhoUploadPanelComp-main .AgroAhoUploadPanelComp-cancelButton {\r\n  background: none;\r\n  border: 1px solid #3E4751;\r\n  margin-right: 10px;\r\n  opacity: .8;\r\n}\r\n.AgroAhoUploadPanelComp-main .AgroAhoUploadPanelComp-cancelButton:hover {\r\n  background: #383F48;\r\n  opacity: 1;\r\n}\r\n.AgroAhoUploadPanelComp-cancelButton {\r\n  width: 107px;\r\n}\r\n.AgroAhoUploadPanelComp-disabled {\r\n  opacity: 0.3;\r\n  cursor: default;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
