import {observer} from "mobx-react";
import * as React from "react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";
import {MaxSvg} from "../../../icons/Legend/MaxSvg";
import {action} from "mobx";

@observer
export class LegendMinComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }
    @action
    toMax() {
        this.props.store.map.legend.legendMinimize = false;
    }
    render() {
        let store = this.props.store;
        return <div className="legend-panel legend-panel_title">
            <div className="flex-columns pointer" onClick={this.toMax}><span>{store.trans.Legend}</span>
                <div className="full-center-content legend-panel_toMax"><MaxSvg/></div>
            </div>
        </div>;
    }
}