import {Utils} from "./Utils";
import {cloneDeep, isPlainObject} from "lodash-es";

function fetchBytes(input: RequestInfo, init?: RequestInit): Promise<ArrayBuffer>{
    let hasError = false;
    return fetch(input, init)
        .then(res => {
            if (!res.ok){
                throw "Unknown server error. "+res.statusText??"";
            }
            return res.arrayBuffer();
        });
}
export interface IBlobAndFilename{
    blob: Blob,
    filename: string
}
async function fetchBlob(input: RequestInfo, init?: RequestInit): Promise<IBlobAndFilename> {
    const response = await fetch(input, init);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const contentDisposition = response.headers.get('content-disposition');
    const filenameMatch = contentDisposition?.match(/filename="?(.+)"?/);
    const filename = filenameMatch ? filenameMatch[1] : 'download';

    let blob = await response.blob();
    return {blob: blob, filename: filename};
}

export function fetchBytesPost(url: string, params: any = null): Promise<ArrayBuffer>{
    return fetchBytes(url, {method: "POST",  headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}, body: Utils.queryEncodeParams(params)});
}

export function fetchBlobPost(url: string, params: any = null): Promise<IBlobAndFilename>{
    return fetchBlob(url, {method: "POST",  headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}, body: Utils.queryEncodeParams(params)});
}

export function fetchBytesGet(url: string, params: any = null): Promise<ArrayBuffer>{
    let url2: string = url;
    if (params != null && isPlainObject(params)) {
        if (url2.indexOf('?') < 0) {
            url2 = url2 + "?";
        }
        let lastChar = url2[url2.length - 1];
        if (lastChar != '?' && lastChar != '&') url2 = url2 + '&';
        url2 = url2 + Utils.queryEncodeParams(params);
    }

    return fetchBytes(url2);
}
export function fetchJson(input: RequestInfo, init?: RequestInit): Promise<any>{
    let init2 = cloneDeep(init);
    if (init2 == null) init2 = {};
    init2.credentials = "include";
    let hasError = false;
    return fetch(input, init2)
        .then((res) => {
            if (!res.ok){
                hasError = true;
                if (res.status == 404) throw "Method not found";
                if (Math.floor(res.status/100) != 4) throw `Unknown server error. ${res.statusText} Status code: ${res.status}`;
            }
            return res.json();
        }).then(json => {
            if (hasError){
                if (json.message) throw "Server error: "+json.message;
                if (json.detail) throw "Server error: "+json.detail;
                throw "Server error: "+JSON.stringify(json);
            } 
            return new Promise<any>((resolve, reject) =>{
                resolve(json);
            });
        });
}
export function fetchJsonPostWithJsonParams(url: string, params: any = null): Promise<any>{
    return fetchJson(url, {method: "POST",  headers: {'Content-Type': 'application/json;charset=UTF-8'}, body: JSON.stringify(params)});
}
export function fetchJsonPost(url: string, params: any = null): Promise<any>{
    return fetchJson(url, {method: "POST",  headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}, body: Utils.queryEncodeParams(params)});
}
export function fetchJsonPut(url: string, params: any = null): Promise<any>{
    return fetchJson(url, {method: "PUT",  headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}, body: Utils.queryEncodeParams(params)});
}
export function fetchJsonGet<T = any>(url: string, params: any = null): Promise<T>{
    let url2: string = url;
    if (params != null && isPlainObject(params)) {
        if (url2.indexOf('?') < 0) {
            url2 = url2 + "?";
        }
        let lastChar = url2[url2.length - 1];
        if (lastChar != '?' && lastChar != '&') url2 = url2 + '&';
        url2 = url2 + Utils.queryEncodeParams(params);
    }
    return fetchJson(url2);
}