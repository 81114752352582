import {ObservableCustomStore} from "../../../app/store/CustomStore";
import {AgroAhoCardStore} from "../agroAhoCardStore";
import {FeatureCollection} from "geojson";
import {observable} from "mobx";
import {AhoUtils} from "../agroAhoUtils";
import {fetchJsonGet} from "../../../app/helper/utils/FetchUtils";


export interface ObjFeatItem {
    field_id: number;
    field_name: string;
    season_id: number;
    season_name: string;
    crop_id: number;
    crop_name: string;
}
export interface FeatSeason {
    id: number;
    name: string;
}
export enum FieldSortType {
    ByName = 'ByName',
    ByNameDesc = 'ByNameDesc',
    ByCrop = 'ByCrop',
    ByCropDesc = 'ByCropDesc'
}
export interface Season {
    season_id: number;
    season_name: string;
    sowing_year: number;
    harvest_year: number;
}
export interface SeasonObj {
    values: Season;
    has_obj: number;
}
export interface FieldHistory {
    map_field_id: number;
    map_field_name: string;
    field_id: number;
    field_name: string;
    season_id: number;
    season_name: string;
    crop_id: number;
    crop_name: string;
    crop_name_short: string;
}
interface SeasonsHistory {
    [season_id: number]: FieldHistory[];
}


export class AgroAhoCardFieldStore extends ObservableCustomStore {
    constructor(parent: AgroAhoCardStore) {
        super(parent);
        this.cardStore = parent;
    }

    cardStore: AgroAhoCardStore;
    objFeats: ObjFeatItem[];
    @observable
    curSeasonKey: number;
    @observable
    sortType: FieldSortType = FieldSortType.ByName;
    seasonObjs: SeasonObj[] = [];
    seasonsHistory: SeasonsHistory = {};

    async updObjFeats_(){
        let fis = this.cardStore.fieldsItems;
        if (!fis) return;
        let ids = fis.map(f=>f.field_id).join(',');
        let p = [this.root.agro.projectName, ids];
        let url = `/api/projects/${p[0]}/object/select?filter={"field_id":{"$in":[${p[1]}]}}&geom=0`;
        let flds: FeatureCollection = await this.cardStore.parentStore.cachedFetchJsonGet(url).catch(()=>{});
        if (!flds?.features) return;
        this.objFeats = flds.features.map(f=>f.properties) as ObjFeatItem[];
        this.curSeasonKey = -1;
    }

    async updSeasonsHistory(){
        this.curSeasonKey = -1;
        this.seasonsHistory = {};
        let fis = this.cardStore.fieldsItems;
        if (!fis) return;
        let fids = fis.map(f=>f.field_id);
        let p = [this.root.agro.projectName, this.cardStore.card.map_id];
        let url = `/api/projects/${p[0]}/reference/season/list`;
        this.seasonObjs = await this.cardStore.parentStore.cachedFetchJsonGet(url).catch(()=>{});
        if (!this.seasonObjs?.length) return;
        let seasonsPromises:any[] = [];
        this.seasonObjs.forEach(s=>{
            let sid = s.values?.season_id;
            if (sid) {
                let url = `/api/projects/${p[0]}/asa2/map/${p[1]}/field/history?season_id=${sid}`;
                seasonsPromises.push(this.cardStore.parentStore.cachedFetchJsonGet(url).catch(()=>{}));
            }
        });
        let seasonsHistoryArray: any[] = [];
        await Promise.all(seasonsPromises).then((values) => {
            seasonsHistoryArray = values;
        }).catch(()=>{});
        if (!seasonsHistoryArray?.length) return;
        seasonsHistoryArray.forEach(sh=>{
            if (sh?.length) {
                let isFieldExists = fids.indexOf(sh[0].map_field_id) >= 0;
                if (isFieldExists) this.seasonsHistory[sh[0].season_id] = sh;
            }
        });
    }

    updCropNames(season_id: number){
        this.cardStore.noUpdFieldOpacity = true;
        this.cardStore.fieldsItems.forEach(f=>{
            f.crop_name = this.getCropName(f.field_id, season_id) as string;
        });
        this.cardStore.noUpdFieldOpacity = false;
    }

    getCropName(field_id: number, season_id: number){
        let fields = this.seasonsHistory[season_id];
        // console.log('fields', season_id, field_id, fields, this.seasonsHistory);
        return fields?.filter(f=>f.map_field_id == field_id)?.[0]?.crop_name_short || '';

        // return this.objFeats.filter(f=>f.field_id == field_id &&
        //     f.season_id == season_id)?.[0]?.crop_name || '';
    }

    getSeasons(){
        let s: any = {};
        // if (!this.seasonObjs?.length) return;
        // this.objFeats.forEach(f=>{
        //     s[f.season_id] = f.season_name;
        // });
        // this.seasonObjs.forEach(so=>{
        //     s[so.values.season_id] = so.values.season_name;
        // });

        if (!Object.keys(this.seasonsHistory || []).length) return [];
        Object.keys(this.seasonsHistory).forEach((key: any)=>{
            let fieldsHistory = this.seasonsHistory[key];
            if (fieldsHistory?.[0]?.season_id) {
                s[fieldsHistory?.[0]?.season_id] = fieldsHistory?.[0]?.season_name;
            }
        });
        // console.log('shis', this.seasonsHistory);

        return Object.keys(s).map(key=>{ return {id: key, name: s[key]}});
    }

    isCropExists(){
        return !this.cardStore.fieldsItems.every(f=>!f.crop_name);
    }

}