import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import '/app/components/Common/Forms/Forms.scss';
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {A2ExportFormat} from "../../../../../store/agro/A2AgroStore";
import {action} from "mobx";
import {A2ConfirmExportMode, A2ConfirmExportStore} from "../../../../../store/agro/Confirms/A2ConfirmExportStore";
import {A2ExportFormatComp} from "./A2ExportFormatComp";
import {ra} from "../../../../../helper/utils/mobxUtils";

export interface IA2ConfirmExportFormComp extends IStoreProps{
    confirmStore: A2ConfirmExportStore,
    onSubmit: ()=>any,
}

@observer
export class A2ConfirmExportFormComp extends React.Component<IA2ConfirmExportFormComp, undefined> {
    constructor(props: IA2ConfirmExportFormComp) {
        super(props);
        autoBindReact(this);
        ra(()=>{
            this.props.confirmStore.filename = "fields";
        });
    }

    @action
    onClickCancel(){
        this.props.confirmStore.doCancel();
    }
    @action
    onClickSubmit(format: A2ExportFormat){
        this.props.confirmStore.format = format;
        this.props.onSubmit();
    }
    @action
    onChange_filename(e: any){
        let txt = e.currentTarget.value;
        this.props.confirmStore.filename = txt;
    }

    render() {
        let store = this.props.store;
        let confirmStore = this.props.confirmStore;
        if (!confirmStore.show) return null;
        let extChilds: any[] = [];


        extChilds = store.events.onAgroExportChildren.call(extChilds);

        return <A2ExportFormatComp store={store}
                                   formats={[A2ExportFormat.geojson, A2ExportFormat.shp, A2ExportFormat.csv]}
                                   extChilds={extChilds}
                                   onSubmit={this.onClickSubmit} onCancel={this.onClickCancel}>

            {confirmStore.mode_export == A2ConfirmExportMode.folder &&
                <div className="gray-text">{store.trans.Nesting}: {confirmStore.path}</div>}
            <div className="gray-text">{store.trans.ObjectsFields}: {confirmStore.fields}</div>
            <div className="gray-text">{store.trans.Area}: {confirmStore.area}</div>
            <div className="Forms_filename">
                <div className="Forms_filenameText">{store.trans["File name"]}</div>
                <input type="text" className="text-box-editor width100" onChange={this.onChange_filename}
                       value={confirmStore.filename}/>
            </div>
            <div className="Forms-header2Text Forms-topIdent2">{store.trans.Format}</div>
        </A2ExportFormatComp>;
    }
}
