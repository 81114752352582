import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import './IndexByPointPopupGroupsComp.scss';
import {ContextMenuCommonComp, PopupCorner, PopupDirection} from "../../../../Common/ContextMenuCommonComp";
import {
    getDefaultIndexType, getIgnoreCompareIndexDataType,
    getIndexDataGroups,
    groupHasClimate,
    IndexDataGroup,
    IndexDataType
} from "../../../../../store/indeByPoint/IndexByPointerStore";
import {TranslateUtils} from "../../../../../helper/lang/TranslateUtils";
import {action, observable} from "mobx";
import {IndexByPointPopupGroupsItemComp} from "./IndexByPointPopupGroupsItemComp";

@observer
export class IndexByPointPopupGroupsComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }
    @observable
    dropDown: boolean = false;

    @action
    onClickItem(group:IndexDataGroup){
        this.setGroup(group);
    }
    @action
    setGroup(newGroup: IndexDataGroup){
        let ibp = this.props.store.indexByPointer;
        ibp.dataGroup = newGroup;
        if (!groupHasClimate(newGroup)){
            ibp.yearPoints = ibp.yearPoints.filter(a => a.yearPeriod.isClimate == false);
            ibp.yearPeriodsData = ibp.yearPeriodsData.filter(a => a.isClimate == false);
        }
        if (ibp.compareSources){
            ibp.setSingleYearPoint();
            let types = getIndexDataGroups()[this.props.store.indexByPointer.dataGroup];
            types = types.filter(a => getIgnoreCompareIndexDataType().find(b => b == a) == null);
            ibp.typesData = types;
            if (types.length <= 1) ibp.compareSources = false;
        }else {
            let types = getIndexDataGroups()[ibp.dataGroup];
            if (types.length > 0){
                ibp.typesData = [getDefaultIndexType(ibp.dataGroup)];
            }else ibp.typesData = [];
        }
        if (newGroup == IndexDataGroup.modis) ibp.compareSources = false;//для модиса никогда не сравниваем
        this.props.store.indexByPointer.syncTypesByGroup();
        this.dropDown = false;
    }
    @action onClickDown(){
        this.dropDown = !this.dropDown;
    }

    render() {
        let store = this.props.store;
        let ibp = this.props.store.indexByPointer;
        let group = ibp.dataGroup;

        let title = TranslateUtils.getTitleIndexDataGroup(group, store);

        let grps = getIndexDataGroups();

        return <div className="IndexByPointPopupGroupsComp-main pointer" onClick={this.onClickDown}>
            <div className="IndexByPointPopupGroupsComp-text">
                {title}
            </div>
            <div  className="IndexByPointPopupGroupsComp-arrow">
                <svg width="12" height="8" viewBox="0 0 12 8"
                     fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.0001 7.39961L0.600098 1.99961L2.0001 0.599609L6.0001 4.59961L10.0001 0.599609L11.4001 1.99961L6.0001 7.39961Z" fill="#C5C5C5"/>
                </svg>
            </div>
            {this.dropDown && <ContextMenuCommonComp className="IndexByPointPopupGroupsComp-dropDown" onClose={this.onClickDown}
                    direction={PopupDirection.horizontal} popupCorner={PopupCorner.leftBottom}>
                <div className="IndexByPointPopupGroupsComp-subGroupTitle">{store.trans["Space photography"]}</div>
                <IndexByPointPopupGroupsItemComp store={store} group={IndexDataGroup.spectral} onClick={this.onClickItem}
                                                 checked={group == IndexDataGroup.spectral} />
                {grps.radar_ew && <IndexByPointPopupGroupsItemComp store={store} group={IndexDataGroup.radar_iw} onClick={this.onClickItem}
                                                 checked={group == IndexDataGroup.radar_iw} />}
                {grps.radar_iw && <IndexByPointPopupGroupsItemComp store={store} group={IndexDataGroup.radar_ew} onClick={this.onClickItem}
                                                                   checked={group == IndexDataGroup.radar_ew} />}
                {grps.modis && <IndexByPointPopupGroupsItemComp store={store} group={IndexDataGroup.modis} onClick={this.onClickItem}
                                                                   checked={group == IndexDataGroup.modis} />}
                <div className="IndexByPointPopupGroupsComp-subGroupTitle">{store.trans["Weather data"]}</div>
                <IndexByPointPopupGroupsItemComp store={store} group={IndexDataGroup.temperature} onClick={this.onClickItem}
                                                 checked={group == IndexDataGroup.temperature} />
                <IndexByPointPopupGroupsItemComp store={store} group={IndexDataGroup.precipitation} onClick={this.onClickItem}
                                                 checked={group == IndexDataGroup.precipitation} />
                {/*<IndexByPointPopupGroupsItemComp store={store} group={IndexDataGroup.solarRadiation} onClick={this.onClickItem}
                                                 checked={group == IndexDataGroup.solarRadiation} />*/}
                <IndexByPointPopupGroupsItemComp store={store} group={IndexDataGroup.relativeHumidity} onClick={this.onClickItem}
                                                 checked={group == IndexDataGroup.relativeHumidity} />
                {/*<div className="IndexByPointPopupGroupsComp-footer">
                    <button className="large-button left-sidebar-active button-jump IndexByPointPopupGroupsComp-footerButton" onClick={this.clickApply}>{store.trans.Apply}</button>
                </div>*/}
            </ContextMenuCommonComp>}
        </div>;
    }
}
