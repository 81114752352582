import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoMergeToursComp.scss';
import {action, observable} from 'mobx';
import {DropDownComp, IDropDownItem} from "../../../../app/components/Common/DropDownComp";
import classNames from "classnames";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {CopyType} from "../../agroAhoCardStore";
import {TranslateUtils} from "../../../../app/helper/lang/TranslateUtils";
import {AhoUtils} from "../../agroAhoUtils";
import {AgroAhoDateComp} from "../uploadTable/AgroAhoDateComp";
import {AhoUploadTablePath} from "../../agroAhoUploadTableStore";

@observer
export class AgroAhoMergeToursComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    onClickCancel(){
        this.props.store.agroAhoStore.toggleModal(false);
    }

    onClickOk(){
        if (!this.canMerge()) return;
        let tstore = this.props.store.agroAhoStore.tourStore;
        tstore.doTourMergeClick();
        this.props.store.agroAhoStore.toggleModal(false);
    }

    canMerge(){
        let tstore = this.props.store.agroAhoStore.tourStore;
        return !!tstore.mergeTourMapName && !!tstore.mergeTourDate;
    }

    onChangeNewName(e: any){
        let tstore = this.props.store.agroAhoStore.tourStore;
        tstore.mergeTourMapName = e.currentTarget.value;
    }

    onDateChange(date: Date){
        let store_ = this.props.store.agroAhoStore;
        let tstore = store_.tourStore;
        tstore.mergeTourDate = date;
    }

    onMergeTourChange(tour: any){
        let store_ = this.props.store.agroAhoStore;
        let tstore = store_.tourStore;
        tstore.curMergeTour = tour?.data;
    }


    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;        
        let tstore = store_.tourStore;        
        let toursName = tstore.selTours.map(t=>t.tour_name).join(', ');

        let items: IDropDownItem[] = [];
        if (tstore.toursItems) tstore.selTours.forEach(t=>{
            items.push({key: t.tour_id, data: t, value: `${t.tour_name} (${t.tour_date})`});
        });

        return <div className="AgroAhoMergeToursComp-main">
            <div className="AgroAhoMergeToursComp-title">{store_.trans['Merging selected tours']}</div>
            <div className="AgroAhoMergeToursComp-statistic">
                <span>{store_.trans['A new tour will be created from the union of these tours:']}&nbsp;</span>
                {toursName}
            </div>
            <div className="AgroAhoMergeToursComp-line" />            
            <div className="AgroAhoMergeToursComp-newNameCover">
                <div className="AgroAhoMergeToursComp-name">
                    {store.trans.Name}<span className="blue-text text-next-half">*</span>
                </div>
                <div className="AgroAhoMergeToursComp-newName">
                    <input type="text" className="text-box-editor width100" onChange={this.onChangeNewName}
                           value={tstore.mergeTourMapName} />
                </div>

                <div className="AgroAhoMergeToursComp-name">
                    {store_.trans['Tour date']}<span className="blue-text text-next-half">*</span>
                </div>
                <div className="AgroAhoMergeToursComp-tourDateCover">
                    <AgroAhoDateComp className="AgroAhoMergeToursComp-tourDate" onChange={this.onDateChange}
                        selected={tstore.mergeTourDate}/>
                </div>

                <div className="AgroAhoMergeToursComp-name">{store_.trans['Tour priority (when overlapping values)']}</div>
                <div className="AgroAhoMergeToursComp-mergeTour">
                    <DropDownComp items={items} onChange={this.onMergeTourChange} currentKey={tstore.curMergeTour?.tour_id}/>
                </div>

            </div>
            <div className="AgroAhoMergeToursComp-line" />
            <div className="AgroAhoMergeToursComp-bottom">
                <button className="left-sidebar-cancel large-button button-jump AgroAhoMergeToursComp-button text-next-2"
                    onClick={this.onClickCancel}>
                    {store.trans.Cancel}
                </button>
                <button className={classNames(
                    "left-sidebar-active large-button AgroAhoMergeToursComp-button text-next-2", {
                        "button-jump": this.canMerge(),
                        "AgroAhoMergeToursComp-disabled": !this.canMerge()
                    })} onClick={this.onClickOk} >
                    {store_.trans.Merge}
                </button>
            </div>

        </div>;
    }
}
