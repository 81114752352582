import * as React from 'react';
import classNames from "classnames";
import {isFunction} from "lodash-es";
import './AgroAhoDateComp.scss'
import ReactDatePicker from "react-datepicker";


export interface IAgroAhoDateCompProps {
    className: string;
    onChange: (e: any) => void;
    selected: Date;
    futureDates?: boolean;
}

export class AgroAhoDateComp extends React.PureComponent<IAgroAhoDateCompProps, undefined> {

    render() {

        let futureDates = this.props.futureDates;
        let maxDate: Date = null;
        if (!futureDates) maxDate = new Date();
        // console.log('maxDate', maxDate);

        return <div className="AgroAhoDateComp-main">
            <ReactDatePicker
                // tabIndex={1}
                // open={this.beginOpen}
                // onCalendarClose={this.onBeginClose}
                // onCalendarOpen={this.onBeginOpen}
                // onFocus={this.onFocusBegin}
                // onBlur={this.onExitBegin}
                // disabled={this.props.disabledItem1}
                placeholderText="YYYY-MM-DD"
                className={`date-input date-input-item1 ${this.props.className}`}
                // onKeyDown={this.onKeyDown}

                //includeDates={this.props.includeDays}
                selected={this.props.selected}
                //onChange={date => this.handleChangeBegin(date as Date)}
                onChange={this.props.onChange}
                onSelect={this.props.onChange}
                // onSelect={this.onSelectBegin}
                // selectsStart
                // startDate={begin}
                // endDate={end}
                // minDate={minMinDate}
                // maxDate={minMaxDate}
                maxDate={maxDate}
                // monthsShown={1}
                dateFormat='yyyy-MM-dd'
                // showYearDropdown
                // yearDropdownItemNumber={5}
                // scrollableYearDropdown
            />
        </div>
    }
}
