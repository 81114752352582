import {CreateGeometryType} from "../../tools/general/ContainerTools";
import {ObservableCustomStore} from "../../CustomStore";
import {AgroEditManager} from "../../toolController/AgroEditManager";
import {FieldLayersTool} from "../../tools/Fields/FieldLayersTool";
import {FieldClickTool} from "../../tools/Fields/FieldClickTool";
import {action, autorun, computed} from "mobx";
import {IReactionDisposer} from "mobx/lib/internal";
import {MeasuringStatus} from "../../../helper/structs/MeasuringStatus";
import {GeometryUtils, SimpleGeometryType} from "../../../helper/utils/GeometryUtils";
import {FieldAutoStore} from "./FieldAutoStore";
import {FieldEditModeType} from "./A2FieldFormCustomStore";
import {GActionTransactionType} from "../../../helper/geometryAction/IGeometryAction";
import {LeftPanelMode} from "../../SearchStore";
import {IKeyColumn} from "../A2AgroStore";
import {A2NavigatorItemStore, NavigatorItemStoreType} from "../A2NavigatorItemStore";

export enum EditModeType {
    edit='edit',
    insert='insert'
}

export class FieldToolController extends ObservableCustomStore{
    editable_field_id: number = null;
    editable_properties: any = null;
    editMode: EditModeType = null;

    autoPolygon: FieldAutoStore = new FieldAutoStore(this);

    subscription(): IReactionDisposer[] {
        return [
            autorun(()=>{
                this.setTools();
            })
        ];
    }

    @computed
    get showPolygonButtons(): boolean{
        let store = this.root;
        store.agro2.editManager.changes;
        //не показываем панель пока вводится первый полигон
        let ok = (store.searchPanel.leftPanelMode == LeftPanelMode.agro &&
            (store.agro2.fieldEditorForm.editMode == FieldEditModeType.edit ||
                    store.agro2.fieldEditorForm.editMode == FieldEditModeType.insert)
        );

        if (!ok) return false;
        let types = GeometryUtils.getSimpleTypes(store.agro2.editManager.state.simpleGeometry);
        let ms = store.map.measuringStatus;
        let sg = store.agro2.editManager.state.simpleGeometry;
        if (ms == MeasuringStatus.agroDeleteContour || ms == MeasuringStatus.agroCreatePolygonHole) return true;
        if (ms == MeasuringStatus.agroCreatePolygon && sg.length > 0 && sg[0].contour.length > 1 &&
            store.agro2.editManager.state.createGeometryType == CreateGeometryType.Polygon) return true;
        if (types.find(a => a == SimpleGeometryType.Point) != null) return false;
        if (sg.length == 0) return false;
        if (sg.length > 1) return true;
        if (sg.length == 1){
            let sg2 = sg[0];
            if (sg2.holes != null && sg2.holes.length > 0) return true;
            if (store.agro2.editManager.isCreateGeometry()) return false;
            return true;
        }
        return ok;
    }

    setTools(){
        let ms = this.root.map.measuringStatus;
        switch(ms){
            case MeasuringStatus.agroEdit:
                this.root.agro2.editManager.toEdit();
                break;
            case MeasuringStatus.agroCreatePoint:
                this.root.agro2.editManager.toCreateGeoemtry(CreateGeometryType.Point);
                break;
            case MeasuringStatus.agroCreateLine:
                this.root.agro2.editManager.toCreateGeoemtry(CreateGeometryType.Line);
                break;
            case MeasuringStatus.agroCreatePolygon:
                this.root.agro2.editManager.toCreateGeoemtry(CreateGeometryType.Polygon);
                break;
            case MeasuringStatus.agroCreatePolygonHole:
                this.root.agro2.editManager.toCreateGeoemtry(CreateGeometryType.Hole);
                break;
            case MeasuringStatus.agroDeleteContour:
                this.root.agro2.editManager.toDeleteContour();
                break;
            default:
                this.root.agro2.editManager.deactivate();
                break;
        }
    }


    get fieldLayerTool(): FieldLayersTool{
        return this.root.map.superTools.fieldLayersTool;
    }

    get fieldClickTool(): FieldClickTool{
        return this.root.map.superTools.fieldClickTool;
    }
    get agroEditorManager(): AgroEditManager{
        return this.root.agro2.editManager;
    }


    @action
    startDrawPolygon(){
        this.root.agro2.editManager.startCreateGeometry(CreateGeometryType.Polygon);
        this.root.agro2.editManager.setGeometry(null);
        if (this.root.agro2.fieldToolController.editable_properties == null) this.root.agro2.fieldToolController.editable_properties = {};
    }
    @action
    removeGeometry(){
        let sg = this.root.agro2.editManager.state.simpleGeometry;
        if (sg.length > 0) {
            let tr = this.root.agro2.editManager.state.actionManager.startGeometryFieldTransaction(GActionTransactionType.deleteGeometry);
            for (let i = sg.length - 1; i >= 0; i--) {
                tr.deleteGeometry(i);
            }
            this.root.agro2.editManager.updateGeometry();
        }
        this.toEditPolygon();
    }
    clearGeometry(){
        this.root.agro2.fieldToolController.editable_field_id = null;
        this.root.agro2.fieldToolController.editable_properties = null;
        this.root.agro2.editManager.setGeometry(null);
        this.toEditPolygon();
    }
    @action
    stopDrawPolygon(): void{
        this.root.agro2.editManager.deactivate();
        this.root.agro2.editManager.resetChanges();
        this.root.map.superTools.fieldLayersTool.setFilters();
        this.root.agro2.editManager.updateGeometry();
    }

    @action
    toEditPolygon(){
        this.root.agro2.editManager.toEdit();
        this.root.agro2.editManager.resetChanges();
        this.root.map.superTools.fieldLayersTool.setFilters();
        this.root.agro2.editManager.updateGeometry();
    }

    getLayerFieldsFilter(valueFiltered: any, valueOther: any): any{
        let store = this.root;
        let navItem: A2NavigatorItemStore = store.agro2.projectStruct.activeNavigatorPage;
        let t = {navItem: navItem };
        this.root.events.onLayerFieldsFilter.call(t);
        navItem = t.navItem;

        let paths = navItem.getNaviPath();
        let filters: any[] = [];
        paths.forEach((a, index) => {
            if (index == 0) return;
            if (a.columnInfo.column_name == null) return;
            let v = ["==",['to-string',['get',a.columnInfo.column_name]],['to-string',a.column_value]];
            filters.push(v);
        });
        if (navItem.type == NavigatorItemStoreType.folder && navItem.filter_string != ""){
            filters.push(["in",["downcase", ["literal", navItem.filter_string]], ["downcase", ['to-string',['get', navItem.columnInfo.getValueColumnName()]]]]);
        }
        if (navItem.type == NavigatorItemStoreType.fields && navItem.filter_string != ""){
            filters.push(["in",["downcase", ["literal", navItem.filter_string]], ["downcase", ['to-string',['get', store.agro2.projectInfo.fieldName_columnName]]]]);
        }
        return ["case",["all", ...filters], valueFiltered, valueOther];
    }

    getHoverFolderFilter(values: IKeyColumn[]): any{
        let store = this.root;
        let filters: any[] = [];
        values.forEach((a, index) => {
            let v = ["==",['to-string',['get',a.column]],['to-string',a.value]];
            filters.push(v);
        });
        return ["all", ...filters];
    }

    public getFilter(selected: boolean, hover: boolean): any{
        let notVis: any = [];//какие ID не показывать
        let vis: any = [];//только эти ID показывать
        let r: any[] = ["all"];
        let store = this.root;
        if (this.editable_field_id != null) notVis.push(this.editable_field_id);
        if (!hover && this.root.agro2.hover_field_id != null) notVis.push(this.root.agro2.hover_field_id);

        if (hover){
            if (this.root.agro2.hover_folder != null){
                r.push(this.getHoverFolderFilter(this.root.agro2.hover_folder));
            }else {
                if (this.root.agro2.hover_field_id != null)
                    vis.push(this.root.agro2.hover_field_id);
                if (vis.length > 0)
                    r.push(["in", ["get", store.agro2.projectInfo.fieldName_id], ["literal", vis]]);
                else r.push(false);
            }
        }else
        if (!selected) {
            if (this.root.agro2.projectStruct.fieldsPage != null) {
                notVis = notVis.concat(this.root.agro2.projectStruct.fieldsPage.selectedFields.map(b => b.id));
            }
        }else{
            if (this.root.agro2.projectStruct.fieldsPage != null) {
                vis = vis.concat(this.root.agro2.projectStruct.fieldsPage.selectedFields.map(b => b.id));
            }
            if (vis.length > 0)
                r.push(["in", ["get",store.agro2.projectInfo.fieldName_id], ["literal", vis]]);
            else r.push(false);
        }
        if (notVis.length > 0) r.push(["!", ["in", ["get",store.agro2.projectInfo.fieldName_id], ["literal", notVis]]]);

        let ev = {
            ok: this.root.agro2.filterMapShowOnlyCurrentNavigatorPath
        };
        store.events.onAgroUseFilterMapShowOnlyCurrentNavigatorPath.call(ev);
        let useFilterMapShowOnlyCurrentNavigatorPath = ev.ok;

        if (useFilterMapShowOnlyCurrentNavigatorPath) r.push(this.getLayerFieldsFilter(true, false));
        return r;
    }

}
