import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import {MinSvg} from "../../icons/Legend/MinSvg";
import {MaxSvg} from "../../icons/Legend/MaxSvg";
import {action} from "mobx";
import autoBindReact from "auto-bind/react";
import {IndexBandProductStore, Interpolation} from "../../../store/productSetting/IndexBandProductStore";
import {LegendLinearComp} from "./LegendLinearComp";
import {Utils} from "../../../helper/utils/Utils";
import {LegendDiscreteComp} from "./LegendDiscreteComp";
import {ProductSettingStore} from "../../../store/productSetting/ProductSettingStore";
import {LegendFixIndexComp} from "./LegendFixIndexComp";
import {LegendCommonComp} from "./LegendCommonComp";
import {LeftPanelMode} from "../../../store/SearchStore";
import {LegendDiscrete} from "./LegendDiscrete";
import {LegendMinComp} from "./LegendLayer/LegendMinComp";

@observer
export class LegendPanel extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    toMin() {
        this.props.store.map.legend.legendMinimize = true;
    }


    onChangeTransparentLinear(options: {isMore: boolean, isLess: boolean, value: boolean}){
        let store = this.props.store;
        let info = store.map.legend.getProdInfo();
        if (info == null) return;
        if (options.isMore)
            info.prod.indexBand.transparentMax = options.value;
        if (options.isLess)
            info.prod.indexBand.transparentMin = options.value;
    }


    render() {
        let store = this.props.store;
        if (!store.map.legend.legendNdviShow) return null;

        let min = store.map.legend.legendMinimize;
        if (min) {
            return <LegendMinComp store={store} />;
        }

        let info = store.map.legend.getProdInfo();
        if (info == null) return null;
        let prod = info.prod;
        let b: IndexBandProductStore = info.prod.indexBand;
        let interpolation: Interpolation = b.interpolation;



        return <LegendCommonComp store={store} className="legend-panel qtransit">
            {<React.Fragment>
                {prod.bandType == "fixedIndex" && <LegendFixIndexComp prod={prod} store={store} />}
                {prod.bandType != "fixedIndex" &&
                    <React.Fragment>
                        {interpolation == Interpolation.discrete && <LegendDiscrete store={store} prod={prod} />}
                        {interpolation == Interpolation.linear &&
                        <LegendLinearComp store={store} max={Utils.parseNumber(b.max)} min={Utils.parseNumber(b.min)} visibleCheckbox={true}
                                          description={prod.unitDescription} values={null} transparentMore={b.transparentMax} transparentLess={b.transparentMin}
                                          onChangeTransparent={this.onChangeTransparentLinear} top_name={prod.productConfig.abbreviation}
                                          colors={b.colors.colors.map(a => a.color)} title={prod.unitName}  />}
                        <div className="legend-panel_toMin" onClick={this.toMin}><MinSvg/></div>
                    </React.Fragment>
                }
            </React.Fragment>}
        </LegendCommonComp>;
    }
}
