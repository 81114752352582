import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoLoadPanelComp.scss'
import {action} from "mobx";
import classNames from "classnames";
import {AgroAhoProgressComp} from "./AgroAhoProgressComp";
import {AgroAhoDownloadFileSvg} from "./AgroAhoDownloadSvg";
import {Utils} from "../../../app/helper/utils/Utils";
import {AhoFileLoadStage, AhoLoadStatus} from "../agroAhoUploadIndcStore";
import {LoadedIndicator} from "../agroAhoCardStore";
import {IAgroAhoStoreProps} from "../agroAhoPlugin";

@observer
export class AgroAhoLoadPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    uploadElement: HTMLInputElement = null;

    @action
    onClickSave(){
        let ustore = this.props.store.agroAhoStore.uploadIndcStore;
        ustore.saveClick();
    }

    @action
    onClickUpload(){
        this.uploadElement.click();
    }

    @action
    onClickCancel(){
        let ustore = this.props.store.agroAhoStore.uploadIndcStore;
        ustore.loadCancelClick();
    }

    uploadEnable(){
        return true;
    }

    @action
    onDownloadSampleClick(){
        let url = `образец_данные_АХО.ods`;
        Utils.downloadFile(url);
        // console.log('Download sample');
    }

    @action
    onDownloadInstructionsClick(){
        let url = `Инструкция.pdf`;
        Utils.downloadFile(url);
        // console.log('Download instructions');
    }

    @action
    uploadChanged(e:any){
        let ustore = this.props.store.agroAhoStore.uploadIndcStore;
        let input = this.uploadElement;
        if (input.files.length == 0) return;
        let file = e.target.files[0];
        input.value = '';
        ustore.uploadFile(file);
    }

    onChangeRef(node: any){
        this.uploadElement = node;
    }

    render() {
        let store = this.props.store;
        let store_ = this.props.store.agroAhoStore;
        let ustore = store_.uploadIndcStore;

        return <div className={classNames("AgroAhoLoadPanelComp-main",{
            "AgroAhoLoadPanelComp-hidden": !store_.ahoDialogShow})}>
            <div className="AgroAhoLoadPanelComp-cover">
                <div className="AgroAhoLoadPanelComp-topPanel">
                    <div className="AgroAhoLoadPanelComp-title">{store.trans['Loading indicators']}</div>
                    <div className="AgroAhoLoadPanelComp-msg">
                        <span>{store.trans['Agrochemical data can be downloaded in OpenDocument (.ods) format (supported by MS Excel, Google Sheets, OpenOffice, etc.). The column names must match the']}</span>
                        <span className="AgroAhoLoadPanelComp-link" onClick={this.onDownloadSampleClick}>{store.trans['pattern']}</span>
                        <span>{store.trans['. How to correct the original spreadsheet file from the lab is described in']}</span>
                        <span className="AgroAhoLoadPanelComp-link" onClick={this.onDownloadInstructionsClick}>{store.trans['instructions']}.</span>
                    </div>
                    <AgroAhoProgressComp store={store}/>
                    <div className="AgroAhoLoadPanelComp-resultCover">
                        {ustore.ahoLoadStatus === AhoLoadStatus.Success && <span className="AgroAhoLoadPanelComp-resultOk">{store.trans['The file has been successfully processed and matches the sample']}</span>}
                        {ustore.ahoLoadStatus === AhoLoadStatus.Warning && <span>
                            <span className="AgroAhoLoadPanelComp-resultOk">{store.trans['The file was processed successfully.']}</span>
                            <span className="AgroAhoLoadPanelComp-resultWarnTitle">{store.trans['Some column names do not match the template']}</span>
                        </span>}
                        {ustore.ahoLoadStatus === AhoLoadStatus.Error && <span className="AgroAhoLoadPanelComp-resultErr">{store.trans['The file has been processed with errors. Fix the following bugs']}:</span>}
                    </div>
                    {ustore.ahoLoadStatus === AhoLoadStatus.Error &&
                        <textarea readOnly={true} className="AgroAhoLoadPanelComp-textarea style-4" value={ustore.ahoLog}/>}
                    {(ustore.ahoLoadStatus === AhoLoadStatus.Success || ustore.ahoLoadStatus === AhoLoadStatus.Warning) && <React.Fragment>
                        <div className="AgroAhoLoadPanelComp-resultTableHeaderCover">
                            <table className="AgroAhoLoadPanelComp-resultTableHeader">
                                <tbody>
                                    <tr>
                                        <td>{store.trans['Loaded indicators']}</td>
                                        <td>{store.trans['Number of samples']}</td>
                                        <td>{store.trans.Columns}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="AgroAhoLoadPanelComp-resultTableCover">
                            <table className="AgroAhoLoadPanelComp-resultTable">
                                <tbody>
                                    {ustore.ahoLoadedIndicators.map((item: LoadedIndicator) =>
                                        <tr key={item.indc_code}>
                                            <td>{item.name}</td>
                                            <td>{item.count}</td>
                                            <td className={classNames({"AgroAhoLoadPanelComp-resultWarning": item.warning})}>{item.indc_code}</td>
                                        </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>}
                </div>
                <div className="AgroAhoLoadPanelComp-bottomPanel">
                    <div className="AgroAhoLoadPanelComp-linkSample" onClick={this.onDownloadSampleClick}>
                        <AgroAhoDownloadFileSvg />
                        <span>{store.trans.Sample}</span>
                    </div>
                    <div className="AgroAhoLoadPanelComp-linkInstructions" onClick={this.onDownloadInstructionsClick}>
                        <AgroAhoDownloadFileSvg />
                        <span>{store.trans.Instruction}</span>
                    </div>
                    <div className="AgroAhoLoadPanelComp-splitter"> </div>
                    <button className="large-button left-sidebar-cancel AgroAhoLoadPanelComp-cancelButton button-jump"
                        onClick={this.onClickCancel}>{store.trans.Cancel}</button>
                    {ustore.ahoFileLoadStage === AhoFileLoadStage.Success && <button onClick={this.onClickSave}
                        className="left-sidebar-active button-jump AgroAhoLoadPanelComp-saveButton">{store.trans.Save}</button>}
                    {ustore.ahoFileLoadStage !== AhoFileLoadStage.Success && <button onClick={this.onClickUpload}
                         className={classNames("left-sidebar-active button-jump AgroAhoLoadPanelComp-saveButton",
                         {"opacity50": !this.uploadEnable()})}>{store.trans.Upload}</button>}
                </div>
            </div>
            <input type="file" style={{display: "none"}} accept=".ods" onChange={this.uploadChanged}
               ref={this.onChangeRef} />
        </div>
    }
}
