import {observer} from "mobx-react";
import * as React from "react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import autoBindReact from "auto-bind/react";
import {CSSProperties} from "react";
import {WindowTriState} from "../../../helper/structs/WindowTriState";
import classNames from "classnames";

interface ILegendCommonCompProps extends IStoreProps {
    style?: React.CSSProperties;
}
@observer
export class LegendCommonComp extends React.Component<ILegendCommonCompProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }
    render(){
        let store = this.props.store;

        let st: CSSProperties = {right: "5px"};
        if (store.indexByPointer.showPanel == WindowTriState.show){
            st.right = "440px";
        }
        else if (store.map.layersMenuOpened) {
            st.right = "355px"
        }
        const combinedStyles: CSSProperties = { ...st, ...this.props.style };
        return <div className={classNames( this.props.className)} style={combinedStyles}>{this.props.children}</div>;
    }
}