import {
    cloneDeep,
    isArray,
    isNull,
    isNumber,
    isObject,
    isPlainObject,
    isString,
    isUndefined,
    padStart
} from "lodash-es";
import {CoordinateStringFormat} from "./CoordinateStringFormat";
import {LngLat, LngLatBounds} from "maplibre-gl";
import {BBox2d} from "@turf/helpers/dist/js/lib/geojson";
import {CustomTool} from "../../store/tools/general/ContainerTools";
import {ReactElement} from "react";
import { fetchBytesGet } from "./FetchUtils";
import { IRGBA } from "./ColorHelper";
import { action } from "mobx";
import * as mapboxgl from "maplibre-gl";
export interface IDictonaryType<T>{[index: string]: T}
export interface IBbox{top:number, left:number, height: number, width:number}
export enum SortDirection{
    asc = 'asc',//по возрастанию
    desc = 'desc'// по убыванию
}


export class Utils {

    //Добаляет пробелы между разрядами для числа в виде строки
    static formatNumberWithSpaces(number: string): string {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    static toDate(value: any): Date{
        if (value == null) return null;
        if (value instanceof Date) return value;
        return new Date(value);
    }

    static roundDigitsWithSpace(n: number, precision: number): string{
        let max = Math.pow(10, precision) - 1;//для 999.7
        let s: string;
        if (n >= max) s = Math.round(n).toString();
        else s = parseFloat(n.toFixed(precision)).toString();
        let parts = s.split(".");
        let res = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        if (parts.length > 1) res += '.' + parts[1];
        return res;
    }
    //выводит число с заданным количеством знаков. Для precision=3: 1234.5678:1234 234.5678:234 34.5678:34.5 4.5678:4.56 0.5678: 0.567
    static roundDigits(n: number, precision: number): string{
        let max = Math.pow(10, precision) - 1;//для 999.7
        if (n >= max) return Math.round(n).toString();
        return n.toPrecision(precision);
    }

    static makeId(length: number = 10) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    static isStringNotEmpty(s: any): boolean{
        return isString(s) && s != "";
    }
    //дата в строку в формате 2020-12-31 для restapi и URL
    static getDateStr(date: Date): string{
        return Utils.pad(date.getFullYear(),4)+"-"+Utils.pad(date.getMonth() + 1,2)+"-"+Utils.pad(date.getDate(),2);
    }

    //возаращает значение функции для x по извесным значениям fx1 в x1 и fx2 в x2
    static lineInterpolation(x: number, x1: number, x2: number, fx1: number, fx2: number): any{
        return (fx1+(fx2 - fx1) *(x - x1)/(x2 - x1));
    }
    //День года 0..365
    static getDayOfYear(date: Date): number{
        var start = new Date(date.getFullYear(), 0, 0);
        var diff = (date.getTime() - start.getTime()) + ((start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000);
        var oneDay = 1000 * 60 * 60 * 24;
        return  Math.floor(diff / oneDay);
    }

    static readonly BaseYear = 2020;
    static readonly DaysByMonth = [31,29,31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    //static readonly DaysByMonth = [31,60,91,121,152,182,213,244,274,305,305,335];
    static readonly DaysByYear = 366;


    private static decodeGlobalAllLeapYears(numDay: number):{year: number, month: number, day: number}{
        let year = Math.floor(numDay / Utils.DaysByYear);
        let temp = year * Utils.DaysByYear;
        let month = 0;
        while(month <= 10){
            let temp2 = temp + Utils.DaysByMonth[month];
            if (temp2 > numDay) break;
            temp = temp2;
            month++;
        }
        let day = numDay - temp;
        return {year, month, day}
    }

    // получить абсолютный номер дня в году начиная с 0 года, считая все года високосными
    static getNumDayForGlobalAllLeapYears(date: Date): number{
        //var start = new Date(year, 0, 0);

        let r = date.getFullYear() * Utils.DaysByYear;
        for(let i = 0; i <= date.getMonth() - 1; i++){
            r = r + Utils.DaysByMonth[i];
        }
        r = r + date.getDate();
        return r;//  Math.floor(diff / oneDay);
    }

    // для URL при отображении данных за не високосные года
    static adjustDayOfYear(
        dateBegin: Date,
        dateEnd: Date
    ): {dayOfYearBegin: number; dayOfYearEnd: number} {
        let dayOfYearBegin = Utils.getDayOfYear(dateBegin);
        let dayOfYearEnd = Utils.getDayOfYear(dateEnd);
        dayOfYearEnd = dayOfYearEnd === 1 ? 365 : dayOfYearEnd;
        dayOfYearBegin =
            dayOfYearBegin >= 61 ? dayOfYearBegin - 1 : dayOfYearBegin;
        dayOfYearEnd =
            dayOfYearBegin === dayOfYearEnd ? dayOfYearEnd - 1 : dayOfYearEnd;
        return {dayOfYearBegin, dayOfYearEnd};
    }

    static getDateByGlobalAllLeapYears(numDay: number): Date {
        let {year, month, day} = Utils.decodeGlobalAllLeapYears(numDay);
        return new Date(year, month, day);
    }

    static getDayOfYearRelativeByGlobalAllLeapYears(date: Date, year: number): number{
        let d1 = Utils.getNumDayForGlobalAllLeapYears(date);
        let d2 = Utils.getNumDayForGlobalAllLeapYears(new Date(year, 0, 1));
        return d1 - d2;
    }
    static getDateByRelativeByGlobalAllLeapYears(relativeNumDay: number, year: number): Date{
        let d1 = year * Utils.DaysByYear + 1 + relativeNumDay;
        return Utils.getDateByGlobalAllLeapYears(d1);
    }

    static getDateOffset(date: Date, days: number) : Date {
        let dt = new Date(date);
        dt.setDate(dt.getDate() + days);
        return new Date(dt.getTime());
    }

    static getDatesDiff(d1: Date, d2: Date, units: "day" | "hour" | "min" | "sec" | "ms") : number {        
        let divider = 1;
        switch (units) {
            case "sec": divider *= 1000; break;
            case "min": divider *= 60*1000; break;
            case "hour": divider *= 60*60*1000; break;
            case "day": divider *= 24*60*60*1000; break;
        }
        return Math.round(((d1 as any) - (d2 as any)) / divider);
    }

    //День года 0..365
    static getGlobalMonthNumber(date: Date): number{
        return date.getFullYear() * 12 + date.getMonth();
    }
    static getDateByGlobalMonth(globalMonth: number): Date{
        let month = globalMonth % 12;
        let year = (globalMonth - month) / 12;
        return new Date(year, month, 1);
    }

    static dateByDayOfYear(dayOfYear: number): Date{
        let d = new Date(Utils.BaseYear, 0, dayOfYear);
        return d;
    }
    //Форматирование даты в формат yyyy-MM-dd для GUI
    static formatDate(date: Date) {
        return Utils.pad(date.getFullYear(),4)+"-"+Utils.pad(date.getMonth() + 1,2)+"-"+Utils.pad(date.getDate(),2);
    }

    //преобразует число в строку с ведущими нулями
    static pad(num: number, size: number) {
        let s = num.toString();
        while (s.length < size) s = "0" + s;
        return s;
    }

    //кодирование параметров для queryString
    static queryEncodeParams(p: any): string{
        let s = "";
        let i = 0;
        for(let key in p){
            if (i > 0) s += "&";
            let v = p[key];
            if (isObject(v)) v = JSON.stringify(v);
            s += encodeURIComponent(key)+"="+encodeURIComponent(v);
            i++;
        }
        return s;
    }

    static getErrorString(err: any, withStack: boolean = false): string{
        let s: string = 'unknow';
        try {
            if (isNull(err) || isUndefined(err)) return "error";
            if (isString(err)) return err;
            if (err instanceof Error) {
                s = (err as Error).message;
                if (withStack) s = s + " Stack:" + (err as Error).stack;
                return s;
            }
            if (isString(err.message)) {
                s = err.message;
                if (withStack && isString(err.stack)) s = s + " Stack:" + err.stack;
            }
        }
        catch(err1){
            return s.toString();
        }

        try{
            return JSON.stringify(err);
        }catch(err1){}
        try{
            s = err.toString();
        }catch(err1){}
        return s;
    }

    static toDegreesMinutesSeconds(coordinate : number): string {
        let absolute = Math.abs(coordinate);
        let degrees = Math.floor(absolute);
        let minutesNotTruncated = (absolute - degrees) * 60;
        let minutes = Math.floor(minutesNotTruncated);
        let seconds = (minutesNotTruncated - minutes) * 60;

        return degrees + "°" +
            padStart(minutes.toString(), 2, '0') + "'" +
            padStart(seconds.toFixed(2), 5, '0') + '"';
    }

    static toDegreesMinutes(coordinate : number): string {
        let absolute = Math.abs(coordinate);
        let degrees = Math.floor(absolute);
        let minutes = (absolute - degrees) * 60;

        return degrees + "°" +
            padStart(minutes.toFixed(4), 7, '0') + "'";
    }

    static toDegrees(coordinate : number): string {
        return (coordinate).toFixed(6)+"°";
    }

    static toDegreesAbs(coordinate : number): string {
        return coordinate.toFixed(6);
    }
    static normalizeCoordinates(latitude: number, longitude: number): {latitude: number, longitude: number} | null {
        // Check if latitude and longitude are valid numbers
        if (isNaN(latitude) || isNaN(longitude)) {
            return null;
        }

        // Normalize latitude to range [-90, 90]
        while (latitude < -90) {
            latitude += 180;
        }
        while (latitude > 90) {
            latitude -= 180;
        }

        // Normalize longitude to range [-180, 180]
        while (longitude < -180) {
            longitude += 360;
        }
        while (longitude > 180) {
            longitude -= 360;
        }

        return {latitude: latitude, longitude: longitude};
    }

    static coosToString(lng : number, lat : number, coosFormat : CoordinateStringFormat) : string {
        let conv = null;
        let r = Utils.normalizeCoordinates(lat, lng);
        lat = r.latitude;
        lng = r.longitude;
        switch (coosFormat)
        {
            case CoordinateStringFormat.abs: conv = Utils.toDegreesAbs; break;
            case CoordinateStringFormat.degMinSec: conv = Utils.toDegreesMinutesSeconds; break;
            case CoordinateStringFormat.degMin: conv = Utils.toDegreesMinutes; break;
            case CoordinateStringFormat.deg: conv = Utils.toDegrees; break;
            default: throw "Invalid format";
        }

        if (lat == null || lng == null) return "unknow";
        if (coosFormat == CoordinateStringFormat.abs){
            return conv(lat) + ", " + conv(lng);
        }
        if (coosFormat == CoordinateStringFormat.deg){
            return conv(lat) + ", " + conv(lng);
        }
        let latitude = conv(lat);
        let latitudeCardinal = lat >= 0 ? "N" : "S";

        let longitude = conv(lng);
        let longitudeCardinal = lng >= 0 ? "E" : "W";

        return latitude + " " + latitudeCardinal + ", " + longitude + " " + longitudeCardinal;
    }

    static copyToClipboard(text:string) {
        if ((<any>window).clipboardData && (<any>window).clipboardData.setData) {
            // IE specific code path to prevent textarea being shown while dialog is visible.
            return ((<any>window).clipboardData).setData("Text", text);

        } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            const textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
            document.body.appendChild(textarea);
            textarea.select();
            try {
                return document.execCommand("copy");  // Security exception may be thrown by some browsers.
            } catch (ex) {

                return false;
            } finally {
                document.body.removeChild(textarea);
            }
        }
    }

    //Количество свойств в объекте
    static objectPropertiesCount(obj: any): number{
        return Object.keys(obj).length;
    }
    static objectTruePropertiesCount(obj: any): number{
        let cnt = 0;
        for(let k in obj) {
            if (obj[k] === true) cnt++;
        }
        return cnt;
    }
    static allKeys(obj: any): string[]{
        let arr: string[] = [];
        for(let k in obj){
            arr.push(k);
        }
        return arr;
    }

    static allValues(obj: any): any[]{
        let arr: any[] = [];
        for(let k in obj){
            arr.push(obj[k]);
        }
        return arr;
    }

    static pointInBbox(bounds: LngLatBounds, point: LngLat): boolean{
        return (point.lat >= bounds.getWest() && point.lat < bounds.getEast() &&
            point.lng >= bounds.getSouth() && point.lng < bounds.getNorth());

    }

    static getInnerBounds(bounds: LngLatBounds, k: number): LngLatBounds{
        //if (k < 0 || k > 0.5) throw "Invalid coefficient.";
        let s = bounds.getSouth();
        let n = bounds.getNorth();
        let e = bounds.getEast();
        let w = bounds.getWest();
        return new LngLatBounds(
            new LngLat(w + k * (e - w), s + k * (n - s)),
            new LngLat(w + (1 - k)*(e - w), s + (1- k) * (n - s))
        );
    }
    static arrayRemoveByIndex(arr: any[], index: number){
        arr.splice(index, 1);
    }
    static arrayRemoveByValue<T>(arr: T[], value: T){
        let idx = arr.indexOf(value);
        if (idx >= 0) this.arrayRemoveByIndex(arr, idx);
    }
    static arrayInsert<T>(arr: T[], index: number, value: T){
        arr.splice(index, 0, value);
    }
    static arrayInsertArray<T>(arr: T[], index: number, items: T[]){
        arr.splice(index, 0, ...items);
    }

    static toObject<T>(arr: string[], value: T): Record<string, T>{
        let obj: Record<string, T> = {};
        arr.forEach(a => {
            obj[a] = value;
        });
        return obj;
    }
    //кодирует строку в html, кодируя возможные теги и т.п.
    static stringToHtmlEncode(rawStr: string): string {
        let encodedStr = rawStr.replace(/[\u00A0-\u9999<>\&]/g, function (i) {
            return '&#' + i.charCodeAt(0) + ';';
        });
        return encodedStr;
    }

    //вроде абсолютные, с учётом главной прокрутки. Подходят для работы с position:absolute размещённых body
    static getElemAbsoluteCoords(elem: Element):IBbox { // кроме IE8-
        const box = elem.getBoundingClientRect();
        return {
            top: box.top + window.pageYOffset,
            left: box.left + window.pageXOffset,
            height: box.height,
            width: box.width
        };
    }
    static pauseAsync(ms: number): Promise<void>{
        return new Promise<void>((resolve => {setTimeout(()=>{ resolve();}, ms)}));
    }
    static parseNumber(s: any): number{
        if (isNumber(s)) return s;
        try{
            let v = parseFloat(s);
            if (isNaN(v)) return null;
            return v;
        }catch{
            return null;
        }
    }

    static scrollIntoView(elem: any){
        (elem as any).parentNode.scrollTop = elem.offsetTop;
    }
    static scrollIntoView2(element: any){
        let container = element.parentNode;
        const elementRect = element.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
        const scrollTop = container.scrollTop;

        const top = elementRect.top - containerRect.top + scrollTop;

        container.scrollTo({
            top,
            behavior: 'auto'
        });
    }

    static getExpBase(num: number): number{
        let s = num.toExponential();
        let exp = s.substr(s.indexOf("e")+1);
        return  Utils.parseNumber(exp);
    }
    static getExpValue(num: number, expBase: number): number{
        return num / Utils.expMult(expBase);
    }

    static expMult(expBase: number): number{
        if (expBase > 0){
            let d = 10;
            for(let i = 1; i < Math.abs(expBase); i++){
                d = d * 10;
            }
            return d;
        }
        if (expBase < 0){
            let d = 10;
            for(let i = 1; i < Math.abs(expBase); i++){
                d = d * 10;
            }
            return 1 / d;
        }
        return 1;
    }

    static toExponential(num: number, expBase: number, maxDigits: number): string{
        let n = num;
        let d = Utils.expMult(expBase);
        n = n / d;

        let s = n.toFixed(maxDigits);
        let extDigits = 1;
        if (n < 0) extDigits++;
        while(s[s.length- 1] != '.' && (s[s.length- 1] == '0' || (s.length - extDigits) > maxDigits)){
            s = s.substr(0, s.length - 1);
        }
        if (s[s.length- 1] == '.') s = s.substr(0, s.length - 1);

        if ((s.length - extDigits) > maxDigits){
            return Utils.toExponential(num, expBase + 3, maxDigits);
        }

        if (expBase != 0)s = s +"e"+expBase.toString();
        return s;
    }

    //округление 'num' до 'p' знаков после запятой
    static toFixedNum(num: number, p: number): number{
        let q = Math.pow(10, p);
        return Math.round(num * q) / q;
    }

    static parseInt(s: any): number{
        if (isNumber(s)) return s;
        try{
            let v = parseInt(s);
            if (isNaN(v)) return null;
            return v;
        }catch{
            return null;
        }
    }

    static parsePositiveInt(value: string) {
        if (/^(\+)?([0-9]+)$/.test(value))
          return Number(value);
        return NaN;
      }



    static compareSortString(a: string, b: string): number{
        if (a == b == null) return 0;
        if (a == null) return -1;
        if (b == null) return 1;
        return a.localeCompare(b);
    }
    static compareSortBoolean(x: boolean, y: boolean): number{
        return (x === y)? 0 : x? -1 : 1;
    }
    static compareSortDates(date1: Date, date2: Date): number{
        if (date1 == date2 == null) {
            return 0;
        }
        if (date1 == null) return -1;
        if (date2 == null) return 1;
        return date1.getTime() - date2.getTime();
    }

    static compareDates(date1: Date, date2: Date, bothNullableAsEqual: boolean = true): boolean{
        if (date1 == date2 == null) {
            return bothNullableAsEqual;
        }
        if (date1 == null || date2 == null) return false;
        return date1.getTime() == date2.getTime();
    }
    //Нормальный toISOString с поддержкой временной зоны
    static dateToISOString(date: Date): string{
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
    }
    //только дата ISOString с поддержкой временной зоны
    static dateOnlyToISOString(date: Date): string{
        if (date == null) return null;
        return Utils.dateToISOString(date).split("T")[0];
    }
    static objectClassName(obj: any): string{
        return (obj.constructor.name);
    }

    static validateEmail(email: string): boolean {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    //сравнивается
    static isEqualObjects(obj1: any, obj2: any): boolean{
        if (obj1 == obj2) return true;
        if (isObject(obj1) == isObject(obj2)){
            for(let k in obj1){
                if (obj2[k] !== obj1[k]) return false;
            }
            for(let k in obj2){
                if (obj2[k] !== obj1[k]) return false;
            }
            return true;
        }
        return false;
    }
    static downloadJson(fileName: string, content: any) {
        let e = document.createElement('a');
        e.setAttribute('href', 'data:text/plain;charset=utf-8,' +
            encodeURIComponent(JSON.stringify(content)));
        e.setAttribute('download', fileName);
        e.style.display = 'none';
        document.body.appendChild(e);
        e.click();
        document.body.removeChild(e);
    }
    static downloadText(fileName: string, content: string) {
        let e = document.createElement('a');
        e.setAttribute('href', 'data:text/plain;charset=utf-8,' +
            encodeURIComponent(content));
        e.setAttribute('download', fileName);
        e.style.display = 'none';
        document.body.appendChild(e);
        e.click();
        document.body.removeChild(e);
    }
    static downloadBlob(blob: Blob, filename: string){
        var blobUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        try {
            a.href = blobUrl;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
        }finally {
            document.body.removeChild(a);
        }

    }
    static downloadFile(url: string, filename: string = null){
        const a = document.createElement('a');
        try {
            a.href = url;
            if (filename != null) a.download = filename;
                else a.download = url.split('/').pop();
            document.body.appendChild(a);
            a.click();
        }finally {
            document.body.removeChild(a);
        }
    }
    static randomStr(): string{
        return Math.round(Math.random() * 1000000000).toString();
    }
    static deepCloneOverride(obj1: any, obj2: any): any{
        if (obj2 == null) return cloneDeep(obj1);
        if (obj1 == null) return cloneDeep(obj2);

        if (isArray(obj1) && isArray(obj2)){
            for(let i = 0; i < obj1.length; i++){
                if (i < obj2.length){
                    obj1[i] = Utils.deepCloneOverride(obj1[i], obj2[i]);
                }else{
                    obj1[i] = cloneDeep(obj1[i]);
                }
            }
            for(let i = obj1.length; i < obj2.length; i++){
                obj1[i] = cloneDeep(obj2[i]);
            }
            return cloneDeep(obj2);
        }else if (isPlainObject(obj1) && isPlainObject(obj2)) {
            let res: any = {};
            for (let key in obj1) {
                res[key] = Utils.deepCloneOverride(obj1[key], obj2[key]);
            }
            for (let key in obj2) {
                if (obj1[key] == null){
                    res[key] = cloneDeep(obj2[key]);
                }
            }
            return res;
        }else{
            return obj2;
        }
    }

    static quantile(arr : Array<number> | Float32Array, p : number, opts : {sorted?: boolean, method?: string} = {}) {
        if (! (Array.isArray(arr) || ArrayBuffer.isView(arr) && !(arr instanceof DataView))) {
            throw new TypeError( 'quantile()::invalid input argument. First argument must be an array.' );
        }
        if ( typeof p !== 'number' || p !== p ) {
            throw new TypeError( 'quantile()::invalid input argument. Quantile probability must be numeric.' );
        }
        if ( p < 0 || p > 1 ) {
            throw new TypeError( 'quantile()::invalid input argument. Quantile probability must be on the interval [0,1].' );
        }
        let len = arr.length;
    
        if ( !opts.sorted ) {
            arr = arr.slice().sort((a, b) => a - b);
        }
    
        // [0] 0th percentile is the minimum value...
        if ( p === 0.0 ) {
            return arr[ 0 ];
        }
        // [1] 100th percentile is the maximum value...
        if ( p === 1.0 ) {
            return arr[ len-1 ];
        }
        // Calculate the vector index marking the quantile:
        let id = ( len*p ) - 1;
    
        // [2] Is the index an integer?
        if ( id === Math.floor( id ) ) {
            // Value is the average between the value at id and id+1:
            return ( arr[ id ] + arr[ id+1 ] ) / 2.0;
        }
        // [3] Round up to the next index:
        id = Math.ceil( id );
        return arr[ id ];
    }

    static convertToRGB(hex: string) {
        var color = [];
        color[0] = parseInt((hex).substring(1, 3), 16);
        color[1] = parseInt((hex).substring(3, 5), 16);
        color[2] = parseInt((hex).substring(5, 7), 16);
        return color;
    }
    static insertReactAfterKey(insertElem: ReactElement, arr: ReactElement[], afterNames: string[]){
        if (insertElem.key == null) throw "key not found";
        if (arr.find(a => a.key == insertElem.key)) return;
        let ok = false;
        if (afterNames != null && afterNames.length > 0){
            for(let i = 0; i < afterNames.length; i++){
                let name = afterNames[i];
                let index = arr.findIndex((v)=> v.key == name);
                if (index >= 0){
                    Utils.arrayInsert(arr, index + 1, insertElem);
                    ok = true;
                    return;
                }
            }
        }
        if (!ok) arr.push(insertElem);
    }
   
    static bytesToPng(byteArray: Int16Array, height: number, width: number): { png: string, colors: IRGBA[], minHeight: number, maxHeight: number } {
        let canvas = document.createElement('canvas');
        let context = canvas.getContext('2d');
        canvas.height = height || 1;
        canvas.width = width || 1;
    
        let imageData = context.createImageData(width || 1, height || 1);
        let data = imageData.data;
    
        let minHeight = Number.MAX_SAFE_INTEGER;
        let maxHeight = Number.MIN_SAFE_INTEGER;
       
        for (let i = 0; i < width * height; i++) {
            const heightValue = byteArray[i];
            if (heightValue > maxHeight) maxHeight = heightValue;
            if (heightValue < minHeight) minHeight = heightValue;
        }
        if (minHeight < -100) {
            minHeight = -100;
        }
        let range = maxHeight - minHeight;
        let interval = range / 5;
    
        const colors: IRGBA[] = [
            { r: 48, g: 18, b: 59, a: 1 },
            { r: 70, g: 130, b: 248, a: 1 },
            { r: 25, g: 226, b: 187, a: 1 },
            { r: 159, g: 253, b: 63, a: 1 },
            { r: 250, g: 186, b: 57, a: 1 },
            { r: 232, g: 75, b: 12, a: 1 },
            { r: 122, g: 4, b: 3, a: 1 }
        ];
    
        function interpolateColor(val: number, min: number, max: number, startColor: IRGBA, endColor: IRGBA): IRGBA {
            let ratio = (val - min) / (max - min);
            let r = Math.round(startColor.r + ratio * (endColor.r - startColor.r));
            let g = Math.round(startColor.g + ratio * (endColor.g - startColor.g));
            let b = Math.round(startColor.b + ratio * (endColor.b - startColor.b));
            let a = 255; //  alpha channel 
            return { r, g, b, a };
        }
    
        for (let i = 0; i < width * height; i++) {
            const heightValue = byteArray[i];
            let color = { r: 0, g: 0, b: 0, a: 255 };
    
            for (let j = 0; j < colors.length - 1; j++) {
                let minRange = minHeight + interval * j;
                let maxRange = minHeight + interval * (j + 1);
                if (heightValue >= minRange && heightValue <= maxRange) {
                    color = interpolateColor(heightValue, minRange, maxRange, colors[j], colors[j + 1]);
                    break;
                }
            }
            data[i * 4] = color.r;
            data[i * 4 + 1] = color.g;
            data[i * 4 + 2] = color.b;
            data[i * 4 + 3] = color.a;
        }
        context.putImageData(imageData, 0, 0);
        return { png: canvas.toDataURL('image/png'), colors, minHeight, maxHeight };
    }
}
let previousUrl: string = "";
let latestPng: string | null = null;
let latestCoordinates: Array<Array<number>> | null = null;
let currentRequestId: number = 0;

export const addBinaryLayer = action(async (map: mapboxgl.Map,callback?: (minHeight: number, maxHeight: number) => void) => {
    const urlTemplate = "/api/raster/dem?format=binary&extent={%22z%22:{z},%22x_min%22:{w},%22y_min%22:{s},%22x_max%22:{e},%22y_max%22:{n}}";
    const bbox = map.getBounds();
    const z = map.getZoom() + 0.5;
    const w = bbox.getWest();
    const e = bbox.getEast();
    const n = bbox.getNorth();
    const s = bbox.getSouth();

    const url = urlTemplate
        .replace("{w}", w.toString())
        .replace("{e}", e.toString())
        .replace("{n}", n.toString())
        .replace("{s}", s.toString())
        .replace("{z}", z.toString());

    if (url === previousUrl && latestPng && latestCoordinates && map.getSource("binaryLayer")) {
        return;
    }

    previousUrl = url;
    currentRequestId++;
    const requestId = currentRequestId;

    try {
        const response = await fetch(url);
        if (requestId !== currentRequestId) {
            return;
        }

        const arrayBuffer = await response.arrayBuffer();
        const byteArray = new Int16Array(arrayBuffer.slice(4));
        const gridHeight = new Int16Array(arrayBuffer.slice(0, 2))[0];
        const gridWidth = new Int16Array(arrayBuffer.slice(2, 4))[0];
        const { png, minHeight, maxHeight } = Utils.bytesToPng(byteArray, gridHeight, gridWidth);

        latestPng = png;
        latestCoordinates = [
            [w, n],
            [e, n],
            [e, s],
            [w, s]
        ];
        callback(minHeight, maxHeight);
        if (requestId === currentRequestId) {
            const sourceId = "binaryLayer";

            if (map.getSource(sourceId)) {
                (map.getSource(sourceId) as mapboxgl.ImageSource).updateImage({
                    url: latestPng,
                    coordinates: latestCoordinates
                });
            } else {
                map.addSource(sourceId, {
                    type: "image",
                    url: latestPng,
                    coordinates: latestCoordinates
                });

                const layers = map.getStyle().layers;
                let beforeId = null;
                if (layers && layers.length > 0) {
                    beforeId = layers[0].id;
                }

                map.addLayer({
                    id: sourceId,
                    source: sourceId,
                    type: "raster"
                }, beforeId);
            }
        }
    } catch (error) {
        console.log("Error adding binary layer:", error);
    }
});
export function manyCheck(...funcs: (()=>any)[]):boolean{
    let r: boolean = true;
    for(let i = 0; i < funcs.length; i++){
        if (!funcs[i]()){
            r = false;
            break;
        }
    }
    return r;
}

function escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function replaceAll(str: string, find: string, replace: string): string {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}