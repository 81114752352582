import {CustomTool} from "../general/ContainerTools";
import {MapComp} from "../../../components/panels/Map/MapComp";
import {IndexAreaType} from "../../indeByPoint/IndexByPointGraphPointStore";
import {GeoJSONSource} from "maplibre-gl";
import {IndexByPointerStore} from "../../indeByPoint/IndexByPointerStore";
import {autorun} from "mobx";
import {IReactionDisposer} from "mobx/lib/internal";
import {ConstantsStore} from "../../config/ConstantsStore";

export class PointIndexLayersTool extends CustomTool{

    indexByPointerStore: IndexByPointerStore;

    static readonly POINT_INDEX_SOURCE_ID : string = ConstantsStore.COMMON_PREFIX+'point_index_src';
    static readonly POINT_INDEX_LAYER_ID: string = ConstantsStore.COMMON_PREFIX+'point_index_layer';
    static readonly POINT_INDEX2_LAYER_ID: string = ConstantsStore.COMMON_PREFIX+'point_index2_layer';

    onSubscription(): IReactionDisposer[] {
        return [autorun(() => {
            this.graphToMapPoints();
        })];
    }

    graphToMapPoints(): void{
        let indexByPointerStore = this.indexByPointerStore;
        let fc: GeoJSON.FeatureCollection = {'type': 'FeatureCollection', 'features': []};
        indexByPointerStore.points.filter(a => a.areaType == IndexAreaType.point).forEach((a, index) => {
            let f: GeoJSON.Feature = {
                type: "Feature",
                id: index.toString(),
                geometry: {type: "Point", coordinates: [a.point.lng, a.point.lat]},
                properties: {
                    label: a.num.toString()
                }
            };
            fc.features.push(f);
        });
        (this.map.getSource(PointIndexLayersTool.POINT_INDEX_SOURCE_ID) as GeoJSONSource).setData(fc);
    }


    onInstall() {
        let map = this.map;
        if (map.getSource(PointIndexLayersTool.POINT_INDEX_SOURCE_ID) == null) {
            map.addSource(PointIndexLayersTool.POINT_INDEX_SOURCE_ID, {'type': 'geojson', data: MapComp.EMPTY_SOURCE});
        }

        if (map.getLayer(PointIndexLayersTool.POINT_INDEX_LAYER_ID) == null) {
            map.addLayer({
                'id': PointIndexLayersTool.POINT_INDEX_LAYER_ID,
                'type': 'circle',
                'source': PointIndexLayersTool.POINT_INDEX_SOURCE_ID,
                'paint': {
                    'circle-radius': 7,
                    'circle-color': '#262B32',// ['get', 'color'],
                    'circle-stroke-width': 2,
                    'circle-stroke-color': '#FFFFFF',
                }
            });
        }

        if (map.getLayer(PointIndexLayersTool.POINT_INDEX2_LAYER_ID) == null) {
            map.addLayer({
                'id': PointIndexLayersTool.POINT_INDEX2_LAYER_ID,
                'type': 'symbol',
                'source': PointIndexLayersTool.POINT_INDEX_SOURCE_ID,
                layout: {
                    'text-field': ['get', 'label'],
                    'text-anchor': 'center',
                    'text-justify': 'center',
                    "text-size": 10,
                    "text-font": [
                        "Open Sans Bold"
                    ],
                    //"text-font": ["Open Sans ExtraBold","Open Sans", 'Open Sans Semibold', 'Arial Unicode MS Bold'],
                    'text-radial-offset': 0.5,
                },
                paint: {
                    "text-color": "#FFF",
                }
            });
        }
    }

    onUninstall() {
        let map = this.map;
        if (map.getLayer(PointIndexLayersTool.POINT_INDEX2_LAYER_ID) != null) this.removeLayer(PointIndexLayersTool.POINT_INDEX2_LAYER_ID);
        if (map.getLayer(PointIndexLayersTool.POINT_INDEX_LAYER_ID) != null) this.removeLayer(PointIndexLayersTool.POINT_INDEX_LAYER_ID);
        if (map.getSource(PointIndexLayersTool.POINT_INDEX_SOURCE_ID) != null) map.removeSource(PointIndexLayersTool.POINT_INDEX_SOURCE_ID);
    }
}