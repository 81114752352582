import {CustomStore, ObservableCustomStore} from "../../app/store/CustomStore";
import {IReactionDisposer} from "mobx/lib/internal";
import {action, computed, observable} from "mobx";
import {save, update} from "../../app/store/PermalinkDecor";
import {IntervalStore} from "../../app/store/IntervalStore";
import {PresetColors, PresetColorValue} from '../../app/store/productSetting/IndexBandProductStore';
import {ColorHelper} from "../../app/helper/utils/ColorHelper";
import mapboxgl, {LngLat} from "maplibre-gl";
import {Utils} from "../../app/helper/utils/Utils";
import {IValueByMouse} from "../../app/components/panels/Map/NdviValueByMoudeComp";
import {fetchJsonGet} from "../../app/helper/utils/FetchUtils";
import {Lang} from "../../pluginApi/store/Lang";
import {MeteoTranslate_en} from "./translate/MeteoTranslate_en";
import {MeteoTranslate_ru} from "./translate/MeteoTranslate_ru";
import {ra} from "../../app/helper/utils/mobxUtils";
import {IRangeValues} from '../../app/components/Common/DropDownRangeSelector/DropDownRangeSelector';
import { ILegendColorDescription } from "./Meteo/ClassLegendComp";

interface IMeteoItem {
    code: string;
    name: string;
}

export interface IMeteoParam extends IMeteoItem {}

export interface IMeteoFunction extends IMeteoItem {}

export interface IDraughtType extends IMeteoItem {}

export interface IMeteoFilter extends IMeteoItem {}

export interface IMeteoQuantile {
    val: number;
    name: string;
}

export interface ICalcStats {
    min: number;
    max: number;
    unit: string;
}

export interface IDeviation {
    min: number;
    max: number;
    ranges: number[];
    autoChange: boolean;
}

export enum ViewMode {
    Grid = 1,
    District
}

export enum PeriodMode {
    Days10, Days30, Custom
}

export class MeteoStore extends ObservableCustomStore {
    constructor(parent: CustomStore) {
        super(parent);
    }

    @observable
    trans: MeteoTranslate_en = new MeteoTranslate_en();
    calculateTranslate(){
        if (this.root.lang == Lang.ru) this.trans = new MeteoTranslate_ru();
        else this.trans = new MeteoTranslate_en();
    }

    public static readonly PARAMS : IMeteoParam[] = [
        {code: 'temp', name: "Active temperature, °C"},
        {code: 'prec', name: "Precipitation, mm"},
        {code: 'gtk', name: "Selyaninov hydrothermal coefficient"},
        {code: 'drought', name: "Atmospheric/soil drought"}
    ];

    public isTempOrPrecip() {
        return ["temp", "prec"].indexOf(this.currentMeteoParam.code) >= 0;
    }

    public isGtk() {
        return this.currentMeteoParam.code == "gtk";
    }

    public isDrought() {
        return this.currentMeteoParam.code == "drought";
    }

    public static readonly FUNCTIONS : IMeteoFunction[] = [
        {code: 'value', name: "Sum"},
        {code: 'norm', name: "Multiyear mean of sum"},
        //{code: 'minus', name: "Difference with multiyear mean"},
        {code: 'div', name: "Ratio to multiyear mean (in percentages)"},
        //{code: 'minus_div', name: "Relative difference with the climatic norm for the period"},
    ];

    public static readonly FILTERS: IMeteoFilter[] = [
//        {code: 'none', name: "No filter"},
        {code: 'gt', name: '>'},
        {code: 'ge', name: '≥'},
        {code: 'le', name: '≤'},
        {code: 'lt', name: '<'},
        {code: 'eq', name: '='},
        {code: 'ne', name: '≠'},
    ];

    public static readonly QUANTILES: IMeteoQuantile[] = [
        {val: 0, name: '0.0 - 1.0'},
        {val: 1, name: '0.01 - 0.99'},
        {val: 5, name: '0.05 - 0.95'},
        {val: 10, name: '0.1 - 0.9'},
    ];

    // blue to red
    public static readonly TEMP_COLORS: string[] = [
        "#7B47C6",
        "#1A9EFF",
        "#78FF7D",
        "#F2AD5D",
        "#D7191C"
    ];

    public static readonly PREC_COLORS: string[] = [
        "#D7191C",
        "#FFAFAF",
        "#D4EDFF",
        "#1A9EFF",
        "#003C68"
    ];

    public static readonly HUMIDIFICATION_ZONES: ILegendColorDescription[] = [
        {
            min: 1.5,
            max: NaN,
            color: "#003C68",
            description: "Избыточное увлаж."
        },
        {
            min: 0.9,
            max: 1.5,
            color: "#1A9EFF",
            description: "Достаточное увлаж."
        },
        {
            min: 0.7,
            max: 0.9,
            color: "#D4EDFF",
            description: "Недостаточное увлаж."
        },
        {
            min: 0.5,
            max: 0.7,
            color: "#FFAFAF",
            description: "Засушливая"
        },
        {
            min: NaN,
            max: 0.5,
            color: "#D7191C",
            description: "Сухая"
        }
    ]

    public static readonly DIV_PERCENTS: number[] = [0, 30, 50, 150, 200, 250];

    public static readonly METEO_DAYS_GAP: number = 1;

    public static readonly DRAUGHT_TYPES : IDraughtType[] = [
        {code: 'day', name: "Draught on date"},
        {code: 'season', name: "Draught days per season"},
    ];


    class(): string {return "MeteoStore";}

    @save @observable
    active: boolean = false;

    @save @observable
    checked: boolean;

    @save @observable
    currentMeteoParam: IMeteoParam = MeteoStore.PARAMS[1];

    @save @observable
    currentFunction: IMeteoFunction = MeteoStore.FUNCTIONS[2];

    lastParamSettings: any = {
        'temp': {
            filterChecked : true,
            filter : MeteoStore.FILTERS[0],
            filterValue : 10
        },
        'prec': {
            filterChecked : false,
            filter : MeteoStore.FILTERS[0],
            filterValue : null
        }
    }

    @save @observable
    filterChecked: boolean = this.lastParamSettings[this.currentMeteoParam.code].filterChecked;

    @save @observable
    currentFilter: IMeteoFilter = this.lastParamSettings[this.currentMeteoParam.code].filter;

    @save @observable
    currentFilterValue: number = this.lastParamSettings[this.currentMeteoParam.code].filterValue;

    @save @observable
    currentFilterTextValue: string = this.lastParamSettings[this.currentMeteoParam.code].filterValue?.toString();

    @save @observable
    currentQuantile: IMeteoQuantile = MeteoStore.QUANTILES[1];

    @save @observable
    somethingChanged: boolean = true;

    @observable
    deviation: IDeviation = null;

    //@save @observable
    // currentPreset: string = MeteoStore.RGB;
    //currentColors: string[] = this.colors;

    @save @observable
    currentDraughtType: IDraughtType = MeteoStore.DRAUGHT_TYPES[0];

    @save @observable
    droughtCalendarOpened: boolean = false;

    @save @observable
    droughtDate: Date = null;

    @save @observable
    analysCalendarOpened: boolean = false;

    @save @observable
    analysDate: Date = new Date((new Date()).setDate(new Date().getDate() - 1));

    @save @observable
    currentRange: IRangeValues = {
        value1: {code: 0},
        value2: {code: 11}
    };

    stats: ICalcStats = null;

    gridSource : {
        data : Float32Array;
        sortedData : Float32Array;
        width : number;
        height : number;
    } = null;

    onlineChange: boolean = false;

    @save
    currentParams: any = null;

    @observable
    visibleWidget: boolean = false;

    @observable
    mouseValue: IValueByMouse = null;

    @save @observable
    validInterval: boolean = false;

    @save
    intervalWarning: string = "";

    @save @observable
    intervalEdited: boolean = true;

    @save @observable
    viewMode: ViewMode = ViewMode.Grid;

    @update @observable
    dateInterval: IntervalStore = new IntervalStore(this);

    @save @observable
    legendMinimized: boolean = false;

    @save @observable
    periodMode: PeriodMode = PeriodMode.Days30;

    @observable @save
    showData: boolean = true;

    subscription(): IReactionDisposer[] {
        return [];
    }

    @computed
    get colors(): string[] {
        return this.currentMeteoParam.code == MeteoStore.PARAMS[0].code? 
            MeteoStore.TEMP_COLORS : MeteoStore.PREC_COLORS;
    }

    @computed
    get deviationPalette(): {interpolation: string, mode: string, palette: any[]} {
        let pal = {interpolation: "linear", mode: "", palette: [] as any[]};
        let colors = this.colors;
        if (!this.deviation) {
            if (this.currentFunction.code == "div") {
                pal.mode = "value";
                for (let i = 0; i < MeteoStore.DIV_PERCENTS.length - 1; i++)
                    pal.palette.push([(MeteoStore.DIV_PERCENTS[i] + MeteoStore.DIV_PERCENTS[i + 1]) / 2, colors[i]]);
            }
            else {
                pal.mode = "quantile";
                pal.palette.push([0, colors[0]]);
                let step = 100 / colors.length;
                for (let i = 0; i < colors.length; i++) {
                    pal.palette.push([step * (0.5 + i), colors[i]]);
                }
                pal.palette.push([100, colors[colors.length - 1]]);    
            }
        }
        else {
            pal.mode = "value";
            pal.palette.push([this.deviation.min, colors[0]]);
            pal.palette.push([(this.deviation.min + this.deviation.ranges[0]) / 2, colors[0]]);
            for (let i = 0; i < this.deviation.ranges.length - 1; i++) {
                pal.palette.push([(this.deviation.ranges[i] + this.deviation.ranges[i + 1]) / 2, colors[i + 1]]);
            }
            pal.palette.push([(this.deviation.max + this.deviation.ranges[this.deviation.ranges.length - 1]) / 2, colors[colors.length - 1]]);
            pal.palette.push([this.deviation.max, colors[colors.length - 1]]);
        }
        return pal;
    }

    getTempPrecipParams() {
        let date1 = null;
        let date2 = null;
        if (this.currentFunction.code != 'norm') {
            date1 = this.periodMode == PeriodMode.Custom? this.dateInterval.begin:
                Utils.getDateOffset(this.analysDate, this.periodMode == PeriodMode.Days10? -10: -30);
            date2 = this.periodMode == PeriodMode.Custom? this.dateInterval.end: 
                Utils.getDateOffset(this.analysDate, -1);
        } else {
            let month1 = this.currentRange.value1.code;
            let month2 = this.currentRange.value2.code;
            let year1 = 2022; //этот и следующий невисокосные
            let year2 = (month1 <= month2) ? year1 : year1 + 1;
            date1 = new Date(year1, month1, 1);
            date2 = Utils.getDateOffset(new Date(year2, month2 + 1, 1), -1);
        }
        return {
            //year: this.dateInterval.begin.getFullYear(),
            startDate: Utils.getDateStr(date1),
            endDate: Utils.getDateStr(date2),
            valueType: 'mean',
            valFunction: this.currentFunction.code,
            dataType: this.currentMeteoParam.code,
            filterFunc: this.filterChecked? this.currentFilter.code : 'none',
            filterValue: this.currentFilterValue,
            qMin: this.currentQuantile.val,
            qMax: 100 - this.currentQuantile.val,
            vMin: this.currentFunction.code == 'div'? MeteoStore.DIV_PERCENTS[0] : null,
            vMax: this.currentFunction.code == 'div'? MeteoStore.DIV_PERCENTS[MeteoStore.DIV_PERCENTS.length - 1] : null,
            //palette: this.currentPreset,
            //colors: this.colors,
            style: this.deviationPalette,
            bbox: null as String
        }
    }

    getDroughtParams() {
        return {
            date: Utils.getDateStr(this.droughtDate)
        }
    }

    async moveEnd(p: LngLat, sp: mapboxgl.Point) {
        this.moveTimerHandle = null;
        let params = this.currentParams;
        let url = `/api/meteo/point?lon=${p.lng}&lat=${p.lat}`
        + `&year=${params.year}&from_date=${params.startDate}&to_date=${params.endDate}`
        + `&type=${params.dataType}&value=${params.valueType}&function=${params.valFunction}`
        + `&filter=${params.filterFunc}&filter_val=${params.filterValue}`;
        let r = await fetchJsonGet(url);
        ra(()=>{
            this.mouseValue = {left: sp.x, top: sp.y, value: r.value?.toFixed(2), loading: false}
            this.visibleWidget = true;
        });
    }

    moveTimerHandle: any = null;

    @action
    getPixelValue(p: LngLat){
        if (this.moveTimerHandle)
            clearTimeout(this.moveTimerHandle);
        let sp = this.root.map.mapbox.project(p);
        this.moveTimerHandle = setTimeout(() => this.moveEnd(p, sp), 500);
        this.mouseValue = {left: sp.x, top: sp.y, value: null, loading: true}
    }
}