import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../../helper/structs/IStoreProps";
import {A2NavigatorSection} from "../../../../../store/agro/A2AgroStore";
import {action} from "mobx";
import classNames from "classnames";
import {MyHint} from "../../../../Common/MyHint";
import {Utils} from "../../../../../helper/utils/Utils";
import {A2LineButton} from "../A2FieldList/A2LineButton";
import {NavigatorItemStoreType} from "../../../../../store/agro/A2NavigatorItemStore";

@observer
export class A2NavigatorSummaryComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }
    selectedCount(): number{
        let store = this.props.store;
        if (store.agro2.projectStruct.activeNavigatorPage == null) return 0;
        return store.agro2.projectStruct.activeNavigatorPage.selectedFields.length;
    }
    selectedArea(): number{
        let store = this.props.store;
        if (store.agro2.projectStruct.activeNavigatorPage == null) return 0;
        return store.agro2.projectStruct.activeNavigatorPage.selectedFields.reduce((sum, cur)=> {return sum + cur.area;}, 0);
    }

    objectsCount(): number{
        let store = this.props.store;
        if (store.agro2.projectStruct.activeNavigatorPage == null) return 0;
        return store.agro2.projectStruct.activeNavigatorPage.filtered_child_values.reduce((sum, cur)=> {return sum + cur.field_cnt;}, 0);
    }
    objectsArea(): number{
        let store = this.props.store;
        if (store.agro2.projectStruct.activeNavigatorPage == null) return 0;
        return store.agro2.projectStruct.activeNavigatorPage.filtered_child_values.reduce((sum, cur)=> {return sum + cur.area_cnt;}, 0);
    }

    @action
    onClickFilterMapByNavigatorPath(){
        this.props.store.agro2.filterMapShowOnlyCurrentNavigatorPath = !this.props.store.agro2.filterMapShowOnlyCurrentNavigatorPath;
    }

    getIconMapFilter(): any{
        let store = this.props.store;
        let hintText = store.trans["Show only current objects on map"];
        let r = this.props.store.agro2.filterMapShowOnlyCurrentNavigatorPath;
        if (r) hintText = store.trans["Show all objects on map"];

        return <MyHint text={hintText} placement="right">
            <div className={classNames("A2NavigatorSummaryComp-objectMapFilter gray-background-onhover",{
                "blue-svg": r,
                "white-svg": !r
            })} style={{borderRadius: "5px"}} onClick={this.onClickFilterMapByNavigatorPath}>
                <div className="flex-full-center">
                <svg width="26" height="16" viewBox="0 0 26 16" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" stroke="none" clipRule="evenodd" d="M10.9977 0.498904C10.9977 0.332992 11.08 0.177885 11.2174 0.0848701C11.3548 -0.00814488 11.5294 -0.0269528 11.6834 0.0346653L16.1655 1.82749L20.6475 0.0346653C20.7667 -0.0130168 20.8997 -0.0130168 21.0189 0.0346653L25.6867 1.90176C25.8765 1.9777 26.001 2.16155 26.001 2.366V14.5021C26.001 14.6681 25.9187 14.8232 25.7813 14.9162C25.6439 15.0092 25.4693 15.028 25.3153 14.9664L20.8332 13.1736L16.3512 14.9664C16.232 15.0141 16.099 15.0141 15.9798 14.9664L11.312 13.0993C11.1222 13.0233 10.9977 12.8395 10.9977 12.635V10.3011H11.9977V12.2965L16.1655 13.9636L20.6475 12.1708C20.7667 12.1231 20.8997 12.1231 21.0189 12.1708L25.001 13.7636V2.70452L20.8332 1.03742L16.3512 2.83024C16.232 2.87792 16.099 2.87792 15.9798 2.83024L11.9977 1.23742V3.7663H10.9977V0.498904Z" />
                    <path fill="none" d="M17.5 7.5L4.35465 7.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path fill="none" d="M4.35456 1.5L6.29004 1.5L6.29004 13.5L4.35455 13.5" strokeLinecap="round" strokeLinejoin="round"/>

                    <path fill="none" d="M1.45122 14.5C0.940985 14.5 0.499606 14.0679 0.499606 13.5C0.499606 12.9321 0.940985 12.5 1.45122 12.5C1.96145 12.5 2.40283 12.9321 2.40283 13.5C2.40283 14.0679 1.96145 14.5 1.45122 14.5Z" />
                    <path fill="none" d="M1.45122 8.5C0.940985 8.5 0.499606 8.06787 0.499606 7.5C0.499606 6.93213 0.940985 6.5 1.45122 6.5C1.96145 6.5 2.40283 6.93213 2.40283 7.5C2.40283 8.06787 1.96145 8.5 1.45122 8.5Z" />
                    <path fill="none" d="M1.45122 2.5C0.940985 2.5 0.499606 2.06787 0.499606 1.5C0.499606 0.932135 0.940985 0.5 1.45122 0.5C1.96145 0.5 2.40283 0.932135 2.40283 1.5C2.40283 2.06787 1.96145 2.5 1.45122 2.5Z" />

                    <path stroke="none" d="M15.0232 5.23059C14.829 5.03428 14.829 4.71601 15.0232 4.5197L15.1856 4.35557C15.3798 4.15926 15.6947 4.15926 15.8889 4.35557L18.6484 7.14475C18.8426 7.34106 18.8426 7.65933 18.6484 7.85564L18.486 8.01977C18.2918 8.21608 17.9769 8.21608 17.7827 8.01977L15.0232 5.23059Z" />
                    <path stroke="none" d="M15.0233 9.76969C14.8291 9.96599 14.8291 10.2843 15.0233 10.4806L15.1857 10.6447C15.3799 10.841 15.6948 10.841 15.889 10.6447L18.6484 7.85552C18.8427 7.65921 18.8427 7.34094 18.6484 7.14463L18.4861 6.9805C18.2918 6.7842 17.977 6.7842 17.7827 6.9805L15.0233 9.76969Z" />
                </svg>
                </div>
            </div></MyHint>;
    }
    @action
    onClickSort(){
        let store = this.props.store;
        let ap = store.agro2.projectStruct.activeNavigatorPage;
        ap.sort_order = !ap.sort_order;
    }
    @action
    onClickClearFilter(){
        let store = this.props.store;
        let activePage = store.agro2.projectStruct.activeNavigatorPage;
        activePage.filter_string = "";
    }
    onChangeFilter(e: any){
        let store = this.props.store;
        let txt = e.currentTarget.value;
        let activePage = store.agro2.projectStruct.activeNavigatorPage;
        activePage.filter_string = txt;
    }

    render() {
        let store = this.props.store;
        let agro = store.agro2;
        let fields = 0;
        let area = 0;
        let columnName = "";
        if (agro.navigatorSection == A2NavigatorSection.projects) return null;
        if (agro.navigatorSection == A2NavigatorSection.folder) {
            let item = agro.projectStruct.activeNavigatorPage;
            if (item != null) {
                if (item.columnInfo != null) {
                    columnName = item.columnInfo.title;
                }
                let t = item.getSummary();
                area = t.area;
                fields = t.fields;
            }
        }
        let showFilterButton = (agro.projectStruct.navigatorRootItem != null);
        let activePage = agro.projectStruct.activeNavigatorPage;
        let foundStr = Utils.isStringNotEmpty(activePage.filter_string)? store.trans.Found : store.trans.ObjectsFields;

        return <div className="A2NavigatorSummaryComp-root">
            <div className="A2NavigatorSummaryComp-main">
                <div className="flex-columns-centerByVertical width100">
                    {activePage.type == NavigatorItemStoreType.fields &&
                        <React.Fragment>
                            <div className="A2NavigatorSummaryComp-total nowrap">{foundStr}:</div>
                            <div className="A2NavigatorSummaryComp-fields nowrap">
                                {fields} {store.trans.pcs} / {Utils.roundDigitsWithSpace(area, 2)} {store.trans.ha}
                            </div>
                        </React.Fragment>}
                    {activePage.type == NavigatorItemStoreType.folder &&
                        <React.Fragment>
                            <div className="A2NavigatorSummaryComp-total nowrap">{columnName}:</div>
                            <div className="A2NavigatorSummaryComp-fields nowrap">
                                {activePage != null && activePage.filtered_child_values.length} {store.trans["pcs"]}
                            </div>


                            <div className="flex-stretch-item" />
                            <div style={{justifyContent: "flex-end"}}
                                 className="flex-columns-centerByVertical flex-stretch-item text-prev">
                                <div className="A2NavigatorSummaryComp-total nowrap">{foundStr}:</div>
                                <div className="A2NavigatorSummaryComp-fields nowrap">
                                    {this.objectsCount()} / {Utils.roundDigitsWithSpace(this.objectsArea(), 2)} {store.trans.ha}
                                </div>

                            </div>
                        </React.Fragment>
                    }
                </div>
                {activePage.type == NavigatorItemStoreType.fields && this.selectedCount() > 0 &&
                    <div style={{justifyContent: "flex-end"}}
                         className="flex-columns-centerByVertical flex-stretch-item text-prev">
                    <div className="A2NavigatorSummaryComp-total nowrap">{store.trans.Selected}:</div>
                    <div className="A2NavigatorSummaryComp-fields nowrap">
                        {this.selectedCount()} / {Utils.roundDigitsWithSpace(this.selectedArea(),2)} {store.trans.ha}
                    </div>
                </div>}

            </div>
            <div className="A2NavigatorSummaryComp-filterDiv">
                <div className="A2NavigatorSummaryComp-filterInputBox">
                    <input type="text" style={{margin: "", height: "30px"}}
                           className={classNames("text-box-editor A2NavigatorSummaryComp-filterInput")}
                           value={activePage.filter_string}
                           onChange={this.onChangeFilter}
                           placeholder={store.trans.Search}/>
                    {activePage.filter_string != "" &&
                        <div className="AgroFarmList-filterInputBoxClear" onClick={this.onClickClearFilter}>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.1875 0.8125C8.9375 0.5625 8.5625 0.5625 8.3125 0.8125L5 4.125L1.6875 0.8125C1.4375 0.5625 1.0625 0.5625 0.8125 0.8125C0.5625 1.0625 0.5625 1.4375 0.8125 1.6875L4.125 5L0.8125 8.3125C0.5625 8.5625 0.5625 8.9375 0.8125 9.1875C0.9375 9.3125 1.0625 9.375 1.25 9.375C1.4375 9.375 1.5625 9.3125 1.6875 9.1875L5 5.875L8.3125 9.1875C8.4375 9.3125 8.625 9.375 8.75 9.375C8.875 9.375 9.0625 9.3125 9.1875 9.1875C9.4375 8.9375 9.4375 8.5625 9.1875 8.3125L5.875 5L9.1875 1.6875C9.4375 1.4375 9.4375 1.0625 9.1875 0.8125Z"
                                    fill="#C5C5C5"/>
                            </svg>
                        </div>}
                </div>
                <div className="flex-center-byPerp flex-nowrap ">
                    {activePage != null && activePage.type == NavigatorItemStoreType.folder &&
                        <div onClick={this.onClickSort}
                             className="full-center-content white-fill  pointer noselect white-stroke gray-background-onhover A2NavigatorSummaryComp-buttons">
                        {!activePage.sort_order &&
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_2645_23389)">
                                    <path d="M0.5 6.5L2.75 0.5H3.25L5.5 6.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M1.25 4.5H4.75" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M0.5 9.5H5.5L0.5 15.5H5.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M11.5 15.5V0.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M15.5 4.5L11.5 0.5L7.5 4.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_2645_23389">
                                        <rect width="16" height="16" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        }
                        {activePage.sort_order &&

                            <svg width="16" height="16" fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.5 6.5L2.75 0.5H3.25L5.5 6.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M1.25 4.5H4.75" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M0.5 9.5H5.5L0.5 15.5H5.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M11.5 0.5V15.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M7.5 11.5L11.5 15.5L15.5 11.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        }
                    </div>}
                    {showFilterButton && this.getIconMapFilter()}
                </div>

            </div>
        </div>;
    }
}
