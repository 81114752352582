import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoEditRoutePanelComp.scss'
import classNames from "classnames";
import {observable} from "mobx";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AhoIndicatorFormat} from "../../agroAhoCardStore";
import {AgroAhoZoomSvg} from "../icons/AgroAhoZoomSvg";

@observer
export class AgroAhoEditRoutePanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    onCancelClick(e: any){
        let rstore = this.props.store.agroAhoStore.mapStore.mapEditRouteStore;
        rstore.doDisableModeClick();
        rstore.doSelectModeClick();
    }

    onSaveClick(e: any){
        let canSave = this.props.store.agroAhoStore.mapStore.mapEditRouteStore.canSave;
        if (!canSave) return;
        // this.props.store.agroAhoStore.editorStore.doSaveClick();
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let canSave = store_.mapStore.mapEditRouteStore.canSave;
        // let field_name = store_.editorStore.fieldInfo?.[0]?.field_name;

        return <div className="AgroAhoEditRoutePanelComp-main">
            <div className="AgroAhoEditRoutePanelComp-window">
                <div>{store_.trans2["Set the detour order"]}</div>
                <div className="AgroAhoEditRoutePanelComp-splitter"> </div>
                <div className="AgroAhoEditRoutePanelComp-cancelButton button-jump" onClick={this.onCancelClick}>
                    {store_.trans.Cancel}</div>
                <div className={classNames("AgroAhoEditRoutePanelComp-saveButton", {
                    "AgroAhoEditRoutePanelComp-disabled": !canSave,
                    "button-jump": canSave
                })} onClick={this.onSaveClick}>
                    {store_.trans.Save}</div>
            </div>
        </div>
    }
}
