import { observable } from "mobx";
import { CustomStore, ObservableCustomStore } from "../../../../store/CustomStore";
import { save } from "../../../../store/PermalinkDecor";
import { IOverlayConfig, IOverlayGroup, IParameterizedOverlay } from "../../../../store/config/ConfigStore";
import { AnyLayer, CirclePaint, FillPaint, Layer, LinePaint, SymbolLayout, Visibility } from "maplibre-gl";
import { OverlayObjectStore, TemporalGroupStore } from "../../../../store/OverlayObjectStore";
import { IStoreProps } from "../../../../helper/structs/IStoreProps";

export enum EStyleType {INVALID, CIRCLE, LINE, FILL}

export enum BorderStyle {Solid, DashDash};


export class OverlaySimpleStyle {
    public static DEFAULT_COLOR = "#000000";

    constructor (style: OverlaySimpleStyle) {
        if (! style) return;
        this.circlePaint = style.circlePaint;
        this.linePaint = style.linePaint;
        this.fillPaint = style.fillPaint;
        this.circleLayerIndex = style.circleLayerIndex;
        this.lineLayerIndex = style.lineLayerIndex;
        this.fillLayerIndex = style.fillLayerIndex;
        this.borderVisibility = style.borderVisibility;
        this.fillVisibility = style.fillVisibility;
    }

    circleLayerIndex : number = null;
    circlePaint : CirclePaint = null;
    lineLayerIndex : number;
    linePaint : LinePaint = null;
    borderVisibility: Visibility = null;
    fillLayerIndex : number = null;
    fillPaint : FillPaint = null;
    fillVisibility: Visibility = null;
    
    public get type() : EStyleType {
        if (this.circlePaint)
            return EStyleType.CIRCLE;
        if (this.linePaint && (! this.fillPaint || (this.fillPaint["fill-opacity"] ?? 1) == 0))
            return EStyleType.LINE;
        if (this.fillPaint) {
            // let fillColor = this.fillPaint["fill-color"];
            // let borderColor = this.linePaint?.["line-color"] || this.fillPaint["fill-outline-color"] || OverlaySimpleStyle.DEFAULT_COLOR;
            return EStyleType.FILL;
        }
        return EStyleType.INVALID;
    }
}

export class StylePaintProperties {
    fillColor: string = OverlaySimpleStyle.DEFAULT_COLOR;
    fillOpacity: number = 1;
    fillVisibility: Visibility = 'visible';
    circleRadius: number = 4;
    borderColor: string = OverlaySimpleStyle.DEFAULT_COLOR;
    borderOpacity: number = 1;
    borderVisibility: Visibility = 'visible';
    borderWidth: number = 1;
    borderStyle: BorderStyle = BorderStyle.Solid;
    dashedFreq?: number = 2;
    dashedSpace?: number = 2;
}

export interface IActiveOverlayConfig extends IOverlayConfig {
    initialVisibility : boolean;
};

export interface IParameterizedOverlayProps extends IStoreProps{
    overlay: IParameterizedOverlay;
}

export class LayersListStore extends ObservableCustomStore{
    constructor(parent: CustomStore) {
        super(parent);
    }
    class(): string {return "LayersListStore";}

    @save
    overlays: (IOverlayConfig | IOverlayGroup)[] = null;//OverlaysStore;

    //@observable не нужен !
    private _activeOverlay : IActiveOverlayConfig;
    public get activeOverlay() : IActiveOverlayConfig {
        return this._activeOverlay;
    }
    public set activeOverlay(v : IActiveOverlayConfig) {
        if (this._activeOverlay && !v) {//restore visibility at dialog closing
            let st = LayersListStore.getOverlaySimpleStyle(this._activeOverlay);
            if (st.type != EStyleType.CIRCLE)
                this._stylePaintProperties.borderVisibility = this._activeOverlay.initialVisibility &&
                    (st.type == EStyleType.LINE || (st.type == EStyleType.FILL && this.showBorderOption))
                    ? 'visible': 'none';
            this._stylePaintProperties.fillVisibility = this._activeOverlay.initialVisibility? 'visible': 'none';
            this.applySimpleStyle();
        }
        this._activeOverlay = v;
    }
    
    @observable
    private _activeTemporalGroup : TemporalGroupStore;
    public get activeTemporalGroup() : TemporalGroupStore {
        return this._activeTemporalGroup;
    }
    public set activeTemporalGroup(v : TemporalGroupStore) {
        this._activeTemporalGroup = v;
    }
    
    
    @save @observable
    showStyleWindow: boolean = false;

    @save @observable
    showTemporalGroupWindow: boolean = false;

    @save @observable
    showBorderOption: boolean = false;

    @save @observable
    private _stylePaintProperties: StylePaintProperties;
    public get stylePaintProperties() : StylePaintProperties {
        return this._stylePaintProperties;
    }
    public set stylePaintProperties(v: StylePaintProperties) {        
        this._stylePaintProperties = v;
        this.applySimpleStyle();
    }

    @save @observable
    fillPickerOpened: boolean = false;

    @save @observable
    borderPickerOpened: boolean = false;

    public static getOverlaySimpleStyle(overlay: IOverlayConfig) : OverlaySimpleStyle {
        let circlePaint : CirclePaint = null;
        let linePaint : LinePaint = null;
        let fillPaint : FillPaint = null;
        let borderVisibility: Visibility = null;
        let fillVisibility: Visibility = null;
        let [circleLayerIndex, lineLayerIndex, fillLayerIndex] = [-1, -1, -1];
        for (let i = 0; i < overlay.layers.length; i++) {
            let lr: Layer = overlay.layers[i];
            if (lr.type == 'symbol') {
                let layout: SymbolLayout = lr.layout as SymbolLayout;
                if (layout?.["icon-image"]) //captions only
                    return null;
            }
            else if (lr.type == 'circle') {
                let paint: CirclePaint = lr.paint as CirclePaint;
                if (circlePaint || linePaint || fillPaint) //only one layer
                    return null;
                circlePaint = paint;
                circleLayerIndex = i;
                fillVisibility = lr.layout?.visibility || 'visible';
                borderVisibility = ((paint["circle-stroke-opacity"] as number) > 0 && 'visible') || 'none';
            }
            else if (lr.type == 'line') {
                let paint: LinePaint = lr.paint as LinePaint;
                if (linePaint || circlePaint) //only one layer
                    return null;
                linePaint = paint;
                lineLayerIndex = i;
                borderVisibility = lr.layout?.visibility || 'visible';
            }
            else if (lr.type == 'fill') {
                let paint: FillPaint = lr.paint as FillPaint;
                if (fillPaint || circlePaint) //only one layer
                    return null;
                fillPaint = paint;
                fillLayerIndex = i;
                fillVisibility = lr.layout?.visibility || 'visible';
            }
        }
        if (circlePaint) {
            let color = circlePaint["circle-color"] || OverlaySimpleStyle.DEFAULT_COLOR;
            if (typeof color != "string") return null;
            let opacity = circlePaint["circle-opacity"] || 1;
            if (typeof opacity != "number") return null;
            let radius = circlePaint["circle-radius"] || 1;
            if (typeof radius != "number") return null;
            let strokeColor = circlePaint["circle-stroke-color"] || OverlaySimpleStyle.DEFAULT_COLOR;
            if (typeof strokeColor != "string") return null;
            let strokeOpacity = circlePaint["circle-stroke-opacity"] || 1;
            if (typeof strokeOpacity != "number") return null;
            let strokeWidth = circlePaint["circle-stroke-width"] || 1;
            if (typeof strokeWidth != "number") return null;
        }
        else if (linePaint && (! fillPaint || (fillPaint["fill-opacity"] ?? 1) == 0)) {
            let color = linePaint["line-color"] || OverlaySimpleStyle.DEFAULT_COLOR;
            if (typeof color != "string") return null;
            let opacity = linePaint["line-opacity"] || 1;
            if (typeof opacity != "number") return null;
            let width = linePaint["line-width"] || 1;
            if (typeof width != "number") return null;
            let da = linePaint["line-dasharray"] || [2, 2];            
        }
        else if (fillPaint) {
            let fillColor = fillPaint["fill-color"];
            let borderColor = linePaint?.["line-color"] || fillPaint["fill-outline-color"] || OverlaySimpleStyle.DEFAULT_COLOR;
            if (typeof fillColor != "string" || typeof borderColor != "string") return null;
            let opacity = fillPaint["fill-opacity"] || 1;
            if (typeof opacity != "number") return null;
        }

        return new OverlaySimpleStyle({
            circlePaint: circlePaint, 
            linePaint: linePaint,
            borderVisibility : borderVisibility, 
            fillPaint: fillPaint,
            fillVisibility: fillVisibility,
            circleLayerIndex: circleLayerIndex,
            lineLayerIndex: lineLayerIndex,
            fillLayerIndex: fillLayerIndex,
            type: null
        });
    }

    getLayer(overlayId: string, idx: number) {
        let layerId = OverlayObjectStore.getOverlayLayerName(overlayId, idx);
        return this.parent.root.map.mapbox.getLayer(layerId);
    }

    setLayerPaint(lr: AnyLayer, paint: any, visibility: Visibility = 'visible') {
        if (! lr) return;
        let map = this.parent.root.map.mapbox;
        Object.keys(paint).forEach((p: string) => {
            map.setPaintProperty(lr.id, p, paint[p]);
        });
        if (map.getLayoutProperty(lr.id, 'visibility') != visibility)
            map.setLayoutProperty(lr.id, 'visibility', visibility)
    }

    applySimpleStyle() {
        let ov = this.activeOverlay;
        if (! ov) return;
        let oss = LayersListStore.getOverlaySimpleStyle(ov);
        let styleType = oss.type;
        if (styleType == EStyleType.CIRCLE) {
            let paint = ov.layers[oss.circleLayerIndex].paint as CirclePaint;
            paint["circle-color"] = this.stylePaintProperties.fillColor;
            paint["circle-opacity"] = this.stylePaintProperties.fillOpacity;
            paint["circle-radius"] = this.stylePaintProperties.circleRadius;
            paint["circle-stroke-color"] = this.stylePaintProperties.borderColor;
            paint["circle-stroke-opacity"] = this.stylePaintProperties.borderVisibility == 'none'? 0 : 
                this.stylePaintProperties.borderOpacity;
            paint["circle-stroke-width"] = this.stylePaintProperties.borderWidth;
            this.setLayerPaint(this.getLayer(ov.id, oss.circleLayerIndex), paint, this.stylePaintProperties.fillVisibility);
        }
        else if (styleType == EStyleType.LINE) {
            let paint = ov.layers[oss.lineLayerIndex].paint as LinePaint;
            paint["line-color"] = this.stylePaintProperties.borderColor;
            paint["line-opacity"] = this.stylePaintProperties.borderOpacity;
            paint["line-width"] = this.stylePaintProperties.borderWidth;
            paint["line-dasharray"] = this.stylePaintProperties.borderStyle == BorderStyle.Solid? null:
                [this.stylePaintProperties.dashedFreq, this.stylePaintProperties.dashedSpace];
            this.setLayerPaint(this.getLayer(ov.id, oss.lineLayerIndex), paint, this.stylePaintProperties.borderVisibility);
            if (! paint["line-dasharray"])
                delete paint["line-dasharray"];
        }
        else if (styleType == EStyleType.FILL) {
            let fillPaint = ov.layers[oss.fillLayerIndex].paint as FillPaint;
            fillPaint["fill-color"] = this.stylePaintProperties.fillColor;
            fillPaint["fill-opacity"] = this.stylePaintProperties.fillOpacity;            
            this.setLayerPaint(this.getLayer(ov.id, oss.fillLayerIndex), fillPaint, this.stylePaintProperties.fillVisibility);
            if (oss.lineLayerIndex < 0)
                return;
            let lineLayer = ov.layers[oss.lineLayerIndex];
            let borderPaint = lineLayer.paint as LinePaint;
            borderPaint["line-color"] = this.stylePaintProperties.borderColor;
            borderPaint["line-opacity"] = this.stylePaintProperties.borderOpacity;
            borderPaint["line-width"] = this.stylePaintProperties.borderWidth;
            borderPaint["line-dasharray"] = this.stylePaintProperties.borderStyle == BorderStyle.Solid? null:
                [this.stylePaintProperties.dashedFreq, this.stylePaintProperties.dashedSpace];
            lineLayer.layout = {visibility: this.stylePaintProperties.borderVisibility};
            lineLayer.metadata = {...lineLayer.metadata, "class:overlay:border:visibility": this.showBorderOption};
            this.setLayerPaint(this.getLayer(ov.id, oss.lineLayerIndex), borderPaint, this.stylePaintProperties.borderVisibility);
            if (! borderPaint["line-dasharray"])
                delete borderPaint["line-dasharray"];
        }
        else return;
        this.overlays = this.root.config.map_layers.overlays;
    }
}