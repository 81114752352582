import * as React from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {action} from "mobx";
import {satDic, SatEnum} from "../../../../helper/utils/satellliteDic";
import {Utils} from "../../../../helper/utils/Utils";
import autoBindReact from "auto-bind/react";
import {SearchSceneSourceType} from "../../../../store/SearchStore";
import {SearchParamsS5Type} from "../../../../store/SearchParamsS5Store";
import {DateUtils} from "../../../../helper/utils/DateUtils";

@observer
export class SearchSceneFiltersComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    deleteDate(){
        let store = this.props.store;
        store.searchPanel.filterDate.reset();
    }
    @action
    deleteCloud(){
        let store = this.props.store;
        store.searchPanel.filterCloud = null;
    }
    @action
    deleteSunelev(){
        let store = this.props.store;
        store.searchPanel.filterSunelev = null;
    }
    @action
    deleteSource(){
        let store = this.props.store;
        store.searchPanel.filterSource = null;
    }
    @action
    deleteSat(){
        let store = this.props.store;
        store.searchPanel.filterSatellites = {L7E: false, L8: false, L45: false, S2: false};
    }
    @action
    async deleteArea(){
        try {
            await this.props.store.map.searchObject.clearAndSetDefault();
        }catch (e) {
            this.props.store.addError(e);
        }
    }
    @action
    zoomToArea(){
        this.props.store.map.zoomToSearchObject();
    }
    @action
    deleteAll(){
        this.deleteCloud();
        this.deleteSat();
        this.deleteSunelev();
        this.deleteDate();
        this.deleteSource();
    }

    getSentinel1Filters(): any[]{
        let store = this.props.store;
        let childs: any[] = [];
        childs.push(<div className="left-sidebar__right-bottom__row2-left_item" key="satStr">
            <div className="search__left-item">
                <div className="satellite_table svg_filter gray"/>
                <div className="search__left-item_text">{store.trans["Sentinel-1"]}</div>
            </div>
        </div>);
        if (store.searchPanel.searchParamsS1.filterDate.hasValid()) childs.push(<div
            className="left-sidebar__right-bottom__row2-left_item" key="filterDate">
            <div className="search__left-item">
                <div className="calendar_filter gray"/>
                <div className="search__left-item_text">
                    {store.searchPanel.searchParamsS1.filterDate.isValidBegin() && Utils.formatDate(store.searchPanel.searchParamsS1.filterDate.begin)}
                    {" "}-{" "}
                    {store.searchPanel.searchParamsS1.filterDate.isValidEnd() && Utils.formatDate(store.searchPanel.searchParamsS1.filterDate.end)}
                </div>
            </div>
        </div>);

        return childs;
    }

    getSentinel5Filters(): any[]{
        let store = this.props.store;
        let childs: any[] = [];
        childs.push(<div className="left-sidebar__right-bottom__row2-left_item" key="satStr">
            <div className="search__left-item">
                <div className="satellite_table svg_filter gray"/>
                <div className="search__left-item_text">{store.trans["Sentinel-5"]}</div>
            </div>
        </div>);
        if (store.searchPanel.searchParamsS5.type == SearchParamsS5Type.composites){
            if (store.searchPanel.searchParamsS5.compositeDate.hasValid()) childs.push(<div
                className="left-sidebar__right-bottom__row2-left_item" key="filterDate">
                <div className="search__left-item">
                    <div className="calendar_filter gray"/>
                    <div className="search__left-item_text">
                        {store.searchPanel.searchParamsS5.compositeDate.isValidBegin() && Utils.formatDate(store.searchPanel.searchParamsS5.compositeDate.begin)}
                        {" "}-{" "}
                        {store.searchPanel.searchParamsS5.compositeDate.isValidEnd() && Utils.formatDate(store.searchPanel.searchParamsS5.compositeDate.end)}
                    </div>
                </div>
            </div>);
        }
        if (store.searchPanel.searchParamsS5.type == SearchParamsS5Type.scenes){
            childs.push(<div
                className="left-sidebar__right-bottom__row2-left_item" key="filterDate">
                <div className="search__left-item">
                    <div className="calendar_filter gray"/>
                    <div className="search__left-item_text">
                        {store.trans.Year}: {store.searchPanel.searchParamsS5.sceneYear}
                        {" "}{store.trans.Month}: {DateUtils.getMonthName(store.searchPanel.searchParamsS5.sceneMonth, store.trans)}
                    </div>
                </div>
            </div>);
        }

        return childs;
    }

    render() {
        let store = this.props.store;
        let fs = store.searchPanel.filterSatellites;
        let sats: string[] = [];
        if (fs.S2) sats.push(satDic[SatEnum.S2]);
        if (fs.L45) sats.push(satDic[SatEnum.L45]);
        if (fs.L7E) sats.push(satDic[SatEnum.L7E]);
        if (fs.L8) sats.push(satDic[SatEnum.L8]);
        let satStr = sats.join(", ");
        let childs: any[] = [];
        if (store.searchPanel.sourceType == SearchSceneSourceType.sentinel1){
            childs.push(this.getSentinel1Filters());
        }else if (store.searchPanel.sourceType == SearchSceneSourceType.sentinel5){
            childs.push(this.getSentinel5Filters());
        }else {
            if (store.searchPanel.filterSource != null) childs.push(<div key="filterSource"
                                                                         className="left-sidebar__right-bottom__row2-left_item">
                <div className="search__left-item">
                    <div className="passive_sensor_filter gray"/>
                    <div className="search__left-item_text">{store.trans["Sentinel 2 + Landsat"]}</div>
                </div>
                <div className="close_filter gray"/>
            </div>);
            if (store.searchPanel.filterCloud != null) childs.push(
                <div className="left-sidebar__right-bottom__row2-left_item" key="filterCloud">
                    <div className="search__left-item">
                        <div className="cloud-sun_filter gray"/>
                        <div className="search__left-item_text">&le; {Math.round(store.searchPanel.filterCloud)}%</div>
                    </div>
                    <div className="close_filter gray" onClick={this.deleteCloud}/>
                </div>);
            if (store.searchPanel.filterSunelev != null) childs.push(<div
                className="left-sidebar__right-bottom__row2-left_item" key="filterSunelev">
                <div className="search__left-item">
                    <div className="angle_filter gray"/>
                    <div
                        className="search__left-item_text">&le; {Math.round(store.searchPanel.filterSunelev)}&deg;</div>
                </div>
                <div className="close_filter gray" onClick={this.deleteSunelev}/>
            </div>);
            if (store.searchPanel.filterDate.hasValid()) childs.push(<div
                className="left-sidebar__right-bottom__row2-left_item" key="filterDate">
                <div className="search__left-item">
                    <div className="calendar_filter gray"/>
                    <div className="search__left-item_text">
                        {store.searchPanel.filterDate.isValidBegin() && Utils.formatDate(store.searchPanel.filterDate.begin)}
                        {" "}-{" "}
                        {store.searchPanel.filterDate.isValidEnd() && Utils.formatDate(store.searchPanel.filterDate.end)}
                    </div>
                </div>
                <div className="close_filter gray" onClick={this.deleteDate}/>
            </div>);
            if (satStr != "") childs.push(<div className="left-sidebar__right-bottom__row2-left_item" key="satStr">
                <div className="search__left-item">
                    <div className="satellite_table svg_filter gray"/>
                    <div className="search__left-item_text">{satStr}</div>
                </div>
                <div className="close_filter gray" onClick={this.deleteSat}/>
            </div>);
        }
        if (store.map.searchObject.isNotEmpty) childs.push(<div
            className="left-sidebar__right-bottom__row2-left_item search__left-item-spatial white-fill black-fill-onhover"
            key="searchObject">
            <div className="search__left-item ">
                <svg width="11" height="11" viewBox="0 0 11 11" className="" xmlns="http://www.w3.org/2000/svg"
                     style={{marginRight: "10px"}}>
                    <path
                        d="M5.49909 6.96617C6.309 6.96617 6.96556 6.30971 6.96556 5.49993C6.96556 4.69015 6.309 4.03369 5.49909 4.03369C4.68918 4.03369 4.03262 4.69015 4.03262 5.49993C4.03262 6.30971 4.68918 6.96617 5.49909 6.96617Z"/>
                    <path
                        d="M1.09985 3.51259V5.50008C1.09985 5.69451 1.17711 5.88098 1.31461 6.01847C1.45212 6.15596 1.63862 6.23319 1.83309 6.23319C2.02755 6.23319 2.21406 6.15596 2.35156 6.01847C2.48907 5.88098 2.56632 5.69451 2.56632 5.50008V3.51259C2.9523 3.34416 3.2685 3.04799 3.46176 2.67388C3.65502 2.29976 3.71355 1.87053 3.62751 1.45834C3.54147 1.04615 3.3161 0.67616 2.98931 0.410572C2.66251 0.144984 2.25422 0 1.83309 0C1.41195 0 1.00366 0.144984 0.67687 0.410572C0.350076 0.67616 0.124711 1.04615 0.0386691 1.45834C-0.0473726 1.87053 0.0111573 2.29976 0.204417 2.67388C0.397676 3.04799 0.713879 3.34416 1.09985 3.51259Z"/>
                    <path
                        d="M9.89858 7.4877V5.50021C9.89858 5.30577 9.82133 5.1193 9.68382 4.98182C9.54631 4.84433 9.35981 4.76709 9.16535 4.76709C8.97088 4.76709 8.78438 4.84433 8.64687 4.98182C8.50937 5.1193 8.43211 5.30577 8.43211 5.50021V7.4877C8.04614 7.65612 7.72994 7.95229 7.53668 8.32641C7.34342 8.70052 7.28489 9.12976 7.37093 9.54194C7.45697 9.95413 7.68234 10.3241 8.00913 10.5897C8.33592 10.8553 8.74421 11.0003 9.16535 11.0003C9.58648 11.0003 9.99477 10.8553 10.3216 10.5897C10.6484 10.3241 10.8737 9.95413 10.9598 9.54194C11.0458 9.12976 10.9873 8.70052 10.794 8.32641C10.6008 7.95229 10.2846 7.65612 9.89858 7.4877Z"/>
                    <path
                        d="M5.49907 2.56739H7.48687C7.65532 2.9533 7.95154 3.26945 8.32571 3.46268C8.69988 3.65591 9.12919 3.71443 9.54144 3.62841C9.95369 3.54238 10.3237 3.31705 10.5894 2.9903C10.855 2.66356 11 2.25533 11 1.83427C11 1.4132 10.855 1.00497 10.5894 0.678229C10.3237 0.351486 9.95369 0.126156 9.54144 0.0401279C9.12919 -0.0459003 8.69988 0.0126204 8.32571 0.20585C7.95154 0.399079 7.65532 0.715232 7.48687 1.10115H5.49907C5.30461 1.10115 5.11811 1.17839 4.9806 1.31587C4.84309 1.45336 4.76584 1.63983 4.76584 1.83427C4.76584 2.0287 4.84309 2.21517 4.9806 2.35266C5.11811 2.49015 5.30461 2.56739 5.49907 2.56739Z"/>
                    <path
                        d="M5.89116 7.90772H4.02735C3.8694 7.54582 3.59166 7.24934 3.24083 7.06813C2.88999 6.88693 2.48747 6.83205 2.10093 6.91272C1.71439 6.9934 1.36742 7.20471 1.11836 7.51112C0.869299 7.81753 0.733337 8.20035 0.733337 8.59522C0.733337 8.99008 0.869299 9.37291 1.11836 9.67932C1.36742 9.98573 1.71439 10.197 2.10093 10.2777C2.48747 10.3584 2.88999 10.3035 3.24083 10.1223C3.59166 9.9411 3.8694 9.64462 4.02735 9.28272H5.89116C6.0735 9.28272 6.24836 9.21028 6.3773 9.08135C6.50623 8.95242 6.57866 8.77755 6.57866 8.59522C6.57866 8.41288 6.50623 8.23801 6.3773 8.10908C6.24836 7.98015 6.0735 7.90772 5.89116 7.90772Z"/>
                </svg>
                <div className="search__left-item_text pointer" onClick={this.zoomToArea}>
                    {store.trans.Area + ": " + store.map.searchObject.getAreaStr}
                </div>
            </div>
            <div className="close_filter gray" onClick={this.deleteArea}/>
        </div>);

        if (childs.length == 0) return null;
        return <div className="left-sidebar__right-bottom__row2">
            {childs}
        </div>;
    }
}
