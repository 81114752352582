import React, { CSSProperties, Fragment, ReactElement, useState } from 'react';
import { MinSvg } from '../../icons/Legend/MinSvg';
import { MaxSvg } from '../../icons/Legend/MaxSvg';
import { isBoolean, isFunction } from 'lodash-es';
import './Legend.scss'
import LegendEyeSvg from './LegendEyeSvg';
import { WindowTriState } from '../../../helper/structs/WindowTriState';
import { ISuperStore } from '../../../../pluginApi/store/SuperStore';

interface ILegendProps {
    header: string;
    minimized?: boolean;
    footer?: string;
    showData?: boolean;
    showDataCallback?: (visible: boolean) => void;
    content?: ReactElement;
    store: ISuperStore;
    // containerStyler?: () => CSSProperties;
}

const Legend = (props: ILegendProps) => {
    const {header, minimized, footer, showData, content, showDataCallback} = props;
    const [isMinimized, setMinimized] = useState(minimized);
    const [visible, setVisible] = useState(showData);

    const clickMinimize = () => {
        setMinimized(true);
    }

    const clickMaximize = () => {
        setMinimized(false);
    }

    const clickShowData = () => {
        setVisible(! visible);
        if (isFunction(showDataCallback)) showDataCallback(! visible);
    }

    const defaultContainerStyler = () => {
        let st: CSSProperties = {right: "5px"};
        if (props.store.indexByPointer.showPanel == WindowTriState.show){
            st.right = "440px";
        }
        else if (props.store.map.layersMenuOpened) {
            st.right = "355px"
        }
        return st;
    }

    if (isMinimized) return (
        <div className="Legend-container Legend-header qtransit" style={defaultContainerStyler()}>
            <div className="Legend-pointer Legend-title-container" onClick={clickMaximize}>
                <span className='Legend-title'>{header}</span>
                <div className="Legend-cursor"><MaxSvg/></div>
            </div>
        </div>
    );
    return (
        <div className='Legend-container qtransit' style={defaultContainerStyler()}>
            <div className='Legend-header'>
                <div className='Legend-eye-container' onClick={clickShowData}>
                    {visible && <LegendEyeSvg/>}
                    {isBoolean(visible) && !visible && <div className='Legend-closed-eye'/>}
                </div>
                <div className='Legend-title-container'>
                    <span className='Legend-title'>{header}</span>
                    {isBoolean(isMinimized) && <div className='Legend-panel-minimize' onClick={clickMinimize}><MinSvg/></div>}
                </div>                
            </div>
            <Fragment>{content}</Fragment>
            {footer && <div>{footer}</div>}
        </div>
      );
}

export default Legend;