import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoEditRouteWaitPanelComp.scss'
import classNames from "classnames";
import {observable} from "mobx";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AhoIndicatorFormat} from "../../agroAhoCardStore";

@observer
export class AgroAhoEditRouteWaitPanelComp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    onGoOutClick(){
        this.props.store.agroAhoStore.mapStore.mapEditRouteStore.doDisableModeClick();
    }

    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;

        return <div className="AgroAhoEditRouteWaitPanelComp-main">
            <div className="AgroAhoEditRouteWaitPanelComp-window">
                <div>{store_.trans["To change the detour order, double-click on the field"]}</div>
                <div className="AgroAhoEditRouteWaitPanelComp-splitter"> </div>
                <div className="AgroAhoEditRouteWaitPanelComp-exitButton button-jump" onClick={this.onGoOutClick}>
                    {store_.trans["Go out"]}</div>
            </div>
        </div>
    }
}
