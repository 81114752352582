import * as React from 'react';
import {ReactNode} from 'react';
import {IndexByPointGraphStore} from "../../../../store/indeByPoint/IndexByPointGraphStore";
import {observer} from "mobx-react";
import {action, observable} from "mobx";
import {CheckboxMiniComp, CheckboxSize, TriState} from "../../../Common/CheckboxMiniComp";
import 'simplebar/dist/simplebar.min.css';
import {LoadStatus} from "../../../../helper/structs/LoadStatus";
import {LoadingAniSvg} from "../../../icons/LoadingAniSvg";
import {IndexByPointDescriptionEditorComp} from "./IndexByPointDescriptionEditorComp";
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import {IndexDataGroup} from "../../../../store/indeByPoint/IndexByPointerStore";
import {ColorHelper} from "../../../../helper/utils/ColorHelper";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {RadioButton} from "../../../Common/RadioButton";
import {IndexByPointYearStore, IYearPeriod} from "../../../../store/indeByPoint/IndexByPointYearStore";
import {IndexAreaType, IndexByPointGraphPointStore} from "../../../../store/indeByPoint/IndexByPointGraphPointStore";
import {Utils} from "../../../../helper/utils/Utils";

export interface IIndexByPointTableRowCompProps extends IStoreProps{
    point: IndexByPointGraphPointStore;
}

@observer
export class IndexByPointTableRowComp extends React.PureComponent<IIndexByPointTableRowCompProps, undefined> {
    constructor(props: IIndexByPointTableRowCompProps) {
        super(props);
        autoBindReact(this);
    }

    @action onCenterToPoint(){
        let g = this.props.point;
        g.root.map.mapbox.easeTo({center:{ lat: g.point.lat, lng: g.point.lng}, zoom: g.root.map.zoom});
    }
    @action onClickDeletePoint(){
        this.props.point.root.indexByPointer.removePoint(this.props.point);
    }
    @action onClickCheckBoxColorYear(event: any, data?: any){
        let y: IndexByPointYearStore = data;
        y.visible = !y.visible;
        if (this.props.store.indexByPointer.compareSources){
            this.props.store.indexByPointer.yearPoints.forEach(yy => {
                if (yy != y) yy.visible = false;
            });
        }
    }
    @action onClickCheckBoxClimate(event: any, data?: any){
        let y: IndexByPointYearStore = data;
        y.visible = !y.visible;
        if (this.props.store.indexByPointer.compareSources){
            this.props.store.indexByPointer.yearPoints.forEach(yy => {
                if (yy != y) yy.visible = false;
            });
        }
    }


    @action onClickCommon(){
        let p = this.props.point;
        let cs = this.getCommonState();
        let newValue: boolean;
        if (cs == TriState.partial || cs == TriState.uncheck) newValue = true;
        else newValue = false;

        this.props.point.yearPoints.forEach(yp => {
            yp.visible = newValue;
        });
    }
    getCommonState(): TriState{
        let checkedCnt = 0;
        let uncheckedCnt = 0;
        this.props.point.yearPoints.forEach(a => {
            if (a.visible) checkedCnt++;
            if (!a.visible) uncheckedCnt++;
        });
        let commonState: TriState = TriState.partial;
        if (uncheckedCnt == 0 && checkedCnt >= 0) commonState = TriState.check; else
        if (uncheckedCnt > 0 && checkedCnt == 0) commonState = TriState.uncheck;
        return commonState;
    }
    mouseEnter(){
        this.hover = true;
    }
    mouseMove(){
        if (this.hover === false) this.hover = true;
    }
    mouseLeave(){
        this.hover = false
    }
    @observable
    hover: boolean = false;

    @action onCloseDescription(){
        this.openDescription = false;
    }
    @action onOpenDescription(){this.openDescription = true;}
    @observable openDescription: boolean = false;

    getImageSimple(data: IndexByPointGraphStore): ReactNode{
        if (data != null && data.status == LoadStatus.loading) return <LoadingAniSvg  />;
        return undefined;
    }

    getTitlePeriod(yp: IYearPeriod): string{
        if (!yp.isClimate) {
            if (this.props.store.indexByPointer.isCrossYear) {
                return yp.year + "-"+ (yp.year+1);
            }else return yp.year.toString();
        }else return this.props.store.trans.Climate;
    }

    render() {
        let store = this.props.store;
        let p = this.props.point;
        let short: boolean = false;
        if (store.indexByPointer.dataGroup == IndexDataGroup.spectral) short = true;

        let graphArr: any[] = [];
        let ypa = store.indexByPointer.yearPoints.filter(y => y.idPoint == p.id).sort((a,b) =>{
            let ok = Utils.compareSortBoolean(a.yearPeriod.isClimate, b.yearPeriod.isClimate);
            if (ok != 0) return ok;
            return b.yearPeriod.year - a.yearPeriod.year;
        });
        ypa.forEach(y => {
            if (store.indexByPointer.compareSources){
                graphArr.push(
                    <RadioButton checked={y.visible} className="pointer"
                                      key={y.yearPeriod.year.toString() + y.yearPeriod.isClimate.toString()}
                                      onClick={this.onClickCheckBoxClimate}
                                      data-attr={y.yearPeriod.year.toString()}
                                      data={y}>
                        <span className="IndexByPointTableRowComp-commonCheckbox-label pointer">{this.getTitlePeriod(y.yearPeriod)}</span>
                    </RadioButton>
                );
            }else {
                graphArr.push(
                    <CheckboxMiniComp state={y.visible ? TriState.check : TriState.uncheck}
                                      key={y.yearPeriod.year.toString() + y.yearPeriod.isClimate.toString()}
                                      size={CheckboxSize.mini}
                                      styleBox={{
                                          stroke: y.visible ? ColorHelper.numToCssHexRgb(y.color) : "#454C52",
                                          fill: ColorHelper.numToCssHexRgb(y.color)
                                      }}
                                      onClick={this.onClickCheckBoxClimate}
                                      data-attr={y.yearPeriod.year.toString()}
                                      data={y}
                                      replaceBox={this.getImageSimple(y.singleGraph)}
                                      classesContainer="pointer IndexByPointTableRowComp-commonCheckbox-withLabel"
                                      classesBox="IndexByPointTableRowComp-commonCheckbox">
                            <span className="IndexByPointTableRowComp-commonCheckbox-label pointer">{this.getTitlePeriod(y.yearPeriod)}</span>
                    </CheckboxMiniComp>
                );
            }

        });
        //let style = {borderColor: Utils.toColorHex(g.color)};
        let commonState = this.getCommonState();

        let numPointText = "";
        if (p.areaType == IndexAreaType.point) numPointText = p.num.toString();
        if (p.areaType == IndexAreaType.field) numPointText = "Field: "+p.filed_name;

        return <div className={classNames("flex-columns IndexByPointTableRowComp",
                {"IndexByPointTableRowComp-hover":this.hover || this.openDescription})}
                    onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave} onMouseMove={this.mouseMove}>
            <div className="IndexByPointTableRowComp-sub1 flex-columns">
                {!store.indexByPointer.compareSources && <CheckboxMiniComp state={commonState} onClick={this.onClickCommon}
                                  size={CheckboxSize.mini}
                                  classesContainer="pointer IndexByPointTableRowComp-commonCheckbox-withLabel"
                                  classesBox="IndexByPointTableRowComp-commonCheckbox IndexByPointTableRowComp-commonCheckbox-white">
                    <span className="IndexByPointTableRowComp-commonCheckbox-label">{numPointText}</span>
                </CheckboxMiniComp>}
                {store.indexByPointer.compareSources && <span className="IndexByPointTableRowComp-commonCheckbox-label">{numPointText}</span>}
                <div className="flex-stretch-item IndexByPointTableRowComp-description">{p.description}</div>
                <div className="IndexByPointTableRowComp-button_right-box" onClick={this.onCenterToPoint}>
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.34375 3.65625H3.65625V9.34375H9.34375V3.65625Z" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M0.40625 4.0625V1.21875C0.40625 0.77025 0.77025 0.40625 1.21875 0.40625H4.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M8.9375 0.40625H11.7812C12.2297 0.40625 12.5938 0.77025 12.5938 1.21875V4.0625" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12.5938 8.9375V11.7812C12.5938 12.2297 12.2297 12.5938 11.7812 12.5938H8.9375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4.0625 12.5938H1.21875C0.77025 12.5938 0.40625 12.2297 0.40625 11.7812V8.9375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
                <div className="IndexByPointTableRowComp-button_right-box" onClick={this.onOpenDescription}>
                    <svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5625 5.6875L7.3125 2.4375" strokeLinecap="round" strokeLinejoin="round"  fill="transparent"/>
                        <path d="M4.46875 11.7812L0.40625 12.5938L1.21875 8.53125L9.34375 0.40625L12.5938 3.65625L4.46875 11.7812Z" strokeLinecap="round" strokeLinejoin="round"  fill="transparent"/>
                    </svg>
                </div>
                <div className="IndexByPointTableRowComp-button_right-box" onClick={this.onClickDeletePoint}>
                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.96875 1.03125L1.03125 9.96875" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1.03125 1.03125L9.96875 9.96875" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
                {this.openDescription && <IndexByPointDescriptionEditorComp store={store} point={p} onClose={this.onCloseDescription} />}
            </div>
            <div className="IndexByPointTableRowComp-sub2 flex-columns flex-wrap">
                {graphArr}
            </div>
        </div>;
    }
}
