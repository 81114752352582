import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import {IStoreProps} from "../../../../helper/structs/IStoreProps";
import {ReactDoubleDatePicker2Comp} from "../../../Common/ReactDoubleDatePicker2Comp";
import {CheckboxMiniComp, CheckboxSize} from "../../../Common/CheckboxMiniComp";

import './ActiveSensorDay.scss';
import {action, runInAction} from "mobx";
import {SpatialButtonsComp} from "../ParamSearchPanel/SpatialButtonsComp";
import {Utils} from '../../../../helper/utils/Utils';
import classNames from 'classnames';
import { RadioButton } from '../../../Common/RadioButton';
import { Sentinel1product } from '../../../../store/export/ActiveSensorDayStore';

@observer
export class ActiveSensorDay extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
        setImmediate(()=>{
            runInAction(()=>{
                this.props.store.exportStore.email = this.props.store.user.curEmail;
            });
        });
    }

    @action
    onChangeProjectName(event: any){
        this.props.store.exportStore.projectName = event.target.value;
    }

    @action
    onChangeOrbits(event: any) {
        this.props.store.exportStore.activeSensorDay.orbitsText = event.target.value;
        this.props.store.exportStore.activeSensorDay.orbits = [];
    }
    @action
    changeProduct(event: any, data: any){
        this.props.store.exportStore.activeSensorDay.product = data
    }
    validateOrbits() {
        let value = this.props.store.exportStore.activeSensorDay.orbitsText;
        let orbits: number[] = [];
        try {
            value.split(",").filter(Boolean).forEach((s: string) => {
                let num = Utils.parsePositiveInt(s);
                if (isNaN(num))
                    throw new Error("Invalid number list format");
                orbits.push(num);
            });
            return orbits;
        } catch {
            return null;
        }
    }

    @action
    onChangeMultitimeFiltering() {
        this.props.store.exportStore.activeSensorDay.multitimeFiltering = 
            !this.props.store.exportStore.activeSensorDay.multitimeFiltering;
    }

    @action
    onChangeEmail(event: any){
        this.props.store.exportStore.email = event.target.value;
    }

    @action
    onClickExport(){
        let store = this.props.store;
        store.exportStore.activeSensorDay.orbits = this.validateOrbits();
        if (store.exportStore.activeSensorDay.orbits == null) return; // show error
        // if (store.searchPanel.leftPanelMode == LeftPanelMode.test)
        //     store.exportStore.legacyExportSentinel1();
        // else
        store.exportStore.exportSentinel1();
    }

    render() {
        let store = this.props.store;
        let asd = store.exportStore.activeSensorDay;
        let hasGeometry = store.map.searchObject.isNotEmpty;
        let disableRun: boolean = false;
        disableRun = (store.exportStore.email.length == 0 ||
            store.map.searchObject.isEmpty ||
            (asd.polarizationTypeVH == false && asd.polarizationTypeVV == false) ||
            !store.searchPanel.filterDate.isValid() ||
            store.exportStore.projectName.length == 0);

        return (
            <div className="ActiveSensorDay-rows">
                <div className="flex-column">
                    <div className="flex-input-title">
                        <div className="flex-stretch-item">
                            {store.trans["Task name"]}
                            <span className="ActiveSensorDay-star">*</span>
                        </div>
                    </div>
                    <div className="flex-input-value">
                        <input type="text" className="text-box-editor width100"
                               onChange={this.onChangeProjectName}
                               value={store.exportStore.projectName}/>
                    </div>
                </div>
                <div className="flex-column">
                    <div className="flex-input-title">
                        <div className="flex-stretch-item">
                            {store.trans.Email}
                            <span className="ActiveSensorDay-star">*</span>
                        </div>
                    </div>
                    <div className="flex-input-value">
                        <input type="text" className="text-box-editor width100"
                               onChange={this.onChangeEmail}
                               value={store.exportStore.email}/>
                    </div>
                </div>

                <div className="flex-column">
                    <div className="flex-input-title">
                        {store.trans.Period}
                        <span className="ActiveSensorDay-star">*</span>
                    </div>
                    <div className="flex-input-value">
                        <ReactDoubleDatePicker2Comp store={store} interval={store.searchPanel.filterDate}/>
                    </div>
                </div>
                <div className="flex-column">
                    <div className="flex-input-title popup-exp--text" style={{marginBottom: "8px"}}>
                        <div className="flex-stretch-item">
                            {store.trans["Project"]}
                           
                        </div>
                    </div>
                    <div className="flex-input-value flex-column" >
                    <RadioButton className='journal-body-div2'
                         checked={asd.product == Sentinel1product.GRD} data={Sentinel1product.GRD} 
                        onClick={this.changeProduct}
                        >
                    <span className="text-next pointer blue_text_onhover">GRD</span>
                </RadioButton>
                <RadioButton 
                        checked={asd.product == Sentinel1product.SLC} data={Sentinel1product.SLC}
                        onClick={this.changeProduct}
                         >
                    <span className="text-next pointer blue_text_onhover">SLC</span>
                </RadioButton>
                    </div>
                </div>
                <div className="flex-column">
                    <div className="flex-input-title popup-exp--text" style={{marginBottom: "8px"}}>
                        <div className="flex-stretch-item">
                            {store.trans["Polarization type"]}
                            <span className="ActiveSensorDay-star">*</span>
                        </div>
                    </div>
                    <div className="flex-input-value flex-column">
                        <CheckboxMiniComp classesContainer='journal-body-div2' state={asd.polarizationTypeVV} size={CheckboxSize.standart} onClick={()=>{
                            asd.polarizationTypeVV = !asd.polarizationTypeVV;
                        }} >
                            <span className="text-next pointer blue_text_onhover" >VV</span>
                        </CheckboxMiniComp>
                        <CheckboxMiniComp 
                            state={asd.polarizationTypeVH} size={CheckboxSize.standart} onClick={()=>{
                            asd.polarizationTypeVH = !asd.polarizationTypeVH
                        }} ><span className="text-next pointer blue_text_onhover">VH</span></CheckboxMiniComp>
                    </div>
                </div>
                <div className="flex-column">
                    <div className="flex-input-title">
                        <div className="flex-stretch-item">
                            {store.trans["Orbit number filter"]}
                        </div>
                        <div className="ActiveSensorDay-spatialLabel">{store.trans["Comma separated relative orbit numbers"]}</div>
                    </div>
                    <div className="flex-input-value">
                        <input type="text" className={classNames("text-box-editor width100", {
                                "red_border": store.exportStore.activeSensorDay.orbits == null
                            })}
                               onChange={this.onChangeOrbits}
                               value={store.exportStore.activeSensorDay.orbitsText}
                               placeholder="37,53,128"/>
                    </div>
                </div>
                <div className="ActiveSensorDay-kv">
                    <div className="ActiveSensorDay-kvKey flex-columns-centerByVertical" style={{alignItems:'center',gap:0}}>
                        <input type="checkbox" className="switch_1" id="multitimeFiltering"
                            checked={store.exportStore.activeSensorDay.multitimeFiltering} onChange={this.onChangeMultitimeFiltering}/>
                        <label htmlFor="multitimeFiltering"
                            className={classNames("css-label-2", {"ProductItemComp-textLabel-m": !store.exportStore.activeSensorDay.multitimeFiltering})}>
                                {store.trans["Multitime filtering"]}</label>
                    </div>
                </div>
                <div className="popup-pref-in__separator" style={{marginTop:'15px',marginBottom:'0px'}}/>
                <div className="ActiveSensorDay-kv">
                    <div className="ActiveSensorDay-kvKey">
                        <div className="flex-stretch-item">{store.trans["Active area"]}
                            <span className="ActiveSensorDay-star">*</span>
                        </div>
                    </div>
                    <div className="ActiveSensorDay-kvValue">
                        <div className="flex-stretch-item flex-rows">
                            <SpatialButtonsComp onlyPolygonButtons={false} 
                            activeArea store={store} className="ActiveSensorDay-spatialBox" classNameButton="ActiveSensorDay-spatialButton"
                            notAccent={true} />
                        </div>
                    </div>
                </div>
                <div className="flex-stretch-item" />
                <div className="ActiveSensorDay-kv" style={{marginTop: "5px"}}>
                    <div className="ActiveSensorDay-kvValue">
                        <div className="flex-stretch-item flex-rows">
                <div className="popup-exp-visible popup-exp-crop" style={{marginTop: "5px"}}>
                    <input type="checkbox" className="switch_1" id="cut"
                           checked={asd.crop_to_region && hasGeometry} 
                           disabled={!hasGeometry}
                          onChange={()=>{
                            asd.crop_to_region = !asd.crop_to_region}}
                           /> 
                           <label
                    htmlFor="cut"
                    className="css-label-2">{store.trans["Crop to active area"]}</label>
                </div>                           
                    </div>
                    </div>
                </div>
                {this.props.children}
                <div className="ActiveSensorDay-bottomDiv" style={{flexDirection:'column'}}>
                <div className="popup-pref-in__separator" style={{marginLeft:'-25px',width:'140%',marginRight:'-25px'}}/>
                    <button onClick={this.onClickExport}
                            disabled={disableRun}
                            className="large-button left-sidebar-active button-jump ActiveSensorDay-bottomButton">
                        {store.trans.Run}
                    </button>

                </div>
            </div>
        );
    }
}
