import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoTableUpload5Comp.scss'
import {action} from "mobx";
import classNames from "classnames";
import {AgroAhoDownloadFileSvg} from "./../AgroAhoDownloadSvg";
import {Utils} from "../../../../app/helper/utils/Utils";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AgroAhoStepsComp} from "./AgroAhoStepsComp";
import {AhoUploadTableColType} from "../../agroAhoUploadTableStore";
import {AgroAhoBackSvg} from "../icons/AgroAhoBackSvg";
import {AgroAhoPointSvg} from "../icons/AgroAhoPointSvg";
import {AgroAhoCheckboxSvg} from "../icons/AgroAhoCheckboxSvg";
import {AgroAhoHelpFileSvg} from "../icons/AgroAhoHelpSvg";

@observer
export class AgroAhoTableUpload5Comp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickNext(){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        if (!ustore.isOverwrite && !ustore.isNoOverwrite) return;
        ustore.step5NextClick();
    }

    @action
    onClickCancel(){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        ustore.uploadCancelClick();
    }

    @action
    onClickBack(){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        ustore.uploadBackClick();
    }

    @action
    onDownloadSampleClick(){
        let url = `образец_данные_АХО.ods`;
        Utils.downloadFile(url);
    }

    @action
    onDownloadInstructionsClick(){
        let url = `Инструкция.pdf`;
        Utils.downloadFile(url);
    }

    @action
    onOverwriteClick(){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        if (ustore.isOverwrite) return;
        ustore.isOverwrite = true;
        ustore.isNoOverwrite = false;
    }
    @action
    onNoOverwriteClick(){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        if (ustore.isNoOverwrite) return;
        ustore.isNoOverwrite = true;
        ustore.isOverwrite = false;
    }


    render() {
        let store = this.props.store;
        let store_ = store.agroAhoStore;
        let ustore = store_.uploadTableStore;
        let tstore = store_.tourStore;
        let pstore = store_.projStore;

        let ed = ustore.tourExistsData;
        // let indcs = pstore.indicators.slice(0, 2).map(i=>{
        // let indcs = ustore.tourExistsData.indcs.map(i=>{
        let indcs = pstore.indicators.filter(i=>{
            return ed.indcs.filter(edi=>edi.indc_code==i.lower_code)?.length
        }).map(i=>{
            return <div key={i.lower_code} className="AgroAhoTableUpload5Comp-colItem">
                <AgroAhoPointSvg/>
                <div className="AgroAhoTableUpload5Comp-colItemTitle">&nbsp;{i.indc_name}&nbsp;/&nbsp;</div>
                <div className="AgroAhoTableUpload5Comp-colItemStand">{i.standard}</div>
            </div>;
        });
        // let rns = ustore.loadedFileData.columns.filter(c=>c.data_type == AhoUploadTableColType.RecNorm).map(c=>{
        let rns = ed.rec_norms.map(c=>{
            return <div key={c.num} className="AgroAhoTableUpload5Comp-colItem">
                <AgroAhoPointSvg/>
                <div className="AgroAhoTableUpload5Comp-colItemTitle">
                    {ustore.getFertName(c.fert_id)}{','}
                    {ustore.getCropName(c.crop_id)}{','}
                    {c.yield || ''}{store_.trans['c/ha']}
                </div>
            </div>;
        });


        return <div className={classNames("AgroAhoTableUpload5Comp-main",{
            "AgroAhoTableUpload5Comp-hidden": !store_.ahoDialogShow})}>
            <div className="AgroAhoTableUpload5Comp-cover">
                <div className="AgroAhoTableUpload5Comp-topPanel">
                    <div className="AgroAhoTableUpload5Comp-titleCover">
                        <div className="AgroAhoTableUpload5Comp-title">{store_.trans['Step 5: View value intersections and errors']}</div>
                        <AgroAhoStepsComp step={ustore.ahoUploadTableStage}/>
                    </div>
                    {/*<div className="AgroAhoTableUpload5Comp-titleCover">*/}
                    {/*    <div className="AgroAhoTableUpload5Comp-titleStatus">{store_.trans['The file was successfully processed and matches the sample']}</div>*/}
                    {/*    <div className="AgroAhoTableUpload5Comp-titleTourName">{store_.trans.Tour}: {ustore.curTour?.tour_name}</div>*/}
                    {/*</div>*/}
                    <div className="AgroAhoTableUpload5Comp-subTitleCover">
                        {!ustore.fileError && <div className="AgroAhoTableUpload5Comp-titleStatus">
                            {store_.trans['The file has been processed successfully!']}</div>}
                        {ustore.fileError && <div className="AgroAhoTableUpload5Comp-titleErrorStatus">
                            {store_.trans['The file was processed with errors. Correct the following errors in the Excel file:']}</div>}
                        {/*<div className="AgroAhoTableUpload5Comp-titleStatus">{store_.trans['The file was successfully processed and matches the sample']}</div>*/}
                        <div className="AgroAhoTableUpload5Comp-splitter"/>
                        <div className="AgroAhoTableUpload5Comp-titleFileName">{store_.trans.File}: {tstore.curTour?.tour_file_name}</div>
                        <div className="AgroAhoTableUpload5Comp-titleTourName">{store_.trans.Tour}: {tstore.curTour?.tour_name}</div>
                    </div>
                    <div className="AgroAhoTableUpload5Comp-line"/>
                </div>
                <div className="AgroAhoTableUpload5Comp-log style-4">
                    <div className="AgroAhoTableUpload5Comp-logTitle">{store_.trans['The tour already contains the following data, which is also contained in the downloadable table:']}</div>
                    <div className="AgroAhoTableUpload5Comp-colsCover">
                        <div className="AgroAhoTableUpload5Comp-leftCol">
                            <div className="AgroAhoTableUpload5Comp-colTitle">{store.trans.Indicators}</div>
                            {indcs}
                        </div>
                        <div className="AgroAhoTableUpload5Comp-rightCol">
                            <div className="AgroAhoTableUpload5Comp-colTitle">{store_.trans['Recommended norms']}</div>
                            {rns}
                        </div>
                    </div>
                </div>
                <div className="AgroAhoTableUpload5Comp-rewriteCover">
                    <div className="AgroAhoTableUpload5Comp-rewriteTitle">{store_.trans['Cell values from intersecting data:']}</div>
                    <div className="AgroAhoTableUpload5Comp-rewriteCheckboxCover" onClick={this.onOverwriteClick}>
                        <AgroAhoCheckboxSvg checked={ustore.isOverwrite}/>
                        <div className="AgroAhoTableUpload5Comp-rewriteCaption">{store_.trans.Overwrite}</div>
                    </div>
                    <div className="AgroAhoTableUpload5Comp-rewriteCheckboxCover" onClick={this.onNoOverwriteClick}>
                        <AgroAhoCheckboxSvg checked={ustore.isNoOverwrite}/>
                        <div className="AgroAhoTableUpload5Comp-rewriteCaption">{store_.trans['Do not overwrite']}</div>
                    </div>
                </div>
                <div className="AgroAhoTableUpload5Comp-bottomPanel">
                    {/*<div className="AgroAhoTableUpload5Comp-linkSample" onClick={this.onDownloadSampleClick}>*/}
                    {/*    <AgroAhoDownloadFileSvg />*/}
                    {/*    <span>{store.trans.Sample}</span>*/}
                    {/*</div>*/}
                    {/*<div className="AgroAhoTableUpload5Comp-linkInstructions" onClick={this.onDownloadInstructionsClick}>*/}
                    {/*    <AgroAhoDownloadFileSvg />*/}
                    {/*    <span>{store.trans.Instruction}</span>*/}
                    {/*</div>*/}
                    <div className="AgroAhoTableUpload5Comp-linkInstructions" onClick={this.onDownloadInstructionsClick}>
                        <AgroAhoHelpFileSvg/>
                        <span>{store.trans.Help}</span>
                    </div>
                    <div className="AgroAhoTableUpload5Comp-splitter"> </div>
                    <button className="large-button left-sidebar-cancel AgroAhoTableUpload5Comp-cancelButton button-jump"
                        onClick={this.onClickCancel}>{store.trans.Cancel}</button>
                    <div className="AgroAhoTableUpload5Comp-backButton" onClick={this.onClickBack}>
                        <AgroAhoBackSvg/>
                        <span>{store.trans.Back}</span>
                    </div>
                    <div className={classNames("AgroAhoTableUpload5Comp-nextButton", {
                        "AgroAhoTableUpload5Comp-disabled": !ustore.isOverwrite && !ustore.isNoOverwrite
                        })} onClick={this.onClickNext}>
                        <span>{store_.trans.Next}</span>
                        <AgroAhoBackSvg isNext={true}/>
                    </div>
                </div>
            </div>
        </div>
    }
}
