import {ISuperStore} from "../../pluginApi/store/SuperStore";
import {action, computed, observable} from "mobx";
import {CustomStore, ObservableCustomStore} from "./CustomStore";

export class SuperStore extends CustomStore implements ISuperStore {
    class(): string {return "SuperStore";}

    getRootAs<T extends ISuperStore>(): T{
        return <any>this as T;
    }

    api = new Api(this);

    protected _observableStores: Map<ObservableCustomStore, boolean> = new Map<ObservableCustomStore, boolean>();
    public addStoreSubscribtion(obj: ObservableCustomStore){
        this._observableStores.set(obj, false);
        this.delayedSubscription();
    }
    removeStoreSubscribtion(obj: ObservableCustomStore){
        this._observableStores.delete(obj);
        obj.dispose();
    }

    delayedSubscriptionHandle: any = null;
    delayedSubscription(){
        if (this.delayedSubscriptionHandle != null) return;
        this.delayedSubscriptionHandle = setImmediate(()=>{
            this.delayedSubscriptionHandle = null;
            let keysArr = Array.from(this._observableStores.keys() );
            keysArr.forEach(k => {
                let v = this._observableStores.get(k);
                if (v === false){
                    k.doSubscribe();
                    let v2 = this._observableStores.get(k);
                    if (v2 == false) this._observableStores.set(k, true);
                }
            });
        });
    }

    public dispose(){
        this._observableStores.forEach((key, value) =>{
            value.dispose();
        });
        this.events.clearAll();
    }

    detectLanguage(){
        let storage = window.localStorage;
        if (storage != null && storage.getItem("lang") != null){
            let lang = storage.getItem("lang");
            let newLang: Lang = Lang.en;
            if (lang == "en" || lang == "eng") newLang = Lang.en;
            if (lang == "ru" || lang == "rus") newLang = Lang.ru;
            if (lang == "hin") newLang = Lang.hin;
            this.setLang(newLang);
        }else{
            let language = window.navigator.language;
            if (this.config.default_language != null){
                this.setLang(this.config.default_language);
                return;
            }
            if (language == "ru-RU"){
                this.setLang(Lang.ru);
            }else this.setLang(Lang.en);
        }
    }

    @action
    init(){
        this.detectLanguage();
        this.map.productInfo.loadFromConfig();
        this.dataShowRoomStore.active = this.config.showRoom2_active;
        loadSettingIndexByPointFromConfig(this.config);

        if (manyCheck(()=>this.config.map_layers, ()=> this.config.map_layers.map)) {
            this.map.superMap.loadFromJson(this.config.map_layers.map);
        }
        this.soilGrids.loadFromConfig(this.config.soilGrids);
        this.map.superTools.createAll();

        this.webSocket.init();
        let zoom = (this.config?.startPosition?.zoom);
        if (zoom != null) this.map.zoom = zoom;
        let center = (this.config?.startPosition?.center);
        if (center?.lat && center?.lng){
            this.map.center.set(center.lat, center.lng);
        }

        if (this.config.default_left_panel){
            this.root.searchPanel.switchPanel(this.config.default_left_panel as any);
        }
        this.pluginsApi.onChangeStore();

        this.map.overlays.init();
        PluginsController.initNewStore(this);
        this.events.onInitStore.call();
    }

    private _history: H.History;
    get history(): H.History{ return this._history; }
    set history(value: H.History){ this._history = value; }

    @observable
    private _topAllComponents: {node: ReactNode, id: string}[] = [];
    @computed get getTopComponents(){
        return this._topAllComponents.map(a => a.node);
    }
    @action public insertTopComponent(node: ReactNode, id: string){
        this._topAllComponents.push({node: node, id: id});
    }
    @action public removeTopComponent(id: string){
        this._topAllComponents = this._topAllComponents.filter(a => a.id != id);
    }

    pluginsApi: PluginApiStore = new PluginApiStore(this);

    debugStore: DebugStore = new DebugStore(this);
    _events: PluginsEvents = new PluginsEvents(this);
    get events(): PluginsEvents{ return this._events;}

    @update
    photo: PhotoStore = new PhotoStore(this);

    @observable
    public active: boolean = true;

    config: IConfigStore = {
        products: [], map_layers: null, productsS5: [], productsS1_IW: [], productsS1_EW: [],
        accessToken: null, agroShow: false, preset_colors: [], s1_order_active: false,
        rshbOrdersShow: false, agroAhoShow: false
    };

    public configUtil: ConfigUtil = new ConfigUtil(this);

    @update @observable
    private _searchPanel: SearchStore = new SearchStore(this);
    get searchPanel(): SearchStore{ return this._searchPanel; }

    qgisExport: QgisExportStore = new QgisExportStore(this);
    @update @observable
    private _exportStore: ExportStore = new ExportStore(this);
    get exportStore(): ExportStore{ return this._exportStore; }

    @update
    private _dataShowRoomStore: ShowRoom2Store = new ShowRoom2Store(this);
    get dataShowRoomStore(): ShowRoom2Store{ return this._dataShowRoomStore; }


    private _egrnStore: EgrnStore = new EgrnStore(this);
    get egrnStore(): EgrnStore {return this._egrnStore}

    private _orderList: OrderListStore = new OrderListStore(this);
    get orderList(): OrderListStore{ return this._orderList; }

    private _userList: UserListStore = new UserListStore(this);
    get userList(): UserListStore {return this._userList; }

    @update @observable
    private _indexByPointer: IndexByPointerStore = new IndexByPointerStore(this);
    get indexByPointer(): IndexByPointerStore{ return this._indexByPointer; }

    @update @observable
    private _layerListStore: LayersListStore = new LayersListStore(this);
    get layerListStore(): LayersListStore {return this._layerListStore;}

    @update
    private _soilGrids: SoilGridsStore = new SoilGridsStore(this);
    get soilGrids(): SoilGridsStore{return this._soilGrids;}

    @update
    private _agro: AgroStore = new AgroStore(this);
    get agro(): AgroStore{ return this._agro; }

    @update
    private _agro2: A2AgroStore = new A2AgroStore(this);
    get agro2(): A2AgroStore{ return this._agro2; }

    private _webSocket: SocketStore = new SocketStore(this);
    get webSocket(): SocketStore{ return this._webSocket; }

    constants: ConstantsStore = new ConstantsStore(this);

    @update
    private _map: MapStore = new MapStore(this);
    get map(): MapStore{ return this._map; }

    private _popup: PopupStore = new PopupStore(this);
    get popup(): PopupStore {return this._popup;}

    public user: UserStore = new UserStore(this);

    @save @observable
    lang: Lang = Lang.en;

    @action
    setLang(newLang: Lang) {
        this.lang = newLang;
        this.loadTranslate(newLang);
        this.pluginsApi.onChangeLanguage();
        this.events.onChangeLang.call();
    }

    public loadTranslate(newLang: Lang){
        let t: Translate_en;
        if (newLang == Lang.en) t = new Translate_en();
        else
        if (newLang == Lang.hin) t = new Translate_hin();
        else t = new Translate_ru();
        t.init();
        this.trans = t;
        let storage = window.localStorage;
        if (storage != null)
            storage.setItem("lang", this.lang);
    }

    getLang(): Lang {
        return this.lang;
    }

    @observable
    trans: Translate_en = new Translate_en();

    @observable
    errors: string[] = [];//список ошибок

    gotoUrl(url: string, saveQuery: boolean = true): void {
        if (url == UrlCoder.getUrlPathOnly()) return;
        if (saveQuery) {
            if (url.indexOf("?") > 0) {
                let q = window.location.search;
                if (q.length > 0 && q.charAt(0) == '?') q = q.substr(1);
                url = url + q;
            } else url = url + window.location.search;
        }
        this.history.push(url);
    }

    toPermalink(): any {
        let json = ClassFactory.toJson(this, ClassValueUpdateType.update);
        let res: any = {url: UrlCoder.makeUrlParams(this), store: json};
        return res;
    }

    async putPermalinkAndGetId(): Promise<string>{
        let url = "/api/jsonput";
        let json = this.toPermalink();
        let p: any = {data: json};
        let jsonRes = await fetchJson(url, {method: "POST", headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body: Utils.queryEncodeParams(p)});
        return jsonRes.id;
    }

    @action fromPermalink(json: any, permalink: string = null) {        
        ClassFactory.loadJsonTo(this, json.store);
        this.initialPermalink = permalink;
        if (this.layerListStore.overlays) {
            this.config.map_layers.overlays = this.layerListStore.overlays;
            this.map.overlays.init(); //reinit overlays store
        }
        UrlCoder.readUrlParams(this, json.url);

        if (this.map.mapbox != null) {
            this.map.mapbox.jumpTo({center: this.map.center.getMbPoint(), zoom: this.map.zoom});
        }
    }

    test: any;

    initialPermalink: string = null;

    autoClearErrorsTimer: any = null;

    @action addError(err: any) {
        let s = Utils.getErrorString(err);
        this.errors.push(s);
        this.infos = [];
        if (this.autoClearErrorsTimer != null) clearTimeout(this.autoClearErrorsTimer);
        this.autoClearErrorsTimer = setTimeout(this.clearErrors, 15000);
        console.error(err);
    }

    @action clearErrors() {
        this.errors = [];
    }

    @observable infos: string[] = [];
    autoClearInfoTimer: any = null;

    @action addInfo(info: string) {
        this.infos.push(info);
        this.errors = [];
        if (this.autoClearInfoTimer != null) clearTimeout(this.autoClearInfoTimer);
        this.autoClearInfoTimer = setTimeout(this.clearInfos, 2000);
    }

    @action clearInfos() {
        this.infos = [];
    }

}

//decorate(SuperStore, {leftPanelOpen: observable, lat: observable, lng: observable, switchLeftPanel: action, toCenter: action});
import {ExportStore} from "./export/ExportStore";
import {OrderListStore} from "./OrderListStore";
import {ConfigUtil, IConfigStore} from "./config/ConfigStore";
import {IndexByPointerStore, loadSettingIndexByPointFromConfig} from "./indeByPoint/IndexByPointerStore";
import {manyCheck, Utils} from "../helper/utils/Utils";
import {UserStore} from "./user/UserStore";
import {UrlCoder} from "../helper/utils/UrlCoder";
import {SearchStore} from "./SearchStore";
import {MapStore} from "./MapStore";
import {save, update} from "./PermalinkDecor";
import {ClassFactory, ClassValueUpdateType} from "./ClassFactory";
import {AgroStore} from "./agro/AgroStore";
import {SocketStore} from "./user/SocketStore";
import {UserListStore} from "./user/UserListStore";
import * as H from "history";
import {DebugStore} from "./DebugStore";
import {PhotoStore} from "./photo/PhotoStore";
import {Translate_ru} from "../helper/lang/Translate_ru";
import {Translate_en} from "../helper/lang/Translate_en";
import {Translate_hin} from "../helper/lang/Translate_hin";
import {SoilGridsStore} from "./solidGrids/SoilGridsStore";
import {ShowRoom2Store} from "./dataShowroom2/ShowRoom2Store";
import {A2AgroStore} from "./agro/A2AgroStore";
import {ConstantsStore} from "./config/ConstantsStore";
import {PopupStore} from "./popup/PopupStore";
import {fetchJson} from "../helper/utils/FetchUtils";
import {Api} from "./Api";
import {EgrnStore} from "./egrn/EgrnStore";
import {ReactNode} from "react";
import {PluginApiStore} from "./PluginApi/PluginApiStore";
import {Lang} from "../../pluginApi/store/Lang";
import {PluginsController} from "../../pluginApi/PluginsController";
import {PluginsEvents} from "../../pluginApi/PluginsEvents";
import {QgisExportStore} from "./export/QgisExportStore";
import { LayersListStore } from "../components/panels/Right/LayersList/LayersListStore";
