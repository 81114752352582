import * as React from 'react';
import {ReactElement} from 'react';
import {observer} from "mobx-react";
import {IStoreProps} from "../../../helper/structs/IStoreProps";
import {LeftPanelMode} from "../../../store/SearchStore";
import {action} from "mobx";
import autoBindReact from "auto-bind/react";
import classNames from "classnames";
import {AgroButtonSvg} from "../../icons/Left/AgroButtonSvg";
import {Utils} from "../../../helper/utils/Utils";
import {MyHint} from "../../Common/MyHint";
import {CloudButtonSvg} from '../../icons/Left/CloudButtonSvg';

@observer
export class LeftSidebarLeftComp extends React.Component<IStoreProps, undefined> {
    constructor(props: IStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickSearch(){
        this.props.store.searchPanel.switchPanel(LeftPanelMode.search);
    }

    @action
    onClickFavorite(){
        this.props.store.searchPanel.switchPanel(LeftPanelMode.favorite);
    }
    @action
    onClickAgro(){
        this.props.store.searchPanel.switchPanel(LeftPanelMode.agro);
    }
    @action
    onClickTest(){
        this.props.store.searchPanel.switchPanel(LeftPanelMode.test);
        this.props.store.egrnStore.loadOrders();
    }
    @action
    onClickSolidGrid(){
        this.props.store.searchPanel.switchPanel(LeftPanelMode.soilGrid);
    }
    @action
    onClickOrders(){
        this.props.store.searchPanel.switchPanel(LeftPanelMode.orders);
    }

    @action
    onClickPhoto(){
        if (!this.photoEnable()) return;
        this.props.store.searchPanel.switchPanel(LeftPanelMode.photo);
    }
    @action
    onClickShowRoom(){
        this.props.store.searchPanel.switchPanel(LeftPanelMode.showRoom);
    }
    @action
    onClickShowRoom2(){
        this.props.store.searchPanel.switchPanel(LeftPanelMode.showRoom2);
    }
    photoEnable():boolean{
        return Utils.isStringNotEmpty(this.props.store.agro.projectName) && Utils.isStringNotEmpty(this.props.store.agro.datasetPhoto);
    }

    render() {
        let store = this.props.store;
        let leftMode = store.searchPanel.leftPanelMode;

        let children: ReactElement[] = [];
        if ((!store.config.rshbOrdersShow && store.config?.theme?.["logo-left"] == null)) {
            children.push(<svg key="rshb_logo" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14" cy="14" r="14" fill="#4DB6BC"/>
            <path d="M11.75 23H11C9.40925 22.9982 7.88416 22.3655 6.75933 21.2407C5.6345 20.1158 5.00179 18.5908 5 17L5 16.25H6.5V17C6.50119 18.1931 6.97568 19.337 7.81934 20.1807C8.66299 21.0243 9.80689 21.4988 11 21.5H11.75V23Z" fill="#2B3138"/>
            <path d="M11.75 20H11C10.2044 20 9.44129 19.6839 8.87868 19.1213C8.31607 18.5587 8 17.7956 8 17V16.25H9.5V17C9.5 17.3978 9.65804 17.7794 9.93934 18.0607C10.2206 18.342 10.6022 18.5 11 18.5H11.75V20Z" fill="#2B3138"/>
            <path d="M19.25 15.5H17.3105L16.5605 14.75L19.625 11.6855L17.9697 10.0302L19.0303 8.96975L20.6855 10.625L20.8137 10.496C21.2527 10.057 21.4994 9.46159 21.4994 8.84075C21.4994 8.21991 21.2527 7.6245 20.8137 7.1855C20.3748 6.7465 19.7793 6.49987 19.1585 6.49987C18.5377 6.49987 17.9422 6.7465 17.5033 7.1855L13.25 11.4395L12.5 10.6895V8.75L8.75 5L5.75 8L9.5 11.75H11.4395L12.1895 12.5L9.9395 14.75L13.25 18.0605L15.5 15.8105L16.25 16.5605V18.5L20 22.25L23 19.25L19.25 15.5Z" fill="#2B3138"/>
        </svg>);
        }else{
            children.push(<img key="logo" src={store.config.theme["logo-left"]} style={{position:"relative", top: store.config?.theme?.["logo-left_relativeTop"]}}/>);
        }

        if (store.config.rshbOrdersShow){
            children.push(<React.Fragment key="rshb">
            <div key="rshb" className="spacer-15" style={{height: "12px"}}/>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="8" y="8" width="8" height="2" rx="1" transform="rotate(-180 8 8)" fill="#4DB6BC"/>
                <rect x="8" y="5" width="8" height="2" rx="1" transform="rotate(-180 8 5)" fill="#4DB6BC"/>
                <rect x="8" y="2" width="8" height="2" rx="1" transform="rotate(-180 8 2)" fill="#4DB6BC"/>
                <rect x="18" y="18" width="8" height="2" rx="1" transform="rotate(-180 18 18)" fill="#4DB6BC"/>
                <rect x="18" y="15" width="8" height="2" rx="1" transform="rotate(-180 18 15)" fill="#4DB6BC"/>
                <rect x="18" y="12" width="8" height="2" rx="1" transform="rotate(-180 18 12)" fill="#4DB6BC"/>
                <rect x="10" y="8" width="8" height="2" rx="0.999999" transform="rotate(-90 10 8)" fill="white"/>
                <rect x="13" y="8" width="8" height="2" rx="0.999999" transform="rotate(-90 13 8)" fill="white"/>
                <rect x="16" y="8" width="8" height="2" rx="0.999999" transform="rotate(-90 16 8)" fill="white"/>
                <rect y="18" width="8" height="2" rx="0.999999" transform="rotate(-90 0 18)" fill="white"/>
                <rect x="3" y="18" width="8" height="2" rx="0.999999" transform="rotate(-90 3 18)" fill="white"/>
                <rect x="6" y="18" width="8" height="2" rx="0.999999" transform="rotate(-90 6 18)" fill="white"/>
            </svg>
            <div className="spacer-23" style={{height: "35px"}}/>
        </React.Fragment>);
        }else{
            children.push(<div key="standard_space" className="spacer-23" style={{height: "35px"}}/>);
        }

        if (store.config.agroShow){ children.push(<React.Fragment key="agro">
            <MyHint text={store.trans.ObjectsFields} placement="right">
                <div className={classNames("icon_container white_svg", {"icon_grayBackground": leftMode == LeftPanelMode.agro})} onClick={this.onClickAgro}>
                    <AgroButtonSvg store={store} className={classNames({
                        "blue-svg-important": leftMode == LeftPanelMode.agro
                    })} />
                </div>
            </MyHint>
            <div className="spacer-5"/>
        </React.Fragment>);
        }
        if (store.config.searchImage) {
            children.push( <React.Fragment key={"searchImage"}>
                <div className="spacer-5"/>
            <MyHint text={store.trans["Image search"]} placement="right">
                <div className={classNames("icon_container icon_container_fill relative", {"active": leftMode == LeftPanelMode.search})} onClick={this.onClickSearch}>
                    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.625 18H4.5C3.30653 18 2.16193 17.5259 1.31802 16.682C0.474106 15.8381 0 14.6935 0 13.5V12.375H2.25V13.5C2.25 14.0967 2.48705 14.669 2.90901 15.091C3.33097 15.5129 3.90326 15.75 4.5 15.75H5.625V18Z"/>
                        <path d="M14.625 11.25H12.8407L11.4829 9.89213L16.1662 5.20876C16.3879 4.98715 16.5636 4.72407 16.6836 4.43453C16.8035 4.14498 16.8652 3.83466 16.8652 3.52126C16.8652 3.20786 16.8035 2.89753 16.6836 2.60799C16.5636 2.31845 16.3879 2.05536 16.1662 1.83376C15.9446 1.61215 15.6816 1.43636 15.392 1.31643C15.1025 1.1965 14.7921 1.13477 14.4787 1.13477C13.8458 1.13477 13.2388 1.3862 12.7912 1.83376L8.10787 6.51713L6.73875 5.14801L6.7275 3.39751L3.41775 0.0888824L0 3.50663L3.375 6.75001H5.15925L6.51712 8.10788L4.5 10.125L7.875 13.5L9.89212 11.4829L11.25 12.8408V14.625L14.6362 18.0113L17.9887 14.6599L14.625 11.25Z"/>
                    </svg>
                </div>
            </MyHint>

            </React.Fragment>);
            children.push(
                <React.Fragment key={"favorite"}>
                    <div className="spacer-5"/>
            <MyHint text={store.trans["Selected images"]} placement="right">
                <div className={classNames("icon_container icon_container_fill relative",{"active": leftMode == LeftPanelMode.favorite})}
                     onClick={this.onClickFavorite}>
                    <div className={classNames("star_left_sidebar gray",
                        {
                            "blue": leftMode == LeftPanelMode.favorite,
                            "animate__animated animate__bounceIn": store.searchPanel.has_animated_fav_icon
                        })}/>
                    {store.searchPanel.favoriteList.records.length > 0 &&
                    <span className={classNames(
                        "favorite_counter"/*, {"color-blue": leftMode == LeftPanelMode.favorite}*/)
                    }
                    >{store.searchPanel.favoriteList.records.length}</span>
                    }
                </div>
            </MyHint>
        </React.Fragment>);}
        if (store.config.photoShow){children.push(<React.Fragment key="photo">
            <div className="spacer-5"/>
            <MyHint text={store.trans.Photos} placement="right">
                <div className={classNames("icon_container icon_container_fill relative",
                    { "opacity30 def-cursor": !this.photoEnable(), "active": leftMode == LeftPanelMode.photo})} onClick={this.onClickPhoto}>
                    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 3.37502H13.6327L12.2625 0.622142C12.1686 0.434272 12.024 0.27643 11.8451 0.166482C11.6662 0.0565344 11.46 -0.00112964 11.25 1.67664e-05H6.75C6.54094 -8.06536e-05 6.33599 0.0580778 6.15814 0.167967C5.98029 0.277857 5.83658 0.43513 5.74312 0.622142L4.36725 3.37502H2.25C1.65326 3.37502 1.08097 3.61207 0.65901 4.03403C0.237053 4.45598 0 5.02828 0 5.62502L0 14.625C0 15.2218 0.237053 15.7941 0.65901 16.216C1.08097 16.638 1.65326 16.875 2.25 16.875H15.75C16.3467 16.875 16.919 16.638 17.341 16.216C17.7629 15.7941 18 15.2218 18 14.625V5.62502C18 5.02828 17.7629 4.45598 17.341 4.03403C16.919 3.61207 16.3467 3.37502 15.75 3.37502ZM9 13.5C8.33249 13.5 7.67997 13.3021 7.12495 12.9312C6.56993 12.5604 6.13735 12.0333 5.88191 11.4166C5.62646 10.7999 5.55962 10.1213 5.68985 9.46659C5.82008 8.8119 6.14151 8.21053 6.61351 7.73853C7.08552 7.26653 7.68688 6.94509 8.34157 6.81487C8.99626 6.68464 9.67486 6.75148 10.2916 7.00692C10.9083 7.26237 11.4354 7.69495 11.8062 8.24997C12.1771 8.80498 12.375 9.45751 12.375 10.125C12.375 11.0201 12.0194 11.8786 11.3865 12.5115C10.7536 13.1444 9.89511 13.5 9 13.5Z"/>
                    </svg>
                    {/*<svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.7529 7.06048C11.7082 6.97098 11.6404 6.89503 11.5565 6.84045C11.4727 6.78587 11.3758 6.75464 11.2759 6.74998C11.1757 6.74138 11.0751 6.76158 10.986 6.80822C10.8969 6.85486 10.823 6.92599 10.773 7.01323L8.33512 10.9125L6.58574 9.16423C6.52598 9.1065 6.45471 9.06203 6.37658 9.03373C6.29846 9.00543 6.21524 8.99393 6.13237 8.99998C6.04943 9.00825 5.96936 9.03484 5.89796 9.07784C5.82655 9.12083 5.76559 9.17916 5.7195 9.24861L3.46949 12.6236C3.41252 12.7083 3.37968 12.807 3.3745 12.909C3.36932 13.0109 3.392 13.1124 3.4401 13.2025C3.48819 13.2925 3.5599 13.3678 3.64753 13.4202C3.73515 13.4726 3.83539 13.5002 3.93749 13.5H14.0625C14.1584 13.5 14.2527 13.4754 14.3365 13.4287C14.4203 13.382 14.4907 13.3146 14.5411 13.233C14.5915 13.1514 14.6202 13.0582 14.6244 12.9624C14.6287 12.8666 14.6083 12.7712 14.5654 12.6855L11.7529 7.06048Z" />
                            <path d="M15.75 0H2.25C1.65326 0 1.08097 0.237053 0.65901 0.65901C0.237053 1.08097 0 1.65326 0 2.25L0 15.75C0 16.3467 0.237053 16.919 0.65901 17.341C1.08097 17.7629 1.65326 18 2.25 18H15.75C16.3467 18 16.919 17.7629 17.341 17.341C17.7629 16.919 18 16.3467 18 15.75V2.25C18 1.65326 17.7629 1.08097 17.341 0.65901C16.919 0.237053 16.3467 0 15.75 0V0ZM2.25 15.75V2.25H15.75V15.75H2.25Z" />
                            <path d="M5.625 6.75C6.24632 6.75 6.75 6.24632 6.75 5.625C6.75 5.00368 6.24632 4.5 5.625 4.5C5.00368 4.5 4.5 5.00368 4.5 5.625C4.5 6.24632 5.00368 6.75 5.625 6.75Z" />
                        </svg>*/}
                </div>
            </MyHint>

        </React.Fragment>);}
        if (store.config.s1_order_active) {children.push(<React.Fragment key="s1_order_active">
            <div className="spacer-5"/>
            <MyHint text={store.trans.Orders} placement="right">
                <div className={classNames("icon_container icon_container_fill relative", {"active": leftMode == LeftPanelMode.orders})} onClick={this.onClickOrders}>
                    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.875 18H1.125C0.45 18 0 17.55 0 16.875V3.375C0 2.7 0.45 2.25 1.125 2.25H4.5V4.5H2.25V15.75H15.75V10.125H18V16.875C18 17.55 17.55 18 16.875 18Z" />
                        <path d="M11.25 3.375C7.65 3.375 4.5 6.1875 4.5 11.25C5.7375 9.3375 7.2 7.875 11.25 7.875V11.25L18 5.625L11.25 0V3.375Z" />
                    </svg>
                </div></MyHint>
        </React.Fragment>);}

        if (!store.config.rshbOrdersShow && store.soilGrids.active) { children.push(<React.Fragment key="soilGrids">
            <div className="spacer-5"/>
            <MyHint text={store.trans["Soil grid"]} placement="right">
                <div className={classNames("icon_container icon_container_fill relative", {"active": leftMode == LeftPanelMode.soilGrid})} onClick={this.onClickSolidGrid}>
                    <svg width="16" height="16" viewBox="0 0 16 16"  xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.299989 15.7C0.499989 15.9 0.699989 16 0.999989 16C1.29999 16 1.49999 15.9 1.69999 15.7L3.49999 13.9C4.39999 14.3 5.69999 14.7 7.09999 14.7C8.69999 14.7 10.4 14.2 12 12.7C15.4 9.3 16 1.4 16 1.1C16 0.8 15.9 0.5 15.7 0.3C15.5 0.1 15.2 0 14.9 0C14.6 0 6.49999 0.8 3.29999 4C1.29999 6 0.999989 8.4 1.39999 10.4L7.39999 7.1L0.199989 14.3C-0.100011 14.7 -0.100011 15.3 0.299989 15.7Z"/>
                    </svg>
                </div>
            </MyHint>
        </React.Fragment>);
        }

        if (store.map.superMap && store.map.superMap.rootFolder && store.map.superMap.rootFolder.items.length > 0){
        children.push(<React.Fragment key="showroom">
            <div className="spacer-5"/>
            <div className={classNames("icon_container icon_container_fill relative", {"active": leftMode == LeftPanelMode.showRoom})} onClick={this.onClickShowRoom}>
                <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.977 4.887C15.9707 4.81912 15.9573 4.75208 15.937 4.687C15.9145 4.62182 15.8846 4.55942 15.848 4.501C15.8338 4.46684 15.8178 4.43346 15.8 4.401L12.8 0.401C12.707 0.27662 12.5862 0.175624 12.4474 0.10602C12.3085 0.0364156 12.1553 0.000116378 12 0L4 0C3.84475 0 3.69164 0.0361451 3.55279 0.105573C3.41393 0.175001 3.29315 0.275804 3.2 0.4L0.2 4.4C0.182246 4.43246 0.166223 4.46584 0.152 4.5C0.115173 4.55867 0.0853054 4.62142 0.063 4.687C0.0425803 4.75205 0.0291708 4.8191 0.023 4.887C0.0128849 4.92413 0.00520189 4.96187 0 5L0 14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H14C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0391 16 14.5304 16 14V5C15.9948 4.96187 15.9872 4.92412 15.977 4.887ZM8 13.5L5 10H7V7H9V10H11L8 13.5ZM3 4L4.5 2H11.5L13 4H3Z" />
                </svg>
            </div>
        </React.Fragment>);}

        if (store.config.showRoom2_active === true){
        children.push(<React.Fragment key="showroom2">
            <div className="spacer-5"/>
            <div className={classNames("icon_container icon_container_fill relative", {"active": leftMode == LeftPanelMode.showRoom2})} onClick={this.onClickShowRoom2}>
                <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.977 4.887C15.9707 4.81912 15.9573 4.75208 15.937 4.687C15.9145 4.62182 15.8846 4.55942 15.848 4.501C15.8338 4.46684 15.8178 4.43346 15.8 4.401L12.8 0.401C12.707 0.27662 12.5862 0.175624 12.4474 0.10602C12.3085 0.0364156 12.1553 0.000116378 12 0L4 0C3.84475 0 3.69164 0.0361451 3.55279 0.105573C3.41393 0.175001 3.29315 0.275804 3.2 0.4L0.2 4.4C0.182246 4.43246 0.166223 4.46584 0.152 4.5C0.115173 4.55867 0.0853054 4.62142 0.063 4.687C0.0425803 4.75205 0.0291708 4.8191 0.023 4.887C0.0128849 4.92413 0.00520189 4.96187 0 5L0 14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H14C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0391 16 14.5304 16 14V5C15.9948 4.96187 15.9872 4.92412 15.977 4.887ZM8 13.5L5 10H7V7H9V10H11L8 13.5ZM3 4L4.5 2H11.5L13 4H3Z" />
                </svg>
            </div>
        </React.Fragment>);
        }



        if (store.config.testShow){ children.push(<React.Fragment key={"testShow"}>
            <MyHint text="Test" placement="right">
                <div className="icon_container " onClick={this.onClickTest}>
                    <CloudButtonSvg className={classNames({
                        "blue-fill": leftMode == LeftPanelMode.test,
                        "white-fill": leftMode != LeftPanelMode.test
                    })} />
                </div>
            </MyHint>
            <div className="spacer-5"/>
        </React.Fragment>)}

/*        {store.config.emergencyShow &&
        <React.Fragment>
            <MyHint text={store.trans["Emergency"]} placement="right">
                <div className="icon_container " onClick={this.onClickEmergency}>
                    <EmergencySvg className={classNames({
                        "blue-fill": leftMode == LeftPanelMode.emergency,
                        "white-fill": leftMode != LeftPanelMode.emergency
                    })} />
                </div>
            </MyHint>
            <div className="spacer-5"/>
        </React.Fragment>}*/



        children = store.events.onLeftSidebarLeftChildren.call(children);

        return <div className="left-sidebar__left noselect">
            <div className="left-sidebar__left-top">
                {children}
            </div>
        </div>;
    }
}
