import * as React from 'react';
import autoBindReact from "auto-bind/react";
import ReactDatePicker from "react-datepicker";
import {action} from "mobx";
import {isDate, isFunction} from "lodash-es";
import {CustomInputComponent, ReactDoubleDatePicker2Comp} from "./ReactDoubleDatePicker2Comp";
import classNames from "classnames";
import "./CalendarComp.scss";
import { Utils } from '../../helper/utils/Utils';

export interface ICalendarCompProps {
    disabled?: boolean,
    open: boolean,
    value: Date,
    classConteiner?: string,
    classCalendar?: string,
    minDate? : Date,
    maxDate? : Date,
    excludeDateIntervals?: Array<{ start: Date; end: Date }>,
    onChange?: (date: Date)=>void,
    onOpen?: ()=>void,
    onClose?: ()=>void,
}

export class CalendarComp extends React.PureComponent<ICalendarCompProps, undefined> {
    constructor(props: ICalendarCompProps) {
        super(props);
        autoBindReact(this);
    }

    onBeginOpen(){
        if (isFunction(this.props.onOpen)) this.props.onOpen();
    }
    onBeginClose(){
        if (isFunction(this.props.onClose)) this.props.onClose();
    }

    @action
    handleFromChange(from: Date) {
        if (isDate(from)) {
            if (isFunction(this.props.onChange)) this.props.onChange(from);
        }else if (isFunction(this.props.onChange)) this.props.onChange(null);
        return true;
    }

    render() {
        return <div className={classNames(this.props.classConteiner)}>
            <ReactDatePicker
            tabIndex={1}
            open={this.props.open}
            disabled={this.props.disabled}
            onCalendarClose={this.onBeginClose}
            onCalendarOpen={this.onBeginOpen}
            onFocus={this.onBeginOpen}
            placeholderText="YYYY-MM-DD"
            className={classNames(this.props.classCalendar)}
            onChange={date => this.handleFromChange(date as Date)}
            selected={this.props.value}
            //selectsStart
            monthsShown={1}
            dateFormat={ReactDoubleDatePicker2Comp.FORMAT}
            showYearDropdown
            yearDropdownItemNumber={5}
            scrollableYearDropdown
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            excludeDateIntervals={this.props.excludeDateIntervals}
            popperPlacement="bottom-end"
            showPopperArrow={false}
            dayClassName={(date) => {
                let [nowday, valday, day] = [Utils.getDayOfYear(new Date()), 
                    this.props.value? Utils.getDayOfYear(this.props.value): -1, Utils.getDayOfYear(date)];
                let todaySelected = valday == nowday;
                if (todaySelected && day == valday) return "today-selected";
                if (!todaySelected && day == valday) return "nottoday-selected";
                if (!todaySelected && day == nowday) return "today-notselected";
                return undefined;
                }}
            customInput={
                <CustomInputComponent
                    onClick={this.onBeginOpen}
                    className='date-input date-input-item1'
                />
            }
        />
            {this.props.children}
    </div>;
    }
}
