import {ShowRoom2LayerPointCustomTool} from "./ShowRoom2LayerPointCustomTool";
import {FillPaint, LinePaint, MapboxGeoJSONFeature, SymbolLayout, SymbolPaint} from "maplibre-gl";

import {action} from "mobx";
import {ShowroomLayerType} from "../../dataShowroom2/ShowRoom2LayerStore";
import {DataShowRoomMode} from "../../dataShowroom2/ShowRoom2Store";
import {LoadStatus} from "../../../helper/structs/LoadStatus";

export class ShowRoom2LayerYieldMapGpTool extends ShowRoom2LayerPointCustomTool{
    @action
    loadPopupById(id: any, feature: MapboxGeoJSONFeature):void{
        this.store.dataShowRoomStore.mapPopup.showRoom2LayerName = ShowroomLayerType.yieldMapPerGP;
        let mp = this.store.dataShowRoomStore.mapPopup;
        mp.status = LoadStatus.ready;
        mp.data = {};
        mp.data["gp"] = feature.properties['gpname'];
        mp.data["crop"] = feature.properties['crop'];
        mp.data["yield"] = feature.properties['yield'];
        mp.data["district"] = feature.properties['distrname'];
        mp.data["state"] = feature.properties['statename'];
        mp.data["points"] = feature.properties['count'];
    }
    idlayerText(): string{
        return this.getLayerId()+"_text";
    }
    idlayerBorder(): string{
        return this.getLayerId()+"_border";
    }

    async addLayers() {
        let map = this.store.map.mapbox;
        let tileUrl = `${window.location.origin}/reports/yield/public.get_gp_yield/{z}/{x}/{y}.pbf?filter=${JSON.stringify(this.store.dataShowRoomStore.getFilters())}`;

        if (!map.getSource(this.getSourceId())){
            map.addSource(this.getSourceId(),{
                type: 'vector',
                tiles: [tileUrl],
                scheme: "xyz",
            });
        }
        //MBUtils.getNewColor
        if (!map.getLayer(this.layerIdPoints())){
            this.addLayer({
                id: this.layerIdPoints(),
                source: this.getSourceId(),
                "source-layer": 'public.get_gp_yield',
                type: 'fill',
                //maxzoom: 6,
                paint:<FillPaint>{
                    'fill-color':  ['case',
                        ['==',['get', 'crop'],'wheat'],
                        [
                            'interpolate',
                            ['linear'], ["to-number",['get', 'yield'],0],
                            1200,'rgba(217,84,87,0.5)',
                            2250,'rgba(255,213,0,0.5)',
                            3300,'rgba(7,159,0,0.5)',
                        ],
                        ['==',['get', 'crop'],'paddy'],
                        [
                            'interpolate',
                            ['linear'], ["to-number",['get', 'yield'],0],
                            1900,'rgba(217,84,87,0.5)',
                            3000,'rgba(255,213,0,0.5)',
                            4100,'rgba(7,159,0,0.5)',
                        ],
                        'rgba(51,36,21,0.5)'
                    ]
                }
            });
        }
        if (!map.getLayer(this.idlayerBorder())){
            this.addLayer({
                id: this.idlayerBorder(),
                source: this.getSourceId(),
                "source-layer": 'public.get_gp_yield',
                type: 'line',
                //maxzoom: 6,
                paint:<LinePaint>{
                    'line-color': ['case',
                        ['==',['get', 'crop'],'wheat'],
                        [
                            'interpolate',
                            ['linear'], ["to-number",['get', 'yield'],0],
                            1200,'rgb(217,84,87)',
                            2250,'rgb(255,213,0)',
                            3300,'rgb(7,159,0)',
                        ],
                        ['==',['get', 'crop'],'paddy'],
                        [
                            'interpolate',
                            ['linear'], ["to-number",['get', 'yield'],0],
                            1900,'rgb(217,84,87)',
                            3000,'rgb(255,213,0)',
                            4100,'rgb(7,159,0)',
                        ],
                        'rgb(51,36,21)'
                    ]
                }
            });
        }

        if (!map.getLayer(this.idlayerText())){
            this.addLayer({
                id: this.idlayerText(),
                source: this.getSourceId(),
                "source-layer": 'public.get_gp_yield',
                type: 'symbol',
                //minzoom: 7,
                layout: <SymbolLayout>{
                    'text-field': ["get", "yield"],
                    'text-size': 11,
                    'text-font': ["Open Sans Bold"],
                    //'icon-allow-overlap': true
                },
                paint: <SymbolPaint>{
                    'text-color': '#22282F',
                    'text-halo-color': 'rgba(225,225,225,0.88)',
                    'text-halo-width': 1,
                    'text-halo-blur':1,
                }
            });
        }
    }

    layerVisible(): boolean{
        return this.showRoomLayerStore.checked && this.store.dataShowRoomStore.dataShowRoomMode == DataShowRoomMode.Projects;
    }
    removeLayers() {
        let map = this.store.map.mapbox;
        if (map.getLayer(this.idlayerText())){
            this.removeLayer(this.idlayerText());
        }
        if (map.getLayer(this.idlayerBorder())){
            this.removeLayer(this.idlayerBorder());
        }
        super.removeLayers();
    }

}