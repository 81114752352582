import * as React from 'react';
import autoBindReact from "auto-bind/react";
import {observer} from "mobx-react";
import './AgroAhoTableUpload1Comp.scss'
import {action} from "mobx";
import classNames from "classnames";
import {AgroAhoDownloadFileSvg} from "./../AgroAhoDownloadSvg";
import {Utils} from "../../../../app/helper/utils/Utils";
import {IAgroAhoStoreProps} from "../../agroAhoPlugin";
import {AgroAhoStepsComp} from "./AgroAhoStepsComp";
import {DropDownComp, IDropDownItem} from "../../../../app/components/Common/DropDownComp";
import {AgroAhoDateComp} from "./AgroAhoDateComp";
import {AhoUploadTablePath} from "../../agroAhoUploadTableStore";
import {AgroAhoHelpFileSvg} from "../icons/AgroAhoHelpSvg";

@observer
export class AgroAhoTableUpload1Comp extends React.Component<IAgroAhoStoreProps, undefined> {
    constructor(props: IAgroAhoStoreProps) {
        super(props);
        autoBindReact(this);
    }

    @action
    onClickNext(){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        if (!this.canNext()) return;
        ustore.uploadNextClick();
    }

    @action
    onClickCancel(){
        let ustore = this.props.store.agroAhoStore.uploadTableStore;
        ustore.doUploadReset();
    }

    @action
    onDownloadSampleClick(){
        let url = `образец_данные_АХО.ods`;
        Utils.downloadFile(url);
    }

    @action
    onDownloadInstructionsClick(){
        let url = `Инструкция.pdf`;
        Utils.downloadFile(url);
    }

    onSelTourChange(tour: any){
        let store_ = this.props.store.agroAhoStore;
        let ustore = store_.uploadTableStore;
        let tstore = store_.tourStore;
        tstore.curTour = tour?.data;
        // console.log('tour', tour);
        ustore.ahoUploadTablePath = tstore.curTour?.tour_id > 0 ?
            AhoUploadTablePath.ExtTour : AhoUploadTablePath.NewTour;
    }

    onNewNameChange(e: any){
        let tstore = this.props.store.agroAhoStore.tourStore;
        tstore.newTourName = e?.currentTarget?.value;
        tstore.curTour.tour_name = tstore.newTourName;
    }

    onDateChange(date: Date){
        let store_ = this.props.store.agroAhoStore;
        let tstore = store_.tourStore;
        tstore.newTourDate = date;
        tstore.curTour.tour_date = store_.format_date2(tstore.newTourDate);
        // console.log('date', ustore.curTour.tour_date);
    }

    canNext(){
        let store_ = this.props.store.agroAhoStore;
        let tstore = store_.tourStore;
        let ustore = store_.uploadTableStore;
        let isExtTour = ustore.ahoUploadTablePath == AhoUploadTablePath.ExtTour &&
            !!tstore.curTour && tstore.curTour?.tour_id > 0;
        let isNewTour = ustore.ahoUploadTablePath == AhoUploadTablePath.NewTour &&
            !!tstore.newTourName && !!tstore.newTourDate;
        return isExtTour || isNewTour;
    }

    render() {
        let store = this.props.store;
        let store_ = this.props.store.agroAhoStore;
        let cstore = store_.cardStore;
        let ustore = store_.uploadTableStore;
        let tstore = store_.tourStore;

        let items: IDropDownItem[] = [{key: 0, data: tstore.newTour, value: store_.trans['Create a new tour']}];
        if (tstore.toursItems) tstore.toursItems.forEach(t=>{
            items.push({key: t.tour_id, data: t, value: `${t.tour_name} (${t.tour_date})`});
        });

        return <div className={classNames("AgroAhoTableUpload1Comp-main",{
            "AgroAhoTableUpload1Comp-hidden": !store_.ahoDialogShow})}>
            <div className="AgroAhoTableUpload1Comp-cover">
                <div className="AgroAhoTableUpload1Comp-topPanel">
                    <div className="AgroAhoTableUpload1Comp-titleCover">
                        <div className="AgroAhoTableUpload1Comp-title">{store_.trans['Step 1. Select a tour']}</div>
                        <AgroAhoStepsComp step={ustore.ahoUploadTableStage}/>
                    </div>
                    <div className="AgroAhoTableUpload1Comp-msg">
                        <span>{store_.trans['The data downloaded from the table file (AHO indicators, recommended application rates) must be assigned to a particular Tour. Grouping data by Tour allows, for example, to save a history of values for one cell over different seasons.']}</span>
                        {/*<span>{store_.trans['Data must be uploaded in OpenDocument (.ods) format. This format is supported in MS Excel, Google Sheets, OpenOffice and other applications. The table must have a column with sample numbers, and the sample numbers must correspond to the numbers of elementary sections/cells in the ASA Map.']}</span>*/}
                        {/*<span>{store.trans['Agrochemical data can be downloaded in OpenDocument (.ods) format (supported by MS Excel, Google Sheets, OpenOffice, etc.). The column names must match the']}</span>*/}
                        {/*<span className="AgroAhoTableUpload1Comp-link" onClick={this.onDownloadSampleClick}>{store.trans['pattern']}</span>*/}
                        {/*<span>{store.trans['. How to correct the original spreadsheet file from the lab is described in']}</span>*/}
                        {/*<span className="AgroAhoTableUpload1Comp-link" onClick={this.onDownloadInstructionsClick}>{store.trans['instructions']}.</span>*/}
                    </div>
                    <div className="AgroAhoTableUpload1Comp-line"/>

                    <div className="AgroAhoTableUpload1Comp-selTourCaption">{store_.trans['Tour selection']}</div>
                    <div className={classNames("AgroAhoTableUpload1Comp-selTour",
                        {"AgroAhoTableUpload1Comp-placeHolder": !tstore.curTour})}>
                        <DropDownComp items={items} placeHolder={store_.trans['Choose from existing tours or create a new one']}
                            onChange={this.onSelTourChange} currentKey={tstore.curTour?.tour_id}/>
                    </div>

                    <div className={classNames("AgroAhoTableUpload1Comp-newTourFields",
                        {"AgroAhoTableUpload1Comp-hidden": tstore.curTour?.tour_id != 0})}>
                        <div className="AgroAhoTableUpload1Comp-selTourCaption">{store_.trans['Tour name']}</div>
                        <input type="text" className="AgroAhoTableUpload1Comp-tourName text-box-editor"
                               value={tstore.newTourName} onChange={this.onNewNameChange}/>

                        <div className="AgroAhoTableUpload1Comp-selTourCaption">{store_.trans['Tour date']}</div>
                        <AgroAhoDateComp className="AgroAhoTableUpload1Comp-tourDate" onChange={this.onDateChange}
                                         selected={tstore.newTourDate}/>
                    </div>

                </div>
                <div className="AgroAhoTableUpload1Comp-bottomPanel">
                    {/*<div className="AgroAhoTableUpload1Comp-linkSample" onClick={this.onDownloadSampleClick}>*/}
                    {/*    <AgroAhoDownloadFileSvg />*/}
                    {/*    <span>{store.trans.Sample}</span>*/}
                    {/*</div>*/}
                    {/*<div className="AgroAhoTableUpload1Comp-linkInstructions" onClick={this.onDownloadInstructionsClick}>*/}
                    {/*    <AgroAhoDownloadFileSvg />*/}
                    {/*    <span>{store.trans.Instruction}</span>*/}
                    {/*</div>*/}
                    <div className="AgroAhoTableUpload1Comp-linkInstructions" onClick={this.onDownloadInstructionsClick}>
                        <AgroAhoHelpFileSvg/>
                        <span>{store.trans.Help}</span>
                    </div>
                    <div className="AgroAhoTableUpload1Comp-splitter"> </div>
                    <button className="large-button left-sidebar-cancel AgroAhoTableUpload1Comp-cancelButton button-jump"
                        onClick={this.onClickCancel}>{store.trans.Cancel}</button>
                    <button className={classNames("left-sidebar-active AgroAhoTableUpload1Comp-nextButton",
                         {"AgroAhoTableUpload1Comp-disabledButton": !this.canNext(), "button-jump": !!tstore.curTour})}
                         onClick={this.onClickNext}>{store_.trans.Next}</button>
                </div>
            </div>
        </div>
    }
}
