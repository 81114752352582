// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgroAhoEditorWaitPanelComp-main {
  position: absolute;
  top: 5px;
  right: 0;
  left: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
}

.AgroAhoEditorWaitPanelComp-window {
  background: #2B3138;
  border: 1px solid #3E4751;
  border-radius: 10px;
  width: 514px;
  height: 46px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  box-sizing: content-box;
  font-size: 11px;
}

.AgroAhoEditorWaitPanelComp-splitter {
  flex-grow: 1;
  border-right: 1px solid #3E4751;
  height: 16px;
  margin-right: 15px;
}

.AgroAhoEditorWaitPanelComp-exitButton {
  background: #3E4751;
  border-radius: 6px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  color: #C5C5C5;
}`, "",{"version":3,"sources":["webpack://./plugins/agroAho/AgroAhoPanel/map/AgroAhoEditorWaitPanelComp.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,QAAA;EACA,QAAA;EACA,OAAA;EACA,UAAA;EACA,aAAA;EACA,uBAAA;AACF;;AACA;EACE,mBAAA;EACA,yBAAA;EAEA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,uBAAA;EACA,eAAA;AACF;;AACA;EACE,YAAA;EACA,+BAAA;EACA,YAAA;EACA,kBAAA;AAEF;;AAAA;EACE,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,eAAA;EAEA,oCAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;AAEF","sourcesContent":[".AgroAhoEditorWaitPanelComp-main {\r\n  position: absolute;\r\n  top: 5px;\r\n  right: 0;\r\n  left: 0;\r\n  z-index: 3;\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n.AgroAhoEditorWaitPanelComp-window {\r\n  background: #2B3138;\r\n  border: 1px solid #3E4751;\r\n  //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\r\n  border-radius: 10px;\r\n  width: 514px;\r\n  height: 46px;\r\n  display: flex;\r\n  align-items: center;\r\n  padding: 0 15px;\r\n  box-sizing: content-box;\r\n  font-size: 11px;\r\n}\r\n.AgroAhoEditorWaitPanelComp-splitter {\r\n  flex-grow: 1;\r\n  border-right: 1px solid #3E4751;\r\n  height: 16px;\r\n  margin-right: 15px;\r\n}\r\n.AgroAhoEditorWaitPanelComp-exitButton {\r\n  background: #3E4751;\r\n  border-radius: 6px;\r\n  height: 30px;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  padding: 0 15px;\r\n  cursor: pointer;\r\n\r\n  font-family: 'Open Sans', sans-serif;\r\n  font-style: normal;\r\n  font-weight: 600;\r\n  font-size: 11px;\r\n  color: #C5C5C5;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
